import React, {createContext, useEffect, useState} from "react";
import Cookies from "universal-cookie";
import {JwtUtil} from "../base/util/JwtUtil";

export type Auth = {
	token?: string;
	username?: string;
	id?: number;
	name?: string;
	email?: string;
	language?: string;
	country?: string;
	roles?: string[];
	setToken: (token: string, expiration?: Date) => void;
	hasRole: (role: string) => boolean;
	isAdmin: () => boolean;
};

export const initialValue: Auth = {
	setToken: () => {},
	hasRole: () => {return false},
	isAdmin: () => {return false},
};

export const AuthContext = createContext<Auth>(initialValue);

export default function AuthProvider(props: any) {

	const TOKEN_COOKIE_NAME = "_auth";

	const cookies = new Cookies();

	const [token, setTokenInternal] = useState<string>(cookies.get(TOKEN_COOKIE_NAME));
	const [id, setId] = useState<number>();
	const [name, setName] = useState<string>();
	const [username, setUsername] = useState<string>();
	const [email, setEmail] = useState<string>();
	const [language, setLanguage] = useState<string>();
	const [country, setCountry] = useState<string>();
	const [roles, setRoles] = useState<string[]>([]);

	const refreshToken = () => {
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json', 'Authorization': ('Bearer ' + token)}
		};
		fetch('/api/auth/refresh', requestOptions).then(response => {
			response.text().then(data => {
				let jwt = JwtUtil.parse(data);
				setToken(data, new Date(jwt.exp * 1000));
			});
		});
	}

	useEffect(() => {
		let jwt = undefined;
		let expiration = undefined;
		if (token) {
			jwt = JwtUtil.parse(token);
			expiration = new Date(jwt.exp * 1000);
			let dayInMillis = 1000 * 60 * 60 * 24;
			let refreshAfter = new Date((jwt.exp * 1000) - (dayInMillis * 25));
			if (new Date() > refreshAfter) {
				refreshToken();
			} else {
				setTimeout(() => {
					refreshToken();
				}, dayInMillis);
			}
		}
		if (expiration && expiration > new Date()) {
			setId(jwt.id);
			setName(jwt.name);
			setUsername(jwt.username);
			setEmail(jwt.email);
			setLanguage(jwt.language);
			setCountry(jwt.country);
			setRoles(jwt.roles);
		} else {
			setId(undefined);
			setName(undefined);
			setUsername(undefined);
			setEmail(undefined);
			setLanguage(undefined);
			setCountry(undefined);
			setRoles([]);
		}
	}, [token]);

	const hasRole = (role: string): boolean => {
		return roles.includes(role);
	}

	const isAdmin = (): boolean => {
		return roles.includes('admin');
	}

	const setToken = (token: string, expiration?: Date) => {
		if (expiration) {
			cookies.set(TOKEN_COOKIE_NAME, token, {path: '/', expires: expiration});
		}
		if (!token) {
			cookies.remove(TOKEN_COOKIE_NAME);
		}
		setTokenInternal(token);
	}

	return (
		<AuthContext.Provider value={{token, id, name, username, email, language, country, roles, setToken, hasRole, isAdmin}}>
			{props.children}
		</AuthContext.Provider>
	)
};
