import React, {useState} from "react";
import {Card, Header, Icon, SemanticICONS} from "semantic-ui-react";
import {View} from "../../../base/enums/View";
import SkinEditor from "./skin/SkinEditor";
import {SkinDto} from "../../../build/generated-sources/dto/SkinDto";

interface EditorCard {
	icon: SemanticICONS,
	title: string,
	description: string,
	url: string,
	mode: string
}

interface EditorPromptProps {
	onSave: (skin: SkinDto, toastMessage?: string) => void
}

export default function EditorPrompt(props: EditorPromptProps) {

	const [mode, setMode] = useState<string>();

	const cards: EditorCard[] = [
		{
			icon: 'car',
			title: 'No Color Variations',
			description: 'Your skin is a single unique artwork.',
			url: View.PUBLISH_SKIN.path,
			mode: 'noColors'
		},
		{
			icon: 'tint',
			title: 'With Color Variations',
			description: 'You want to upload a skin with multiple color variations.',
			url: View.PUBLISH_SKIN.path,
			mode: 'colors'
		}
	];

	return (
		<>
			{
				!mode && <>
					<Header as={'h1'} textAlign={'center'}>Select Skin Type</Header>
					<Card.Group className={'editor-prompt'} centered>
						{
							cards.map(card => {
								return <Card key={card.icon} color={'blue'} style={{padding: '30px 40px 20px', height: '250px', color: 'black'}} onClick={() => setMode(card.mode)}>
									<Header as='h2' icon>
										<Icon name={card.icon}/>
										{card.title}
									</Header>
									<Card.Description textAlign={'center'}>{card.description}</Card.Description>
								</Card>
							})
						}
					</Card.Group>
				</>
			}
			{
				!!mode && <SkinEditor onSave={props.onSave} standalone enableColorVariations={mode === 'colors'} />
			}
		</>
	);
}
