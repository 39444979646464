import React, {useEffect} from "react";
import {Container, Header, Icon, List} from "semantic-ui-react";
import {View} from "../../../base/enums/View";
import {Link} from "react-router-dom";
import {Constant} from "../../../base/enums/Constant";

export default function PrivacyPolicy() {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Container>
			<Header as='h2'>
				<Icon name='eye slash' />
				<Header.Content>
					Privacy Policy
					<Header.Subheader>Last updated on 4th of June 2022</Header.Subheader>
				</Header.Content>
			</Header>

			<p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from {Constant.WEBSITE_URL_SHORT}.</p>

			<Header as='h4'>TL;DR</Header>
			<List bulleted>
				<List.Item>We don't use third-party advertising or share any data with such services.</List.Item>
				<List.Item>We only share the most necessary data (your name, email and country) with payment providers when purchasing items via their payment gate.</List.Item>
				<List.Item>You have the right to be forgotten. <Link to={View.ABOUT.path}>Contact us</Link> and we will delete your data from our servers.</List.Item>
				<List.Item>Your browser saves your shopping cart contents between sessions. This data stays on your computers until the moment you confirm your
					purchase.</List.Item>
				<List.Item>Your browser saves your credentials when you are logged in as an authorization token. This token is sent with every request to our servers
					to authenticate and authorize you and show data that is relevant to you.</List.Item>
				<List.Item>We log every attempt to Register, Login, Reset Password, Download a skin and Purchase anything through the site.</List.Item>
			</List>

			<Header as='h4'>Personal information we collect</Header>
			<p>
				When you visit our site, we don't automatically collect any information about your web browser or how you access our site. If you create an account on our site, we
				keep records of your orders, items you upload to our site, profile information you provide to us during registration or when editing your profile. We record timestamp
				of when such data is created or modified. We log every login and register attempt whether it's successful or not.
			</p>

			<p>
				Logged in users are identified via the following methods:
			</p>

			<List bulleted>
				<List.Item>
					Token containing your profile settings like email, username, country and language. This token is used to authorize every page request user makes to the Site
					and is not shared with any third-party service. Should you have cookies disabled, you will not be able to log in. This shouldn't prevent you from Downloading
					free skins or Purchasing skins anonymously.
				</List.Item>
			</List>

			<p>
				Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, email address,
				and country. This data is then associated with your user profile so you can access this data from your profile page at a later date.
			</p>

			<Header as='h4'>How we use your personal information</Header>
			<p>
				We use the order information that we collect to fulfill any orders placed through our site (including processing your payment information, delivering your
				order via email, and providing you with order confirmations). Additionally, we use this order information to:
			</p>

			<List bulleted>
				<List.Item>
					Communicate with you.
				</List.Item>
				<List.Item>
					Screen our orders for potential risk or fraud.
				</List.Item>
				<List.Item>
					When in line with the preferences you have shared with us, provide you with latest news and items available on our Site.
				</List.Item>
			</List>

			<Header as='h4'>Sharing you personal information</Header>
			<p>
				We may share some of your personal information with third party payment providers should you decide to use such payment method when submitting an order. Finally, we
				may also share your personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful
				request for information we receive, or to otherwise protect our rights.
			</p>

			<Header as='h4'>Tracking and advertising</Header>
			<p>
				This site contains no third-party advertising. We don't track your movement to, from or on the site.
			</p>

			<Header as='h4'>Your rights</Header>
			<p>
				If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated,
				or deleted. If you would like to exercise this right, please <Link to={View.ABOUT.path}>contact us</Link>. While residents of other countries don't inherently share
				this right, they may also <Link to={View.ABOUT.path}>contact us</Link>.
			</p>

			<p>
				Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if
				you make an order through the Site), or otherwise to pursue our legitimate interests listed above. Additionally, please note that some of your information may be
				transferred outside of Europe when paying any of your orders via third-party payment services.
			</p>

			<Header as='h4'>Data retention</Header>
			<p>
				When you place an order through the Site, we will keep your order information within our system unless and until you ask us to delete this information. This is to
				allow you to access every purchased item at a later date should you lose it from your devices. We keep submissions from all authors, their preview pictures and all
				information about the artwork. This is to present their work on this site and to enable players to download it.
			</p>

			<Header as='h4'>Changes</Header>
			<p>
				We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory
				reasons.
			</p>

			<Header as='h4'>Contact us</Header>
			<p>
				If you have any questions or need more information about our privacy policies, or if you would like to make a complaint, please <Link to={View.ABOUT.path}>contact
				us</Link>.
			</p>
		</Container>
	);
}
