import React, {useState} from "react";
import {Button, Form, Grid, Header, Icon, Segment, Statistic} from "semantic-ui-react";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import {useGetAllPayouts} from "../../build/generated-sources/service/QueryService";
import PageMapper from "../../base/mapper/PageMapper";
import {UseGetAllPayoutsData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {Formatter} from "../../base/util/Formatter";
import PaginationControls from "../base/component/PaginationControls";
import {AuthorPayoutBatchDto} from "../../build/generated-sources/dto/AuthorPayoutBatchDto";
import AuthorPayoutBatchMapper from "../../base/mapper/AuthorPayoutBatchMapper";
import PayoutInformation from "../profile/components/PayoutInformation";
import {EPayoutStatus} from "../../build/generated-sources/enum/EPayoutStatus";
import {AuthorPayoutBatchFilterUtil} from "../../base/util/AuthorPayoutBatchFilterUtil";
import {useLockPayout, useSendPayout} from "../../build/generated-sources/service/MutationService";
import {UseLockPayoutData, UseSendPayoutData} from "../../build/generated-sources/service/MutationServiceModel";
import {success} from "@redrobot/npm-core-generator-builder/dist/graphql-builder/base/util/ConsoleUtils";
import {SyncOperationState} from "../../base/state/SyncOperationState";
import {toast} from "react-hot-toast";
import {View} from "../../base/enums/View";
import {useHistory} from "react-router-dom";
import {PayoutUtil} from "../../base/util/PayoutUtil";

export default function AdministrationPayouts() {

	const history = useHistory();
	const [syncHideState, setSyncHideState] = useState<SyncOperationState>({
		inProgress: false
	});
	const [status, setStatus] = useState<string>('all');
	const [list, setList] = useState<AuthorPayoutBatchDto[]>([]);
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 20,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	useGetAllPayouts({
		pageInfo: PageMapper.ALL,
		content: AuthorPayoutBatchMapper.ALL
	}, {
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize
			},
			status: status
		},
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetAllPayoutsData) => {
			setList(data.allPayouts.content);
			setPageInfo(data.allPayouts.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const [lockPayout] = useLockPayout({
			id: true,
			success: true
		}, {
			onCompleted: (data: UseLockPayoutData) => {
				setSyncHideState({inProgress: false});
				if (!success) {
					return;
				}
				let updatedList : AuthorPayoutBatchDto[] = Object.assign([], list);
				setList(updatedList.map(row => row.id === data.lockPayout.id ? {...row, payoutStatus: EPayoutStatus.LOCKED} : row));
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	const [sendPayout] = useSendPayout({
			id: true,
			success: true
		}, {
			onCompleted: (data: UseSendPayoutData) => {
				setSyncHideState({inProgress: false});
				if (!success) {
					return;
				}
				let updatedList : AuthorPayoutBatchDto[] = Object.assign([], list);
				setList(updatedList.map(row => row.id === data.sendPayout.id ? {...row, payoutStatus: EPayoutStatus.SENT} : row));
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	const lock = (id: number) => {
		setSyncHideState({
			id: id,
			inProgress: true
		});

		toast.promise(
			lockPayout({
				variables: {
					id: id
				}
			}),
			{
				loading: 'Locking Payout ' + id + ' manually',
				success: <strong>Payout locked</strong>,
				error: <strong>Error locking the payout.</strong>,
			}
		);
	}

	const send = (id: number) => {
		setSyncHideState({
			id: id,
			inProgress: true
		});

		toast.promise(
			sendPayout({
				variables: {
					id: id
				}
			}),
			{
				loading: 'Sending Payout ' + id + ' manually',
				success: <strong>Payout sent</strong>,
				error: <strong>Error sending the payout.</strong>,
			}
		);
	}

	const handleStatusChange = (data: any) => {
		setStatus(data);
	}

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='users'/>
				<Header.Content>
					Payouts
					<Header.Subheader>List of all payouts across site</Header.Subheader>
				</Header.Content>
			</Header>
			<Form>
				<Form.Select options={AuthorPayoutBatchFilterUtil.getAuthorPayoutBatchFilterOptions()}
							 style={{maxWidth: '200px'}}
							 label={'Status'}
							 onChange={(e, data) => handleStatusChange(data.value)}
							 value={status}
							 type={'text'}/>
			</Form>
			<br/>
			{
				list.length === 0 ? <Segment placeholder>
						<Header icon>
							<Icon name='search'/> There are no payouts {status !== 'all' && 'of this type'} yet. {status === 'all' && <span>Either noone bought your skin yet, or you don't have any paid skins.</span>}
						</Header>
					</Segment>
					:
					<>
						{
							list.map(payout => {
								return <div style={{marginBottom: '20px'}} key={payout.id}>
									<Segment onClick={() => history.push(View.ADMIN_PAYOUT.path.replace(":id", payout.id.toString()))} className={'payout clickable'} color={PayoutUtil.getColor(payout.payoutStatus)}>
										<Grid stackable>
											<Grid.Column width={6}>
												<PayoutInformation payout={payout} />
											</Grid.Column>
											<Grid.Column width={5} style={{margin: 'auto'}}>
												<Statistic.Group style={{justifyContent: 'center'}}>
													<Statistic>
														<Statistic.Value>
															{
																payout.amount ? Formatter.money(payout.amount) : Formatter.money(payout.items.map(item => item.authorReward).reduce((a, b) => a + b, 0))
															}
														</Statistic.Value>
														<Statistic.Label>{payout.amount ? 'Total payout' : 'Estimated payout'}</Statistic.Label>
													</Statistic>
												</Statistic.Group>
											</Grid.Column>
											<Grid.Column width={3} style={{margin: 'auto'}}>
												{EPayoutStatus.PENDING === payout.payoutStatus && <Button loading={syncHideState.inProgress && syncHideState.id === payout.id} disabled={syncHideState.inProgress} onClick={() => lock(payout.id)}><Icon name={'lock'}/> Lock</Button>}
												{EPayoutStatus.LOCKED === payout.payoutStatus && <Button loading={syncHideState.inProgress && syncHideState.id === payout.id} disabled={syncHideState.inProgress} onClick={() => send(payout.id)}><Icon name={'send'}/> Send</Button>}
											</Grid.Column>
										</Grid>
									</Segment>
								</div>
							})
						}

					<PaginationControls current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
						setPageInfo({
							...pageInfo,
							pageNumber: page
						});
					}}/>
				</>
			}
		</>
	);
}
