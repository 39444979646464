import {SkinInputDtoInput} from "../../build/generated-sources/dto/SkinInputDtoInput";
import {Constant} from "../enums/Constant";
import {Asset} from "../dto/Asset";

export class SkinUtil {

	public static nameValid = (skin : SkinInputDtoInput) : boolean  => {
		if (!skin.title || skin.title.length < Constant.MIN_SKIN_NAME_LENGTH || skin.title.length > Constant.MAX_SKIN_NAME_LENGTH) {
			return false;
		}
		return true;
	}

	public static descriptionValid = (skin : SkinInputDtoInput) : boolean  => {
		if (skin.description && skin.description.length > Constant.MAX_SKIN_DESCR_LENGTH) {
			return false;
		}
		return true;
	}

	public static priceValid = (skin : SkinInputDtoInput) : boolean  => {
		if (skin.price < 0 || (skin.price > 0 && skin.price < 2) || skin.price > Constant.MAX_SKIN_PRICE) {
			return false;
		}
		return true;
	}

	public static imagesExist = (images : Asset[]) : boolean  => {
		if (images.length === 0) {
			return false;
		}
		return true;
	}

	public static imagesValid = (images : Asset[]) : boolean  => {
		if (images.filter(i => (!i.id || i.uploading || (!i.uploading && !i.success))).length > 0) {
			return false;
		}
		return true;
	}

	public static sourceValid = (source : Asset) : boolean => {
		if (!source || !source.id || source.uploading || (!source.uploading && !source.success)) {
			return false;
		}
		return true;
	}

	public static validate(skin : SkinInputDtoInput, images : Asset[], source : Asset, id?: string): boolean {
		if (!SkinUtil.imagesExist(images)) {
			return false;
		}
		if (!SkinUtil.imagesValid(images)) {
			return false;
		}
		if (!id && !source) {
			return false;
		}
		if (!id && !SkinUtil.sourceValid(source)) {
			return false;
		}
		if (!SkinUtil.nameValid(skin)) {
			return false;
		}
		if (!SkinUtil.descriptionValid(skin)) {
			return false;
		}
		if (!SkinUtil.priceValid(skin)) {
			return false;
		}
		return true;
	}
}
