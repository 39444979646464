import {Button, Icon} from "semantic-ui-react";
import React, {useContext} from "react";
import {ShoppingCart, ShoppingCartContext} from "../../../contexts/ShoppingCartContext";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {BundleDto} from "../../../build/generated-sources/dto/BundleDto";
import {OwnedBundles, OwnedBundlesContext} from "../../../contexts/OwnedBundlesContext";

interface GetBundleButtonProps {
	bundle: BundleDto
	onClick: () => void
}

export default function GetBundleButton(props: GetBundleButtonProps) {

	const auth = useContext<Auth>(AuthContext);
	const cart = useContext<ShoppingCart>(ShoppingCartContext);
	const ownedBundles = useContext<OwnedBundles>(OwnedBundlesContext);

	const cartContainsBundle = cart.containsBundle(props.bundle);
	const owned = ownedBundles.hasBundle(props.bundle.id);
	const myBundle = props.bundle.author.id === auth.id;

	return (
		<>
			{
				myBundle && <Button color='black' animated='fade' onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
					props.onClick();
				}}>
					<Button.Content visible>&nbsp;&nbsp;&nbsp;&nbsp;My Bundle&nbsp;&nbsp;&nbsp;&nbsp;</Button.Content>
					<Button.Content hidden><Icon name='download'/>Contents</Button.Content>
				</Button>
			}
			{
				owned && !myBundle && <Button color='black' animated='fade' onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
					props.onClick();
				}}>
					<Button.Content visible>&nbsp;&nbsp;&nbsp;&nbsp;Owned&nbsp;&nbsp;&nbsp;&nbsp;</Button.Content>
					<Button.Content hidden><Icon name='download'/>Contents</Button.Content>
				</Button>
			}
			{
				!owned && !myBundle && cartContainsBundle && props.bundle.price > 0 &&
				<Button disabled color='black' animated='fade' onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
				}}>
					<Button.Content><Icon name='shop'/> In cart &nbsp;&nbsp;&nbsp;</Button.Content>
				</Button>
			}
			{
				!owned && !myBundle && !cartContainsBundle && props.bundle.price > 0 &&
				<Button color='black' animated='fade' onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
					cart.addBundle(props.bundle);
				}}>
					<Button.Content visible>&nbsp;&nbsp;&nbsp;{props.bundle.price} €&nbsp;&nbsp;&nbsp;</Button.Content>
					<Button.Content hidden><Icon name='shop'/></Button.Content>
				</Button>
			}
			{
				!owned && !myBundle && props.bundle.price === 0 &&
				<Button color='black' animated='fade' onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
					props.onClick();
				}}>
					<Button.Content visible>&nbsp;&nbsp;&nbsp;&nbsp;Free&nbsp;&nbsp;&nbsp;&nbsp;</Button.Content>
					<Button.Content hidden><Icon name='download'/>Contents</Button.Content>
				</Button>
			}
		</>
	)
}
