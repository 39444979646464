import React, {useEffect} from "react";
import {Container, Grid, Header, Icon, List, Message, Table} from "semantic-ui-react";
import {Constant} from "../../../base/enums/Constant";

export default function AuthorPayoutSystem() {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Container className={'author-payout-system'}>
			<Grid>
				<Grid.Column width={16}>
					<Header as='h2'>
						<Icon name='gem'/>
						<Header.Content>
							How does the Author Payout system work?
							<Header.Subheader>Last updated on 18th of September 2023</Header.Subheader>
						</Header.Content>
					</Header>
					<List bulleted>
						<List.Item>
							Every paid skin you put on this site will be available to players immediately after you upload it.
						</List.Item>
						<List.Item>
							Every purchase of your skins and bundles can be monitored via the <strong>Payouts page</strong> in your profile. This section in
							profile will become visible after you upload your first paid skin. You will be able to see how many sales your skins and bundles
							made and how much money you can expect upon the next payout date.
						</List.Item>
						<List.Item>
							The site collects all purchase made under your profile (which you can view via the <strong>Payouts page</strong>) and
							will send it to you together in a single batch depending on your selected Payout method:
							<List bulleted>
								<List.Item>
									<strong>Bank transfer:</strong> Approximately once every half a month to your bank account. System can handle this automatically.
								</List.Item>
								<List.Item>
									<strong>PayPal:</strong> Once in a while upon your request. This has to be handled manually from our side and as such we ask you for
									patience when processing these requests.
								</List.Item>
							</List>
						</List.Item>
						<List.Item>
							You must select Payout method and fill your bank account or PayPal information on your <strong>Profile settings</strong> page once you
							upload a skin. Until you do this the site doesn't know where to send you the money and will keep collecting all purchase made under your profile
							(you will not lose anything during that time) and delay payouts until it has all the necessary information available.
						</List.Item>
						<List.Item>
							Batch is a collection of money gathered via sales of your skins. This batch is "locked" couple of days before the actual payout
							happens to enable payment gates (like Stripe and PayPal) to process
							payments in time and transfer the money to us so we have the funds to send you. Every new purchase made after this "lock"
							occurs is collected under a new batch which will be processed as part of the next payout batch.
						</List.Item>
						<List.Item>
							Some money will be deducted from every purchase. More information on this in <strong>Fees</strong> section below.
						</List.Item>
					</List>
					<Message warning>
						<p>
							<Icon name={'warning'}/> Payouts via bank transfer for <strong>authors outside EEA*</strong> will be sent out only after they are <strong>above {Constant.MIN_PAYOUT_AMOUNT}€</strong> due
							to fees setup by my current bank for transactions to banks outside EEA. This fee is around 8€. The limit is there to make sure you can get most of what
							belongs to you.
						</p>
						<p>
							You will be able to change this limit in the near future at your own discretion. It's an arbitrary number I picked, but it may not suit your needs.
							This won't make the bank fee go away, but it will give you more options.
						</p>
						<p>
							<strong>These authors are encouraged to make use of the PayPal payout method.</strong>
						</p>
					</Message>
				</Grid.Column>
				<Grid.Column width={16}>
					<strong>*EEA = European Economic Area</strong> (see <a href={"https://en.wikipedia.org/wiki/European_Economic_Area"} rel={'noreferrer'} target={'_blank'}>Wikipedia page</a> for complete list of member countries)
				</Grid.Column>
				<Grid.Column width={16}>
					<Header as='h2'>
						<Icon name='money bill alternate'/>
						<Header.Content>
							Fees
						</Header.Content>
					</Header>
					<p>
						As much as I would love authors to receive <strong>all</strong> the money that belongs to them, the fact is the payment gates that allow us to pay
						for skins comfortably take a considerable piece of the pie. And as much as I would love to run this place for free for everyone to enjoy,
						the server providers don't feel the same way and I have to pay them from my own pocket to run the server. This is why the site will also
						take a small piece of the pie to cover these costs. I can't predict how many sales will happen through this site in the future, but if it
						becomes more than enough, the site's fees will be adjusted accordingly.
					</p>
					<p>
						There are alternatives to the current internal fee of {Constant.INTERNAL_FEE_PERCENTAGE}% to cover the server costs which <strong>might</strong> be
						implemented in the future. If these alternatives work well, it is possible the fees will be dropped entirely or may be used in other way as skin
						competition prize pools, giveaways or something similar. If this happens, it will be discussed with authors - they will decide.
					</p>
					<p>
						Proposed alternatives include (but are not limited to):
					</p>
					<List bulleted>
						<List.Item>
							<strong>a) Donation system.</strong> People who enjoy this site and want to support it could do so through some form of a donation. If there
							are enough donations, website's fees will be disabled as long as the server costs are taken care of.
						</List.Item>
						<List.Item>
							<strong>b) Lowering website fees due to high volume of sales.</strong> The cost of running the server is somewhat fixed. That means if there is
							enough money collected though fees, it will be possible to lower them and still cover the expenses.
						</List.Item>
					</List>
					<p>
						As the site gets bigger and bigger, the server costs will slowly, but gradually increase (more disk space = more money). I do not want
						to significantly limit authors as to what and how much they can upload to keep the quality of their work at maximum. This includes allowing them to
						present their work with high quality screenshots. I hope this will never change, but in the end, it is always about the money. I don't aim to make
						any money off of this site, but at the same time, if this is to be a long running project, I don't want to pay things out of my pocket. There is,
						unfortunately, no banana stand. We will have to work on this together to keep this basic principle intact.
					</p>
					<p>
						<strong>Anyway, let us look at fees setup by all involved parties:</strong>
					</p>
					<Table unstackable celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Fee Source</Table.HeaderCell>
								<Table.HeaderCell>Amount</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Row>
							<Table.Cell>Stripe (EEA players)</Table.Cell>
							<Table.Cell>{Constant.STRIPE_FEE_FIXED}€ + {Constant.STRIPE_FEE_PERCENTAGE_EU}%</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Stripe (UK players)</Table.Cell>
							<Table.Cell>{Constant.STRIPE_FEE_FIXED}€ + {Constant.STRIPE_FEE_PERCENTAGE_GB}%</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>Stripe (Non-EEA/Non-UK players)</Table.Cell>
							<Table.Cell>{Constant.STRIPE_FEE_FIXED}€ + {Constant.STRIPE_FEE_PERCENTAGE_OTHER}%</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>PayPal</Table.Cell>
							<Table.Cell><a href={'https://www.paypal.com/cz/webapps/mpp/merchant-fees'} rel={'noreferrer'} target={'_blank'}>PayPal Merchant Fees</a>*</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>{Constant.WEBSITE_TITLE} (Everyone)</Table.Cell>
							<Table.Cell>{Constant.INTERNAL_FEE_PERCENTAGE}%</Table.Cell>
						</Table.Row>
					</Table>
					<p>
						Stripe's pricing has couple more specifics - you can read all about them here: <a href={'https://stripe.com/pricing'} rel={'noreferrer'} target={'_blank'}>https://stripe.com/pricing</a>
					</p>
					<p>
						*I have never seen more incoherent set of information. PayPal has similar pricing model like Stripe (flat fee + percentage), but specific numbers are hidden somewhere in their
						pricing and I can't make sense of it.
					</p>
					<Message info>
						<Icon name={'edit outline'}/><strong>Let's try to look at some examples to get a better idea how this can play out so you can better setup your
						price plan.</strong> You can put any price tag on your artwork between 2€ and 500€, but the tables below are to give you an idea how things
						can play out with some reasonable price tags.
					</Message>

					<Header as='h3'>Example 1: Somebody from EEA buys your skin via Stripe</Header>

					<Table unstackable celled textAlign={'center'}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell><strong>Published Price</strong></Table.HeaderCell>
								<Table.HeaderCell><strong>Stripe Fee</strong></Table.HeaderCell>
								<Table.HeaderCell><strong>{Constant.WEBSITE_TITLE} Fee</strong></Table.HeaderCell>
								<Table.HeaderCell><strong>You receive</strong></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>

						{
							[2,5,10].map(i => {
								const stripeFixed = Constant.STRIPE_FEE_FIXED;
								const stripeEEA = Math.round(((i / 100) * Constant.STRIPE_FEE_PERCENTAGE_EU) * 100) / 100;
								const stripeTotal = Math.round((stripeFixed + stripeEEA) * 100) / 100;
								const internal = Math.round((i / 100) * Constant.INTERNAL_FEE_PERCENTAGE * 100) / 100;
								const totalEEA = Math.round((stripeFixed + stripeEEA + internal) * 100) / 100;
								const receivedEEA = i - totalEEA;
								const receivedEEAPercentage = Math.round(((100 / i) * receivedEEA) * 100) / 100;
								return <Table.Row>
									<Table.Cell><strong>{i}€</strong></Table.Cell>
									<Table.Cell>{stripeFixed}€ + {stripeEEA}€ = <strong>{stripeTotal}€</strong></Table.Cell>
									<Table.Cell>{internal}€</Table.Cell>
									<Table.Cell>{receivedEEA}€ (<strong>{receivedEEAPercentage}%</strong> from total price)</Table.Cell>
								</Table.Row>
							})
						}
						</Table.Body>
					</Table>

					<Header as='h3'>Example 2: Somebody outside of EEA buys your skin via Stripe</Header>

					<Table unstackable celled textAlign={'center'}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell><strong>Published Price</strong></Table.HeaderCell>
								<Table.HeaderCell><strong>Stripe Fee</strong></Table.HeaderCell>
								<Table.HeaderCell><strong>{Constant.WEBSITE_TITLE} Fee</strong></Table.HeaderCell>
								<Table.HeaderCell><strong>You receive</strong></Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>

						{
							[2,5,10].map(i => {
								const stripeFixed = Constant.STRIPE_FEE_FIXED;
								const stripeNonEEA = Math.round(((i / 100) * Constant.STRIPE_FEE_PERCENTAGE_OTHER) * 100) / 100;
								const stripeTotal = Math.round((stripeFixed + stripeNonEEA) * 100) / 100;
								const internal = Math.round((i / 100) * Constant.INTERNAL_FEE_PERCENTAGE * 100) / 100;
								const totalNonEEA = Math.round((stripeFixed + stripeNonEEA + internal) * 100) / 100;
								const receivedNonEEA = i - totalNonEEA;
								const receivedNonEEAPercentage = Math.round(((100 / i) * receivedNonEEA) * 100) / 100;
								return <Table.Row>
									<Table.Cell><strong>{i}€</strong></Table.Cell>
									<Table.Cell>{stripeFixed}€ + {stripeNonEEA}€ = <strong>{stripeTotal}€</strong></Table.Cell>
									<Table.Cell>{internal}€</Table.Cell>
									<Table.Cell>{receivedNonEEA.toFixed(2)}€ (<strong>{receivedNonEEAPercentage}%</strong> from total price)</Table.Cell>
								</Table.Row>
							})
						}
						</Table.Body>
					</Table>
					<p>
						As you can see, very small price tags suffer from Stripe's fixed fee, so keep that in mind when posting your work up for sale.
						This is also the reason why there is a limit of {Constant.MIN_SKIN_PRICE}€ as the lowest price you can post your artwork for. It
						is to protect you from Stripe eating most of the money.
					</p>
					<Message info>
						<p>
							<Icon name={'info circle'} /> While probably not very common, it is possible for players to buy multiple skins within the same purchase. In such case,
							the examples above no longer apply as the <strong>fees are calculated from the total price of the order</strong>. This will affect any fixed fee
							(such as Stripe's 0.3€ per purchase). It will be split evenly (weighted by the price of the skin) among the purchased items.
						</p>
						<p>
							<strong>Example: </strong> Player buys two skins from two different authors, one for 5€ the other for 10€. The fixed fee of {Constant.STRIPE_FEE_FIXED}€
							will be split among these two items in the following way: 0.1€ will be taken from the 5€ skin and 0.2€ will be taken from the 10€ skin.
						</p>
						<p>
							This is beneficial to the authors, as more money will reach them. How often will these types of orders happen is yet to be seen.
						</p>
					</Message>
				</Grid.Column>
			</Grid>
		</Container>
	);
}
