import {EPaymentStatus} from "../../build/generated-sources/enum/EPaymentStatus";

export interface PaymentStatusFilterOption {
	key?: string,
	value?: string,
	text: string
}

export class PaymentStatusFilterUtil {

	public static getPaymentStatusFilterOptions(): PaymentStatusFilterOption[] {
		return [
			{key: 'all', value: 'all', text: 'All'},
			{key: EPaymentStatus.BEFORE_CHECKOUT.toString(), value: EPaymentStatus.BEFORE_CHECKOUT.toString(), text: 'Before Checkout'},
			{key: EPaymentStatus.CANCELLED.toString(), value: EPaymentStatus.CANCELLED.toString(), text: 'Cancelled'},
			{key: EPaymentStatus.PAYMENT_ON_THE_WAY.toString(), value: EPaymentStatus.PAYMENT_ON_THE_WAY.toString(), text: 'Awaiting payment'},
			{key: EPaymentStatus.PAYMENT_RECEIVED.toString(), value: EPaymentStatus.PAYMENT_RECEIVED.toString(), text: 'Payment Received'},
		]
	}
}
