import {
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import {GraphqlBuilder, GraphQLType} from '@redrobot/npm-core-generator';
import { SimpleResponseDtoMapper } from '../dto/SimpleResponseDto'
import {UseDeleteSkinData, UseDeleteSkinVariables} from './MutationServiceModel'
import { SkinDtoMapper } from '../dto/SkinDto'
import {UseCreateSkinData, UseCreateSkinVariables} from './MutationServiceModel'
import { CommentDtoMapper } from '../dto/CommentDto'
import {UseDeleteCommentData, UseDeleteCommentVariables} from './MutationServiceModel'
import { UserDtoMapper } from '../dto/UserDto'
import {UseUpdateUserData, UseUpdateUserVariables} from './MutationServiceModel'
import { BundleDtoMapper } from '../dto/BundleDto'
import {UseCreateBundleData, UseCreateBundleVariables} from './MutationServiceModel'
import { SimpleIdDtoMapper } from '../dto/SimpleIdDto'
import {UseCreateSkinReportData, UseCreateSkinReportVariables} from './MutationServiceModel'
import {UseCreateCommentData, UseCreateCommentVariables} from './MutationServiceModel'
import {UseSendPayoutData, UseSendPayoutVariables} from './MutationServiceModel'
import {UseDeleteBundleData, UseDeleteBundleVariables} from './MutationServiceModel'
import {UseUpdateMyUserData, UseUpdateMyUserVariables} from './MutationServiceModel'
import {UseHideBundleData, UseHideBundleVariables} from './MutationServiceModel'
import {UseUpdateBundleData, UseUpdateBundleVariables} from './MutationServiceModel'
import { DataGraphResponse_BooleanMapper } from '../dto/DataGraphResponse_Boolean'
import {UsePublishSkinOnTwitterData, UsePublishSkinOnTwitterVariables} from './MutationServiceModel'
import { SkinRatingDtoMapper } from '../dto/SkinRatingDto'
import {UseRateSkinData, UseRateSkinVariables} from './MutationServiceModel'
import {UseCreateUserReportData, UseCreateUserReportVariables} from './MutationServiceModel'
import {UseUpdateSkinData, UseUpdateSkinVariables} from './MutationServiceModel'
import {UseLockPayoutData, UseLockPayoutVariables} from './MutationServiceModel'
import {UsePublishSkinOnInstagramData, UsePublishSkinOnInstagramVariables} from './MutationServiceModel'
import {UseHideSkinData, UseHideSkinVariables} from './MutationServiceModel'

export function useDeleteSkin(
  mapper: SimpleResponseDtoMapper,
  options?: MutationHookOptions<UseDeleteSkinData, UseDeleteSkinVariables>
): MutationTuple<UseDeleteSkinData, UseDeleteSkinVariables> {
  const mutationBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('deleteSkin')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(
        `url: $url`
      )
      .build(),
    options
  );
}


export function useCreateSkin(
  mapper: SkinDtoMapper,
  options?: MutationHookOptions<UseCreateSkinData, UseCreateSkinVariables>
): MutationTuple<UseCreateSkinData, UseCreateSkinVariables> {
  const mutationBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('createSkin')
      .setReturnProperties(mapper)
      .declareVariables({
        dto: 'SkinInputDtoInput',        
      })
      .setProperties(
        `dto: $dto`
      )
      .build(),
    options
  );
}


export function useDeleteComment(
  mapper: CommentDtoMapper,
  options?: MutationHookOptions<UseDeleteCommentData, UseDeleteCommentVariables>
): MutationTuple<UseDeleteCommentData, UseDeleteCommentVariables> {
  const mutationBuilder: GraphqlBuilder<CommentDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('deleteComment')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long',        
      })
      .setProperties(
        `id: $id`
      )
      .build(),
    options
  );
}


export function useUpdateUser(
  mapper: UserDtoMapper,
  options?: MutationHookOptions<UseUpdateUserData, UseUpdateUserVariables>
): MutationTuple<UseUpdateUserData, UseUpdateUserVariables> {
  const mutationBuilder: GraphqlBuilder<UserDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateUser')
      .setReturnProperties(mapper)
      .declareVariables({
        dto: 'UserInputDtoInput',        
      })
      .setProperties(
        `dto: $dto`
      )
      .build(),
    options
  );
}


export function useCreateBundle(
  mapper: BundleDtoMapper,
  options?: MutationHookOptions<UseCreateBundleData, UseCreateBundleVariables>
): MutationTuple<UseCreateBundleData, UseCreateBundleVariables> {
  const mutationBuilder: GraphqlBuilder<BundleDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('createBundle')
      .setReturnProperties(mapper)
      .declareVariables({
        dto: 'BundleInputDtoInput',        
      })
      .setProperties(
        `dto: $dto`
      )
      .build(),
    options
  );
}


export function useCreateSkinReport(
  mapper: SimpleIdDtoMapper,
  options?: MutationHookOptions<UseCreateSkinReportData, UseCreateSkinReportVariables>
): MutationTuple<UseCreateSkinReportData, UseCreateSkinReportVariables> {
  const mutationBuilder: GraphqlBuilder<SimpleIdDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('createSkinReport')
      .setReturnProperties(mapper)
      .declareVariables({
        arg0: 'HttpServletRequestInput',
        dto: 'SkinReportDtoInput',        
      })
      .setProperties(
        `arg0: $arg0,
        dto: $dto`
      )
      .build(),
    options
  );
}


export function useCreateComment(
  mapper: CommentDtoMapper,
  options?: MutationHookOptions<UseCreateCommentData, UseCreateCommentVariables>
): MutationTuple<UseCreateCommentData, UseCreateCommentVariables> {
  const mutationBuilder: GraphqlBuilder<CommentDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('createComment')
      .setReturnProperties(mapper)
      .declareVariables({
        skin: 'Long',
        text: 'String',
        bundle: 'Long',        
      })
      .setProperties(
        `skin: $skin,
        text: $text,
        bundle: $bundle`
      )
      .build(),
    options
  );
}


export function useSendPayout(
  mapper: SimpleResponseDtoMapper,
  options?: MutationHookOptions<UseSendPayoutData, UseSendPayoutVariables>
): MutationTuple<UseSendPayoutData, UseSendPayoutVariables> {
  const mutationBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('sendPayout')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long',        
      })
      .setProperties(
        `id: $id`
      )
      .build(),
    options
  );
}


export function useDeleteBundle(
  mapper: SimpleResponseDtoMapper,
  options?: MutationHookOptions<UseDeleteBundleData, UseDeleteBundleVariables>
): MutationTuple<UseDeleteBundleData, UseDeleteBundleVariables> {
  const mutationBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('deleteBundle')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(
        `url: $url`
      )
      .build(),
    options
  );
}


export function useUpdateMyUser(
  mapper: UserDtoMapper,
  options?: MutationHookOptions<UseUpdateMyUserData, UseUpdateMyUserVariables>
): MutationTuple<UseUpdateMyUserData, UseUpdateMyUserVariables> {
  const mutationBuilder: GraphqlBuilder<UserDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateMyUser')
      .setReturnProperties(mapper)
      .declareVariables({
        dto: 'UserInputDtoInput',        
      })
      .setProperties(
        `dto: $dto`
      )
      .build(),
    options
  );
}


export function useHideBundle(
  mapper: SimpleResponseDtoMapper,
  options?: MutationHookOptions<UseHideBundleData, UseHideBundleVariables>
): MutationTuple<UseHideBundleData, UseHideBundleVariables> {
  const mutationBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('hideBundle')
      .setReturnProperties(mapper)
      .declareVariables({
        hide: 'Boolean!',
        id: 'Long!',        
      })
      .setProperties(
        `hide: $hide,
        id: $id`
      )
      .build(),
    options
  );
}


export function useUpdateBundle(
  mapper: BundleDtoMapper,
  options?: MutationHookOptions<UseUpdateBundleData, UseUpdateBundleVariables>
): MutationTuple<UseUpdateBundleData, UseUpdateBundleVariables> {
  const mutationBuilder: GraphqlBuilder<BundleDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateBundle')
      .setReturnProperties(mapper)
      .declareVariables({
        dto: 'BundleInputDtoInput',        
      })
      .setProperties(
        `dto: $dto`
      )
      .build(),
    options
  );
}


export function usePublishSkinOnTwitter(
  mapper: DataGraphResponse_BooleanMapper,
  options?: MutationHookOptions<UsePublishSkinOnTwitterData, UsePublishSkinOnTwitterVariables>
): MutationTuple<UsePublishSkinOnTwitterData, UsePublishSkinOnTwitterVariables> {
  const mutationBuilder: GraphqlBuilder<DataGraphResponse_BooleanMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('publishSkinOnTwitter')
      .setReturnProperties(mapper)
      .declareVariables({
        dto: 'SocialMediaCreateRequestDtoInput',        
      })
      .setProperties(
        `dto: $dto`
      )
      .build(),
    options
  );
}


export function useRateSkin(
  mapper: SkinRatingDtoMapper,
  options?: MutationHookOptions<UseRateSkinData, UseRateSkinVariables>
): MutationTuple<UseRateSkinData, UseRateSkinVariables> {
  const mutationBuilder: GraphqlBuilder<SkinRatingDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('rateSkin')
      .setReturnProperties(mapper)
      .declareVariables({
        rating: 'Int!',
        skinId: 'Long!',        
      })
      .setProperties(
        `rating: $rating,
        skinId: $skinId`
      )
      .build(),
    options
  );
}


export function useCreateUserReport(
  mapper: SimpleIdDtoMapper,
  options?: MutationHookOptions<UseCreateUserReportData, UseCreateUserReportVariables>
): MutationTuple<UseCreateUserReportData, UseCreateUserReportVariables> {
  const mutationBuilder: GraphqlBuilder<SimpleIdDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('createUserReport')
      .setReturnProperties(mapper)
      .declareVariables({
        arg0: 'HttpServletRequestInput',
        dto: 'UserReportDtoInput',        
      })
      .setProperties(
        `arg0: $arg0,
        dto: $dto`
      )
      .build(),
    options
  );
}


export function useUpdateSkin(
  mapper: SkinDtoMapper,
  options?: MutationHookOptions<UseUpdateSkinData, UseUpdateSkinVariables>
): MutationTuple<UseUpdateSkinData, UseUpdateSkinVariables> {
  const mutationBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('updateSkin')
      .setReturnProperties(mapper)
      .declareVariables({
        dto: 'SkinInputDtoInput',        
      })
      .setProperties(
        `dto: $dto`
      )
      .build(),
    options
  );
}


export function useLockPayout(
  mapper: SimpleResponseDtoMapper,
  options?: MutationHookOptions<UseLockPayoutData, UseLockPayoutVariables>
): MutationTuple<UseLockPayoutData, UseLockPayoutVariables> {
  const mutationBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('lockPayout')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long',        
      })
      .setProperties(
        `id: $id`
      )
      .build(),
    options
  );
}


export function usePublishSkinOnInstagram(
  mapper: DataGraphResponse_BooleanMapper,
  options?: MutationHookOptions<UsePublishSkinOnInstagramData, UsePublishSkinOnInstagramVariables>
): MutationTuple<UsePublishSkinOnInstagramData, UsePublishSkinOnInstagramVariables> {
  const mutationBuilder: GraphqlBuilder<DataGraphResponse_BooleanMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('publishSkinOnInstagram')
      .setReturnProperties(mapper)
      .declareVariables({
        dto: 'SocialMediaCreateRequestDtoInput',        
      })
      .setProperties(
        `dto: $dto`
      )
      .build(),
    options
  );
}


export function useHideSkin(
  mapper: SimpleResponseDtoMapper,
  options?: MutationHookOptions<UseHideSkinData, UseHideSkinVariables>
): MutationTuple<UseHideSkinData, UseHideSkinVariables> {
  const mutationBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.MUTATION);
  return useMutation(
    mutationBuilder
      .setName('hideSkin')
      .setReturnProperties(mapper)
      .declareVariables({
        hide: 'Boolean!',
        id: 'Long!',        
      })
      .setProperties(
        `hide: $hide,
        id: $id`
      )
      .build(),
    options
  );
}


