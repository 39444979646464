import React from "react";
import {Grid, Header, Icon, Segment} from "semantic-ui-react";
import {View} from "../../../base/enums/View";
import {Link} from "react-router-dom";
import {Constant} from "../../../base/enums/Constant";

export default function Footer() {

	return (
		<Segment className={'footer'}>
			<Grid stackable columns={3}>
				<Grid.Column style={{lineHeight: 1.4}}>
					<Header style={{color: 'white'}}>{Constant.WEBSITE_TITLE}</Header>
					<a rel="noreferrer" className={'icons'} href={Constant.TWITTER_URL} target={'_blank'}><Icon size={'large'} name={'twitter'}/></a>&nbsp;&nbsp;&nbsp;
					<a rel="noreferrer" className={'icons'} href={Constant.INSTAGRAM_URL} target={'_blank'}><Icon size={'large'} name={'instagram'}/></a>&nbsp;&nbsp;&nbsp;
					{/*<a rel="noreferrer" className={'icons'} href={Constant.DISCORD_URL} target={'_blank'}><Icon size={'large'} name={'discord'}/></a>&nbsp;&nbsp;&nbsp;*/}
					<a rel="noreferrer" className={'icons'} href={'mailto:' + Constant.WEBSITE_EMAIL} target={'_blank'}><Icon size={'large'} name={'mail'}/></a><br/><br/>
					<span>This site is not affiliated with or endorsed by Nadeo or Ubisoft. All trademarks belong to their respective owners.</span>
				</Grid.Column>
				<Grid.Column className={'links'} style={{fontWeight: 'bold'}}>
					<Link to={View.ABOUT.path}>About</Link><br/>
					<Link to={View.BLOG.path}>Blog</Link><br/>
					<Link to={View.FAQ.path}>FAQ</Link><br/>
					<Link to={View.INSTALLATION_GUIDE.path}>Installation Guide</Link><br/>
					<Link to={View.ROADMAP.path}>Roadmap</Link>
				</Grid.Column>
				<Grid.Column className={'links'} style={{fontWeight: 'bold'}}>
					<Link to={View.ABOUT_AUTHOR_PAYOUTS.path}>Payout System</Link><br/>
					<Link to={View.PRIVACY_POLICY.path}>Privacy Policy</Link><br/>
					<Link to={View.REFUND_POLICY.path}>Refund Policy</Link><br/>
					<Link to={View.SUBMISSIONS_RULES.path}>Rules for Submission</Link><br/>
					<Link to={View.TERMS_OF_SERVICE.path}>Terms of Service</Link>
				</Grid.Column>
			</Grid>
		</Segment>
	);
}
