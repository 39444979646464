export interface CountrySelection {
	key: string,
	value: string,
	flag: string,
	text: string,
}

export class Country {

	static getByKey = (key: string): CountrySelection => {
		return Country.ALL_COUNTRIES_MAP[key];
	}

	static _isEEU = (country: string) => {
		switch (country) {
			case Country.AT.key:
			case Country.BE.key:
			case Country.BG.key:
			case Country.HR.key:
			case Country.CY.key:
			case Country.CZ.key:
			case Country.DK.key:
			case Country.EE.key:
			case Country.FI.key:
			case Country.FR.key:
			case Country.DE.key:
			case Country.GR.key:
			case Country.HU.key:
			case Country.IS.key:
			case Country.IE.key:
			case Country.IT.key:
			case Country.LV.key:
			case Country.LI.key:
			case Country.LT.key:
			case Country.LU.key:
			case Country.MT.key:
			case Country.NL.key:
			case Country.NO.key:
			case Country.PL.key:
			case Country.PT.key:
			case Country.RO.key:
			case Country.SK.key:
			case Country.SI.key:
			case Country.ES.key:
			case Country.SE.key:
				return true;
			default:
				return false;
		}
	}

	static isEEU = (country: CountrySelection) => {
		return Country._isEEU(country.key);
	}

	static AF: CountrySelection = {key: 'af', value: 'af', flag: 'af', text: 'Afghanistan'};
	static AL: CountrySelection = {key: 'al', value: 'al', flag: 'al', text: 'Albania'};
	static DZ: CountrySelection = {key: 'dz', value: 'dz', flag: 'dz', text: 'Algeria'};
	static AD: CountrySelection = {key: 'ad', value: 'ad', flag: 'ad', text: 'Andorra'};
	static AO: CountrySelection = {key: 'ao', value: 'ao', flag: 'ao', text: 'Angola'};
	static AR: CountrySelection = {key: 'ar', value: 'ar', flag: 'ar', text: 'Argentina'};
	static AM: CountrySelection = {key: 'am', value: 'am', flag: 'am', text: 'Armenia'};
	static AU: CountrySelection = {key: 'au', value: 'au', flag: 'au', text: 'Australia'};
	static AT: CountrySelection = {key: 'at', value: 'at', flag: 'at', text: 'Austria'};
	static AZ: CountrySelection = {key: 'az', value: 'az', flag: 'az', text: 'Azerbaijan'};
	static BS: CountrySelection = {key: 'bs', value: 'bs', flag: 'bs', text: 'Bahamas'};
	static BH: CountrySelection = {key: 'bh', value: 'bh', flag: 'bh', text: 'Bahrain'};
	static BD: CountrySelection = {key: 'bd', value: 'bd', flag: 'bd', text: 'Bangladesh'};
	static BB: CountrySelection = {key: 'bb', value: 'bb', flag: 'bb', text: 'Barbados'};
	static BY: CountrySelection = {key: 'by', value: 'by', flag: 'by', text: 'Belarus'};
	static BE: CountrySelection = {key: 'be', value: 'be', flag: 'be', text: 'Belgium'};
	static BZ: CountrySelection = {key: 'bz', value: 'bz', flag: 'bz', text: 'Belize'};
	static BM: CountrySelection = {key: 'bm', value: 'bm', flag: 'bm', text: 'Bermuda'};
	static BT: CountrySelection = {key: 'bt', value: 'bt', flag: 'bt', text: 'Bhutan'};
	static BO: CountrySelection = {key: 'bo', value: 'bo', flag: 'bo', text: 'Bolivia'};
	static BA: CountrySelection = {key: 'ba', value: 'ba', flag: 'ba', text: 'Bosnia and Herzegovina'};
	static BW: CountrySelection = {key: 'bw', value: 'bw', flag: 'bw', text: 'Botswana'};
	static BR: CountrySelection = {key: 'br', value: 'br', flag: 'br', text: 'Brazil'};
	static BN: CountrySelection = {key: 'bn', value: 'bn', flag: 'bn', text: 'Brunei Darussalam'};
	static BG: CountrySelection = {key: 'bg', value: 'bg', flag: 'bg', text: 'Bulgaria'};
	static BF: CountrySelection = {key: 'bf', value: 'bf', flag: 'bf', text: 'Burkina Faso'};
	static BI: CountrySelection = {key: 'bi', value: 'bi', flag: 'bi', text: 'Burundi'};
	static KH: CountrySelection = {key: 'kh', value: 'kh', flag: 'kh', text: 'Cambodia'};
	static CM: CountrySelection = {key: 'cm', value: 'cm', flag: 'cm', text: 'Cameroon'};
	static CA: CountrySelection = {key: 'ca', value: 'ca', flag: 'ca', text: 'Canada'};
	static CV: CountrySelection = {key: 'cv', value: 'cv', flag: 'cv', text: 'Cape Verde'};
	static KY: CountrySelection = {key: 'ky', value: 'ky', flag: 'ky', text: 'Cayman Islands'};
	static CF: CountrySelection = {key: 'cf', value: 'cf', flag: 'cf', text: 'Central African Republic'};
	static TD: CountrySelection = {key: 'td', value: 'td', flag: 'td', text: 'Chad'};
	static CL: CountrySelection = {key: 'cl', value: 'cl', flag: 'cl', text: 'Chile'};
	static CN: CountrySelection = {key: 'cn', value: 'cn', flag: 'cn', text: 'China'};
	static CO: CountrySelection = {key: 'co', value: 'co', flag: 'co', text: 'Colombia'};
	static CG: CountrySelection = {key: 'cg', value: 'cg', flag: 'cg', text: 'Congo'};
	static CD: CountrySelection = {key: 'cd', value: 'cd', flag: 'cd', text: 'Congo, Democratic Republic of the Congo'};
	static CR: CountrySelection = {key: 'cr', value: 'cr', flag: 'cr', text: 'Costa Rica'};
	static HR: CountrySelection = {key: 'hr', value: 'hr', flag: 'hr', text: 'Croatia'};
	static CU: CountrySelection = {key: 'cu', value: 'cu', flag: 'cu', text: 'Cuba'};
	static CY: CountrySelection = {key: 'cy', value: 'cy', flag: 'cy', text: 'Cyprus'};
	static CZ: CountrySelection = {key: 'cz', value: 'cz', flag: 'cz', text: 'Czech Republic'};
	static DK: CountrySelection = {key: 'dk', value: 'dk', flag: 'dk', text: 'Denmark'};
	static DJ: CountrySelection = {key: 'dj', value: 'dj', flag: 'dj', text: 'Djibouti'};
	static DM: CountrySelection = {key: 'dm', value: 'dm', flag: 'dm', text: 'Dominica'};
	static DO: CountrySelection = {key: 'do', value: 'do', flag: 'do', text: 'Dominican Republic'};
	static EC: CountrySelection = {key: 'ec', value: 'ec', flag: 'ec', text: 'Ecuador'};
	static EG: CountrySelection = {key: 'eg', value: 'eg', flag: 'eg', text: 'Egypt'};
	static SV: CountrySelection = {key: 'sv', value: 'sv', flag: 'sv', text: 'El Salvador'};
	static GQ: CountrySelection = {key: 'gq', value: 'gq', flag: 'gq', text: 'Equatorial Guinea'};
	static ER: CountrySelection = {key: 'er', value: 'er', flag: 'er', text: 'Eritrea'};
	static EE: CountrySelection = {key: 'ee', value: 'ee', flag: 'ee', text: 'Estonia'};
	static ET: CountrySelection = {key: 'et', value: 'et', flag: 'et', text: 'Ethiopia'};
	static FJ: CountrySelection = {key: 'fj', value: 'fj', flag: 'fj', text: 'Fiji'};
	static FI: CountrySelection = {key: 'fi', value: 'fi', flag: 'fi', text: 'Finland'};
	static FR: CountrySelection = {key: 'fr', value: 'fr', flag: 'fr', text: 'France'};
	static GF: CountrySelection = {key: 'gf', value: 'gf', flag: 'gf', text: 'French Guiana'};
	static GA: CountrySelection = {key: 'ga', value: 'ga', flag: 'ga', text: 'Gabon'};
	static GM: CountrySelection = {key: 'gm', value: 'gm', flag: 'gm', text: 'Gambia'};
	static GE: CountrySelection = {key: 'ge', value: 'ge', flag: 'ge', text: 'Georgia'};
	static DE: CountrySelection = {key: 'de', value: 'de', flag: 'de', text: 'Germany'};
	static GH: CountrySelection = {key: 'gh', value: 'gh', flag: 'gh', text: 'Ghana'};
	static GI: CountrySelection = {key: 'gi', value: 'gi', flag: 'gi', text: 'Gibraltar'};
	static GR: CountrySelection = {key: 'gr', value: 'gr', flag: 'gr', text: 'Greece'};
	static GL: CountrySelection = {key: 'gl', value: 'gl', flag: 'gl', text: 'Greenland'};
	static GD: CountrySelection = {key: 'gd', value: 'gd', flag: 'gd', text: 'Grenada'};
	static GP: CountrySelection = {key: 'gp', value: 'gp', flag: 'gp', text: 'Guadeloupe'};
	static GU: CountrySelection = {key: 'gu', value: 'gu', flag: 'gu', text: 'Guam'};
	static GT: CountrySelection = {key: 'gt', value: 'gt', flag: 'gt', text: 'Guatemala'};
	static GN: CountrySelection = {key: 'gn', value: 'gn', flag: 'gn', text: 'Guinea'};
	static GW: CountrySelection = {key: 'gw', value: 'gw', flag: 'gw', text: 'Guinea-Bissau'};
	static GY: CountrySelection = {key: 'gy', value: 'gy', flag: 'gy', text: 'Guyana'};
	static HT: CountrySelection = {key: 'ht', value: 'ht', flag: 'ht', text: 'Haiti'};
	static VA: CountrySelection = {key: 'va', value: 'va', flag: 'va', text: 'Holy See (Vatican City State)'};
	static HN: CountrySelection = {key: 'hn', value: 'hn', flag: 'hn', text: 'Honduras'};
	static HK: CountrySelection = {key: 'hk', value: 'hk', flag: 'hk', text: 'Hong Kong'};
	static HU: CountrySelection = {key: 'hu', value: 'hu', flag: 'hu', text: 'Hungary'};
	static IS: CountrySelection = {key: 'is', value: 'is', flag: 'is', text: 'Iceland'};
	static IN: CountrySelection = {key: 'in', value: 'in', flag: 'in', text: 'India'};
	static ID: CountrySelection = {key: 'id', value: 'id', flag: 'id', text: 'Indonesia'};
	static IR: CountrySelection = {key: 'ir', value: 'ir', flag: 'ir', text: 'Iran, Islamic Republic of'};
	static IQ: CountrySelection = {key: 'iq', value: 'iq', flag: 'iq', text: 'Iraq'};
	static IE: CountrySelection = {key: 'ie', value: 'ie', flag: 'ie', text: 'Ireland'};
	static IL: CountrySelection = {key: 'il', value: 'il', flag: 'il', text: 'Israel'};
	static IT: CountrySelection = {key: 'it', value: 'it', flag: 'it', text: 'Italy'};
	static JM: CountrySelection = {key: 'jm', value: 'jm', flag: 'jm', text: 'Jamaica'};
	static JP: CountrySelection = {key: 'jp', value: 'jp', flag: 'jp', text: 'Japan'};
	static JO: CountrySelection = {key: 'jo', value: 'jo', flag: 'jo', text: 'Jordan'};
	static KZ: CountrySelection = {key: 'kz', value: 'kz', flag: 'kz', text: 'Kazakhstan'};
	static KE: CountrySelection = {key: 'ke', value: 'ke', flag: 'ke', text: 'Kenya'};
	static KI: CountrySelection = {key: 'ki', value: 'ki', flag: 'ki', text: 'Kiribati'};
	static KP: CountrySelection = {key: 'kp', value: 'kp', flag: 'kp', text: 'Korea, Democratic People\'s Republic of'};
	static KR: CountrySelection = {key: 'kr', value: 'kr', flag: 'kr', text: 'Korea, Republic of'};
	static KW: CountrySelection = {key: 'kw', value: 'kw', flag: 'kw', text: 'Kuwait'};
	static KG: CountrySelection = {key: 'kg', value: 'kg', flag: 'kg', text: 'Kyrgyzstan'};
	static LA: CountrySelection = {key: 'la', value: 'la', flag: 'la', text: 'Lao People\'s Democratic Republic'};
	static LV: CountrySelection = {key: 'lv', value: 'lv', flag: 'lv', text: 'Latvia'};
	static LB: CountrySelection = {key: 'lb', value: 'lb', flag: 'lb', text: 'Lebanon'};
	static LS: CountrySelection = {key: 'ls', value: 'ls', flag: 'ls', text: 'Lesotho'};
	static LR: CountrySelection = {key: 'lr', value: 'lr', flag: 'lr', text: 'Liberia'};
	static LY: CountrySelection = {key: 'ly', value: 'ly', flag: 'ly', text: 'Libyan Arab Jamahiriya'};
	static LI: CountrySelection = {key: 'li', value: 'li', flag: 'li', text: 'Liechtenstein'};
	static LT: CountrySelection = {key: 'lt', value: 'lt', flag: 'lt', text: 'Lithuania'};
	static LU: CountrySelection = {key: 'lu', value: 'lu', flag: 'lu', text: 'Luxembourg'};
	static MK: CountrySelection = {key: 'mk', value: 'mk', flag: 'mk', text: 'Macedonia, the Former Yugoslav Republic of'};
	static MG: CountrySelection = {key: 'mg', value: 'mg', flag: 'mg', text: 'Madagascar'};
	static MW: CountrySelection = {key: 'mw', value: 'mw', flag: 'mw', text: 'Malawi'};
	static MY: CountrySelection = {key: 'my', value: 'my', flag: 'my', text: 'Malaysia'};
	static MV: CountrySelection = {key: 'mv', value: 'mv', flag: 'mv', text: 'Maldives'};
	static ML: CountrySelection = {key: 'ml', value: 'ml', flag: 'ml', text: 'Mali'};
	static MT: CountrySelection = {key: 'mt', value: 'mt', flag: 'mt', text: 'Malta'};
	static MH: CountrySelection = {key: 'mh', value: 'mh', flag: 'mh', text: 'Marshall Islands'};
	static MQ: CountrySelection = {key: 'mq', value: 'mq', flag: 'mq', text: 'Martinique'};
	static MR: CountrySelection = {key: 'mr', value: 'mr', flag: 'mr', text: 'Mauritania'};
	static MU: CountrySelection = {key: 'mu', value: 'mu', flag: 'mu', text: 'Mauritius'};
	static MX: CountrySelection = {key: 'mx', value: 'mx', flag: 'mx', text: 'Mexico'};
	static MD: CountrySelection = {key: 'md', value: 'md', flag: 'md', text: 'Moldova, Republic of'};
	static MC: CountrySelection = {key: 'mc', value: 'mc', flag: 'mc', text: 'Monaco'};
	static MN: CountrySelection = {key: 'mn', value: 'mn', flag: 'mn', text: 'Mongolia'};
	static ME: CountrySelection = {key: 'me', value: 'me', flag: 'me', text: 'Montenegro'};
	static MA: CountrySelection = {key: 'ma', value: 'ma', flag: 'ma', text: 'Morocco'};
	static MZ: CountrySelection = {key: 'mz', value: 'mz', flag: 'mz', text: 'Mozambique'};
	static MM: CountrySelection = {key: 'mm', value: 'mm', flag: 'mm', text: 'Myanmar'};
	static NA: CountrySelection = {key: 'na', value: 'na', flag: 'na', text: 'Namibia'};
	static NP: CountrySelection = {key: 'np', value: 'np', flag: 'np', text: 'Nepal'};
	static NL: CountrySelection = {key: 'nl', value: 'nl', flag: 'nl', text: 'Netherlands'};
	static AN: CountrySelection = {key: 'an', value: 'an', flag: 'an', text: 'Netherlands Antilles'};
	static NC: CountrySelection = {key: 'nc', value: 'nc', flag: 'nc', text: 'New Caledonia'};
	static NZ: CountrySelection = {key: 'nz', value: 'nz', flag: 'nz', text: 'New Zealand'};
	static NI: CountrySelection = {key: 'ni', value: 'ni', flag: 'ni', text: 'Nicaragua'};
	static NE: CountrySelection = {key: 'ne', value: 'ne', flag: 'ne', text: 'Niger'};
	static NG: CountrySelection = {key: 'ng', value: 'ng', flag: 'ng', text: 'Nigeria'};
	static NO: CountrySelection = {key: 'no', value: 'no', flag: 'no', text: 'Norway'};
	static OM: CountrySelection = {key: 'om', value: 'om', flag: 'om', text: 'Oman'};
	static PK: CountrySelection = {key: 'pk', value: 'pk', flag: 'pk', text: 'Pakistan'};
	static PA: CountrySelection = {key: 'pa', value: 'pa', flag: 'pa', text: 'Panama'};
	static PG: CountrySelection = {key: 'pg', value: 'pg', flag: 'pg', text: 'Papua New Guinea'};
	static PY: CountrySelection = {key: 'py', value: 'py', flag: 'py', text: 'Paraguay'};
	static PE: CountrySelection = {key: 'pe', value: 'pe', flag: 'pe', text: 'Peru'};
	static PH: CountrySelection = {key: 'ph', value: 'ph', flag: 'ph', text: 'Philippines'};
	static PL: CountrySelection = {key: 'pl', value: 'pl', flag: 'pl', text: 'Poland'};
	static PT: CountrySelection = {key: 'pt', value: 'pt', flag: 'pt', text: 'Portugal'};
	static PR: CountrySelection = {key: 'pr', value: 'pr', flag: 'pr', text: 'Puerto Rico'};
	static QA: CountrySelection = {key: 'qa', value: 'qa', flag: 'qa', text: 'Qatar'};
	static RO: CountrySelection = {key: 'ro', value: 'ro', flag: 'ro', text: 'Romania'};
	static RU: CountrySelection = {key: 'ru', value: 'ru', flag: 'ru', text: 'SSSR'};
	static RW: CountrySelection = {key: 'rw', value: 'rw', flag: 'rw', text: 'Rwanda'};
	static WS: CountrySelection = {key: 'ws', value: 'ws', flag: 'ws', text: 'Samoa'};
	static SM: CountrySelection = {key: 'sm', value: 'sm', flag: 'sm', text: 'San Marino'};
	static SA: CountrySelection = {key: 'sa', value: 'sa', flag: 'sa', text: 'Saudi Arabia'};
	static SN: CountrySelection = {key: 'sn', value: 'sn', flag: 'sn', text: 'Senegal'};
	static RS: CountrySelection = {key: 'rs', value: 'rs', flag: 'rs', text: 'Serbia'};
	static CS: CountrySelection = {key: 'cs', value: 'cs', flag: 'cs', text: 'Serbia and Montenegro'};
	static SC: CountrySelection = {key: 'sc', value: 'sc', flag: 'sc', text: 'Seychelles'};
	static SL: CountrySelection = {key: 'sl', value: 'sl', flag: 'sl', text: 'Sierra Leone'};
	static SG: CountrySelection = {key: 'sg', value: 'sg', flag: 'sg', text: 'Singapore'};
	static SK: CountrySelection = {key: 'sk', value: 'sk', flag: 'sk', text: 'Slovakia'};
	static SI: CountrySelection = {key: 'si', value: 'si', flag: 'si', text: 'Slovenia'};
	static SO: CountrySelection = {key: 'so', value: 'so', flag: 'so', text: 'Somalia'};
	static ZA: CountrySelection = {key: 'za', value: 'za', flag: 'za', text: 'South Africa'};
	static ES: CountrySelection = {key: 'es', value: 'es', flag: 'es', text: 'Spain'};
	static LK: CountrySelection = {key: 'lk', value: 'lk', flag: 'lk', text: 'Sri Lanka'};
	static SD: CountrySelection = {key: 'sd', value: 'sd', flag: 'sd', text: 'Sudan'};
	static SR: CountrySelection = {key: 'sr', value: 'sr', flag: 'sr', text: 'Suriname'};
	static SZ: CountrySelection = {key: 'sz', value: 'sz', flag: 'sz', text: 'Swaziland'};
	static SE: CountrySelection = {key: 'se', value: 'se', flag: 'se', text: 'Sweden'};
	static CH: CountrySelection = {key: 'ch', value: 'ch', flag: 'ch', text: 'Switzerland'};
	static SY: CountrySelection = {key: 'sy', value: 'sy', flag: 'sy', text: 'Syrian Arab Republic'};
	static TW: CountrySelection = {key: 'tw', value: 'tw', flag: 'tw', text: 'Taiwan'};
	static TJ: CountrySelection = {key: 'tj', value: 'tj', flag: 'tj', text: 'Tajikistan'};
	static TZ: CountrySelection = {key: 'tz', value: 'tz', flag: 'tz', text: 'Tanzania, United Republic of'};
	static TH: CountrySelection = {key: 'th', value: 'th', flag: 'th', text: 'Thailand'};
	static TT: CountrySelection = {key: 'tt', value: 'tt', flag: 'tt', text: 'Trinidad and Tobago'};
	static TN: CountrySelection = {key: 'tn', value: 'tn', flag: 'tn', text: 'Tunisia'};
	static TR: CountrySelection = {key: 'tr', value: 'tr', flag: 'tr', text: 'Turkey'};
	static TM: CountrySelection = {key: 'tm', value: 'tm', flag: 'tm', text: 'Turkmenistan'};
	static TV: CountrySelection = {key: 'tv', value: 'tv', flag: 'tv', text: 'Tuvalu'};
	static UG: CountrySelection = {key: 'ug', value: 'ug', flag: 'ug', text: 'Uganda'};
	static UA: CountrySelection = {key: 'ua', value: 'ua', flag: 'ua', text: 'Ukraine'};
	static AE: CountrySelection = {key: 'ae', value: 'ae', flag: 'ae', text: 'United Arab Emirates'};
	static GB: CountrySelection = {key: 'gb', value: 'gb', flag: 'gb', text: 'United Kingdom'};
	static US: CountrySelection = {key: 'us', value: 'us', flag: 'us', text: 'United States'};
	static UY: CountrySelection = {key: 'uy', value: 'uy', flag: 'uy', text: 'Uruguay'};
	static UZ: CountrySelection = {key: 'uz', value: 'uz', flag: 'uz', text: 'Uzbekistan'};
	static VU: CountrySelection = {key: 'vu', value: 'vu', flag: 'vu', text: 'Vanuatu'};
	static VE: CountrySelection = {key: 've', value: 've', flag: 've', text: 'Venezuela'};
	static VN: CountrySelection = {key: 'vn', value: 'vn', flag: 'vn', text: 'Viet Nam'};
	static EH: CountrySelection = {key: 'eh', value: 'eh', flag: 'eh', text: 'Western Sahara'};
	static YE: CountrySelection = {key: 'ye', value: 'ye', flag: 'ye', text: 'Yemen'};
	static ZM: CountrySelection = {key: 'zm', value: 'zm', flag: 'zm', text: 'Zambia'};
	static ZW: CountrySelection = {key: 'zw', value: 'zw', flag: 'zw', text: 'Zimbabwe'};

	static DEFAULT_COUNTRY: CountrySelection = Country.CZ;

	static ALL_COUNTRIES_MAP: {
		[key: string]: CountrySelection;
	} = {
		'af': Country.AF,
		'al': Country.AL,
		'dz': Country.DZ,
		'ad': Country.AD,
		'ao': Country.AO,
		'ar': Country.AR,
		'am': Country.AM,
		'au': Country.AU,
		'at': Country.AT,
		'az': Country.AZ,
		'bs': Country.BS,
		'bh': Country.BH,
		'bd': Country.BD,
		'bb': Country.BB,
		'by': Country.BY,
		'be': Country.BE,
		'bz': Country.BZ,
		'bm': Country.BM,
		'bt': Country.BT,
		'bo': Country.BO,
		'ba': Country.BA,
		'bw': Country.BW,
		'br': Country.BR,
		'bn': Country.BN,
		'bg': Country.BG,
		'bf': Country.BF,
		'bi': Country.BI,
		'kh': Country.KH,
		'cm': Country.CM,
		'ca': Country.CA,
		'cv': Country.CV,
		'ky': Country.KY,
		'cf': Country.CF,
		'td': Country.TD,
		'cl': Country.CL,
		'cn': Country.CN,
		'co': Country.CO,
		'cg': Country.CG,
		'cd': Country.CD,
		'cr': Country.CR,
		'hr': Country.HR,
		'cu': Country.CU,
		'cy': Country.CY,
		'cz': Country.CZ,
		'dk': Country.DK,
		'dj': Country.DJ,
		'dm': Country.DM,
		'do': Country.DO,
		'ec': Country.EC,
		'eg': Country.EG,
		'sv': Country.SV,
		'gq': Country.GQ,
		'er': Country.ER,
		'ee': Country.EE,
		'et': Country.ET,
		'fj': Country.FJ,
		'fi': Country.FI,
		'fr': Country.FR,
		'gf': Country.GF,
		'ga': Country.GA,
		'gm': Country.GM,
		'ge': Country.GE,
		'de': Country.DE,
		'gh': Country.GH,
		'gi': Country.GI,
		'gr': Country.GR,
		'gl': Country.GL,
		'gd': Country.GD,
		'gp': Country.GP,
		'gu': Country.GU,
		'gt': Country.GT,
		'gn': Country.GN,
		'gw': Country.GW,
		'gy': Country.GY,
		'ht': Country.HT,
		'va': Country.VA,
		'hn': Country.HN,
		'hk': Country.HK,
		'hu': Country.HU,
		'is': Country.IS,
		'in': Country.IN,
		'id': Country.ID,
		'ir': Country.IR,
		'iq': Country.IQ,
		'ie': Country.IE,
		'il': Country.IL,
		'it': Country.IT,
		'jm': Country.JM,
		'jp': Country.JP,
		'jo': Country.JO,
		'kz': Country.KZ,
		'ke': Country.KE,
		'ki': Country.KI,
		'kp': Country.KP,
		'kr': Country.KR,
		'kw': Country.KW,
		'kg': Country.KG,
		'la': Country.LA,
		'lv': Country.LV,
		'lb': Country.LB,
		'ls': Country.LS,
		'lr': Country.LR,
		'ly': Country.LY,
		'li': Country.LI,
		'lt': Country.LT,
		'lu': Country.LU,
		'mk': Country.MK,
		'mg': Country.MG,
		'mw': Country.MW,
		'my': Country.MY,
		'mv': Country.MV,
		'ml': Country.ML,
		'mt': Country.MT,
		'mh': Country.MH,
		'mq': Country.MQ,
		'mr': Country.MR,
		'mu': Country.MU,
		'mx': Country.MX,
		'md': Country.MD,
		'mc': Country.MC,
		'mn': Country.MN,
		'me': Country.ME,
		'ma': Country.MA,
		'mz': Country.MZ,
		'mm': Country.MM,
		'na': Country.NA,
		'np': Country.NP,
		'nl': Country.NL,
		'an': Country.AN,
		'nc': Country.NC,
		'nz': Country.NZ,
		'ni': Country.NI,
		'ne': Country.NE,
		'ng': Country.NG,
		'no': Country.NO,
		'om': Country.OM,
		'pk': Country.PK,
		'pa': Country.PA,
		'pg': Country.PG,
		'py': Country.PY,
		'pe': Country.PE,
		'ph': Country.PH,
		'pl': Country.PL,
		'pt': Country.PT,
		'pr': Country.PR,
		'qa': Country.QA,
		'ro': Country.RO,
		'ru': Country.RU,
		'rw': Country.RW,
		'ws': Country.WS,
		'sm': Country.SM,
		'sa': Country.SA,
		'sn': Country.SN,
		'rs': Country.RS,
		'cs': Country.CS,
		'sc': Country.SC,
		'sl': Country.SL,
		'sg': Country.SG,
		'sk': Country.SK,
		'si': Country.SI,
		'so': Country.SO,
		'za': Country.ZA,
		'es': Country.ES,
		'lk': Country.LK,
		'sd': Country.SD,
		'sr': Country.SR,
		'sz': Country.SZ,
		'se': Country.SE,
		'ch': Country.CH,
		'sy': Country.SY,
		'tw': Country.TW,
		'tj': Country.TJ,
		'tz': Country.TZ,
		'th': Country.TH,
		'tt': Country.TT,
		'tn': Country.TN,
		'tr': Country.TR,
		'tm': Country.TM,
		'tv': Country.TV,
		'ug': Country.UG,
		'ua': Country.UA,
		'ae': Country.AE,
		'gb': Country.GB,
		'us': Country.US,
		'uy': Country.UY,
		'uz': Country.UZ,
		'vu': Country.VU,
		've': Country.VE,
		'vn': Country.VN,
		'eh': Country.EH,
		'ye': Country.YE,
		'zm': Country.ZM,
		'zw': Country.ZW,
	};

	static ALL_COUNTRIES: CountrySelection[] = [
		Country.AF,
		Country.AL,
		Country.DZ,
		Country.AD,
		Country.AO,
		Country.AR,
		Country.AM,
		Country.AU,
		Country.AT,
		Country.AZ,
		Country.BS,
		Country.BH,
		Country.BD,
		Country.BB,
		Country.BY,
		Country.BE,
		Country.BZ,
		Country.BM,
		Country.BT,
		Country.BO,
		Country.BA,
		Country.BW,
		Country.BR,
		Country.BN,
		Country.BG,
		Country.BF,
		Country.BI,
		Country.KH,
		Country.CM,
		Country.CA,
		Country.CV,
		Country.KY,
		Country.CF,
		Country.TD,
		Country.CL,
		Country.CN,
		Country.CO,
		Country.CG,
		Country.CD,
		Country.CR,
		Country.HR,
		Country.CU,
		Country.CY,
		Country.CZ,
		Country.DK,
		Country.DJ,
		Country.DM,
		Country.DO,
		Country.EC,
		Country.EG,
		Country.SV,
		Country.GQ,
		Country.ER,
		Country.EE,
		Country.ET,
		Country.FJ,
		Country.FI,
		Country.FR,
		Country.GF,
		Country.GA,
		Country.GM,
		Country.GE,
		Country.DE,
		Country.GH,
		Country.GI,
		Country.GR,
		Country.GL,
		Country.GD,
		Country.GP,
		Country.GU,
		Country.GT,
		Country.GN,
		Country.GW,
		Country.GY,
		Country.HT,
		Country.VA,
		Country.HN,
		Country.HK,
		Country.HU,
		Country.IS,
		Country.IN,
		Country.ID,
		Country.IR,
		Country.IQ,
		Country.IE,
		Country.IL,
		Country.IT,
		Country.JM,
		Country.JP,
		Country.JO,
		Country.KZ,
		Country.KE,
		Country.KI,
		Country.KP,
		Country.KR,
		Country.KW,
		Country.KG,
		Country.LA,
		Country.LV,
		Country.LB,
		Country.LS,
		Country.LR,
		Country.LY,
		Country.LI,
		Country.LT,
		Country.LU,
		Country.MK,
		Country.MG,
		Country.MW,
		Country.MY,
		Country.MV,
		Country.ML,
		Country.MT,
		Country.MH,
		Country.MQ,
		Country.MR,
		Country.MU,
		Country.MX,
		Country.MD,
		Country.MC,
		Country.MN,
		Country.ME,
		Country.MA,
		Country.MZ,
		Country.MM,
		Country.NA,
		Country.NP,
		Country.NL,
		Country.AN,
		Country.NC,
		Country.NZ,
		Country.NI,
		Country.NE,
		Country.NG,
		Country.NO,
		Country.OM,
		Country.PK,
		Country.PA,
		Country.PG,
		Country.PY,
		Country.PE,
		Country.PH,
		Country.PL,
		Country.PT,
		Country.PR,
		Country.QA,
		Country.RO,
		Country.RU,
		Country.RW,
		Country.WS,
		Country.SM,
		Country.SA,
		Country.SN,
		Country.RS,
		Country.CS,
		Country.SC,
		Country.SL,
		Country.SG,
		Country.SK,
		Country.SI,
		Country.SO,
		Country.ZA,
		Country.ES,
		Country.LK,
		Country.SD,
		Country.SR,
		Country.SZ,
		Country.SE,
		Country.CH,
		Country.SY,
		Country.TW,
		Country.TJ,
		Country.TZ,
		Country.TH,
		Country.TT,
		Country.TN,
		Country.TR,
		Country.TM,
		Country.TV,
		Country.UG,
		Country.UA,
		Country.AE,
		Country.GB,
		Country.US,
		Country.UY,
		Country.UZ,
		Country.VU,
		Country.VE,
		Country.VN,
		Country.EH,
		Country.YE,
		Country.ZM,
		Country.ZW,
	];
}
