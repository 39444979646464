import React, {useState} from "react";
import {Button, Grid, Header, Icon, Popup, Segment, Table} from "semantic-ui-react";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import {SkinDto} from "../../build/generated-sources/dto/SkinDto";
import {useGetMyCreatedSkins} from "../../build/generated-sources/service/QueryService";
import PageMapper from "../../base/mapper/PageMapper";
import SkinMapper from "../../base/mapper/SkinMapper";
import {UseGetMyCreatedSkinsData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import PaginationControls from "../base/component/PaginationControls";
import {Link} from "react-router-dom";
import {View} from "../../base/enums/View";
import SimpleLoader from "../../components/SimpleLoader";
import {useHideSkin} from "../../build/generated-sources/service/MutationService";
import {UseHideSkinData} from "../../build/generated-sources/service/MutationServiceModel";
import {SyncOperationState} from "../../base/state/SyncOperationState";
import {toast} from "react-hot-toast";
import {success} from "@redrobot/npm-core-generator-builder/dist/graphql-builder/base/util/ConsoleUtils";
import {ResourceUtil} from "../../base/util/ResourceUtil";
import {SkinTypeUtil} from "../../base/util/SkinTypeUtil";
import { Formatter } from "../../base/util/Formatter";
import moment from "moment/moment";
import {BundleDto} from "../../build/generated-sources/dto/BundleDto";

export default function CreatedSkins() {

	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 10,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const [list, setList] = useState<SkinDto[]>([]);

	const [syncHideState, setSyncHideState] = useState<SyncOperationState>({
		inProgress: false
	});

	const getCreatedSkins = useGetMyCreatedSkins({
		pageInfo: PageMapper.ALL,
		content: SkinMapper.ALL
	}, {
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize
			}
		},
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetMyCreatedSkinsData) => {
			setList(data.myCreatedSkins.content);
			setPageInfo(data.myCreatedSkins.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const [hideSkin] = useHideSkin({
			id: true,
			success: true
		}, {
			onCompleted: (data: UseHideSkinData) => {
				setSyncHideState({inProgress: false});
				if (!data.hideSkin.success) {
					return;
				}
				let updatedList : BundleDto[] = Object.assign([], list);
				setList(updatedList.map(item => item.id === data.hideSkin.id ? {...item, published: !item.published} : item));
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	const downloadSkin = (skin: SkinDto) => {
		window.location.replace('/assets/skin/' + skin.url);
	}

	const hide = (id: number, hide: boolean) => {
		setSyncHideState({
			id: id,
			inProgress: true
		});

		toast.promise(
			hideSkin({
				variables: {
					id: id,
					hide: hide
				}
			}),
			{
				loading: hide ? 'Hiding your skin from players' : 'Publishing your skin for everyone to see',
				success: hide ? <strong>Skin successfully hidden</strong> : <strong>Skin successfully published</strong>,
				error: <strong>Error changing skin's visibility.</strong>,
			}
		);
	}

	return (
		<>
			<Grid stackable>
				<Grid.Column width={9}>
					<Header as='h2' style={{margin: '0 0 40px'}}>
						<Icon name='car'/>
						<Header.Content>
							Uploaded Skins
							<Header.Subheader>Let players drive with style!</Header.Subheader>
						</Header.Content>
					</Header>
				</Grid.Column>
				<Grid.Column textAlign={'right'} style={{paddingTop: '24px'}} width={7}>
					<Link className={'hide-on-mobile'} to={View.PUBLISH.path}><Button color={'black'}><Icon name={'plus'}/> Upload New Skin</Button></Link>
				</Grid.Column>
			</Grid>
			{
				getCreatedSkins.loading && <SimpleLoader text={'Loading your uploaded skins...'}/>
			}
			{
				!getCreatedSkins.loading && list.length === 0 && <Segment placeholder>
					<Header icon>
						<Icon name='frown outline'/> You have not uploaded any skins yet.
					</Header>
				</Segment>
			}
			{
				!getCreatedSkins.loading && list.length > 0 && <>
					<Table unstackable striped>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className={'hide-on-mobile'}></Table.HeaderCell>
								<Table.HeaderCell className={'hide-on-mobile'}>Submitted</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Preview</Table.HeaderCell>
								<Table.HeaderCell>Description</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Price</Table.HeaderCell>
								<Table.HeaderCell className={'hide-on-mobile'} textAlign={'center'}>Type</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Actions</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{
								list.length === 0 && <Table.Row>
									<Table.Cell colSpan={7} textAlign={'center'}>You haven't uploaded any skins yet.</Table.Cell>
								</Table.Row>
							}
							{
								list.map(skin => {
									return <Table.Row key={skin.id}>
										<Table.Cell className={'hide-on-mobile'} textAlign={'center'} collapsing>
											{
												!skin.published && <Popup wide position={'top center'} content='Hidden' trigger={<Icon name='eye slash'/>}/>
											}
											{
												skin.published && <Popup wide position={'top center'} content='Published' trigger={<Icon name='eye'/>}/>
											}
										</Table.Cell>
										<Table.Cell className={'hide-on-mobile'} singleLine collapsing>
											{moment(skin.createdAt, moment.ISO_8601).format("DD. MM. YYYY")}
										</Table.Cell>
										<Table.Cell collapsing textAlign={'center'}>
											<img alt={skin.title} style={{height: '50px', width: '80px', objectFit: 'cover'}} src={ResourceUtil.getThumbnailPath(skin.resources)}/>
										</Table.Cell>
										<Table.Cell>
											<Link to={View.SKIN.path + skin.url}><strong>{skin.title}</strong><br/></Link>
											<span className={'hide-on-mobile'}>{skin.description}</span>
										</Table.Cell>
										<Table.Cell collapsing textAlign={'center'}>
											<strong>{skin.price === 0 ? 'Free' : (Formatter.money(skin.price))}</strong><br/>
										</Table.Cell>
										<Table.Cell className={'hide-on-mobile'} collapsing textAlign={'center'}>
											<strong>{SkinTypeUtil.getOptionExtended(skin.type).shortText}</strong><br/>
										</Table.Cell>
										<Table.Cell collapsing textAlign={'left'}>
											<Link to={View.EDIT_SKIN.path.replace(":id", skin.url)}>
												<Popup content='Update' position={'top center'} trigger={<Button icon='edit'/>}/>
											</Link>
											{
												skin.published && <Popup wide content='Hide - people will not be able to see this skin'
																		 trigger={<Button icon='eye slash'
																						  className={'hide-on-mobile'}
																						  loading={syncHideState.inProgress && syncHideState.id === skin.id}
																						  disabled={syncHideState.inProgress} onClick={() => hide(skin.id, true)}/>}/>
											}
											{
												!skin.published && <Popup wide content='Publish - everyone will be able to see this skin'
																		  trigger={<Button icon='eye'
																						   className={'hide-on-mobile'}
																						   loading={syncHideState.inProgress && syncHideState.id === skin.id}
																						   disabled={syncHideState.inProgress} onClick={() => hide(skin.id, false)}/>}/>
											}
											<Popup wide content='Download' position={'top center'} trigger={<Button className={'hide-on-mobile'} icon={'download'} onClick={() => downloadSkin(skin)}/>} />
										</Table.Cell>
									</Table.Row>
								})
							}
						</Table.Body>
					</Table>

					<PaginationControls current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
						setPageInfo({
							...pageInfo,
							pageNumber: page
						});
					}}/>
				</>
			}
		</>
	);
}
