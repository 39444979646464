import React, {useState} from "react";
import {Flag, Header, Icon, Table} from "semantic-ui-react";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import {useGetAllComments} from "../../build/generated-sources/service/QueryService";
import PageMapper from "../../base/mapper/PageMapper";
import {UseGetAllCommentsData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import moment from "moment";
import PaginationControls from "../base/component/PaginationControls";
import {CommentDto} from "../../build/generated-sources/dto/CommentDto";
import CommentMapper from "../../base/mapper/CommentMapper";

export default function AdministrationComments() {

	const [list, setList] = useState<CommentDto[]>([]);
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 20,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const getComments = useGetAllComments({
		pageInfo: PageMapper.ALL,
		content: CommentMapper.ALL
	}, {
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize
			}
		},
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetAllCommentsData) => {
			setList(data.allComments.content);
			setPageInfo(data.allComments.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='comments'/>
				<Header.Content>
					List of Comments
				</Header.Content>
			</Header>
			{
				!getComments.loading && list.length > 0 && <>
					<Table unstackable striped>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell textAlign={'center'}>Created</Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
								<Table.HeaderCell>User</Table.HeaderCell>
								<Table.HeaderCell>Text</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{
								list.length === 0 && <Table.Row>
									<Table.Cell colSpan={7} textAlign={'center'}>There are no users.</Table.Cell>
								</Table.Row>
							}
							{
								list.map(comment => {
									return <Table.Row key={comment.id}>
										<Table.Cell singleLine collapsing>
											{moment(comment.createdAt, moment.ISO_8601).format('DD-MM-YYYY HH:mm')}
										</Table.Cell>
										<Table.Cell singleLine collapsing>
											<Flag name={comment.user.country as any}/>
										</Table.Cell>
										<Table.Cell singleLine collapsing>
											{comment.user.username}
										</Table.Cell>
										<Table.Cell singleLine>
											{comment.text}
										</Table.Cell>
									</Table.Row>
								})
							}
						</Table.Body>
					</Table>

					<PaginationControls current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
						setPageInfo({
							...pageInfo,
							pageNumber: page
						});
					}}/>
				</>
			}
		</>
	);
}
