import {EPayoutStatus} from "../../build/generated-sources/enum/EPayoutStatus";
import {SemanticCOLORS} from "semantic-ui-react";

export class PayoutUtil {

	public static getColor = (status : EPayoutStatus) : SemanticCOLORS => {
		if (status === EPayoutStatus.PENDING) {
			return 'grey';
		}
		if (status === EPayoutStatus.LOCKED) {
			return 'olive';
		}
		if (status === EPayoutStatus.SENT) {
			return 'green';
		}
		if (status === EPayoutStatus.ERROR) {
			return 'red';
		}
	}
}
