import React, {useEffect} from "react";
import {Container, Header, Icon, List} from "semantic-ui-react";

export default function RulesForSubmissions() {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Container>
			<Header as='h2'>
				<Icon name='clipboard check'/>
				<Header.Content>
					Rules for Submissions
					<Header.Subheader>Last updated on 1st of July 2022</Header.Subheader>
				</Header.Content>
			</Header>

			<p>
				Breaking any of these rules may result in immediate termination of your account, deleting offending submission or blocking the author from
				submitting new items. Any rewards for selling such skin may also be removed as the receiving player may be eligible for full refund.
				The punishment will depend on the severity of the infraction.
			</p>

			<List bulleted>
				<List.Item>
					<strong>Rule #1:</strong> Artwork must be appropriate for any age.
				</List.Item>
				<List.Item>
					<strong>Rule #2:</strong> No extreme violence or nudity can be displayed on submitted skins.
				</List.Item>
				<List.Item>
					<strong>Rule #3:</strong> Submitted screenshots or videos must resemble the submitted skin. Showing different looking skin on pictures than
					what the player receives is considered false advertising.
				</List.Item>
				<List.Item>
					<strong>Rule #4:</strong> Submitted artwork belongs to the person who submitted the skin to our site. Stolen submissions will be removed and their "authors" will be banned.
				</List.Item>
				<List.Item>
					<strong>Rule #5:</strong> Submitting files that contain viruses or other types of malware that may endanger players will be removed and authors will be banned.
				</List.Item>
			</List>

			<Header as='h4'>Changes</Header>
			<p>
				We may update these Rules from time to time in order to reflect changes to our practices or for other operational, legal or regulatory reasons.
			</p>
		</Container>
	);
}
