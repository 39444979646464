import React, {useContext, useState} from "react";
import {Button, Form, Header, Icon, Message, Modal} from "semantic-ui-react";
import {JwtUtil} from "../../../../base/util/JwtUtil";
import {SyncOperationState} from "../../../../base/state/SyncOperationState";
import {Auth, AuthContext} from "../../../../contexts/AuthContext";
import {Constant} from "../../../../base/enums/Constant";
import {toast} from "react-hot-toast";

interface LoginProps {
	close: () => void
	setMode: (mode: string) => void
}

export default function Login(props: LoginProps) {

	const [validate, setValidate] = useState<boolean>(false);
	const auth = useContext<Auth>(AuthContext);
	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [syncState, setSyncState] = useState<SyncOperationState>({
		inProgress: false,
		error: false,
		done: false
	});

	const login = () => {
		if (username.length < 4 || password.length < 8) {
			setValidate(true);
			return;
		}
		setValidate(false);
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({username: username, password: password})
		};
		setSyncState({
			inProgress: true,
			error: false,
			done: false
		});

		const toastId = toast.loading('Logging in...');

		fetch('/api/auth/login', requestOptions).then(response => {
			if (response.status >= 400) {
				toast.dismiss(toastId);
				setSyncState({
					error: true,
					done: true,
					id: response.status
				});
			} else {
				response.text().then(data => {
					setSyncState({
						id: response.status,
						done: true,
					});
					let jwt = JwtUtil.parse(data);
					auth.setToken(data, new Date(jwt.exp * 1000));
					toast.success(<strong>Welcome back, {jwt.username}</strong>, {id: toastId});
					props.close();
				});
			}
		});
	}

	return (
		<>
			<Modal.Content>
				<Header as='h2'>
					<Icon name='sign in' />
					<Header.Content>
						Sign in
						<Header.Subheader>{Constant.WEBSITE_TITLE} awaits</Header.Subheader>
					</Header.Content>
				</Header>
				{
					syncState.error && <Message error className={'error-msg'}>
						<Icon color={'red'} name={'warning sign'}/> Sorry, that user or password doesn't match anyone we know. You
						can <span className={'clickable highlight'} onClick={() => props.setMode('forgot-password')}>reset your password</span>.
					</Message>
				}
				<Form>
					<Form.Input error={validate && username.length < 4} disabled={syncState.inProgress} label={'Username or email'} type={'text'} value={username} onChange={(e) => setUsername(e.target.value)}/>
					<Form.Input error={validate && password.length < 8} disabled={syncState.inProgress} label={'Password'} type={'password'} value={password} onChange={(e) => setPassword(e.target.value)}/>
					<span className={'clickable highlight'} onClick={() => props.setMode('forgot-password')}>I forgot my password.</span>
					<Button floated={'right'} disabled={syncState.inProgress} color={'black'} onClick={() => login()}>
						Login
					</Button>
				</Form>
				<br/><br/>
			</Modal.Content>
			<Modal.Actions>Don't have an account? <span className={'clickable highlight'} onClick={() => props.setMode('register')}>Sign up.</span></Modal.Actions>
		</>
	);
}
