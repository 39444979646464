import {SkinDtoMapper} from "../../build/generated-sources/dto/SkinDto";
import UserMapper from "./UserMapper";
import ResourceMapper from "./ResourceMapper";
import SkinRatingMapper from "./SkinRatingMapper";

export default class SkinMapper {

	public static COLOR_VARIATIONS: SkinDtoMapper = {
		id: true,
		url: true,
		colorPrimary: true,
		colorSecondary: true,
		resources: ResourceMapper.ALL
	}

	public static ALL: SkinDtoMapper = {
		id: true,
		availableAlone: true,
		url: true,
		published: true,
		title: true,
		videoUrl: true,
		rating: SkinRatingMapper.ALL,
		author: UserMapper.SHORT,
		colorPrimary: true,
		colorSecondary: true,
		createdAt: true,
		deletedAt: true,
		description: true,
		type: true,
		resources: ResourceMapper.ALL,
		price: true,
		colorVariations: SkinMapper.COLOR_VARIATIONS
	}

	public static WITHOUT_AUTHOR: SkinDtoMapper = {
		id: true,
		availableAlone: true,
		url: true,
		title: true,
		createdAt: true,
		deletedAt: true,
		description: true,
		videoUrl: true,
		colorPrimary: true,
		colorSecondary: true,
		type: true,
		resources: ResourceMapper.ALL,
		rating: SkinRatingMapper.ALL,
		price: true,
		colorVariations: SkinMapper.COLOR_VARIATIONS
	}
}
