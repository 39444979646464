import React, {useContext, useState} from "react";
import {Button, Container, Comment, Form, Header} from "semantic-ui-react";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {Constant} from "../../../base/enums/Constant";
import {useGetComments} from "../../../build/generated-sources/service/QueryService";
import {UseGetCommentsData} from "../../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {PageInfo} from "../../../build/generated-sources/dto/PageInfo";
import CommentMapper from "../../../base/mapper/CommentMapper";
import PageMapper from "../../../base/mapper/PageMapper";
import {CommentDto} from "../../../build/generated-sources/dto/CommentDto";
import PaginationControls from "../../base/component/PaginationControls";
import {useCreateComment} from "../../../build/generated-sources/service/MutationService";
import {UseCreateCommentData} from "../../../build/generated-sources/service/MutationServiceModel";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import {Direction} from "../../../build/generated-sources/enum/Direction";
import moment from "moment";
import {toast} from "react-hot-toast";

interface CommentsProps {
	bundle?: number
	skin?: number
}

export default function Comments(props: CommentsProps) {

	const auth = useContext<Auth>(AuthContext);

	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: Constant.PAGE_LENGTH_COMMENTS,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});
	const [comments, setComments] = useState<CommentDto[]>([]);
	const [validate, setValidate] = useState<boolean>(false);
	const [reply, setReply] = useState<string>('');
	const [syncState, setSyncState] = useState<SyncOperationState>({
		inProgress: false,
		done: false
	});

	const getComments = useGetComments({
		pageInfo: PageMapper.ALL,
		content: CommentMapper.ALL
	}, {
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize,
				sortBy: 'createdAt',
				sortDirection: Direction.DESC
			},
			skin: props.skin,
			bundle: props.bundle
		},
		onCompleted: (data: UseGetCommentsData) => {
			setComments(data.comments.content);
			setPageInfo(data.comments.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message)
		}
	});

	const [sendComment] = useCreateComment(CommentMapper.ALL, {
		onCompleted: (data: UseCreateCommentData) => {
			setSyncState({
				inProgress: false,
				done: true
			});

			let commentsCopy = [data.createComment];
			comments.forEach(comment => commentsCopy.push(comment));
			setComments(commentsCopy);
			setReply('');
		}
	})

	const send = () => {
		if (reply.length < 3 || syncState.done || syncState.inProgress) {
			setValidate(true);
			return;
		}
		setValidate(false);
		setSyncState({
			inProgress: true
		});

		toast.promise(
			sendComment({
				variables: {
					text: reply,
					skin: props.skin,
					bundle: props.bundle
				}
			}),
			{
				loading: 'Sending your comment to the HQ...',
				success: <strong>Comment uploaded!</strong>,
				error: <strong>There was an error uploading your comment.</strong>,
			}
		);
	}

	return (
		<Container style={{marginBottom: '50px'}}>
			<Comment.Group minimal style={{textAlign: 'left', maxWidth: '100%'}}>
				<Header as='h3' dividing style={{textAlign: 'left'}}>
					Comments ({Math.max(0, pageInfo.totalElements) + (syncState.done ? 1 : 0)})
				</Header>
				{
					comments.length === 0 && <span>
						There are no comments yet.
					</span>
				}
				{
					comments.map(comment => {
						return <Comment style={{maxWidth: '550px'}} key={comment.id}>
							<Comment.Avatar src={comment.user.avatar ? '/public/' + comment.user.avatar : '/img/1.jpg'} />
							<Comment.Content>
								<Comment.Author as={'span'}>{comment.user.username}</Comment.Author>
								<Comment.Metadata>{moment(comment.createdAt, moment.ISO_8601).format("DD. MM. YYYY")}</Comment.Metadata>
								<Comment.Text>{comment.text}</Comment.Text>
							</Comment.Content>
						</Comment>
					})
				}

				<PaginationControls disabled={getComments.loading} current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
					setPageInfo({
						...pageInfo,
						pageNumber: page
					});
				}}/>

				<Form reply>
					<Form.TextArea
						style={{height: '5em'}}
						label={validate ? 'Write a comment (at least 3 characters)' : 'Write a comment'}
						rows={2}
						value={reply}
						error={validate && reply.length < 3}
						placeholder={!auth.token ? 'You must first login to write a comment' : 'What do you think?'}
						disabled={!auth.token || syncState.inProgress || syncState.done}
						onChange={(e) => setReply(e.target.value)}/>
					<Button content={syncState.done ? 'Comment sent!' : 'Send comment'} loading={syncState.inProgress} disabled={!auth.token || syncState.done || syncState.inProgress} labelPosition='left' icon='edit' color={'black'} onClick={() => send()}/>
				</Form>
			</Comment.Group>
		</Container>
	);
}
