import React, {useContext, useState} from "react";
import {Auth, AuthContext} from "../../../../contexts/AuthContext";
import {Button, Form, Header, Icon, Message, Modal} from "semantic-ui-react";
import {UserDto} from "../../../../build/generated-sources/dto/UserDto";
import {Country} from "../../../../base/util/Country";
import {JwtUtil} from "../../../../base/util/JwtUtil";
import {SyncOperationState} from "../../../../base/state/SyncOperationState";
import {Constant} from "../../../../base/enums/Constant";
import {toast} from "react-hot-toast";

interface RegisterProps {
	close: () => void
	setMode: (mode: string) => void
}

export default function Register(props: RegisterProps) {

	const auth = useContext<Auth>(AuthContext);
	const [user, setUser] = useState<UserDto>({
		username: '',
		email: '',
		country: Country.DEFAULT_COUNTRY.key,
		subscribedToNews: false
	});
	const [validate, setValidate] = useState<boolean>(false);
	const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
	const [password, setPassword] = useState<string>('');
	const [syncState, setSyncState] = useState<SyncOperationState>({
		inProgress: false,
		error: false,
		done: false
	});

	const handleChange = (e: any, field: string) => {
		setUser({
			...user,
			[field]: e.target.value
		});
	}

	const register = () => {
		setValidate(true);
		if (!usernameValid() || !emailValid() || !passwordValid()) {
			return;
		}
		setValidate(false);
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({username: user.username, email: user.email, password: password, country: user.country, subscribedToNews: user.subscribedToNews})
		};
		setSyncState({
			inProgress: true,
			error: false,
			done: false
		});

		const toastId = toast.loading('Creating your account...');

		fetch('/api/auth/register', requestOptions).then(response => {
				toast.error(<strong>There was an error creating your account.<br/>Try again or contact us.</strong>, {id: toastId});
			if (response.status === 400) {
				setSyncState({
					error: true,
					done: true,
					id: response.status
				});
			} else {
				response.text().then(data => {
					setSyncState({
						id: response.status,
						done: true,
					});
					let jwt = JwtUtil.parse(data);
					auth.setToken(data, new Date(jwt.exp * 1000));
					toast.success(<strong>Welcome to {Constant.WEBSITE_TITLE}, {jwt.username}</strong>, {id: toastId});
					props.close();
				});
			}
		})
	}

	const usernameValid = () => {
		return user.username.length >= 4 && user.username.length <= 40 && !user.username.includes(' ');
	}

	const emailValid = () => {
		return user.email.match(Constant.MAIL_REGEX);
	}

	const passwordValid = () => {
		return password.length >= 8;
	}

	return (
		<>
			<Modal.Content>
				<Header as='h2'>
					<Icon name='bolt' />
					<Header.Content>
						Register to {Constant.WEBSITE_TITLE}
						<Header.Subheader>Join the most creative Trackmania community</Header.Subheader>
					</Header.Content>
				</Header>
				{
					syncState.id === 400 && <Message error className={'error-msg'} color={'red'}>
						<Icon color={'red'} name={'warning sign'}/> Sorry, but this email is already used by someone. Maybe you already have an account here?
					</Message>
				}
				<Form>
					<Form.Input error={validate && !usernameValid()} required
								label={(validate && !usernameValid()) ? 'Username (Between 4 to 40 characters, no whitespace)' : 'Username'}
								placeholder={'Between 4 to 40 characters'} value={user.username} onChange={(e) => handleChange(e, 'username')}/>
					<Form.Input error={syncState.id === 400 || (validate && !emailValid())} required label={'Email'} type={'email'} placeholder={'@'} value={user.email}
								onChange={(e) => handleChange(e, 'email')}/>
					<Form.Input error={validate && !passwordValid()} required label={(validate && !passwordValid()) ? 'Password (At least 8 characters)' : 'Password'}
								placeholder={'At least 8 characters'} type={'password'} value={password} onChange={(e) => setPassword(e.target.value)}/>
					<Form.Select required label={'Country'} placeholder='Select Country' search selection
								 options={Country.ALL_COUNTRIES}
								 value={user.country}
								 onChange={(event, data: any) => setUser({...user, country: data.value})}
					/>
					<br/>
					<Form.Checkbox checked={user.subscribedToNews} label={'Notify me about latest news, skins and events'}
								   onChange={(e, data) => setUser({...user, subscribedToNews: data.checked})}/>
					<Form.Checkbox checked={termsAccepted}
								   label={<label>By checking this you confirm that you've read and agree to our <strong>Terms of Service</strong> and <strong>Privacy
									   Policy</strong></label>}
								   onChange={(e, data) => setTermsAccepted(data.checked)}/>
					<Button floated={'right'} disabled={!termsAccepted || syncState.inProgress} color={'black'} onClick={() => register()}>
						Register
					</Button>
					<br/><br/>
				</Form>
			</Modal.Content>
			<Modal.Actions>Already have an account? <span className={'clickable highlight'} onClick={() => props.setMode('login')}>Login.</span></Modal.Actions>
		</>
	);
}
