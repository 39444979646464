import React, {createContext, useEffect, useState} from "react";
import {SkinDto} from "../build/generated-sources/dto/SkinDto";
import {useLazyGetBundlesById, useLazyGetSkinsById} from "../build/generated-sources/service/QueryService";
import SkinMapper from "../base/mapper/SkinMapper";
import {UseGetBundlesByIdData, UseGetSkinsByIdData} from "../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {BundleDto} from "../build/generated-sources/dto/BundleDto";
import BundleMapper from "../base/mapper/BundleMapper";
import { Constant } from "../base/enums/Constant";

export type ShoppingCart = {
    skins: SkinDto[];
    bundles: BundleDto[];
    addSkin: (skin: SkinDto) => void;
    addBundle: (bundle: BundleDto) => void;
    containsSkin: (skin: SkinDto) => boolean;
    containsBundle: (bundle: BundleDto) => boolean;
    removeSkin: (skin: SkinDto) => void;
    removeBundle: (bundle: BundleDto) => void;
    clear: () => void;
};

export const initialValue: ShoppingCart = {
    skins: [],
    bundles: [],
    addSkin: () => {},
    addBundle: () => {},
    containsSkin: () => false,
    containsBundle: () => false,
    removeSkin: () => {},
    removeBundle: () => {},
    clear: () => {},
};

export const ShoppingCartContext = createContext<ShoppingCart>(initialValue);

export default function ShoppingCartProvider(props: any) {

    const [skins, setSkins] = useState<SkinDto[]>([]);
    const [bundles, setBundles] = useState<BundleDto[]>([]);
    const [getSkinsById] = useLazyGetSkinsById(SkinMapper.ALL, {
        onCompleted: (data: UseGetSkinsByIdData) => {
            setSkins(data.skinsById ? data.skinsById : []);
        },
        onError: (data: ApolloError) => {
            console.error(data.message);
        }
    });

    const [getBundlesById] = useLazyGetBundlesById(BundleMapper.ALL, {
        onCompleted: (data: UseGetBundlesByIdData) => {
            setBundles(data.bundlesById ? data.bundlesById : []);
        },
        onError: (data: ApolloError) => {
            console.error(data.message);
        }
    });

    useEffect(() => {
        if (localStorage.getItem(Constant.LOCAL_STORAGE_KEY_SKINS) !== null) {
            getSkinsById({
                variables: {
                    ids: JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_KEY_SKINS))
                }
            });
        }
    }, [getSkinsById, skins]);

    useEffect(() => {
        if (localStorage.getItem(Constant.LOCAL_STORAGE_KEY_BUNDLES) !== null) {
            getBundlesById({
                variables: {
                    ids: JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_KEY_BUNDLES))
                }
            });
        }
    }, [getBundlesById, bundles]);


    const addSkin = (skin: SkinDto) => {
        if (skin.price > 0) {
            let sc: SkinDto[] = Object.assign([], skins);
            sc.push(skin);
            localStorage.setItem(Constant.LOCAL_STORAGE_KEY_SKINS, JSON.stringify(sc.map(s => s.id)));
            setSkins(sc);
        }
    }

    const addBundle = (bundle: BundleDto) => {
        if (bundle.price > 0) {
            let bc: BundleDto[] = Object.assign([], skins);
            bc.push(bundle);
            localStorage.setItem(Constant.LOCAL_STORAGE_KEY_BUNDLES, JSON.stringify(bc.map(s => s.id)));
            setBundles(bc);
        }
    }

    const containsSkin = (skin: SkinDto) : boolean => {
        return skins.find(s => s.id === skin.id) !== undefined;
    }

    const containsBundle = (bundle: BundleDto) : boolean => {
        return bundles.find(b => b.id === bundle.id) !== undefined;
    }

    const removeSkin = (skin: SkinDto) => {
        let sc: SkinDto[] = Object.assign([], skins).filter(s => s.id !== skin.id);
        localStorage.setItem(Constant.LOCAL_STORAGE_KEY_SKINS, JSON.stringify(sc.map(s => s.id)));
        setSkins(sc);
    }

    const removeBundle = (bundle: BundleDto) => {
        let bc: BundleDto[] = Object.assign([], bundles).filter(b => b.id !== bundle.id);
        localStorage.setItem(Constant.LOCAL_STORAGE_KEY_BUNDLES, JSON.stringify(bc.map(b => b.id)));
        setBundles(bc);
    }

    const clear = () => {
        localStorage.removeItem(Constant.LOCAL_STORAGE_KEY_SKINS);
        localStorage.removeItem(Constant.LOCAL_STORAGE_KEY_BUNDLES);
        setSkins([]);
        setBundles([]);
    }

    return(
        <ShoppingCartContext.Provider value={{
            skins, bundles, addSkin, addBundle, containsSkin, containsBundle, removeSkin, removeBundle, clear
        }}>
            {props.children}
        </ShoppingCartContext.Provider>
    )
};
