import {ResourceDtoMapper} from "../../build/generated-sources/dto/ResourceDto";

export default class ResourceMapper {

	public static ALL: ResourceDtoMapper = {
		id: true,
		title: true,
		description: true,
		filename: true,
		type: true,
		order: true,
	}
}
