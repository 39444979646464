import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, Grid, Header, Icon, Message, Radio, TextArea} from "semantic-ui-react";
import {SkinInputDtoInput} from "../../../../build/generated-sources/dto/SkinInputDtoInput";
import {SkinTypeUtil} from "../../../../base/util/SkinTypeUtil";
import {View} from "../../../../base/enums/View";
import {Link, useHistory} from "react-router-dom";
import {useCreateSkin, useUpdateSkin} from "../../../../build/generated-sources/service/MutationService";
import SkinMapper from "../../../../base/mapper/SkinMapper";
import {ApolloError} from "@apollo/client";
import {UseCreateSkinData, UseUpdateSkinData} from "../../../../build/generated-sources/service/MutationServiceModel";
import {SyncOperationState} from "../../../../base/state/SyncOperationState";
import {SkinDto} from "../../../../build/generated-sources/dto/SkinDto";
import {toast} from "react-hot-toast";
import {Constant} from "../../../../base/enums/Constant";
import {Formatter} from "../../../../base/util/Formatter";
import {useGetMyCreatedSkins, useLazyGetSkin} from "../../../../build/generated-sources/service/QueryService";
import {UseGetMyCreatedSkinsData, UseGetSkinData} from "../../../../build/generated-sources/service/QueryServiceModel";
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import SkinAssetContainer from "../../components/SkinAsset";
import {ESkinType} from "../../../../build/generated-sources/enum/ESkinType";
import {EResourceType} from "../../../../build/generated-sources/enum/EResourceType";
import {ResourceDto} from "../../../../build/generated-sources/dto/ResourceDto";
import {AssetUtil} from "../../../../base/util/AssetUtil";
import {DroppableUtil} from "../../../../base/util/DroppableUtil";
import {Asset} from "../../../../base/dto/Asset";
import {ColorResult} from "react-color";
import SkinCardPreview from "../../../public/skin/SkinCardPreview";
import ColorPicker from "../components/ColorPicker";
import Dropzone from "../Dropzone";
import {SkinUtil} from "../../../../base/util/SkinUtil";
import PageMapper from "../../../../base/mapper/PageMapper";
import {ResourceUtil} from "../../../../base/util/ResourceUtil";
import { MySkinEntry } from "../bundle/BundleEditor";
import ModalSkinEditor from "../../components/ModalSkinEditor";
import SkinColorMarker from "../../../public/skin/SkinColorMarker";

interface SkinEditorProps {
	skin?: string
	onSave: (skin: SkinDto, toastMessage?: string) => void
	title?: string,
	subtitle?: string,
	standalone: boolean,
	type?: ESkinType,
	enableColorVariations: boolean
}

export default function SkinEditor(props: SkinEditorProps) {

	const history = useHistory();
	const [syncSourceState, setSyncSourceState] = useState<SyncOperationState>({inProgress: false, error: false});
	const [syncState, setSyncState] = useState<SyncOperationState>({inProgress: false, error: false, done: false});
	const [toastId, setToastId] = useState<string>();
	const [extraFiles, setExtraFiles] = useState<File[]>([]);
	const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
	const [validate, setValidate] = useState<boolean>(false);
	const [skinEditorOpen, setSkinEditorOpen] = useState<boolean>(false);

	// @ts-ignore
	const onDrop = useCallback(acceptedFiles => {
		setExtraFiles([...extraFiles, ...acceptedFiles])
	}, [extraFiles]);

	const [colorVariations, setColorVariations] = useState<SkinInputDtoInput[]>([]);
	const [images, setImages] = useState<Asset[]>([]);
	const [source, setSource] = useState<Asset>();
	const [singleColor, setSingleColor] = useState<boolean>(true);
	const [mySkins, setMySkins] = useState<SkinDto[]>([]);
	const [mySkinsDropdown, setMySkinsDropdown] = useState<MySkinEntry[]>([]);

	useEffect(() => {
		if (source && source.uploading) {
			upload(source);
		}
	}, [source])

	useEffect(() => {
		if (extraFiles.length === 0) {
			return;
		}

		let existingFilenames = images.filter(i => !!i.file).map(i => i.file.name);
		let count = images.length;
		let assets: Asset[] = Object.assign([], images);

		extraFiles.filter(i => !existingFilenames.includes(i.name)).forEach(file => assets.push(createFileAsset(file, EResourceType.IMAGE, count++)));
		setImages(assets);
	}, [extraFiles]);

	useEffect(() => {
		if (syncState.inProgress) {
			return;
		}
		let assetsForUpload = images.filter(i => i.file && !uploadedFiles.includes(i.file.name));

		if (assetsForUpload.length === 0) {
			return;
		}

		let upldFls = Object.assign([], uploadedFiles);
		assetsForUpload.forEach(a => upldFls.push(a.file.name));
		setUploadedFiles(upldFls);

		assetsForUpload.forEach(a => {
			upload(a)
		});

	}, [images])

	const [skin, setSkin] = useState<SkinInputDtoInput>({
		id: undefined,
		availableAlone: true,
		price: 0,
		title: '',
		description: '',
		videoUrl: '',
		colorPrimary: '#000000',
		colorSecondary: '#000000',
		type: props.type ? props.type : SkinTypeUtil.DEFAULT_TYPE.key,
	});

	const createUrlAsset = (resource: ResourceDto): Asset => {
		return {
			id: resource.filename,
			url: '/public/' + resource.filename,
			type: resource.type,
			success: true,
			uploading: false,
			order: resource.order
		}
	}

	const createFileAsset = (file: File, type: EResourceType, order: number): Asset => {
		return {
			id: file.name,
			file: file,
			type: type,
			success: false,
			uploading: true,
			order: order
		}
	}

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		if (result.source.index === result.destination.index) {
			return;
		}

		const items = AssetUtil.reorder(
			images,
			result.source.index,
			result.destination.index
		);
		setImages(items);
	}

	const upload = (asset: Asset) => {
		let form = new FormData();
		form.append("file", asset.file);
		const requestOptions = {
			method: 'POST',
			body: form
		};

		fetch('/assets/skin/temp', requestOptions).then(response => {
			response.text().then(data => {
				let assetResponse = JSON.parse(data);
				let ar = {
					id: assetResponse.id,
					success: assetResponse.success,
					message: assetResponse.message,
					uploading: false,
				}
				if (asset.type === EResourceType.IMAGE) {
					setImages((currentImages) =>
						currentImages.map(i => (i.id === asset.id ? Object.assign({}, {...i, ...ar}) : i))
					);
				} else if (asset.type === EResourceType.SKIN_SOURCE) {
					setSource((currentSource) => Object.assign({}, {...currentSource, ...ar}));
				}
			});
		});
	};

	const [getSkin] = useLazyGetSkin(SkinMapper.WITHOUT_AUTHOR, {
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetSkinData) => {
			setSkin({
				id: data.skin.id,
				availableAlone: data.skin.availableAlone,
				price: data.skin.price,
				title: data.skin.title,
				description: data.skin.description ?? '',
				colorPrimary: data.skin.colorPrimary ?? '#000000',
				colorSecondary: data.skin.colorSecondary ?? '#000000',
				videoUrl: data.skin.videoUrl ?? '',
				type: data.skin.type
			});
			let assets : Asset[] = data.skin.resources.map(r => {
				return createUrlAsset(r);
			});
			setSingleColor(!data.skin.colorSecondary);
			setImages(assets);
			setColorVariations(data.skin.colorVariations.map(s =>  {
				return {
					id: s.id,
					colorPrimary: s.colorPrimary,
					colorSecondary: s.colorSecondary,
					availableAlone: s.availableAlone
				}
			}));
		}
	});

	const getCreatedSkins = useGetMyCreatedSkins({
		pageInfo: PageMapper.ALL,
		content: SkinMapper.ALL
	}, {
		variables: {
			page: {
				page: 0,
				size: 100
			}
		},
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetMyCreatedSkinsData) => {
			setMySkins(data.myCreatedSkins.content);
			setMySkinsDropdown(data.myCreatedSkins.content
				.filter(item => item.id !== skin.id)
				.map(item => {
				const id = item.id.toString();
				return {
					key: id,
					value: id,
					text: item.title.length > 25 ? item.title.substr(0, 25) + '...' : item.title,
					image: {
						avatar: true,
						src: ResourceUtil.getThumbnailPath(item.resources)
					}
				};
			}));
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	useEffect(() => {
		if (props.skin && !skin.id) {
			getSkin({
				variables: {
					url: props.skin
				}
			});
		}
	}, [props.skin, getSkin, skin.id]);

	const [create] = useCreateSkin(SkinMapper.WITHOUT_AUTHOR, {
		onCompleted: (data: UseCreateSkinData) => {
			toast.dismiss(toastId);
			props.onSave(data.createSkin, props.standalone ? 'Skin uploaded and published!' : 'Skin uploaded and added to the bundle');
		},
		onError: (data: ApolloError) => {
			toast.dismiss(toastId);
			toast.error(<strong>Error uploading the skin.</strong>);
			setSyncState({
				...syncState,
				inProgress: false,
				error: true
			});
		}
	});

	const [update] = useUpdateSkin(SkinMapper.WITHOUT_AUTHOR, {
		onCompleted: (data: UseUpdateSkinData) => {
			toast.dismiss(toastId);
			props.onSave(data.updateSkin, 'Skin updated!');
			history.push(View.CREATED_SKINS.path);
		},
		onError: (data: ApolloError) => {
			toast.dismiss(toastId);
			toast.error(<strong>Error updating the skin.</strong>);
			setSyncState({
				...syncState,
				inProgress: false,
				error: true
			});
		}
	});

	const onFieldChange = (value: any, field: string) => {
		setSkin({
			...skin,
			[field]: value
		});
	}

	const onChange = (e: any, field: string) => {
		onFieldChange(e.target.value, field);
	}

	const onSelectChange = (target: any, field: string) => {
		onFieldChange(target.value, field);
	}

	const onColorChange = (color: ColorResult, field: string) => {
		onFieldChange(color.hex, field);
	}

	const removeImage = (asset: Asset) => {
		let assets = images.filter(a => a.id !== asset.id)
			.map(a => {
				return {...a, order: a.order > asset.order ? a.order - 1 : a.order}
			});
		setUploadedFiles(uploadedFiles.filter(f => (!asset.file || f !== asset.file.name)));
		setImages(assets);
		setExtraFiles(extraFiles.filter(f => (!asset.file || f.name !== asset.file.name)));
	}

	const validateSkinSource = (file: File) => {
		return file.size <= Constant.MAX_SKIN_SOURCE_SIZE && file.name.endsWith(".zip");
	}

	const onSkinSourceChange = (event: any) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			if (!validateSkinSource(file)) {
				setSyncSourceState({...syncSourceState, error: true});
			} else {
				setSyncSourceState({...syncSourceState, error: false})
				setSource(createFileAsset(file, EResourceType.SKIN_SOURCE, 0));
			}
		}
	};

	const publish = () => {
		if (!SkinUtil.validate(skin, images, source, props.skin)) {
			setValidate(true);
			return;
		}
		setValidate(false);
		setSyncState({
			...syncState,
			inProgress: true
		});
		let cv : number[] = [];
		colorVariations.forEach(c => {
			if (!cv.includes(c.id)) {
				cv.push(c.id);
			}
		});
		let requestDto = {
			...skin,
			colorSecondary: singleColor ? undefined : skin.colorSecondary,
			colorVariations: cv,
			images: images.map(i => {
				return {
					id: i.id,
					temporary: !!i.file,
					type: EResourceType.IMAGE,
					order: i.order
				}
			}),
		}
		if (props.skin) {
			setToastId(toast.loading('Updating your skin...'));
			update({
				variables: {
					dto: requestDto
				}
			});
		} else {
			setToastId(toast.loading('Publishing your new skin...'));
			create({
				variables: {
					dto: {
						...requestDto,
						skinSource: {
							id: source.id,
							temporary: true,
							type: EResourceType.SKIN_SOURCE,
							order: source.order
						}
					}
				}
			});
		}
	}

	const getUploadInputLabel = (source?: Asset): string => {
		if (syncSourceState.error) {
			return "Must be a valid ZIP file (Max. " + Constant.MAX_SKIN_SOURCE_SIZE_MB + " MB)";
		}
		if (source && !source.success) {
			switch (source.message) {
				case "ZIP_TOO_LARGE":
					return "File is too large. Limit is " + Constant.MAX_SKIN_SOURCE_SIZE_MB + " MB."
				case "UNKNOWN_FILENAME":
				case "INVALID_FILENAME":
					return "Invalid file. Make sure you are uploading a valid .zip file."
			}
		}
		if ([ESkinType.TMNF, ESkinType.TMNF].includes(skin.type)) {
			return 'Skin ZIP file (Max. ' + Constant.MAX_SKIN_SOURCE_SIZE_MB + ' MB) - This ZIP should contain both .loc file and .zip with your skin'
		} else {
			return 'Skin ZIP file (Max. ' + Constant.MAX_SKIN_SOURCE_SIZE_MB + ' MB)';
		}
	}

	const uploadSourceInputId = props.standalone ? 'uploadSourceInput' : 'uploadSourceInputModal';

	const onClickUploadInput = () => {
		window.document.getElementById(uploadSourceInputId)?.click()
	}

	const p: any = skin.price;
	const price = Number.parseFloat(p);

	return (
		<Grid>
			<Grid.Row>
				<ModalSkinEditor
						enableColorVariations={false}
						type={skin.type}
						title={'Upload and Link New Skin'}
						subtitle={'It will be added as a color variation automatically'}
						open={skinEditorOpen}
						close={() => setSkinEditorOpen(false)}
						onSave={(skin, toastMessage) => {
							let skins = Object.assign([], colorVariations);
							skins.push(skin);
							getCreatedSkins.refetch({page: {page: 0, size: 100}});
							setColorVariations(skins);
							toast.success(toastMessage);
							setSkinEditorOpen(false);
						}}
					/>
				<Grid.Column width={10}>
					{
						<Header as='h2'>
							<Icon name='upload' />
							<Header.Content>
								{props.title ? props.title : (props.skin ? 'Update Skin' : 'Upload New Skin')}
								<Header.Subheader>{props.subtitle ? props.subtitle : (props.skin ? 'Changes will propagate immediately' : 'Publish your work in an instant')}</Header.Subheader>
							</Header.Content>
						</Header>
					}
					<Form className={'editor'}>
						<Form.Group>
							<Form.Input width={12} error={(validate && !SkinUtil.nameValid(skin)) || skin.title.length > Constant.MAX_SKIN_NAME_LENGTH} type={'text'}
										label={skin.title.length > ((Constant.MAX_SKIN_NAME_LENGTH / 3) * 2) ? 'Name (' + skin.title.length + '/' + Constant.MAX_SKIN_NAME_LENGTH + ')' : 'Name'}
										disabled={!!props.skin}
										value={skin.title} onChange={(e) => {
									onChange(e, 'title')
							}}/>
							<Form.Input width={4} error={!SkinUtil.priceValid(skin)} type={'number'}
										label={'Price (€)'} value={skin.price} onChange={(e) => onChange(e, 'price')}/>
						</Form.Group>
						{(isNaN(price) || price === 0) &&
							<Message icon={'thumbs up'} positive header={'This will be a free skin'} content={'Anyone will be able to download and use this skin.'}/>}
						{price > 0.001 && price < 2 &&
							<Message icon={'warning sign'} negative header={'Minimum price for paid skins is ' + Formatter.money(Constant.MIN_SKIN_PRICE)}
									 content={'This is to protect both you and the site from flat fees charge by the payment gates (like PayPal and Stripe). This could result in all (if not most) of the money to be taken by them and none of it reaching you.'}/>}
						{price >= 2 && price <= Constant.MAX_SKIN_PRICE && <Message icon={'money bill alternate outline'} positive header={'This will be a paid skin'}
																					content={'Every time someone buys your skin, the money will be collected and periodically sent to your account. You can track these payments in your profile under My Payouts. Make sure you have your bank account details setup via Profile Settings.'}/>}
						{price > Constant.MAX_SKIN_PRICE &&
							<Message icon={'warning sign'} negative header={'Maximum price for paid skins is ' + Formatter.money(Constant.MAX_SKIN_PRICE)}
									 content={'If you have serious interest in submitting such a high valued item to this site, let us personally know.'}/>}
						<Form.Select label='Game' value={skin.type} disabled={!!props.type} options={SkinTypeUtil.getOptions()}
									 onChange={(e, target) => onSelectChange(target, 'type')}
						/>
						<Form.Input control={TextArea}
									label={skin.description.length > ((Constant.MAX_SKIN_DESCR_LENGTH / 3) * 2) ? 'Description (' + skin.description.length + '/' + Constant.MAX_SKIN_DESCR_LENGTH + ')' : 'Description'}
									error={(validate && !SkinUtil.descriptionValid(skin)) || skin.description.length > Constant.MAX_SKIN_DESCR_LENGTH} value={skin.description} onChange={(e) => {
							onChange(e, 'description')
						}}/>
						<Form.Input type={'text'} label={'Youtube video URL (optional)'} placeholder={'https://www.youtube.com/watch?v=dQw4w9WgXcQ'} icon={'youtube'} value={skin.videoUrl}
									onChange={(e) => onChange(e, 'videoUrl')}/>

						<Form.Group>
							<Form.Input className={'color-picker'} width={4} style={{display: 'flex', justifyContent: 'center'}} label={'Primary Color'}>
								<ColorPicker color={skin.colorPrimary} onChange={(result) => onColorChange(result, 'colorPrimary')} />
							</Form.Input>
							<Form.Input disabled={singleColor} className={'color-picker'} width={4} style={{display: 'flex', justifyContent: 'center'}} label={'Secondary Color'} >
								<ColorPicker color={skin.colorSecondary} onChange={(result) => onColorChange(result, 'colorSecondary')} />
							</Form.Input>
							<Radio className={'color-picker-toggle'} toggle label={'Skin has two signature colors'} checked={!singleColor} onChange={() => setSingleColor(!singleColor)} />
						</Form.Group>

						{
							!props.skin && source && <Form.Input error={!source.uploading && !source.success} type={'text'} label={getUploadInputLabel(source)} value={source.file.name}
											onClick={onClickUploadInput} className={'hide-caret source'} action>
									<input/>
									<Button basic disabled={source.uploading} color={source.uploading ? 'black' : (source.success ? 'green' : 'red')}
											onClick={onClickUploadInput}>
										<Icon name={'file archive'}/>
										{source.uploading ? 'Uploading' : (source.success ? 'Uploaded' : 'Error')} &nbsp;&nbsp;&nbsp;
										<Icon name={(source.uploading) ? 'circle notch' : (source.success ? 'checkmark' : 'close')} loading={source.uploading}/>
									</Button>
								</Form.Input>
						}
						{
							!props.skin && !source && <Form.Input error={validate || syncSourceState.error} type={'text'} label={getUploadInputLabel()} value={''} onClick={onClickUploadInput}
										className={'hide-caret source'} action>
								<input/>
								<Button basic color={validate ? 'red' : 'blue'} onClick={onClickUploadInput}>
									<Icon name={'file archive'}/> Upload ZIP
								</Button>
							</Form.Input>
						}


						{
							!props.skin && <input hidden id={uploadSourceInputId} type={'file'} onChange={(e) => onSkinSourceChange(e)}/>
						}

						{
							images.length > 0 && <DragDropContext onDragEnd={onDragEnd}>
								<Header color={'black'} as={'h4'}><Icon name={'info circle'}/> Order Your Images by Dragging Them</Header>
								<Droppable droppableId="1" isDropDisabled={syncState.inProgress} direction="horizontal">
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											style={DroppableUtil.getListStyle(snapshot.isDraggingOver)}
											{...provided.droppableProps}
										>
											{images.map((item, index) => {
												return <Draggable key={item.id} draggableId={(item.id)} index={index}>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															style={DroppableUtil.getItemStyle(
																snapshot.isDragging,
																provided.draggableProps.style
															)}
														>
															<SkinAssetContainer asset={item} validate={validate} onRemove={removeImage}/>
														</div>
													)}
												</Draggable>
											})}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						}

						<Dropzone onDrop={onDrop} validate={validate} images={images} accept={{'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg']}} />

						{
							validate && !SkinUtil.validate(skin, images, source, props.skin) && <Message negative>
								<Message.Content>
									<Message.Header>Validation errors</Message.Header>
									<p>You must correct these issues before attempting to publish your skin:</p>
									<Message.List>
										{!props.skin && !source && <Message.Item>You must select your skin ZIP file.</Message.Item>}
										{!props.skin && source && !SkinUtil.sourceValid(source) && <Message.Item>
											You must wait for ZIP file to finish uploading. Error may occur if the ZIP file is too large or if it's not a valid ZIP file.
										</Message.Item>}
										{!SkinUtil.imagesExist(images) && <Message.Item>You must upload at least one image.</Message.Item>}
										{!SkinUtil.imagesValid(images) && <Message.Item>
											You must fix any images that failed to upload by removing them and trying to upload them again. Upload may have
											failed due to incorrect format, image being larger than {Constant.MAX_SKIN_IMAGE_SIZE_MB} MB or connection issues.
										</Message.Item>}
										{!SkinUtil.nameValid(skin) && <Message.Item>Skin name is a required field. It must have
											between {Constant.MIN_SKIN_NAME_LENGTH} and {Constant.MAX_SKIN_NAME_LENGTH} characters.</Message.Item>}
										{!SkinUtil.descriptionValid(skin) && <Message.Item>Description can have
											up to {Constant.MAX_SKIN_DESCR_LENGTH} characters.</Message.Item>}
									</Message.List>
								</Message.Content>
							</Message>
						}

						{
							props.enableColorVariations && <>

								<Header>Linked Color Variations ({colorVariations.length})</Header>

								<Button color={'blue'} disabled={syncState.inProgress} onClick={() => {
									let skins = Object.assign([], colorVariations);
									skins.push({});
									setColorVariations(skins);
								}}><Icon name={'plus'}/> Link Existing Skin</Button>
								<Button floated={'right'} disabled={syncState.inProgress} color={'blue'} onClick={() => setSkinEditorOpen(true)}><Icon name={'upload'}/> Upload and Link New Skin</Button>

								<br/>
								<br/>
								<br/>

								{
									colorVariations.map((skin, i) => {
										return <Form.Group key={i}>
											<Form.Select value={skin.id ? skin.id.toString() : skin.id} selection search label={'Name'} width={12} options={mySkinsDropdown} onChange={(e, item) => {
												let preset = mySkins.find(s => s.id.toString() === item.value);
												let skins = Object.assign([], colorVariations);
												skins[i].id = preset.id;
												skins[i].price = preset.price;
												skins[i].availableAlone = preset.availableAlone;
												skins[i].colorPrimary = preset.colorPrimary;
												skins[i].colorSecondary = preset.colorSecondary;
												setColorVariations(skins);
											}}/>
											<Form.Input className={'centered'} label={'Color'} width={2}>
												<SkinColorMarker primary={colorVariations[i].colorPrimary} secondary={colorVariations[i].colorSecondary}/>
											</Form.Input>

											<Form.Input className={'centered'} style={{justifyContent: 'center'}} label={'Remove'} width={2}>
												<Button basic disabled={syncState.inProgress} color={'red'} icon={'trash'} onClick={() => setColorVariations(colorVariations.filter(s => s.id !== colorVariations[i].id))} />
											</Form.Input>
										</Form.Group>
									})
								}

								<br/>
								<br/>
							</>

						}
						<Link to={View.CREATED_SKINS.path}><Button size={'large'} floated={'left'} disabled={syncState.inProgress}>Cancel</Button></Link>
						<Button size={'large'} color={'black'} floated={'right'} loading={syncState.inProgress} onClick={() => publish()}>
							<Icon name={syncState.inProgress ? 'circle notch' : 'upload'}/> {props.skin ? 'Update Skin' : 'Publish Skin'}
						</Button>
					</Form>
				</Grid.Column>
				<Grid.Column width={6}>
					<Header>Skin Card Preview</Header>
					<SkinCardPreview skin={skin} image={images.length ? images[0] : undefined}/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
}
