import React, {useState} from "react";
import {Button, Image, Form, Grid, Header, Icon, List, Segment, Card, Label} from "semantic-ui-react";
import {Country} from "../../base/util/Country";
import {UserDto} from "../../build/generated-sources/dto/UserDto";
import {useUpdateMyUser} from "../../build/generated-sources/service/MutationService";
import UserMapper from "../../base/mapper/UserMapper";
import {ApolloError} from "@apollo/client";
import {Link} from "react-router-dom";
import {View} from "../../base/enums/View";
import {SyncOperationState} from "../../base/state/SyncOperationState";
import {toast} from "react-hot-toast";
import {UserInputDtoInput} from "../../build/generated-sources/dto/UserInputDtoInput";
import {EPayoutMethod} from "../../build/generated-sources/enum/EPayoutMethod";

interface ProfileSettingsProps {
	user: UserDto
	onUpdate: (user : UserDto) =>  void
}
export default function ProfileSettings(props: ProfileSettingsProps) {

	const [payoutMethod, setPayoutMethod] = useState<EPayoutMethod>(props.user.payoutMethod ?? undefined);
	const [user, setUser] = useState<UserInputDtoInput>({
		id: props.user.id,
		username: props.user.username,
		email: props.user.email,
		description: props.user.description ?? '',
		street: props.user.street ?? '',
		city: props.user.city ?? '',
		country: props.user.country ?? Country.DEFAULT_COUNTRY.key,
		fullname: props.user.fullname ?? '',
		facebook: props.user.facebook ?? '',
		instagram: props.user.instagram ?? '',
		twitch: props.user.twitch ?? '',
		twitter: props.user.twitter ?? '',
		discord: props.user.discord ?? '',
		website: props.user.website ?? '',
		subscribedToNews: props.user.subscribedToNews ?? false,
		payoutMethod: props.user.payoutMethod ?? undefined,
		paypalAccount: props.user.paypalAccount ?? '',
		bankAccountIban: props.user.bankAccountIban ?? '',
		bankAccountBic: props.user.bankAccountBic ?? '',
	});

	const [syncState, setSyncState] = useState<SyncOperationState>({
		inProgress: false,
		error: false,
		done: false
	});

	const [updateUser] = useUpdateMyUser(UserMapper.PRIVATE_ALL, {
		variables: {
			dto: user
		},
		onCompleted: () => {
			setSyncState({
				inProgress: false,
				error: false,
				done: true
			});
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
			setSyncState({
				inProgress: false,
				error: true,
				done: true
			});
		}
	})

	const onChange = (e : any, field : string) => {
		setUser({
			...user,
			[field]: e.target.value
		})
	}

	const onFileChange = (event : any) => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const filetype = event.target.files[0].type;
			if (file.size > 500000 || (!['image/jpeg'].includes(filetype))) {
				// TODO throw error
				return;
			}
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				let url = URL.createObjectURL(file);
				setUser({
					...user,
					avatar: {
						filename: file.name,
						url: url,
						type: filetype,
						content: reader.result
					}
				});
			}
		}
	};

	const save = () => {
		setSyncState({
			inProgress: true,
			done: false
		});
		toast.promise(
			updateUser({
				variables: {
					dto: {
						...user,
						payoutMethod: payoutMethod
					}
				}
			}),
			{
				loading: 'Updating profile...',
				success: <strong>Profile updated!</strong>,
				error: <strong>Error updating your profile.</strong>,
			}
		);
	}

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='settings' />
				<Header.Content>
					Profile Settings
					<Header.Subheader>Hi there! Where are you from?</Header.Subheader>
				</Header.Content>
			</Header>

			<Form>
				<Form.Group>
					<Form.Input width={6} type={'text'} label={'Username'} value={user.username} disabled />
					<Form.Input width={6} type={'text'} label={'Email'} value={user.email} disabled />
					<Form.Select width={6} label={'Country'} required labeled fluid search selection
					   options={Country.ALL_COUNTRIES}
					   value={user.country}
					   onChange={(e, data : any) => setUser({...user, country: data.value})}
					/>
				</Form.Group>
				<Form.Group style={props.user.avatar ? {paddingLeft: '8px'} : {}}>
					{props.user.avatar && <Image size={'tiny'} src={'/public/' + props.user.avatar} />}
					<Form.Input width={16} type={'file'} label={'Avatar Image (max. 400x400)'} onChange={(e:any) => onFileChange(e)} />
				</Form.Group>
				<Form.Checkbox checked={user.subscribedToNews} label={'Notify me about latest news, skins and events via email'}
							   onChange={(e, data) => setUser({...user, subscribedToNews: data.checked})}/>

				{
					props.user.skins && props.user.skins.length > 0 && <>

						<br/>

						<Header as='h3'>
							<Icon name='twitter' />
							<Header.Content>
								Social Media
								<Header.Subheader>Show players where they can reach you on social media</Header.Subheader>
							</Header.Content>
						</Header>

						<br/>

						<Form.Group>
							<Form.Input width={6} type={'text'} icon={'twitter'} label={'Twitter'} value={user.twitter} onChange={(e) => onChange(e, 'twitter')} />
							<Form.Input width={6} type={'text'} icon={'instagram'} label={'Instagram'} value={user.instagram} onChange={(e) => onChange(e, 'instagram')} />
							<Form.Input width={6} type={'text'} icon={'twitch'} label={'Twitch'} value={user.twitch} onChange={(e) => onChange(e, 'twitch')} />
						</Form.Group>
						<Form.Group>
							<Form.Input width={6} type={'text'} icon={'discord'} placeholder={'Player#1234'} label={'Discord'} value={user.discord} onChange={(e) => onChange(e, 'discord')} />
							<Form.Input width={6} type={'text'} icon={'facebook'} label={'Facebook'} value={user.facebook} onChange={(e) => onChange(e, 'facebook')} />
							<Form.Input width={6} type={'text'} icon={'computer'} label={'Website'} value={user.website} onChange={(e) => onChange(e, 'website')} />
						</Form.Group>
						<Form.Input label={'Short bio'} placeholder={'Tell us something about yourself...'} value={user.description} onChange={(e) => onChange(e, 'description')}/>
						<p>
							<Icon name={'warning circle'}/> Filling any of these in will make your social media accounts visible to the public.
						</p>


						<br/>

						<Header as='h3'>
							<Icon name='university' />
							<Header.Content>
								Payout details
								<Header.Subheader>For Creators with paid skins</Header.Subheader>
							</Header.Content>
						</Header>

						<Segment>
							<p><Icon name={'warning sign'}/> <strong>The following information is required only if:</strong></p>
							<List bulleted>
								<List.Item>You submitted paid skins (Their price is higher than 0).</List.Item>
								<List.Item>And you want to get paid for every purchase made.</List.Item>
							</List>
						</Segment>

						<br/>

						<Header textAlign={'center'}>Select Payout method</Header>

						<Card.Group className={'editor-prompt'} centered>
							<Card className={payoutMethod === EPayoutMethod.PAYPAL ? 'selected' : ''} color={'blue'} style={{
								opacity: payoutMethod === EPayoutMethod.BANK_TRANSFER ? 0.5 : 1,
								borderRadius: 0,
								padding: '20px 20px 20px',
								width: '40%',
								height: '180px',
								color: 'black'
							}} onClick={() => setPayoutMethod(EPayoutMethod.PAYPAL)}>
								{payoutMethod === EPayoutMethod.PAYPAL && <Label corner color={'green'} icon={'check'}/>}
								<Header as='h2' icon style={{margin: 0}}>
									<Icon name={'paypal'}/>
									PayPal
								</Header>
								<Card.Description textAlign={'center'}>Money will be sent to your PayPal</Card.Description>
							</Card>
							<Card className={payoutMethod === EPayoutMethod.BANK_TRANSFER ? 'selected' : ''} color={'blue'} style={{
								opacity: payoutMethod === EPayoutMethod.PAYPAL ? 0.5 : 1,
								borderRadius: 0,
								padding: '20px 20px 20px',
								width: '40%',
								height: '180px',
								color: 'black'
							}} onClick={() => setPayoutMethod(EPayoutMethod.BANK_TRANSFER)}>
								{payoutMethod === EPayoutMethod.BANK_TRANSFER && <Label corner color={'green'} icon={'check'}/>}
								<Header as='h2' icon style={{margin: 0}}>
									<Icon name={'university'}/>
									Bank transfer
								</Header>
								<Card.Description textAlign={'center'}>Money will be sent to your bank account</Card.Description>
							</Card>
						</Card.Group>

						<br/>

						{
							payoutMethod === EPayoutMethod.PAYPAL && <>
								<Segment>
									<p>
										<Icon name={'info circle'} /> Every purchase of your skins made by players is collected by us and attached to your profile. Then, upon request, we manually transfer all outstanding
										payments to your PayPal account. Unlike <strong>Bank transfer</strong> we are (for now) unable to process PayPal payouts automatically, therefor we ask you for patience when processing these
										requests.
									</p>
									<p>
										You can see detailed information about pending (and already processed) payments on your profile on <Link to={View.PAYOUTS.path}>My Payouts</Link> page.
									</p>
								</Segment>

								<Header as={'h3'}>PayPal information</Header>
								<Form.Group>
									<Form.Input width={8} type={'text'} label={'PayPal email address'} value={user.paypalAccount} onChange={(e) => onChange(e, 'paypalAccount')} />
									<Form.Input width={8} type={'text'} label={'Your full name'} value={user.fullname} onChange={(e) => onChange(e, 'fullname')} />
								</Form.Group>
							</>
						}

						{
							payoutMethod === EPayoutMethod.BANK_TRANSFER && <>
								<Segment>
									<p>
										<Icon name={'info circle'} /> To correctly create, setup and process bank transactions, we are required to attach your name, street address, city
										and country with the transaction. <strong>Incorrect data will result in transactions to your account being declined.</strong>
									</p>
									<p>
										Every purchase of your skins made by players is collected by us and attached to your profile. Then, twice a month, we transfer all outstanding
										payments to you. If you fail to provide the required information below, these transfers <strong>will be postponed until you provide this information</strong>.
										You will not lose anything, but the payment will be delayed until we have this information available.
									</p>
									<p>
										You can see detailed information about pending (and already processed) payments on your profile on <Link to={View.PAYOUTS.path}>My Payouts</Link> page.
									</p>
								</Segment>

								<Header as={'h3'}>Personal information</Header>
								<Form.Group>
									<Form.Input width={8} type={'text'} label={'Your full name'} value={user.fullname} onChange={(e) => onChange(e, 'fullname')} />
									<Form.Input width={8} type={'text'} label={'Street'} value={user.street} onChange={(e) => onChange(e, 'street')} />
								</Form.Group>
								<Form.Group>
									<Form.Input width={8} type={'text'} label={'City'} value={user.city} onChange={(e) => onChange(e, 'city')} />
									<Form.Select width={8} label={'Country'} required labeled fluid search selection
												 options={Country.ALL_COUNTRIES}
												 value={user.country}
												 onChange={(e) => onChange(e, 'country')}
									/>
								</Form.Group>

								<Header as={'h3'}>Bank Account Details</Header>
								<Form.Group>
									<Form.Input width={8} type={'text'} error={user.bankAccountIban.length > 34} icon={'university'} placeholder={'CZ1234560000000123456789'} label={'IBAN'} value={user.bankAccountIban} onChange={(e) => onChange(e, 'bankAccountIban')} />
									<Form.Input width={8} type={'text'} error={user.bankAccountBic.length > 11} label={'BIC (SWIFT)'} placeholder={'DEUTAA1XXXX'} value={user.bankAccountBic} onChange={(e) => onChange(e, 'bankAccountBic')} />
								</Form.Group>
								<Grid>
									<Grid.Column width={8}>
										<Icon name={'info circle'}/> <strong>IBAN</strong> is your bank account number for internation bank transfers. Typically up to 34 characters.
									</Grid.Column>
									<Grid.Column width={8}>
										<Icon name={'info circle'}/> <strong>BIC (also known as SWIFT)</strong> is an internation identifier of your bank. Typically 8 to 11 characters.
									</Grid.Column>
								</Grid>
							</>
						}

					</>
				}
				<br/>
				<Button floated='right' color={'black'} loading={syncState.inProgress} disabled={syncState.inProgress} onClick={() => save()}><Icon name={'save'}/> Update Profile</Button>
			</Form>
		</>
	);
}
