import React, {useEffect, useMemo, useState} from "react";
import {Container, Flag, Image, Grid, Header, Icon, Loader} from "semantic-ui-react";
import {useGetAuthor} from "../../../build/generated-sources/service/QueryService";
import {UseGetAuthorData} from "../../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import SkinCard from "../skin/SkinCard";
import {UserDto} from "../../../build/generated-sources/dto/UserDto";
import {Link} from "react-router-dom";
import {View} from "../../../base/enums/View";
import UserMapper from "../../../base/mapper/UserMapper";
import moment from "moment";

interface AuthorProps {
	url: string
}

export default function Author(props: AuthorProps) {

	const [author, setAuthor] = useState<UserDto>();
	const [flag, setFlag] = useState<any>();

	const getAuthor = useGetAuthor(UserMapper.AUTHOR, {
			variables: {
				url: props.url
			},
			onCompleted: (data: UseGetAuthorData) => {
				setAuthor(data.author);
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	useEffect(() => {
		if (author && author.country) {
			setFlag(author.country);
		}
	}, [author]);

	const latestSkins = useMemo(() => {
		if (author) {
			return author.skins.slice(0).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1);
		}
		return [];
	}, [author]);

	return (
		<Container>
			{
				getAuthor.loading || !author ? <Loader size='large'>Loading...</Loader>
					:
					<>
						<Grid stackable columns={2} className={'author'}>
							<Grid.Column>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<div style={{display: 'flex', flexDirection: 'column'}}>

										<Header as='h3'>
											<Icon name='user'/>
											<Header.Content>
												{author.username}&nbsp;&nbsp;{flag && <Flag name={flag}/>}
												<Header.Subheader>Joined {moment(author.createdAt, moment.ISO_8601).format("DD. MM. YYYY")}</Header.Subheader>
											</Header.Content>
										</Header>

										<div>
											{author.website && <a rel="noreferrer" target="_blank" href={author.website}><Icon size={'large'} name={'sitemap'}/></a>}
											{author.facebook &&
												<a rel="noreferrer" target="_blank" href={'https://facebook.com/' + author.facebook}><Icon size={'large'} name={'facebook'}/></a>}
											{author.twitter &&
												<a rel="noreferrer" target="_blank" href={'https://twitter.com/' + author.twitter}><Icon size={'large'} name={'twitter'}/></a>}
											{author.instagram &&
												<a rel="noreferrer" target="_blank" href={'https://instagram.com/' + author.instagram}><Icon size={'large'} name={'instagram'}/></a>}
											{author.twitch &&
												<a rel="noreferrer" target="_blank" href={'https://twitch.com/' + author.twitch}><Icon size={'large'} name={'twitch'}/></a>}
										</div>

										{
											author.description && <>
												<Header as='h4'>About me</Header>
												<p>{author.description}</p>
											</>
										}
									</div>
								</div>
							</Grid.Column>
							{
								author.avatar && <Grid.Column className={'avatar'}>
									<Image size={'medium'} alt={'Avatar image of ' + author.username} src={'/public/' + author.avatar}/>
								</Grid.Column>
							}
						</Grid>
						<Grid stackable columns={3} className={'author'}>
							{
								latestSkins.map(skin => {
									return <Grid.Column key={skin.id}>
										<Link to={View.SKIN.path + skin.url}>
											<SkinCard skin={skin} showRating showPurchase linkToSkin/>
										</Link>
									</Grid.Column>
								})
							}
						</Grid>
					</>
			}
		</Container>
	);
}
