import React, {useCallback, useEffect, useState} from "react";
import {Button, Container, Form, Grid, Header, Icon, Segment} from "semantic-ui-react";
import {useGetAuthors} from "../../../build/generated-sources/service/QueryService";
import {ApolloError} from "@apollo/client";
import PaginationControls from "../../base/component/PaginationControls";
import {PageInfo} from "../../../build/generated-sources/dto/PageInfo";
import {UserDto} from "../../../build/generated-sources/dto/UserDto";
import {UseGetAuthorsData} from "../../../build/generated-sources/service/QueryServiceModel";
import AuthorCard from "./AuthorCard";
import PageMapper from "../../../base/mapper/PageMapper";
import UserMapper from "../../../base/mapper/UserMapper";
import SimpleLoader from "../../../components/SimpleLoader";
import {Constant} from "../../../base/enums/Constant";
import {useHistory, useLocation} from "react-router-dom";
import {QueryUtil} from "../../../base/util/QueryUtil";

export default function AuthorList() {

	const location = useLocation();
	const history = useHistory();
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: Constant.PAGE_LENGTH_AUTHORS,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});
	const [searchTemp, setSearchTemp] = useState<string>('');
	const [search, setSearch] = useState<string>('');
	const [authors, setAuthors] = useState<UserDto[]>([]);

	const getAuthors = useGetAuthors({
			pageInfo: PageMapper.ALL,
			content: UserMapper.AUTHOR
		}, {
			variables: {
				page: {
					page: pageInfo.pageNumber,
					size: pageInfo.pageSize,
				},
				search: search
			},
			onCompleted: (data: UseGetAuthorsData) => {
				setAuthors(data.authors.content);
				setPageInfo(data.authors.pageInfo);
			},
			onError: (data: ApolloError) => {
				console.error(data.message)
			}
		}
	);

	useEffect(() => {
		const search = QueryUtil.getParam(location.search, "s");
		if (search) {
			setSearchTemp(search);
			setSearch(search);
		}
	}, [location.search]);

	const setQueryParam = useCallback((key: string, value: string) => {
		const currentUrlParams = new URLSearchParams(window.location.search);
		currentUrlParams.set(key, value);
		history.push(window.location.pathname + "?" + currentUrlParams.toString());
	}, [history]);

	useEffect(() => {
		const delayTimeout = setTimeout(() => {
			if (searchTemp.length >= 3 || (searchTemp.length === 0 && search.length > 0)) {
				setQueryParam("s", searchTemp);
				setSearch(searchTemp);
			}
		}, Constant.SEARCH_QUERY_TYPING_DELAY)
		return () => clearTimeout(delayTimeout)
	}, [searchTemp, search.length, setQueryParam])

	const onSearchChange = (text: string) => {
		setSearchTemp(text);
	}

	return (
		<Container>
			<Form>
				<Form.Group className={'filter right'}>
					<Form.Input icon={'search'} placeholder={'Find author...'} type={'text'} value={searchTemp} onChange={(e) => onSearchChange(e.target.value)}/>
				</Form.Group>
			</Form>
			<Grid stackable columns={4}>
				{
					getAuthors.loading ? <SimpleLoader text={'Loading authors...'}/> : <>
						{
							authors.length === 0 && <Grid.Column width={16}>
								<Segment placeholder>
									<Header icon>
										<Icon name='search'/> There are no skins matching your search query.
									</Header>
									<Segment.Inline>
										<Button color={'black'} onClick={() => {setSearchTemp(''); setSearch('')}}>Clear Search Query</Button>
									</Segment.Inline>
								</Segment>
							</Grid.Column>
						}
						{
							authors.map(author => {
								return <Grid.Column key={author.id}>
									<AuthorCard linkToProfile={true} author={author} detail={true}/>
								</Grid.Column>
							})
						}
					</>
				}
			</Grid>
			<PaginationControls disabled={getAuthors.loading} current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
				setPageInfo({
					...pageInfo,
					pageNumber: page
				});
			}}/>
		</Container>
	);
}
