import {
  QueryHookOptions,
  useQuery,
  useLazyQuery,
  QueryResult,
  QueryTuple,
} from '@apollo/client';
import {GraphqlBuilder, GraphQLType} from '@redrobot/npm-core-generator';
import { PageableResponse_CommentDto_CommentMapper } from '../dto/PageableResponse_CommentDto_Comment'
import {UseGetAllCommentsData, UseGetAllCommentsVariables} from './QueryServiceModel'
import { DataGraphListResponse_DownloadHistoryDtoMapper } from '../dto/DataGraphListResponse_DownloadHistoryDto'
import {UseGetMyDownloadHistoryData, UseGetMyDownloadHistoryVariables} from './QueryServiceModel'
import { SimpleResponseDtoMapper } from '../dto/SimpleResponseDto'
import {UseGetCanDeleteBundleData, UseGetCanDeleteBundleVariables} from './QueryServiceModel'
import {UseGetCanDeleteSkinData, UseGetCanDeleteSkinVariables} from './QueryServiceModel'
import { UserDtoMapper } from '../dto/UserDto'
import {UseGetMyUserData, UseGetMyUserVariables} from './QueryServiceModel'
import { MoneySpentSummaryDtoMapper } from '../dto/MoneySpentSummaryDto'
import {UseGetMoneySpentData, UseGetMoneySpentVariables} from './QueryServiceModel'
import { AuthorPayoutBatchDtoMapper } from '../dto/AuthorPayoutBatchDto'
import {UseGetPayoutData, UseGetPayoutVariables} from './QueryServiceModel'
import { SkinDtoMapper } from '../dto/SkinDto'
import {UseGetSkinData, UseGetSkinVariables} from './QueryServiceModel'
import {UseGetSkinOfTheMonthData, UseGetSkinOfTheMonthVariables} from './QueryServiceModel'
import { PageableResponse_SkinDto_SkinMapper } from '../dto/PageableResponse_SkinDto_Skin'
import {UseGetMyOwnedSkinsData, UseGetMyOwnedSkinsVariables} from './QueryServiceModel'
import { BundleDtoMapper } from '../dto/BundleDto'
import {UseGetBundlesByIdData, UseGetBundlesByIdVariables} from './QueryServiceModel'
import {UseGetSkinsData, UseGetSkinsVariables} from './QueryServiceModel'
import { SimpleIdDtoMapper } from '../dto/SimpleIdDto'
import {UseGetMyOwnedBundleIdsData, UseGetMyOwnedBundleIdsVariables} from './QueryServiceModel'
import { PageableResponse_BundleDto_BundleMapper } from '../dto/PageableResponse_BundleDto_Bundle'
import {UseGetMyOwnedBundlesData, UseGetMyOwnedBundlesVariables} from './QueryServiceModel'
import {UseGetMyOwnedSkinIdsData, UseGetMyOwnedSkinIdsVariables} from './QueryServiceModel'
import {UseGetMyCreatedBundlesData, UseGetMyCreatedBundlesVariables} from './QueryServiceModel'
import {UseGetBundleData, UseGetBundleVariables} from './QueryServiceModel'
import {UseGetDownloadHistoryData, UseGetDownloadHistoryVariables} from './QueryServiceModel'
import { DataGraphListResponse_PurchaseHistoryDtoMapper } from '../dto/DataGraphListResponse_PurchaseHistoryDto'
import {UseGetPurchaseHistoryData, UseGetPurchaseHistoryVariables} from './QueryServiceModel'
import { DownloadCountSummaryDtoMapper } from '../dto/DownloadCountSummaryDto'
import {UseGetMyDownloadCountData, UseGetMyDownloadCountVariables} from './QueryServiceModel'
import {UseGetCommentsData, UseGetCommentsVariables} from './QueryServiceModel'
import {UseGetAuthorData, UseGetAuthorVariables} from './QueryServiceModel'
import { PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatchMapper } from '../dto/PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatch'
import {UseGetMyPayoutsData, UseGetMyPayoutsVariables} from './QueryServiceModel'
import { SkinPurchaseCountSummaryDtoMapper } from '../dto/SkinPurchaseCountSummaryDto'
import {UseGetSkinPurchasePopularityData, UseGetSkinPurchasePopularityVariables} from './QueryServiceModel'
import {UseGetAllPayoutsData, UseGetAllPayoutsVariables} from './QueryServiceModel'
import { PageableResponse_UserDto_UserMapper } from '../dto/PageableResponse_UserDto_User'
import {UseGetUsersData, UseGetUsersVariables} from './QueryServiceModel'
import { PageableResponse_PurchaseDto_PurchaseMapper } from '../dto/PageableResponse_PurchaseDto_Purchase'
import {UseGetPurchaseListData, UseGetPurchaseListVariables} from './QueryServiceModel'
import {UseGetMyPurchaseListData, UseGetMyPurchaseListVariables} from './QueryServiceModel'
import {UseGetMostDownloadedSkinsData, UseGetMostDownloadedSkinsVariables} from './QueryServiceModel'
import { SkinDownloadCountSummaryDtoMapper } from '../dto/SkinDownloadCountSummaryDto'
import {UseGetMySkinDownloadPopularityData, UseGetMySkinDownloadPopularityVariables} from './QueryServiceModel'
import {UseGetSkinDownloadPopularityData, UseGetSkinDownloadPopularityVariables} from './QueryServiceModel'
import {UseGetBundlesData, UseGetBundlesVariables} from './QueryServiceModel'
import {UseGetMyCreatedSkinsData, UseGetMyCreatedSkinsVariables} from './QueryServiceModel'
import {UseGetSkinsByIdData, UseGetSkinsByIdVariables} from './QueryServiceModel'
import {UseGetDownloadCountData, UseGetDownloadCountVariables} from './QueryServiceModel'
import {UseGetAuthorsData, UseGetAuthorsVariables} from './QueryServiceModel'

export function useGetAllComments(
  mapper: PageableResponse_CommentDto_CommentMapper,
  options?: QueryHookOptions<UseGetAllCommentsData, UseGetAllCommentsVariables>
): QueryResult<UseGetAllCommentsData, UseGetAllCommentsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_CommentDto_CommentMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('allComments')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',        
      })
      .setProperties(`page: $page`)
      .build(),
    options
  );
}

export function useLazyGetAllComments(
  mapper: PageableResponse_CommentDto_CommentMapper,
  options?: QueryHookOptions<UseGetAllCommentsData, UseGetAllCommentsVariables>
): QueryTuple<UseGetAllCommentsData, UseGetAllCommentsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_CommentDto_CommentMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('allComments')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',        
      })
      .setProperties(`page: $page`)
      .build(),
    options
  );
}


export function useGetMyDownloadHistory(
  mapper: DataGraphListResponse_DownloadHistoryDtoMapper,
  options?: QueryHookOptions<UseGetMyDownloadHistoryData, UseGetMyDownloadHistoryVariables>
): QueryResult<UseGetMyDownloadHistoryData, UseGetMyDownloadHistoryVariables> {
  const queryBuilder: GraphqlBuilder<DataGraphListResponse_DownloadHistoryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myDownloadHistory')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetMyDownloadHistory(
  mapper: DataGraphListResponse_DownloadHistoryDtoMapper,
  options?: QueryHookOptions<UseGetMyDownloadHistoryData, UseGetMyDownloadHistoryVariables>
): QueryTuple<UseGetMyDownloadHistoryData, UseGetMyDownloadHistoryVariables> {
  const queryBuilder: GraphqlBuilder<DataGraphListResponse_DownloadHistoryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myDownloadHistory')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetCanDeleteBundle(
  mapper: SimpleResponseDtoMapper,
  options?: QueryHookOptions<UseGetCanDeleteBundleData, UseGetCanDeleteBundleVariables>
): QueryResult<UseGetCanDeleteBundleData, UseGetCanDeleteBundleVariables> {
  const queryBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('canDeleteBundle')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}

export function useLazyGetCanDeleteBundle(
  mapper: SimpleResponseDtoMapper,
  options?: QueryHookOptions<UseGetCanDeleteBundleData, UseGetCanDeleteBundleVariables>
): QueryTuple<UseGetCanDeleteBundleData, UseGetCanDeleteBundleVariables> {
  const queryBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('canDeleteBundle')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}


export function useGetCanDeleteSkin(
  mapper: SimpleResponseDtoMapper,
  options?: QueryHookOptions<UseGetCanDeleteSkinData, UseGetCanDeleteSkinVariables>
): QueryResult<UseGetCanDeleteSkinData, UseGetCanDeleteSkinVariables> {
  const queryBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('canDeleteSkin')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}

export function useLazyGetCanDeleteSkin(
  mapper: SimpleResponseDtoMapper,
  options?: QueryHookOptions<UseGetCanDeleteSkinData, UseGetCanDeleteSkinVariables>
): QueryTuple<UseGetCanDeleteSkinData, UseGetCanDeleteSkinVariables> {
  const queryBuilder: GraphqlBuilder<SimpleResponseDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('canDeleteSkin')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}


export function useGetMyUser(
  mapper: UserDtoMapper,
  options?: QueryHookOptions<UseGetMyUserData, UseGetMyUserVariables>
): QueryResult<UseGetMyUserData, UseGetMyUserVariables> {
  const queryBuilder: GraphqlBuilder<UserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myUser')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetMyUser(
  mapper: UserDtoMapper,
  options?: QueryHookOptions<UseGetMyUserData, UseGetMyUserVariables>
): QueryTuple<UseGetMyUserData, UseGetMyUserVariables> {
  const queryBuilder: GraphqlBuilder<UserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myUser')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetMoneySpent(
  mapper: MoneySpentSummaryDtoMapper,
  options?: QueryHookOptions<UseGetMoneySpentData, UseGetMoneySpentVariables>
): QueryResult<UseGetMoneySpentData, UseGetMoneySpentVariables> {
  const queryBuilder: GraphqlBuilder<MoneySpentSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('moneySpent')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetMoneySpent(
  mapper: MoneySpentSummaryDtoMapper,
  options?: QueryHookOptions<UseGetMoneySpentData, UseGetMoneySpentVariables>
): QueryTuple<UseGetMoneySpentData, UseGetMoneySpentVariables> {
  const queryBuilder: GraphqlBuilder<MoneySpentSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('moneySpent')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetPayout(
  mapper: AuthorPayoutBatchDtoMapper,
  options?: QueryHookOptions<UseGetPayoutData, UseGetPayoutVariables>
): QueryResult<UseGetPayoutData, UseGetPayoutVariables> {
  const queryBuilder: GraphqlBuilder<AuthorPayoutBatchDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('payout')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}

export function useLazyGetPayout(
  mapper: AuthorPayoutBatchDtoMapper,
  options?: QueryHookOptions<UseGetPayoutData, UseGetPayoutVariables>
): QueryTuple<UseGetPayoutData, UseGetPayoutVariables> {
  const queryBuilder: GraphqlBuilder<AuthorPayoutBatchDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('payout')
      .setReturnProperties(mapper)
      .declareVariables({
        id: 'Long!',        
      })
      .setProperties(`id: $id`)
      .build(),
    options
  );
}


export function useGetSkin(
  mapper: SkinDtoMapper,
  options?: QueryHookOptions<UseGetSkinData, UseGetSkinVariables>
): QueryResult<UseGetSkinData, UseGetSkinVariables> {
  const queryBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('skin')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}

export function useLazyGetSkin(
  mapper: SkinDtoMapper,
  options?: QueryHookOptions<UseGetSkinData, UseGetSkinVariables>
): QueryTuple<UseGetSkinData, UseGetSkinVariables> {
  const queryBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('skin')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}


export function useGetSkinOfTheMonth(
  mapper: SkinDtoMapper,
  options?: QueryHookOptions<UseGetSkinOfTheMonthData, UseGetSkinOfTheMonthVariables>
): QueryResult<UseGetSkinOfTheMonthData, UseGetSkinOfTheMonthVariables> {
  const queryBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('skinOfTheMonth')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetSkinOfTheMonth(
  mapper: SkinDtoMapper,
  options?: QueryHookOptions<UseGetSkinOfTheMonthData, UseGetSkinOfTheMonthVariables>
): QueryTuple<UseGetSkinOfTheMonthData, UseGetSkinOfTheMonthVariables> {
  const queryBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('skinOfTheMonth')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetMyOwnedSkins(
  mapper: PageableResponse_SkinDto_SkinMapper,
  options?: QueryHookOptions<UseGetMyOwnedSkinsData, UseGetMyOwnedSkinsVariables>
): QueryResult<UseGetMyOwnedSkinsData, UseGetMyOwnedSkinsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_SkinDto_SkinMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myOwnedSkins')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`page: $page, type: $type`)
      .build(),
    options
  );
}

export function useLazyGetMyOwnedSkins(
  mapper: PageableResponse_SkinDto_SkinMapper,
  options?: QueryHookOptions<UseGetMyOwnedSkinsData, UseGetMyOwnedSkinsVariables>
): QueryTuple<UseGetMyOwnedSkinsData, UseGetMyOwnedSkinsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_SkinDto_SkinMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myOwnedSkins')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`page: $page, type: $type`)
      .build(),
    options
  );
}


export function useGetBundlesById(
  mapper: BundleDtoMapper,
  options?: QueryHookOptions<UseGetBundlesByIdData, UseGetBundlesByIdVariables>
): QueryResult<UseGetBundlesByIdData, UseGetBundlesByIdVariables> {
  const queryBuilder: GraphqlBuilder<BundleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('bundlesById')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}

export function useLazyGetBundlesById(
  mapper: BundleDtoMapper,
  options?: QueryHookOptions<UseGetBundlesByIdData, UseGetBundlesByIdVariables>
): QueryTuple<UseGetBundlesByIdData, UseGetBundlesByIdVariables> {
  const queryBuilder: GraphqlBuilder<BundleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('bundlesById')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}


export function useGetSkins(
  mapper: PageableResponse_SkinDto_SkinMapper,
  options?: QueryHookOptions<UseGetSkinsData, UseGetSkinsVariables>
): QueryResult<UseGetSkinsData, UseGetSkinsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_SkinDto_SkinMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('skins')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`search: $search, page: $page, type: $type`)
      .build(),
    options
  );
}

export function useLazyGetSkins(
  mapper: PageableResponse_SkinDto_SkinMapper,
  options?: QueryHookOptions<UseGetSkinsData, UseGetSkinsVariables>
): QueryTuple<UseGetSkinsData, UseGetSkinsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_SkinDto_SkinMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('skins')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`search: $search, page: $page, type: $type`)
      .build(),
    options
  );
}


export function useGetMyOwnedBundleIds(
  mapper: SimpleIdDtoMapper,
  options?: QueryHookOptions<UseGetMyOwnedBundleIdsData, UseGetMyOwnedBundleIdsVariables>
): QueryResult<UseGetMyOwnedBundleIdsData, UseGetMyOwnedBundleIdsVariables> {
  const queryBuilder: GraphqlBuilder<SimpleIdDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myOwnedBundleIds')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetMyOwnedBundleIds(
  mapper: SimpleIdDtoMapper,
  options?: QueryHookOptions<UseGetMyOwnedBundleIdsData, UseGetMyOwnedBundleIdsVariables>
): QueryTuple<UseGetMyOwnedBundleIdsData, UseGetMyOwnedBundleIdsVariables> {
  const queryBuilder: GraphqlBuilder<SimpleIdDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myOwnedBundleIds')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetMyOwnedBundles(
  mapper: PageableResponse_BundleDto_BundleMapper,
  options?: QueryHookOptions<UseGetMyOwnedBundlesData, UseGetMyOwnedBundlesVariables>
): QueryResult<UseGetMyOwnedBundlesData, UseGetMyOwnedBundlesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_BundleDto_BundleMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myOwnedBundles')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`page: $page, type: $type`)
      .build(),
    options
  );
}

export function useLazyGetMyOwnedBundles(
  mapper: PageableResponse_BundleDto_BundleMapper,
  options?: QueryHookOptions<UseGetMyOwnedBundlesData, UseGetMyOwnedBundlesVariables>
): QueryTuple<UseGetMyOwnedBundlesData, UseGetMyOwnedBundlesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_BundleDto_BundleMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myOwnedBundles')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`page: $page, type: $type`)
      .build(),
    options
  );
}


export function useGetMyOwnedSkinIds(
  mapper: SimpleIdDtoMapper,
  options?: QueryHookOptions<UseGetMyOwnedSkinIdsData, UseGetMyOwnedSkinIdsVariables>
): QueryResult<UseGetMyOwnedSkinIdsData, UseGetMyOwnedSkinIdsVariables> {
  const queryBuilder: GraphqlBuilder<SimpleIdDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myOwnedSkinIds')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetMyOwnedSkinIds(
  mapper: SimpleIdDtoMapper,
  options?: QueryHookOptions<UseGetMyOwnedSkinIdsData, UseGetMyOwnedSkinIdsVariables>
): QueryTuple<UseGetMyOwnedSkinIdsData, UseGetMyOwnedSkinIdsVariables> {
  const queryBuilder: GraphqlBuilder<SimpleIdDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myOwnedSkinIds')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetMyCreatedBundles(
  mapper: PageableResponse_BundleDto_BundleMapper,
  options?: QueryHookOptions<UseGetMyCreatedBundlesData, UseGetMyCreatedBundlesVariables>
): QueryResult<UseGetMyCreatedBundlesData, UseGetMyCreatedBundlesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_BundleDto_BundleMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myCreatedBundles')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',        
      })
      .setProperties(`page: $page`)
      .build(),
    options
  );
}

export function useLazyGetMyCreatedBundles(
  mapper: PageableResponse_BundleDto_BundleMapper,
  options?: QueryHookOptions<UseGetMyCreatedBundlesData, UseGetMyCreatedBundlesVariables>
): QueryTuple<UseGetMyCreatedBundlesData, UseGetMyCreatedBundlesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_BundleDto_BundleMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myCreatedBundles')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',        
      })
      .setProperties(`page: $page`)
      .build(),
    options
  );
}


export function useGetBundle(
  mapper: BundleDtoMapper,
  options?: QueryHookOptions<UseGetBundleData, UseGetBundleVariables>
): QueryResult<UseGetBundleData, UseGetBundleVariables> {
  const queryBuilder: GraphqlBuilder<BundleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('bundle')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}

export function useLazyGetBundle(
  mapper: BundleDtoMapper,
  options?: QueryHookOptions<UseGetBundleData, UseGetBundleVariables>
): QueryTuple<UseGetBundleData, UseGetBundleVariables> {
  const queryBuilder: GraphqlBuilder<BundleDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('bundle')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}


export function useGetDownloadHistory(
  mapper: DataGraphListResponse_DownloadHistoryDtoMapper,
  options?: QueryHookOptions<UseGetDownloadHistoryData, UseGetDownloadHistoryVariables>
): QueryResult<UseGetDownloadHistoryData, UseGetDownloadHistoryVariables> {
  const queryBuilder: GraphqlBuilder<DataGraphListResponse_DownloadHistoryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('downloadHistory')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetDownloadHistory(
  mapper: DataGraphListResponse_DownloadHistoryDtoMapper,
  options?: QueryHookOptions<UseGetDownloadHistoryData, UseGetDownloadHistoryVariables>
): QueryTuple<UseGetDownloadHistoryData, UseGetDownloadHistoryVariables> {
  const queryBuilder: GraphqlBuilder<DataGraphListResponse_DownloadHistoryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('downloadHistory')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetPurchaseHistory(
  mapper: DataGraphListResponse_PurchaseHistoryDtoMapper,
  options?: QueryHookOptions<UseGetPurchaseHistoryData, UseGetPurchaseHistoryVariables>
): QueryResult<UseGetPurchaseHistoryData, UseGetPurchaseHistoryVariables> {
  const queryBuilder: GraphqlBuilder<DataGraphListResponse_PurchaseHistoryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('purchaseHistory')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetPurchaseHistory(
  mapper: DataGraphListResponse_PurchaseHistoryDtoMapper,
  options?: QueryHookOptions<UseGetPurchaseHistoryData, UseGetPurchaseHistoryVariables>
): QueryTuple<UseGetPurchaseHistoryData, UseGetPurchaseHistoryVariables> {
  const queryBuilder: GraphqlBuilder<DataGraphListResponse_PurchaseHistoryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('purchaseHistory')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetMyDownloadCount(
  mapper: DownloadCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetMyDownloadCountData, UseGetMyDownloadCountVariables>
): QueryResult<UseGetMyDownloadCountData, UseGetMyDownloadCountVariables> {
  const queryBuilder: GraphqlBuilder<DownloadCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myDownloadCount')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetMyDownloadCount(
  mapper: DownloadCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetMyDownloadCountData, UseGetMyDownloadCountVariables>
): QueryTuple<UseGetMyDownloadCountData, UseGetMyDownloadCountVariables> {
  const queryBuilder: GraphqlBuilder<DownloadCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myDownloadCount')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetComments(
  mapper: PageableResponse_CommentDto_CommentMapper,
  options?: QueryHookOptions<UseGetCommentsData, UseGetCommentsVariables>
): QueryResult<UseGetCommentsData, UseGetCommentsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_CommentDto_CommentMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('comments')
      .setReturnProperties(mapper)
      .declareVariables({
        skin: 'Long',
        page: 'PageableInputInput',
        bundle: 'Long',        
      })
      .setProperties(`skin: $skin, page: $page, bundle: $bundle`)
      .build(),
    options
  );
}

export function useLazyGetComments(
  mapper: PageableResponse_CommentDto_CommentMapper,
  options?: QueryHookOptions<UseGetCommentsData, UseGetCommentsVariables>
): QueryTuple<UseGetCommentsData, UseGetCommentsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_CommentDto_CommentMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('comments')
      .setReturnProperties(mapper)
      .declareVariables({
        skin: 'Long',
        page: 'PageableInputInput',
        bundle: 'Long',        
      })
      .setProperties(`skin: $skin, page: $page, bundle: $bundle`)
      .build(),
    options
  );
}


export function useGetAuthor(
  mapper: UserDtoMapper,
  options?: QueryHookOptions<UseGetAuthorData, UseGetAuthorVariables>
): QueryResult<UseGetAuthorData, UseGetAuthorVariables> {
  const queryBuilder: GraphqlBuilder<UserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('author')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}

export function useLazyGetAuthor(
  mapper: UserDtoMapper,
  options?: QueryHookOptions<UseGetAuthorData, UseGetAuthorVariables>
): QueryTuple<UseGetAuthorData, UseGetAuthorVariables> {
  const queryBuilder: GraphqlBuilder<UserDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('author')
      .setReturnProperties(mapper)
      .declareVariables({
        url: 'String',        
      })
      .setProperties(`url: $url`)
      .build(),
    options
  );
}


export function useGetMyPayouts(
  mapper: PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatchMapper,
  options?: QueryHookOptions<UseGetMyPayoutsData, UseGetMyPayoutsVariables>
): QueryResult<UseGetMyPayoutsData, UseGetMyPayoutsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatchMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myPayouts')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',
        status: 'String',        
      })
      .setProperties(`page: $page, status: $status`)
      .build(),
    options
  );
}

export function useLazyGetMyPayouts(
  mapper: PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatchMapper,
  options?: QueryHookOptions<UseGetMyPayoutsData, UseGetMyPayoutsVariables>
): QueryTuple<UseGetMyPayoutsData, UseGetMyPayoutsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatchMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myPayouts')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',
        status: 'String',        
      })
      .setProperties(`page: $page, status: $status`)
      .build(),
    options
  );
}


export function useGetSkinPurchasePopularity(
  mapper: SkinPurchaseCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetSkinPurchasePopularityData, UseGetSkinPurchasePopularityVariables>
): QueryResult<UseGetSkinPurchasePopularityData, UseGetSkinPurchasePopularityVariables> {
  const queryBuilder: GraphqlBuilder<SkinPurchaseCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('skinPurchasePopularity')
      .setReturnProperties(mapper)
      .declareVariables({
        length: 'Int!',        
      })
      .setProperties(`length: $length`)
      .build(),
    options
  );
}

export function useLazyGetSkinPurchasePopularity(
  mapper: SkinPurchaseCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetSkinPurchasePopularityData, UseGetSkinPurchasePopularityVariables>
): QueryTuple<UseGetSkinPurchasePopularityData, UseGetSkinPurchasePopularityVariables> {
  const queryBuilder: GraphqlBuilder<SkinPurchaseCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('skinPurchasePopularity')
      .setReturnProperties(mapper)
      .declareVariables({
        length: 'Int!',        
      })
      .setProperties(`length: $length`)
      .build(),
    options
  );
}


export function useGetAllPayouts(
  mapper: PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatchMapper,
  options?: QueryHookOptions<UseGetAllPayoutsData, UseGetAllPayoutsVariables>
): QueryResult<UseGetAllPayoutsData, UseGetAllPayoutsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatchMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('allPayouts')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',
        status: 'String',        
      })
      .setProperties(`page: $page, status: $status`)
      .build(),
    options
  );
}

export function useLazyGetAllPayouts(
  mapper: PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatchMapper,
  options?: QueryHookOptions<UseGetAllPayoutsData, UseGetAllPayoutsVariables>
): QueryTuple<UseGetAllPayoutsData, UseGetAllPayoutsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_AuthorPayoutBatchDto_AuthorPayoutBatchMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('allPayouts')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',
        status: 'String',        
      })
      .setProperties(`page: $page, status: $status`)
      .build(),
    options
  );
}


export function useGetUsers(
  mapper: PageableResponse_UserDto_UserMapper,
  options?: QueryHookOptions<UseGetUsersData, UseGetUsersVariables>
): QueryResult<UseGetUsersData, UseGetUsersVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_UserDto_UserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('users')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        includeAnonymous: 'Boolean',
        page: 'PageableInputInput',        
      })
      .setProperties(`search: $search, includeAnonymous: $includeAnonymous, page: $page`)
      .build(),
    options
  );
}

export function useLazyGetUsers(
  mapper: PageableResponse_UserDto_UserMapper,
  options?: QueryHookOptions<UseGetUsersData, UseGetUsersVariables>
): QueryTuple<UseGetUsersData, UseGetUsersVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_UserDto_UserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('users')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        includeAnonymous: 'Boolean',
        page: 'PageableInputInput',        
      })
      .setProperties(`search: $search, includeAnonymous: $includeAnonymous, page: $page`)
      .build(),
    options
  );
}


export function useGetPurchaseList(
  mapper: PageableResponse_PurchaseDto_PurchaseMapper,
  options?: QueryHookOptions<UseGetPurchaseListData, UseGetPurchaseListVariables>
): QueryResult<UseGetPurchaseListData, UseGetPurchaseListVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_PurchaseDto_PurchaseMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('purchaseList')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',
        status: 'EPaymentStatus',        
      })
      .setProperties(`search: $search, page: $page, status: $status`)
      .build(),
    options
  );
}

export function useLazyGetPurchaseList(
  mapper: PageableResponse_PurchaseDto_PurchaseMapper,
  options?: QueryHookOptions<UseGetPurchaseListData, UseGetPurchaseListVariables>
): QueryTuple<UseGetPurchaseListData, UseGetPurchaseListVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_PurchaseDto_PurchaseMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('purchaseList')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',
        status: 'EPaymentStatus',        
      })
      .setProperties(`search: $search, page: $page, status: $status`)
      .build(),
    options
  );
}


export function useGetMyPurchaseList(
  mapper: PageableResponse_PurchaseDto_PurchaseMapper,
  options?: QueryHookOptions<UseGetMyPurchaseListData, UseGetMyPurchaseListVariables>
): QueryResult<UseGetMyPurchaseListData, UseGetMyPurchaseListVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_PurchaseDto_PurchaseMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myPurchaseList')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',        
      })
      .setProperties(`page: $page`)
      .build(),
    options
  );
}

export function useLazyGetMyPurchaseList(
  mapper: PageableResponse_PurchaseDto_PurchaseMapper,
  options?: QueryHookOptions<UseGetMyPurchaseListData, UseGetMyPurchaseListVariables>
): QueryTuple<UseGetMyPurchaseListData, UseGetMyPurchaseListVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_PurchaseDto_PurchaseMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myPurchaseList')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',        
      })
      .setProperties(`page: $page`)
      .build(),
    options
  );
}


export function useGetMostDownloadedSkins(
  mapper: SkinDtoMapper,
  options?: QueryHookOptions<UseGetMostDownloadedSkinsData, UseGetMostDownloadedSkinsVariables>
): QueryResult<UseGetMostDownloadedSkinsData, UseGetMostDownloadedSkinsVariables> {
  const queryBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('mostDownloadedSkins')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`search: $search, page: $page, type: $type`)
      .build(),
    options
  );
}

export function useLazyGetMostDownloadedSkins(
  mapper: SkinDtoMapper,
  options?: QueryHookOptions<UseGetMostDownloadedSkinsData, UseGetMostDownloadedSkinsVariables>
): QueryTuple<UseGetMostDownloadedSkinsData, UseGetMostDownloadedSkinsVariables> {
  const queryBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('mostDownloadedSkins')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`search: $search, page: $page, type: $type`)
      .build(),
    options
  );
}


export function useGetMySkinDownloadPopularity(
  mapper: SkinDownloadCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetMySkinDownloadPopularityData, UseGetMySkinDownloadPopularityVariables>
): QueryResult<UseGetMySkinDownloadPopularityData, UseGetMySkinDownloadPopularityVariables> {
  const queryBuilder: GraphqlBuilder<SkinDownloadCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('mySkinDownloadPopularity')
      .setReturnProperties(mapper)
      .declareVariables({
        length: 'Int!',        
      })
      .setProperties(`length: $length`)
      .build(),
    options
  );
}

export function useLazyGetMySkinDownloadPopularity(
  mapper: SkinDownloadCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetMySkinDownloadPopularityData, UseGetMySkinDownloadPopularityVariables>
): QueryTuple<UseGetMySkinDownloadPopularityData, UseGetMySkinDownloadPopularityVariables> {
  const queryBuilder: GraphqlBuilder<SkinDownloadCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('mySkinDownloadPopularity')
      .setReturnProperties(mapper)
      .declareVariables({
        length: 'Int!',        
      })
      .setProperties(`length: $length`)
      .build(),
    options
  );
}


export function useGetSkinDownloadPopularity(
  mapper: SkinDownloadCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetSkinDownloadPopularityData, UseGetSkinDownloadPopularityVariables>
): QueryResult<UseGetSkinDownloadPopularityData, UseGetSkinDownloadPopularityVariables> {
  const queryBuilder: GraphqlBuilder<SkinDownloadCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('skinDownloadPopularity')
      .setReturnProperties(mapper)
      .declareVariables({
        length: 'Int!',        
      })
      .setProperties(`length: $length`)
      .build(),
    options
  );
}

export function useLazyGetSkinDownloadPopularity(
  mapper: SkinDownloadCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetSkinDownloadPopularityData, UseGetSkinDownloadPopularityVariables>
): QueryTuple<UseGetSkinDownloadPopularityData, UseGetSkinDownloadPopularityVariables> {
  const queryBuilder: GraphqlBuilder<SkinDownloadCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('skinDownloadPopularity')
      .setReturnProperties(mapper)
      .declareVariables({
        length: 'Int!',        
      })
      .setProperties(`length: $length`)
      .build(),
    options
  );
}


export function useGetBundles(
  mapper: PageableResponse_BundleDto_BundleMapper,
  options?: QueryHookOptions<UseGetBundlesData, UseGetBundlesVariables>
): QueryResult<UseGetBundlesData, UseGetBundlesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_BundleDto_BundleMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('bundles')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`search: $search, page: $page, type: $type`)
      .build(),
    options
  );
}

export function useLazyGetBundles(
  mapper: PageableResponse_BundleDto_BundleMapper,
  options?: QueryHookOptions<UseGetBundlesData, UseGetBundlesVariables>
): QueryTuple<UseGetBundlesData, UseGetBundlesVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_BundleDto_BundleMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('bundles')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',
        type: 'ESkinType',        
      })
      .setProperties(`search: $search, page: $page, type: $type`)
      .build(),
    options
  );
}


export function useGetMyCreatedSkins(
  mapper: PageableResponse_SkinDto_SkinMapper,
  options?: QueryHookOptions<UseGetMyCreatedSkinsData, UseGetMyCreatedSkinsVariables>
): QueryResult<UseGetMyCreatedSkinsData, UseGetMyCreatedSkinsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_SkinDto_SkinMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('myCreatedSkins')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',        
      })
      .setProperties(`page: $page`)
      .build(),
    options
  );
}

export function useLazyGetMyCreatedSkins(
  mapper: PageableResponse_SkinDto_SkinMapper,
  options?: QueryHookOptions<UseGetMyCreatedSkinsData, UseGetMyCreatedSkinsVariables>
): QueryTuple<UseGetMyCreatedSkinsData, UseGetMyCreatedSkinsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_SkinDto_SkinMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('myCreatedSkins')
      .setReturnProperties(mapper)
      .declareVariables({
        page: 'PageableInputInput',        
      })
      .setProperties(`page: $page`)
      .build(),
    options
  );
}


export function useGetSkinsById(
  mapper: SkinDtoMapper,
  options?: QueryHookOptions<UseGetSkinsByIdData, UseGetSkinsByIdVariables>
): QueryResult<UseGetSkinsByIdData, UseGetSkinsByIdVariables> {
  const queryBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('skinsById')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}

export function useLazyGetSkinsById(
  mapper: SkinDtoMapper,
  options?: QueryHookOptions<UseGetSkinsByIdData, UseGetSkinsByIdVariables>
): QueryTuple<UseGetSkinsByIdData, UseGetSkinsByIdVariables> {
  const queryBuilder: GraphqlBuilder<SkinDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('skinsById')
      .setReturnProperties(mapper)
      .declareVariables({
        ids: '[Long]',        
      })
      .setProperties(`ids: $ids`)
      .build(),
    options
  );
}


export function useGetDownloadCount(
  mapper: DownloadCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetDownloadCountData, UseGetDownloadCountVariables>
): QueryResult<UseGetDownloadCountData, UseGetDownloadCountVariables> {
  const queryBuilder: GraphqlBuilder<DownloadCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('downloadCount')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}

export function useLazyGetDownloadCount(
  mapper: DownloadCountSummaryDtoMapper,
  options?: QueryHookOptions<UseGetDownloadCountData, UseGetDownloadCountVariables>
): QueryTuple<UseGetDownloadCountData, UseGetDownloadCountVariables> {
  const queryBuilder: GraphqlBuilder<DownloadCountSummaryDtoMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('downloadCount')
      .setReturnProperties(mapper)
      .build(),
    options
  );
}


export function useGetAuthors(
  mapper: PageableResponse_UserDto_UserMapper,
  options?: QueryHookOptions<UseGetAuthorsData, UseGetAuthorsVariables>
): QueryResult<UseGetAuthorsData, UseGetAuthorsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_UserDto_UserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useQuery(
    queryBuilder
      .setName('authors')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',        
      })
      .setProperties(`search: $search, page: $page`)
      .build(),
    options
  );
}

export function useLazyGetAuthors(
  mapper: PageableResponse_UserDto_UserMapper,
  options?: QueryHookOptions<UseGetAuthorsData, UseGetAuthorsVariables>
): QueryTuple<UseGetAuthorsData, UseGetAuthorsVariables> {
  const queryBuilder: GraphqlBuilder<PageableResponse_UserDto_UserMapper> = new GraphqlBuilder(GraphQLType.QUERY);
  return useLazyQuery(
    queryBuilder
      .setName('authors')
      .setReturnProperties(mapper)
      .declareVariables({
        search: 'String',
        page: 'PageableInputInput',        
      })
      .setProperties(`search: $search, page: $page`)
      .build(),
    options
  );
}


