import React from "react";

interface SkinColorMarkerProps {
	primary: string
	secondary?: string
	margin?: string
	size?: number
	onClick?: () => void
}

export default function SkinColorMarker(props: SkinColorMarkerProps) {

	return (
		<div style={{padding: '5px', margin: props.margin ? props.margin : '0 10px', background: '#fff', borderRadius: '1px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)', cursor: 'pointer'}} onClick={props.onClick}>
			<div style={{textAlign: 'center', width: props.size ? props.size + 'px' : '26px', height: props.size ? props.size + 'px' : '26px', borderRadius: '2px', background: props.secondary ? 'linear-gradient(to bottom right, ' + props.primary + ' 60%, ' + props.secondary + ' 40%)' : props.primary}}/>
		</div>
	)
}
