import {UserDtoMapper} from "../../build/generated-sources/dto/UserDto";
import RoleMapper from "./RoleMapper";
import ResourceMapper from "./ResourceMapper";
import SkinRatingMapper from "./SkinRatingMapper";

export default class UserMapper {

	public static SHORT: UserDtoMapper = {
		id: true,
		url: true,
		avatar: true,
		country: true,
		username: true
	}

	public static SHORT_ADMIN: UserDtoMapper = {
		...UserMapper.SHORT,
		email: true,
		createdAt: true,
		modifiedAt: true,
		facebook: true,
		twitter: true,
		twitch: true,
		instagram: true,
		discord: true,
		website: true,
	}

	public static AUTHOR: UserDtoMapper = {
		id: true,
		url: true,
		country: true,
		username: true,
		facebook: true,
		twitter: true,
		twitch: true,
		instagram: true,
		discord: true,
		website: true,
		description: true,
		avatar: true,
		createdAt: true,
		skins: {
			id: true,
			url: true,
			title: true,
			createdAt: true,
			description: true,
			type: true,
			resources: ResourceMapper.ALL,
			rating: SkinRatingMapper.ALL,
			price: true,
			author: {
				id: true,
				url: true,
				username: true,
				country: true
			}
		}
	}

	public static PRIVATE_ALL: UserDtoMapper = {
		...UserMapper.AUTHOR,
		fullname: true,
		payoutMethod: true,
		paypalAccount: true,
		bankAccountIban: true,
		bankAccountBic: true,
		street: true,
		city: true,
		language: true,
		email: true,
		subscribedToNews: true,
		purchase: {
			externalId: true,
			totalPrice: true,
			paymentType: true,
			paymentReceivedAt: true,
			paymentStatus: true
		},
		roles: RoleMapper.ALL
	}
}
