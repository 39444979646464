import React, {useEffect} from "react";
import {Container, Grid, Header, Icon, List, Segment} from "semantic-ui-react";
import {Constant} from "../../../base/enums/Constant";
import {Link} from "react-router-dom";
import {View} from "../../../base/enums/View";

export default function About() {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Container className={'about'}>
			<Grid stackable>
				<Grid.Column width={16}>
					<Header as='h2'>
						<Icon name='rocket'/>
						<Header.Content>
							About {Constant.WEBSITE_TITLE}
							<Header.Subheader>Last updated on 4th of June 2022</Header.Subheader>
						</Header.Content>
					</Header>
				</Grid.Column>
				<Grid.Column width={8}>
					<Header as='h2'>
						<Icon name='paint brush'/>
						<Header.Content>
							Features for Authors
						</Header.Content>
					</Header>
					<p>
						Skin creators are given tools to present and promote their amazing work. Every registered player can also become an author
						simply by uploading their creations via their profile. After you log in, access your profile by clicking the user icon in the
						top right corner of the page.
					</p>
					<List bulleted>
						<List.Item>
							<strong>Create a portfolio of your work.</strong> You can build up a gallery of your art by uploading your skins
							and allowing players to download/purchase it from you.
						</List.Item>
						<List.Item>
							<strong>Sell your artwork.</strong> You may submit your skins with a price tag. Every purchase will be credited to
							your profile. The site will periodically send you all that you earn to your bank account. You can monitor your earnings
							via your profile payouts too! This page will be available to you once you upload a paid skin.
						</List.Item>
						<List.Item>
							<strong>Compete with others.</strong> The most popular skin every week will be picked as the <strong>Skin of the
							Month</strong> and featured on the main page.
						</List.Item>
					</List>
				</Grid.Column>
				<Grid.Column width={8}>
					<Header as='h2'>
						<Icon name='gamepad'/>
						<Header.Content>
							Features for Players
						</Header.Content>
					</Header>
					<p>
						By registering and logging in you players will be able to participate in giving valuable feedback to authors and using
						the full potential of this site.
					</p>
					<List bulleted>
						<List.Item>
							<strong>Rate skins.</strong> Your impression of the rated skin will be taken into account when featuring the best
							skins via our <strong>Skin of the Month</strong> selection and in general when promoting the best skins available.
							Please, rate skins responsibly. Authors put a great deal of work into their art. Notice the details under the flaps,
							the tire design, rims, cockpit, side vents, front suspension, tail lights. Take it all in. Enjoy it.
						</List.Item>
						<List.Item>
							<strong>Comment on skins.</strong> Let authors know what you think about their work. Both positive and negative
							feedback is important, but keep the conversation civil and on point.
						</List.Item>
						<List.Item>
							<strong>Keep all purchased skins.</strong> Any skin you buy via the site will be stored on your profile and you
							can access and download them later.
						</List.Item>
						<List.Item>
							<strong>Support authors.</strong> By purchasing skins you support authors at creating more awesome stuff.
						</List.Item>
					</List>
					<br/>
				</Grid.Column>
				<Grid.Column width={11}>
					<Header as='h2'>
						<Header.Content>
							About the Author and {Constant.WEBSITE_TITLE}
						</Header.Content>
					</Header>
					<p><strong>
						Hello everyone, my name is Hanzik and I would like introduce you a bit closer to this website. The idea started taking shape due to a simple
						(and rather silly) problem I had with Trackmania. There was no easy way to just browse all available skins. You either had to click through the clunky
						Club UI within TM 2020 (which showed skins only for that game and that club owner) or you had to know certain authors to look at their website or Twitter or
						whatever (which only showed skins of that particular author). That was not good enough for me. I didn't know any author to search his/her work. I just wanted to
						look at cool stuff.
					</strong></p>
					<p>
						So the goal became quite clear and simple - to create the largest and most complete repository of Trackmania skins possible - both old
						and new. This will take some time. Authors must feel comfortable submitting their work here. Players must know to look for it here. This will take time,
						but I hope that by giving players and authors a smooth experience, they will enjoy it here and come back regularly. I will be happy if this becomes part
						of the Trackmania and skin creators community.
					</p>
					<p>
						I know nothing about creating skins, but I love Trackmania and its community. That's why I am open to any suggestions as to how this website can be improved.
						<strong>I don't believe this will be able to achieve its full potential without your input.</strong>
					</p>
					<p>
						I soon discovered that players are comfortable spending money to support skin authors and buy skins from them. That is amazing! But it brought to surface another problem.
						How do you make this the largest repository of Trackmania skins if authors are going to upload only some of them? How do you give authors tools to publish their work and
						make it easy for them to get rewarded for their awesome work? This meant I had to design a system that will allow them to sell skins and at the same time not make a slave out of me
						managing this process. Automating this process is a fun challenge, but at the same time, whenever there is money involved, it brings with it some extra obstacles - (your) trust, legislation,
						cross-country limitations, fees and more. It's why write these long walls of text to be as open as possible and to give everyone a good idea what this place is about.
					</p>
					<p>
						The future of this website can take many shapes and while I outlined some planned features in the <Link to={View.ROADMAP.path}>Roadmap</Link> nothing is set
						in stone. If the community feels the priority should be somewhere else, it will be done instead.
					</p>
					<p>
						While this was created as a solo project I hope this will grow into a community driven platform. I can automate the next <strong>Skin of the
						Month</strong> selection but I would much more prefer if it was selected by the players. I can implement new features as I see fit but I would much rather if the community
						had a say in what they would like added. So please, if you read this far, join me. Join us. <span style={{color: 'lightgrey'}}>This is not a cult I promise.</span>
					</p>
				</Grid.Column>
				<Grid.Column width={5}>
					<Segment style={{position: 'relative', top: '40px'}} color={'blue'}>
						<Header>Contact me!</Header>
						<p>Got ideas on how to improve this website? Is there a feature that would really be useful but isn't on the Roadmap? Found a problem that needs fixing? Let me know!</p>
						<Icon name={'at'}/> <a href={'mailto:' + Constant.WEBSITE_EMAIL}>{Constant.WEBSITE_EMAIL}</a> <br/>
						<Icon name={'twitter'}/> <a href={Constant.TWITTER_URL}>TrackmaniaSkins</a> <br/>
						<Icon name={'instagram'}/> <a href={Constant.INSTAGRAM_URL}>TrackmaniaSkins</a> <br/>
					</Segment>
				</Grid.Column>
			</Grid>
		</Container>
	);
}
