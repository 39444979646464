import {Modal} from "semantic-ui-react";
import React from "react";
import SkinEditor from "../editors/skin/SkinEditor";
import {SkinDto} from "../../../build/generated-sources/dto/SkinDto";
import {ESkinType} from "../../../build/generated-sources/enum/ESkinType";

interface ModalSkinEditorProps {
	enableColorVariations: boolean,
	open: boolean,
	close: () => void,
	title: string,
	subtitle: string,
	type: ESkinType,
	onSave: (skin : SkinDto, toastMessage?: string) => void
}

export default function ModalSkinEditor(props: ModalSkinEditorProps) {

	return (
		<Modal
			className={'login-modal'}
			size={'large'}
			open={props.open}
			onClose={() => props.close()}
		>
			<>
				<Modal.Content>
					<SkinEditor enableColorVariations={props.enableColorVariations} type={props.type} standalone={false} onSave={props.onSave} title={props.title} subtitle={props.subtitle} />
				</Modal.Content>
			</>
		</Modal>
	)
}
