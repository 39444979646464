import React, {useContext, useEffect, useState} from "react";
import {Button, Flag, Header, Icon, Message, Segment, Table} from "semantic-ui-react";
import {useGetMyOwnedSkins} from "../../build/generated-sources/service/QueryService";
import PageMapper from "../../base/mapper/PageMapper";
import SkinMapper from "../../base/mapper/SkinMapper";
import {UseGetMyOwnedSkinsData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import {SkinDto} from "../../build/generated-sources/dto/SkinDto";
import PaginationControls from "../base/component/PaginationControls";
import {ShoppingCart, ShoppingCartContext} from "../../contexts/ShoppingCartContext";
import {SyncOperationState} from "../../base/state/SyncOperationState";
import SimpleLoader from "../../components/SimpleLoader";
import {Link, useLocation} from "react-router-dom";
import {View} from "../../base/enums/View";
import {ResourceUtil} from "../../base/util/ResourceUtil";

interface OwnedSkinsProps {
	checkout?: string,
}

export default function OwnedSkins(props: OwnedSkinsProps) {

	const location = useLocation();
	const cart = useContext<ShoppingCart>(ShoppingCartContext);

	useEffect(() => {
		if (props.checkout && props.checkout === 'success') {
			cart.clear();
		}
	}, [props.checkout, cart])

	useEffect(() => {
		if ((cart.skins.length + cart.bundles.length) > 0 && location.pathname.endsWith('/success')) {
			cart.clear();
		}
	}, [location.pathname, cart]);

	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 10,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const [syncState, setSyncState] = useState<SyncOperationState>({
		inProgress: false,
	});

	const [list, setList] = useState<SkinDto[]>([]);

	const getMyOwnedSkins = useGetMyOwnedSkins({
		pageInfo: PageMapper.ALL,
		content: SkinMapper.ALL
	}, {
		fetchPolicy: 'network-only',
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize
			}
		},
		onCompleted: (data: UseGetMyOwnedSkinsData) => {
			setList(data.myOwnedSkins.content);
			setPageInfo(data.myOwnedSkins.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const download = (skin: SkinDto) => {
		setSyncState({
			...syncState,
			inProgress: true,
			id: skin.id
		});
		setTimeout(() => {
			setSyncState({
				inProgress: false,
				id: undefined
			});
		}, 3000);
		window.location.replace("/assets/skin/" + skin.url);
	}

	const getFlagCode = (skin : SkinDto) : any => {
		let cntr : any = skin.author.country;
		return cntr;
	}

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='list' />
				<Header.Content>
					My Collection of Skins
					<Header.Subheader>Review all the skins you've purchased</Header.Subheader>
				</Header.Content>
			</Header>
			{
				props.checkout && props.checkout === 'success' && <Message success icon='check'
												header='Purchase successful'
												content='You can download your new skins via the Download button from the table below.'/>
			}
			{
				props.checkout && props.checkout === 'failed' && <Message warning
										   icon='x'
										   header='Purchase cancelled'
										   content='You can try purchasing the items again or if there was an error during the checkout, let us know.'/>
			}
			{
				getMyOwnedSkins.loading && <SimpleLoader text={'Loading owned skins...'}/>
			}
			{
				!getMyOwnedSkins.loading && list.length === 0 && <Segment placeholder>
					<Header icon>
						<Icon name='frown outline'/> You have not purchased any skins yet.
					</Header>
				</Segment>
			}
			{
				list.length > 0 && <>
						<Table unstackable striped>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Skin</Table.HeaderCell>
									<Table.HeaderCell>Name</Table.HeaderCell>
									<Table.HeaderCell className={'hide-on-mobile'}>Author</Table.HeaderCell>
									<Table.HeaderCell textAlign={'center'}>Download</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{
									list.map(skin => {
										return <Table.Row key={skin.id}>
											<Table.Cell collapsing textAlign={'center'}>
												<img alt={skin.title} style={{height: '50px', width: '80px', objectFit: 'cover'}} src={ResourceUtil.getThumbnailPath(skin.resources)}/>
											</Table.Cell>
											<Table.Cell>
												<Link to={View.SKIN.path + skin.url}><strong>{skin.title}</strong></Link>
											</Table.Cell>
											<Table.Cell className={'hide-on-mobile'}>
												by <Link to={View.AUTHOR.path + skin.author.url}>{skin.author.username}</Link> <Flag name={getFlagCode(skin)}/>
											</Table.Cell>
											<Table.Cell textAlign={'center'}>
												<Button icon={'download'} disabled={syncState.id === skin.id && syncState.inProgress} onClick={() => {
													download(skin)
												}}/>
											</Table.Cell>
										</Table.Row>
									})
								}
							</Table.Body>
						</Table>

						<PaginationControls current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
							setPageInfo({
								...pageInfo,
								pageNumber: page
							});
						}}/>
					</>
			}

		</>
	);
}
