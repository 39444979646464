import {ESkinType} from "../../build/generated-sources/enum/ESkinType";

interface SkinTypeSelection {
    key: ESkinType,
    value: ESkinType,
    text: string
}

interface SkinTypeSelectionExtended {
    key: ESkinType,
    value: ESkinType,
    text: string
    shortText: string
}

export class SkinTypeUtil {

    static DEFAULT_TYPE : SkinTypeSelection = {key: ESkinType.TM2020, value: ESkinType.TM2020, text: 'Trackmania 2020'}

    public static getOptions() : SkinTypeSelection[] {
        return SkinTypeUtil.getOptionsExtended().map(i => {
            return {
                key: i.key,
                value: i.value,
                text: i.text
            }
        });
    }

    public static getOptionsExtended() : SkinTypeSelectionExtended[] {
        return [
            { key: ESkinType.TM2020, value: ESkinType.TM2020, text: 'Trackmania 2020', shortText: 'TM 2020' },
            { key: ESkinType.TMNF, value: ESkinType.TMNF, text: 'Nations & United Forever', shortText: 'TMNF & UF' },
            { key: ESkinType.TM_TURBO, value: ESkinType.TM_TURBO, text: 'Trackmania Turbo', shortText: 'TM Turbo' },
            { key: ESkinType.TM2, value: ESkinType.TM2, text: 'Trackmania 2', shortText: 'TM2' },
        ]
    }

    public static getOptionExtended(type : ESkinType) : SkinTypeSelectionExtended {
        return SkinTypeUtil.getOptionsExtended().find(option => option.key === type);
    }
}
