import React, {useState} from "react";
import {Button, Grid, Header, Icon, Popup, Segment, Table} from "semantic-ui-react";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import {useGetMyCreatedBundles} from "../../build/generated-sources/service/QueryService";
import PageMapper from "../../base/mapper/PageMapper";
import {UseGetMyCreatedBundlesData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import PaginationControls from "../base/component/PaginationControls";
import {Link} from "react-router-dom";
import {View} from "../../base/enums/View";
import SimpleLoader from "../../components/SimpleLoader";
import {useHideBundle} from "../../build/generated-sources/service/MutationService";
import {UseHideBundleData} from "../../build/generated-sources/service/MutationServiceModel";
import {SyncOperationState} from "../../base/state/SyncOperationState";
import {toast} from "react-hot-toast";
import {success} from "@redrobot/npm-core-generator-builder/dist/graphql-builder/base/util/ConsoleUtils";
import {ResourceUtil} from "../../base/util/ResourceUtil";
import {SkinTypeUtil} from "../../base/util/SkinTypeUtil";
import { Formatter } from "../../base/util/Formatter";
import {BundleDto} from "../../build/generated-sources/dto/BundleDto";
import moment from "moment";
import BundleMapper from "../../base/mapper/BundleMapper";

export default function CreatedBundles() {

	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 10,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const [list, setList] = useState<BundleDto[]>([]);

	const [syncHideState, setSyncHideState] = useState<SyncOperationState>({
		inProgress: false
	});

	const getCreatedBundles = useGetMyCreatedBundles({
		pageInfo: PageMapper.ALL,
		content: BundleMapper.ALL
	}, {
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize
			}
		},
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetMyCreatedBundlesData) => {
			setList(data.myCreatedBundles.content);
			setPageInfo(data.myCreatedBundles.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const [hideBundle] = useHideBundle({
			id: true,
			success: true
		}, {
			onCompleted: (data: UseHideBundleData) => {
				setSyncHideState({inProgress: false});
				if (!data.hideBundle.success) {
					return;
				}
				let updatedList : BundleDto[] = Object.assign([], list);
				setList(updatedList.map(item => item.id === data.hideBundle.id ? {...item, published: !item.published} : item));
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	const hide = (id: number, hide: boolean) => {
		setSyncHideState({
			id: id,
			inProgress: true
		});

		toast.promise(
			hideBundle({
				variables: {
					id: id,
					hide: hide
				}
			}),
			{
				loading: hide ? 'Hiding your bundle from players' : 'Publishing your bundle for everyone to see',
				success: hide ? <strong>Bundle successfully hidden</strong> : <strong>Bundle successfully published</strong>,
				error: <strong>Error changing bundle's visibility.</strong>,
			}
		);
	}

	return (
		<>
			<Grid stackable>
				<Grid.Column width={9}>
					<Header as='h2' style={{margin: '0 0 40px'}}>
						<Icon name='paint brush'/>
						<Header.Content>
							Uploaded Bundles
							<Header.Subheader>Create and share your collections!</Header.Subheader>
						</Header.Content>
					</Header>
				</Grid.Column>
				<Grid.Column textAlign={'right'} style={{paddingTop: '24px'}} width={7}>
					<Link className={'hide-on-mobile'} to={View.PUBLISH_BUNDLE.path}><Button color={'black'}><Icon name={'plus'}/> Upload New Bundle</Button></Link>
				</Grid.Column>
			</Grid>
			{
				getCreatedBundles.loading && <SimpleLoader text={'Loading your uploaded bundles...'}/>
			}
			{
				!getCreatedBundles.loading && list.length === 0 && <Segment placeholder>
					<Header icon>
						<Icon name='frown outline'/> You have not uploaded any bundles yet.
					</Header>
				</Segment>
			}
			{
				!getCreatedBundles.loading && list.length > 0 && <>
					<Table unstackable striped>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className={'hide-on-mobile'}></Table.HeaderCell>
								<Table.HeaderCell className={'hide-on-mobile'}>Submitted</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Preview</Table.HeaderCell>
								<Table.HeaderCell>Description</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Price</Table.HeaderCell>
								<Table.HeaderCell className={'hide-on-mobile'} textAlign={'center'}>Type</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Actions</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{
								list.length === 0 && <Table.Row>
									<Table.Cell colSpan={7} textAlign={'center'}>You haven't uploaded any skins yet.</Table.Cell>
								</Table.Row>
							}
							{
								list.map(bundle => {
									return <Table.Row key={bundle.id}>
										<Table.Cell className={'hide-on-mobile'} textAlign={'center'} collapsing>
											{
												!bundle.published && <Popup wide position={'top center'} content='Hidden' trigger={<Icon name='eye slash'/>}/>
											}
											{
												bundle.published && <Popup wide position={'top center'} content='Published' trigger={<Icon name='eye'/>}/>
											}
										</Table.Cell>
										<Table.Cell className={'hide-on-mobile'} singleLine collapsing>
											{moment(bundle.createdAt, moment.ISO_8601).format("DD. MM. YYYY")}
										</Table.Cell>
										<Table.Cell collapsing textAlign={'center'}>
											<img alt={bundle.title} style={{height: '50px'}} src={ResourceUtil.getThumbnailPath(bundle.resources)}/>
										</Table.Cell>
										<Table.Cell>
											<Link to={View.BUNDLE.path + bundle.url}><strong>{bundle.title}</strong><br/></Link>
											<span className={'hide-on-mobile'}>{bundle.description}</span>
										</Table.Cell>
										<Table.Cell collapsing textAlign={'center'}>
											<strong>{bundle.price === 0 ? 'Free' : (Formatter.money(bundle.price))}</strong><br/>
										</Table.Cell>
										<Table.Cell className={'hide-on-mobile'} collapsing textAlign={'center'}>
											<strong>{SkinTypeUtil.getOptionExtended(bundle.type).shortText}</strong><br/>
										</Table.Cell>
										<Table.Cell collapsing textAlign={'left'}>
											<Link to={View.EDIT_BUNDLE.path.replace(":id", bundle.url)}>
												<Popup content='Update' position={'top center'} trigger={<Button icon='edit'/>}/>
											</Link>
											{
												bundle.published && <Popup wide content='Hide - people will not be able to see this bundle'
																		 trigger={<Button icon='eye slash'
																						  className={'hide-on-mobile'}
																						  loading={syncHideState.inProgress && syncHideState.id === bundle.id}
																						  disabled={syncHideState.inProgress} onClick={() => hide(bundle.id, true)}/>}/>
											}
											{
												!bundle.published && <Popup wide content='Publish - everyone will be able to see this bundle'
																		  trigger={<Button icon='eye'
																						   className={'hide-on-mobile'}
																						   loading={syncHideState.inProgress && syncHideState.id === bundle.id}
																						   disabled={syncHideState.inProgress} onClick={() => hide(bundle.id, false)}/>}/>
											}
										</Table.Cell>
									</Table.Row>
								})
							}
						</Table.Body>
					</Table>

					<PaginationControls current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
						setPageInfo({
							...pageInfo,
							pageNumber: page
						});
					}}/>
				</>
			}
		</>
	);
}
