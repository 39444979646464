import React, {useCallback, useEffect, useState} from "react";
import {Button, Container, Form, Grid, Header, Icon, Message, Segment} from "semantic-ui-react";
import {ApolloError} from "@apollo/client";
import BundleCard from "./BundleCard";
import {SkinFilterUtil} from "../../../base/util/SkinFilterUtil";
import PaginationControls from "../../base/component/PaginationControls";
import {PageInfo} from "../../../build/generated-sources/dto/PageInfo";
import {Direction} from "../../../build/generated-sources/enum/Direction";
import {useHistory, useLocation} from "react-router-dom";
import PageMapper from "../../../base/mapper/PageMapper";
import SimpleLoader from "../../../components/SimpleLoader";
import {Constant} from "../../../base/enums/Constant";
import {ESkinType} from "../../../build/generated-sources/enum/ESkinType";
import {SkinTypeUtil} from "../../../base/util/SkinTypeUtil";
import {BundleDto} from "../../../build/generated-sources/dto/BundleDto";
import {useGetBundles} from "../../../build/generated-sources/service/QueryService";
import {UseGetBundlesData} from "../../../build/generated-sources/service/QueryServiceModel";
import BundleMapper from "../../../base/mapper/BundleMapper";
import {QueryUtil} from "../../../base/util/QueryUtil";

export default function BundleList() {

	const location = useLocation();
	const history = useHistory();
	const [orderBy, setOrderBy] = useState<string>('createdAt');
	const [type, setType] = useState<ESkinType>(ESkinType.TM2020);
	const [searchTemp, setSearchTemp] = useState<string>('');
	const [search, setSearch] = useState<string>('');
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: Constant.PAGE_LENGTH_SKINS,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const [bundles, setBundles] = useState<BundleDto[]>([]);

	const handleStatusChange = (data: any) => {
		setQueryParam("o", data);
		setOrderBy(data);
	}

	const handleTypeChange = (data: any) => {
		setQueryParam("t", data);
		setType(data);
	}

	const getBundles = useGetBundles({
			pageInfo: PageMapper.ALL,
			content: BundleMapper.ALL
		}, {
			variables: {
				page: {
					page: pageInfo.pageNumber,
					size: pageInfo.pageSize,
					sortBy: orderBy,
					sortDirection: orderBy === 'createdAt' ? Direction.DESC : Direction.ASC
				},
				type: type,
				search: search
			},
			onCompleted: (data: UseGetBundlesData) => {
				setBundles(data.bundles.content);
				setPageInfo(data.bundles.pageInfo);
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	useEffect(() => {
		const orderBy = QueryUtil.getParam(location.search, "o");
		if (orderBy && SkinFilterUtil.getSkinFilterOptions().map(i => i.value).includes(orderBy)) {
			setOrderBy(orderBy);
		}
		const gameType = QueryUtil.getParam(location.search, "t");
		if (gameType) {
			const gameTypeEnum = SkinTypeUtil.getOptionsExtended().find(i => i.key.toString() === gameType).value;
			if (gameTypeEnum) {
				setType(gameTypeEnum);
			}
		}
		const search = QueryUtil.getParam(location.search, "s");
		if (search) {
			setSearchTemp(search);
			setSearch(search);
		}
	}, [location.search]);

	const setQueryParam = useCallback((key: string, value: string) => {
		const currentUrlParams = new URLSearchParams(window.location.search);
		currentUrlParams.set(key, value);
		history.push(window.location.pathname + "?" + currentUrlParams.toString());
	}, [history]);

	useEffect(() => {
		const delayTimeout = setTimeout(() => {
			if (searchTemp.length >= 3 || (searchTemp.length === 0 && search.length > 0)) {
				setQueryParam("s", searchTemp);
				setSearch(searchTemp);
			}
		}, Constant.SEARCH_QUERY_TYPING_DELAY)
		return () => clearTimeout(delayTimeout)
	}, [searchTemp, search.length, setQueryParam]);

	const onSearchChange = (text : string) => {
		setSearchTemp(text);
	}

	return (
		<Container>
			{
				location.pathname.endsWith('/success') && <Message icon='check' success
																  header='Purchase successful'
																  content='You should receive email with download link(s) in a short while.'/>
			}
			{
				location.pathname.endsWith('/failed') && <Message warning
																  icon='x'
																  header='Purchase cancelled'
																  content='You can try purchasing the items again or if there was an error during the checkout, let us know.'/>
			}
			<Form>
				<Form.Group className={'filter'}>
					<Form.Select options={SkinFilterUtil.getSkinFilterOptions()} width={4} label={'Sort by'}
								 onChange={(e, data) => handleStatusChange(data.value)} value={orderBy} type={'text'}/>
					<Form.Select options={SkinTypeUtil.getOptions()} width={4} label={'Game'}
								 onChange={(e, data) => handleTypeChange(data.value)} value={type} type={'text'}/>
					<Form.Input width={6} style={{display:'none'}}/>
					<Form.Input width={4}icon={'search'} placeholder={'Find bundle...'} type={'text'} value={searchTemp} onChange={(e) => onSearchChange(e.target.value)} />
				</Form.Group>
			</Form>
			<Grid stackable columns={3}>
				{
					getBundles.loading ? <SimpleLoader text={'Loading bundles...'}/> : <>
						{
							bundles.length === 0 && <Grid.Column width={16}>
								<Segment placeholder>
									<Header icon>
										<Icon name='search' /> There are no bundles matching your search query.
									</Header>
									<Segment.Inline>
										<Button color={'black'} onClick={() => {setSearchTemp(''); setSearch(''); setType(ESkinType.TM2020)}}>Clear Search Query</Button>
									</Segment.Inline>
								</Segment>
							</Grid.Column>
						}
						{
							bundles.map(bundle => {
								return <Grid.Column key={bundle.id}>
									<BundleCard bundle={bundle} showPurchase linkToBundle />
								</Grid.Column>
							})
						}
					</>
				}
			</Grid>
			<PaginationControls disabled={getBundles.loading} current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
				setPageInfo({
					...pageInfo,
					pageNumber: page
				});
			}}/>
		</Container>
	);
}
