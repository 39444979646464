import {PurchaseDtoMapper} from "../../build/generated-sources/dto/PurchaseDto";
import PurchaseItemMapper from "./PurchaseItemMapper";

export default class PurchaseMapper {

	public static ALL: PurchaseDtoMapper = {
		externalId: true,
		totalPrice: true,
		paymentType: true,
		paymentReceivedAt: true,
		paymentStatus: true,
		createdAt: true,
		items: PurchaseItemMapper.ALL
	}
}
