import React, {ReactNode, useContext} from 'react';
import './App.css';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import SkinList from "./views/public/skin/SkinList";
import Skin from "./views/public/skin/Skin";
import {ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache} from "@apollo/client";
import {withScalars} from "apollo-link-scalars";
import {View} from "./base/enums/View";
import TopNavigation from "./views/base/component/menu/TopNavigation";
import {schema} from "./build/generated-sources/scalars/ScalarConstants";
import ShoppingCartProvider from "./contexts/ShoppingCartContext";
import CartSummary from "./views/public/cart/CartSummary";
import AuthorList from './views/public/author/AuthorList';
import Footer from "./views/public/components/Footer";
import FAQ from "./views/public/misc/FAQ";
import Blog from "./views/public/misc/Blog";
import About from "./views/public/misc/About";
import Author from "./views/public/author/Author";
import AuthProvider, {Auth, AuthContext} from './contexts/AuthContext';
import PrivateRoute from "./components/private/PrivateRoute";
import TmRoles from "./base/auth/TmRoles";
import Profile from "./views/profile/Profile";
import TermsOfService from "./views/public/misc/TermsOfService";
import PrivacyPolicy from "./views/public/misc/PrivacyPolicy";
import RefundPolicy from "./views/public/misc/RefundPolicy";
import RulesForSubmissions from "./views/public/misc/RulesForSubmissions";
import Roadmap from "./views/public/misc/Roadmap";
import OwnedSkinsProvider from "./contexts/OwnedSkinsContext";
import ResetPassword from './views/public/misc/ResetPassword';
import Homepage from './views/public/misc/Homepage';
import AuthorPayoutSystem from "./views/public/misc/AuthorPayoutSystem";
import InstallationGuide from "./views/public/misc/InstallationGuide";
import Bundle from "./views/public/bundle/Bundle";
import BundleList from "./views/public/bundle/BundleList";
import OwnedBundlesProvider from "./contexts/OwnedBundlesContext";
import PostToSocialMedia from "./views/admin/skin/PostToSocialMedia";

const ConnectionWrapper: React.FunctionComponent<{children?: ReactNode | undefined }> = ({ children }) => {

	const auth = useContext<Auth>(AuthContext);
	const authorizationHeader = auth.token ? {Authorization: `Bearer ${auth.token}`} : {};

	const link = ApolloLink.from([
		withScalars({schema}),
		new HttpLink({
			uri: '/graphql',
			headers: {
				...authorizationHeader,
			},
		}),
	]);

	const client = new ApolloClient({
		uri: '/graphql',
		cache: new InMemoryCache(),
		link,
	});

	return (
		<ApolloProvider client={client}>
			{children}
		</ApolloProvider>
	);
};

const ProviderWrapper: React.FunctionComponent<{children?: ReactNode | undefined }> = ({children}) => {
	return (
		<OwnedSkinsProvider>
			<OwnedBundlesProvider>
				<ShoppingCartProvider>
					{children}
				</ShoppingCartProvider>
			</OwnedBundlesProvider>
		</OwnedSkinsProvider>
	);
};

function App() {

	return (
		<AuthProvider>
			<ConnectionWrapper>
				<BrowserRouter>
					<ProviderWrapper>
						<TopNavigation/>
							<Switch>
								<Route path={View.RESET_PASSWORD.path} render={(props) => <ResetPassword/>}/>
								<Route path={View.SKIN.path + ':id'} render={(props) => <Skin url={props.match.params.id}/>}/>
								<Route path={View.SKINS.path} render={() => <SkinList/>}/>
								<Route path={View.BUNDLE.path + ':id'} render={(props) => <Bundle url={props.match.params.id}/>}/>
								<Route path={View.BUNDLES.path} render={() => <BundleList/>}/>
								<Route path={View.AUTHOR.path + ':id'} render={(props) => <Author url={props.match.params.id}/>}/>
								<Route path={View.AUTHORS.path} render={() => <AuthorList/>}/>
								<Route path={View.CART_SUMMARY.path} render={() => <CartSummary/>}/>
								<Route path={View.ABOUT.path} render={() => <About/>}/>
								<Route path={View.ABOUT_AUTHOR_PAYOUTS.path} render={() => <AuthorPayoutSystem/>}/>
								<Route path={View.INSTALLATION_GUIDE.path} render={() => <InstallationGuide/>}/>
								<Route path={View.BLOG.path} render={() => <Blog/>}/>
								<Route path={View.FAQ.path} render={() => <FAQ/>}/>
								<Route path={View.TERMS_OF_SERVICE.path} render={() => <TermsOfService/>}/>
								<Route path={View.REFUND_POLICY.path} render={() => <RefundPolicy/>}/>
								<Route path={View.SUBMISSIONS_RULES.path} render={() => <RulesForSubmissions/>}/>
								<Route path={View.PRIVACY_POLICY.path} render={() => <PrivacyPolicy/>}/>
								<Route path={View.ROADMAP.path} render={() => <Roadmap/>}/>

								<PrivateRoute path={View.PROFILE.path} requiredRoles={[TmRoles.REGULAR]} render={() => <Profile/>}/>
								<PrivateRoute path={View.ADMIN_SKIN_SOCIAL_MEDIA.path} requiredRoles={[TmRoles.ADMIN]} render={() => <PostToSocialMedia />}/>
								<PrivateRoute path={View.ADMIN.path} requiredRoles={[TmRoles.ADMIN]} render={() => <Profile/>}/>

								<Route path={View.HOMEPAGE.path} render={() => <Homepage/>}/>
								<Redirect to={View.HOMEPAGE.path}/>
							</Switch>
							<Footer/>
					</ProviderWrapper>
				</BrowserRouter>
			</ConnectionWrapper>
		</AuthProvider>
	)
}

export default App;
