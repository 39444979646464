import {PurchaseItemDtoMapper} from "../../build/generated-sources/dto/PurchaseItemDto";
import SkinMapper from "./SkinMapper";
import BundleMapper from "./BundleMapper";

export default class PurchaseItemMapper {

	public static ALL: PurchaseItemDtoMapper = {
		id: true,
		name: true,
		price: true,
		tip: true,
		skin: SkinMapper.ALL,
		bundle: BundleMapper.ALL
	}
}
