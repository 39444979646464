import {SemanticICONS} from "semantic-ui-react";
import {UserDto} from "../../build/generated-sources/dto/UserDto";
import {View} from "../enums/View";

interface ProfileMenuItem {
    title: string,
    shortTitle: string,
    key: string,
    path: string,
    icon: SemanticICONS,
    display?: (user: UserDto) => boolean
}

export class ProfileMenuUtil {

    public static getPlayerItems() : ProfileMenuItem[] {
        return [
            {title: 'My Collection', shortTitle: 'Owned',  key: 'collection', icon: 'list', path: View.OWNED_SKINS.path},
            {title: 'Purchase History', shortTitle: 'Orders', key: 'purchase', icon: 'cart', path: View.PURCHASE_HISTORY.path},
            {title: 'Profile Settings', shortTitle: 'Settings', key: 'settings', icon: 'settings', path: View.PROFILE_SETTINGS.path},
        ]
    }

    public static getAuthorItems() : ProfileMenuItem[] {
        return [
            {title: 'Creativity Dashboard', shortTitle: 'Dashboard', key: 'dashboard', icon: 'dashboard', path: View.CREATIVITY_DASHBOARD.path, display: (user) => user.skins && user.skins.length > 0},
            {title: 'Skins', shortTitle: 'Skins', key: 'createdSkins', icon: 'car', path: View.CREATED_SKINS.path},
            {title: 'Bundles', shortTitle: 'Bundles', key: 'createdBundles', icon: 'paint brush', path: View.CREATED_BUNDLES.path},
            {title: 'My Payouts', shortTitle: 'Payouts', key: 'payouts', icon: 'money bill alternate', path: View.PAYOUTS.path, display: (user) => user.skins && user.skins.filter(item => item.price > 0).length > 0},
        ]
    }

    public static getAdminItems() : ProfileMenuItem[] {
        return [
            {title: 'Dashboard', shortTitle: 'Dashboard', key: 'adminDashboard', icon: 'dashboard', path: View.ADMIN_DASHBOARD.path},
            {title: 'Orders', shortTitle: 'Orders', key: 'allOrders', icon: 'list', path: View.ADMIN_ORDERS.path},
            {title: 'Payouts', shortTitle: 'Payouts', key: 'allPayouts', icon: 'money bill alternate outline', path: View.ADMIN_PAYOUTS.path},
            {title: 'Skins', shortTitle: 'Skins', key: 'allSkins', icon: 'car', path: View.ADMIN_SKINS.path},
            {title: 'Comments', shortTitle: 'Comments', key: 'allComments', icon: 'comments', path: View.ADMIN_COMMENTS.path},
            {title: 'Users', shortTitle: 'Users', key: 'allUsers', icon: 'users', path: View.ADMIN_USERS.path},
        ]
    }
}
