import {CommentDtoMapper} from "../../build/generated-sources/dto/CommentDto";

export default class CommentMapper {

	public static ALL: CommentDtoMapper = {
		id: true,
		text: true,
		createdAt: true,
		user: {
			id: true,
			avatar: true,
			country: true,
			username: true
		}
	}
}
