import React, {useContext, useState} from "react";
import {Icon} from "semantic-ui-react";
import {SkinDto} from "../../../build/generated-sources/dto/SkinDto";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import {useRateSkin} from "../../../build/generated-sources/service/MutationService";
import SkinRatingMapper from "../../../base/mapper/SkinRatingMapper";
import {UseRateSkinData} from "../../../build/generated-sources/service/MutationServiceModel";
import {SkinRatingDto} from "../../../build/generated-sources/dto/SkinRatingDto";
import {toast} from "react-hot-toast";
import {IconSizeProp} from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

interface SkinRatingProps {
	skin: SkinDto
	size?: IconSizeProp,
	label?: string
	disabled: boolean
}

export default function SkinRating(props: SkinRatingProps) {

	const auth = useContext<Auth>(AuthContext);

	const [syncState, setSyncState] = useState<SyncOperationState>({
		done: false
	});

	const [rating, setRating] = useState<SkinRatingDto>(props.skin.rating);
	const [activeRating, setActiveRating] = useState<number>(props.skin.rating.average);

	const [rateSkin] = useRateSkin(SkinRatingMapper.ALL, {
		onCompleted: ((data: UseRateSkinData) => {
			setRating(data.rateSkin);
			setActiveRating(data.rateSkin.average);
		})
	});

	const rate = (e: any, rating: number) => {
		e.preventDefault();
		setSyncState({
			...syncState,
			done: true
		});
		toast.success('Thank you for voting.', {duration: 200000});
		rateSkin({
			variables: {
				rating: rating,
				skinId: props.skin.id
			}
		});
	}

	let enabled = (!props.disabled && auth.token);

	return (
		<>
			{
				enabled && <span onMouseLeave={() => setActiveRating(rating.average)}>{props.label && <strong>{props.label}:&nbsp;</strong>}
					<Icon size={props.size} name='star' color={activeRating >= 1 ? 'yellow' : 'grey'} onClick={(e: any) => {
						rate(e, 1)
					}} onMouseEnter={() => setActiveRating(1)}/>
					<Icon size={props.size} name='star' color={activeRating >= 2 ? 'yellow' : 'grey'} onClick={(e: any) => {
						rate(e, 2)
					}} onMouseEnter={() => setActiveRating(2)}/>
					<Icon size={props.size} name='star' color={activeRating >= 3 ? 'yellow' : 'grey'} onClick={(e: any) => {
						rate(e, 3)
					}} onMouseEnter={() => setActiveRating(3)}/>
					<Icon size={props.size} name='star' color={activeRating >= 4 ? 'yellow' : 'grey'} onClick={(e: any) => {
						rate(e, 4)
					}} onMouseEnter={() => setActiveRating(4)}/>
					<Icon size={props.size} name='star' color={activeRating >= 5 ? 'yellow' : 'grey'} onClick={(e: any) => {
						rate(e, 5)
					}} onMouseEnter={() => setActiveRating(5)}/>
				</span>
			}
			<span>
			{
				!enabled && rating.average > 0 && <><Icon size={props.size} name='star' disabled color={'orange'}/> <span
					style={{position: 'relative', top: '3px'}}>{Math.round(rating.average * 100) / 100}</span></>
			}
			</span>
		</>
	);
}
