import {Dimmer, Grid, Loader, Placeholder, Segment} from "semantic-ui-react";
import React from "react";

interface SimpleLoaderProps {
    text: string,
    short?: boolean
}

export default function SimpleLoader(props: SimpleLoaderProps) {

    return (
        <Grid.Row>
            <Grid.Column width={16}>
                <Segment>
                    <Dimmer active inverted>
                        <Loader inverted active>{props.text}</Loader>
                    </Dimmer>
                    <Placeholder fluid>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                        {
                            !props.short && <>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </>
                        }
                    </Placeholder>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    );
}
