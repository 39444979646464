import {EPayoutStatus} from "../../build/generated-sources/enum/EPayoutStatus";

export interface AuthorPayoutBatchFilterOption {
	key?: string,
	value?: string,
	text: string
}

export class AuthorPayoutBatchFilterUtil {

	public static getAuthorPayoutBatchFilterOptions(): AuthorPayoutBatchFilterOption[] {
		return [
			{key: 'all', value: 'all', text: 'All'},
			{key: EPayoutStatus.PENDING.toString(), value: EPayoutStatus.PENDING.toString(), text: 'Pending'},
			{key: EPayoutStatus.LOCKED.toString(), value: EPayoutStatus.LOCKED.toString(), text: 'Locked'},
			{key: EPayoutStatus.SENT.toString(), value: EPayoutStatus.SENT.toString(), text: 'Sent'},
			{key: EPayoutStatus.ERROR.toString(), value: EPayoutStatus.ERROR.toString(), text: 'Error'},
		]
	}
}
