import {SkinDto} from "../../../build/generated-sources/dto/SkinDto";
import {Button, Icon} from "semantic-ui-react";
import React, {useContext, useState} from "react";
import {ShoppingCart, ShoppingCartContext} from "../../../contexts/ShoppingCartContext";
import {OwnedSkins, OwnedSkinsContext} from "../../../contexts/OwnedSkinsContext";
import {Auth, AuthContext} from "../../../contexts/AuthContext";

interface GetButtonProps {
	skin: SkinDto
}

export default function GetSkinButton(props: GetButtonProps) {

	const auth = useContext<Auth>(AuthContext);
	const cart = useContext<ShoppingCart>(ShoppingCartContext);
	const ownedSkins = useContext<OwnedSkins>(OwnedSkinsContext);

	const cartContainsSkin = cart.containsSkin(props.skin);
	const owned = ownedSkins.hasSkin(props.skin.id);
	const mySkin = props.skin.author.id === auth.id;

	const [disabled, setDisabled] = useState<boolean>(false);

	const downloadSkin = () => {
		setDisabled(true);
		setTimeout(() => {
			setDisabled(false);
		}, 5000);
		window.location.replace('/assets/skin/' + props.skin.url);
	}

	return (
		<>
			{
				mySkin && <Button color='black' animated='fade' disabled={disabled} onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
					downloadSkin();
				}}>
					<Button.Content visible>&nbsp;&nbsp;&nbsp;&nbsp;My Skin&nbsp;&nbsp;&nbsp;&nbsp;</Button.Content>
					<Button.Content hidden><Icon name='download'/>Download</Button.Content>
				</Button>
			}
			{
				owned && !mySkin && <Button color='black' animated='fade' disabled={disabled} onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
					downloadSkin();
				}}>
					<Button.Content visible>&nbsp;&nbsp;&nbsp;&nbsp;Owned&nbsp;&nbsp;&nbsp;&nbsp;</Button.Content>
					<Button.Content hidden><Icon name='download'/>Download</Button.Content>
				</Button>
			}
			{
				!owned && !mySkin && cartContainsSkin && props.skin.price > 0 &&
				<Button disabled color='black' animated='fade' onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
				}}>
					<Button.Content><Icon name='shop'/> In cart &nbsp;&nbsp;&nbsp;</Button.Content>
				</Button>
			}
			{
				!owned && !mySkin && !cartContainsSkin && props.skin.price > 0 &&
				<Button color='black' animated='fade' onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
					cart.addSkin(props.skin);
				}}>
					<Button.Content visible>&nbsp;&nbsp;&nbsp;{props.skin.price} €&nbsp;&nbsp;&nbsp;</Button.Content>
					<Button.Content hidden><Icon name='shop'/></Button.Content>
				</Button>
			}
			{
				!owned && !mySkin && props.skin.price === 0 &&
				<Button color='black' animated='fade' disabled={disabled} onClick={(e) => {
					e.preventDefault();
					e.currentTarget.blur();
					downloadSkin();
				}}>
					<Button.Content visible>&nbsp;&nbsp;&nbsp;&nbsp;Free&nbsp;&nbsp;&nbsp;&nbsp;</Button.Content>
					<Button.Content hidden><Icon name='download'/>Download</Button.Content>
				</Button>
			}
		</>
	)
}
