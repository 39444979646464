import {Grid, Header} from "semantic-ui-react";
import React from "react";

export default function SkinOfTheMonthAd() {

	return (
		<>
			<Header as={'h1'} style={{color: '#111', textAlign: 'center'}}>
				SKIN OF THE MONTH
				<Header.Subheader>CHOOSE THE NEXT ONE</Header.Subheader>
			</Header>
			<Grid centered>
				<Grid.Column width={8} textAlign={'center'} style={{background: '#f6de89', padding: '40px 30px', maxWidth: '380px'}}>
						<Header as={'h2'}>
							RATE SKINS
						</Header>
						<p style={{fontWeight: 'bold', marginTop: '20px'}}>
							Log in, browse skins, inspect their quality and originality and rate them with our rating system.
						</p>
				</Grid.Column>
				<Grid.Column width={8} textAlign={'center'} style={{background: '#f6de89', color: '#333', padding: '40px 30px', maxWidth: '380px'}}>
						<Header as={'h2'}>
							THE BEST AUTHOR WINS
						</Header>
						<p style={{fontWeight: 'bold', marginTop: '20px'}}>
							At the start of every month we select the next Skin of the Month which will be featured here and on our social sites.
						</p>
				</Grid.Column>
			</Grid>
		</>
	)
}
