import React, {useEffect, useMemo, useState} from "react";
import {Container, Header, Icon, Image, Message} from "semantic-ui-react";
import {Constant} from "../../../base/enums/Constant";
import {Link} from "react-router-dom";
import {View} from "../../../base/enums/View";
import Lightbox from "react-image-lightbox";

export default function Blog() {

	const [colorVariationsGalleryOpen, setColorVariationsGalleryOpen] = useState<boolean>(false);
	const [colorVariationsImagesIndex, setColorVariationsImagesIndex] = useState<number>(0);
	const colorVariationsImages = useMemo(() => {
		return [
			'availablecolors.png',
			'colorpicker.png',
			'variations.png',
		]
	}, []);
	const [paypalGalleryOpen, setPaypalGalleryOpen] = useState<boolean>(false);
	const [paypalImagesIndex, setPaypalImagesIndex] = useState<number>(0);
	const paypalImages = useMemo(() => {
		return [
			'payout.png',
			'paypal.png',
		]
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Container className={'blog'}>

			<Header as='h2'>
				<Icon name='paypal' />
				<Header.Content>
					We now accept PayPal!
					<Header.Subheader>Published on 23th of October 2023</Header.Subheader>
				</Header.Content>
			</Header>
			<Message>
				<Message.Header>TL;DR</Message.Header>
				<p>
					<strong>Players can pay for skins via PayPal. Authors can receive money via PayPal. Stripe payment method remains as an option.</strong>
				</p>
			</Message>
			<p>
				When I first launched this project a year back, I didn't quite realize how important alternative payment methods can be. I hope that by giving players
				the option to use PayPal will help them support their favorite content creators more easily. Sorry this took so long. PayPal doesn't make things easy.
			</p>
			<p>
				Authors can now choose how they want to be paid. The old bank transaction method remains, but you can now alternatively also use PayPal for this. This
				can be setup in <Icon name={'user'}/> Profile &gt; <Icon name={'cogs'}/> Profile Settings &gt; <Icon name={'university'}/> Payout details.
			</p>
			<p>
				<strong>This update brings few more interesting changes.</strong>
			</p>
			<p>
				Filters for games has been streamlined - Trackmania 2 has been grouped up no matter the environment, old games were removed and thus only these four options remain:
				<ul>
					<li>Trackmania 2020</li>
					<li>Nations & United Forever</li>
					<li>Trackmania Turbo</li>
					<li>Trackmania 2</li>
				</ul>
				This was to make the filter more clear and with only the games Trackmania authors are active in. If there are some of you who would wish to see this list
				changed, let me know, we can always discuss its structure. Maybe you've got skins for some of the other games that you want to share and that's great!
			</p>
			<p>
				<strong>What's next?</strong> In the near future, I would also like to release something I like to call <strong>Creativity Dashboard</strong> that would give authors better overview of how players
				interact with their skins. Which are the most popular, what are their approximate download numbers and how do those number change over time and maybe some other statistics based on your feedback.
			</p>

			<Image.Group size='medium' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px 0' }}>
				{
					paypalImages.map((image, index) => {
						return <Image className={'clickable'} src={'img/blog/' + image} onClick={() => {
							setPaypalImagesIndex(index);
							setPaypalGalleryOpen(true)
						}}/>
					})
				}
			</Image.Group>

			{
				paypalGalleryOpen && <Lightbox
					imageTitle={'We now support PayPal!'}
					imageCaption={'Players can pay for skins via PayPal. Authors can receive money via PayPal.'}
					mainSrc={'img/blog/' + paypalImages[paypalImagesIndex]}
					nextSrc={'img/blog/' + paypalImages[(paypalImagesIndex + 1) % paypalImages.length]}
					prevSrc={'img/blog/' + paypalImages[(paypalImagesIndex + paypalImages.length - 1) % paypalImages.length]}
					onCloseRequest={() => setColorVariationsGalleryOpen(false)}
					onMovePrevRequest={() =>
						setPaypalImagesIndex((paypalImagesIndex + paypalImages.length - 1) % paypalImages.length)
					}
					onMoveNextRequest={() =>
						setPaypalImagesIndex((paypalImagesIndex + 1) % paypalImages.length)
					}
				/>
			}

			<br/>

			<Header as='h2'>
				<Icon name='paint brush' />
				<Header.Content>
					Bundles and Color variations
					<Header.Subheader>Published on 12th of September 2023</Header.Subheader>
				</Header.Content>
			</Header>
			<Message>
				<Message.Header>TL;DR</Message.Header>
				<p>
					<strong>Authors can now create (and sell) bundles of skins. They can now also link multiple color variations of the same skin together.</strong>
				</p>
			</Message>
			<p>
				After a very, very long delay, I am proud to present to you new features that both the authors and the players can now enjoy for better browsing experience.
			</p>
			<p>
				<strong>Bundles:</strong> Many skins can share a theme or multiple color variations and makes sense to distribute them together in one package. That's where Bundles
				come into play. Authors will now find a new option in <strong><Icon name={'user'}/> Profile &gt; Bundles &gt; <Icon name={'plus'}/> Upload New Bundle</strong>. The
				Bundle editor looks and works similar to Skin editor - every bundle must have a name, one or two (or ten!) pictures and perhaps some description or Youtube video. Where
				it starts to differ is the last part where authors must select which skins belong to the bundle. They can select any of their skins. <strong>Additionaly</strong> they may
				choose that certain skin is only available through the bundle. Say you have 3 color variations, but want to add a premium 4th version to people purchasing the complete
				bundle. Or maybe you just want to distribute all color variations together. It's just another tool that you, authors, can use as you see fit.
			</p>
			<p>
				<strong>Color variations:</strong> It's not unusual that certain skins have multiple color variations, but are 'technically' the same skin. This new feature will allow
				authors to link together multiple skins and enable players to easily choose the one they like the most. For authors fully utilize this feature make sure to:
			</p>
			<ul>
				<li>
					<strong>Select Primary Color</strong> on your skins. Primary Color is the most dominant color for the given skin. You can also select a <strong>Secondary
					Color</strong> for where it makes sense but it's optional. These colors are used to show button that links skin variations together. For existing skins you can go to your
					<strong><Icon name={'user'}/> Profile &gt; <Icon name={'car'}/> Skins &gt; <Icon name={'edit'}/> Update</strong>. On the <strong>Update Skin</strong> page
					select <strong>Primary Color</strong> and don't forget to save.
				</li>
				<li>
					<strong>Link skin's color variations.</strong> You need to setup which skins are color variations of one another. This can also be done in the Skin Editor via
					<strong><Icon name={'user'}/> Profile &gt; Skins &gt; <Icon name={'plus'}/> Upload New Skin or <Icon name={'edit'}/> Update</strong>. At the bottom of the editor
					you can find section <strong>Linked Color Variations</strong>. You have to do this on one of the skins. If you link Skin A with Skin B, it will automatically be linked
					in both directions.
				</li>
			</ul>
			<p>
				Some of the new features have many combinations and ways they can be used. Should you encounter any issue <Link to={View.ABOUT.path}>Contact
				me</Link> please. <strong>Thank you.</strong>
			</p>

			<Image.Group size='medium' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px 0' }}>
				{
					colorVariationsImages.map((image, index) => {
						return <Image className={'clickable'} src={'img/guide/colors/' + image} onClick={() => {
							setColorVariationsImagesIndex(index);
							setColorVariationsGalleryOpen(true)
						}}/>
					})
				}
			</Image.Group>

			{
				colorVariationsGalleryOpen && <Lightbox
					imageTitle={'Color Variations'}
					imageCaption={'Authors can now link color variations together'}
					mainSrc={'img/guide/colors/' + colorVariationsImages[colorVariationsImagesIndex]}
					nextSrc={'img/guide/colors/' + colorVariationsImages[(colorVariationsImagesIndex + 1) % colorVariationsImages.length]}
					prevSrc={'img/guide/colors/' + colorVariationsImages[(colorVariationsImagesIndex + colorVariationsImages.length - 1) % colorVariationsImages.length]}
					onCloseRequest={() => setColorVariationsGalleryOpen(false)}
					onMovePrevRequest={() =>
						setColorVariationsImagesIndex((colorVariationsImagesIndex + colorVariationsImages.length - 1) % colorVariationsImages.length)
					}
					onMoveNextRequest={() =>
						setColorVariationsImagesIndex((colorVariationsImagesIndex + 1) % colorVariationsImages.length)
					}
				/>
			}

			<br/>

			<Header>Older articles:</Header>

			<Header as='h2'>
				<Icon name='hourglass half' />
				<Header.Content>
					Preserving the past
					<Header.Subheader>Published on 6th of September 2022</Header.Subheader>
				</Header.Content>
			</Header>
			<Message>
				<Message.Header>TL;DR</Message.Header>
				<p>
					Do you have <strong>any</strong> skins for <strong>older</strong> Trackmania games where the author may be long gone from the scene? Submit them
					to Trackmania-Skins.com or contact me.
				</p>
			</Message>
			<p>
				Older Trackmania games are still very popular but there is no reasonable way to get some cool skins for your aging cars. <strong>Let us fix that!</strong> Do you
				have skins for older Trackmania games on your computer that either belong to you or to an author long gone from the scene? Perhaps the author might not even be
				known! Let us be real, some of the older games really are <strong>very</strong> old.
			</p>
			<p>
				I am not a big fan of publishing work of an author without their consent or without them even knowing about it. Unfortunately, internet was very different ten years
				back and I don't see a reasonable way of how we can locate and talk to many of the authors. For most of these skins, it may not even be possible to know who created them.
				Now, you may have a different opinion about this and please feel free to voice your opinion on this matter, but I feel like preserving their (now very old and vintage)
				work and making it available to players is worth this dilemma. <strong>I also believe there is a middle ground.</strong>
			</p>
			<p>
				The plan is to bundle all these skins of unknown origin under "Unknown author". In the unlikely event that the original author comes forward, they
				can either be claim their work (it will be  and attributed to the author or deleted completely.
			</p>
			<p>
				As far as I am aware, all sites with skins for older games are dead as of today. That's the main problem. So the only alternative are your secret stashes located on
				your computers. Let us move together and let us hope our fate is not similar to that of Carpark and other sites like it.
			</p>
			<p>
				If you have some skins you want to share or if you just want to voice your opinion, please, do let me know. Contact me via email (info[at]trackmania-skins.com) or on
				Discord (Hanzik).
			</p>
			<p>
				<strong>Thank you.</strong>
			</p>

			<br/>

			<Header as='h2'>
				<Icon name='rocket' />
				<Header.Content>
					Liftoff!
					<Header.Subheader>Published on 9th of June 2022</Header.Subheader>
				</Header.Content>
			</Header>
			<p>
				Hello and welcome everyone! I am happy to finally roll out {Constant.WEBSITE_TITLE} and make it available to everyone. Big thank you goes to all the skin
				authors I contacted in the past weeks and months and who gave me a very important feedback. I haven't had the chance to work on all of their ideas yet,
				but I feel like the site is in a pretty solid place right now to be taken public and available to all.
			</p>
			<p>
				<strong>Not so fast Hanzik, what is this place anyway?</strong> Right, I never mentioned why we're here. It's because this is to be the place where players
				go to find their new Trackmania skin, of course! This is where you will have the largest selection of cars to chose from. This is the place where <strong>all authors
				can upload their skins and where they can sell them (or publish them for free for everyone)</strong>. Authors will not be limited by their ability to run their own
				website or their own shop. Everything can be simplified for them and made easier to share their work with the whole world.
			</p>
			<p>
				For those of you who are interested in more details about why this page exists and what it aims to achieve, read more on the <Link to={View.ABOUT.path}>About {Constant.WEBSITE_TITLE}</Link> page.
				If that doesn't answer your question there is also <Link to={View.FAQ.path}>FAQ page</Link>. And for everything else - feel free to contact me. I am always
				interested in your thoughts and ideas. There is a lot of text there as I aim to be as open about this project as possible, so for those of you who really want
				to know everything, it is all, hopefully, there.
			</p>
			<p>
				In the initial phase I would like to personally talk to as many skin authors and let them know about this site so they can populate it with some of their work.
				This is so when I write to more public places like Reddit, Discord servers and other similar sites to let players know they can find skins here it will already
				have some content.
			</p>
			<p>
				I hope we will all have a great time here.
			</p>
			<p>
				<strong>Hanzik, author of {Constant.WEBSITE_TITLE}</strong>
			</p>
		</Container>
	);
}
