import React from "react";
import {Icon, Pagination, PaginationProps} from "semantic-ui-react";

interface PaginationControlsProps {
	current: number,
	totalPages: number,
	disabled?: boolean,
	onPageChange: (page: number) => void
}

export default function PaginationControls(props: PaginationControlsProps) {

	return (
		<div className={'pagination'}>
			{
				props.totalPages > 1 && <Pagination
					disabled={props.disabled}
					defaultActivePage={props.current + 1}
					ellipsisItem={{content: <Icon name='ellipsis horizontal'/>, icon: true}}
					firstItem={{content: <Icon name='angle double left'/>, icon: true}}
					lastItem={{content: <Icon name='angle double right'/>, icon: true}}
					prevItem={{content: <Icon name='angle left'/>, icon: true}}
					nextItem={{content: <Icon name='angle right'/>, icon: true}}
					totalPages={props.totalPages}
					onPageChange={(event, data : PaginationProps) => {
						if (typeof data.activePage === 'number') {
							props.onPageChange(data.activePage - 1)
						}
					}}
				/>
			}
		</div>
	);
}
