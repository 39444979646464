import TmRoles from "../auth/TmRoles";
import {SemanticICONS} from "semantic-ui-react";

export class View {
	public static AUTHORS : View = new View('Authors', null, '/authors');
	public static AUTHOR : View = new View('Author', null, '/authors/');

	public static RESET_PASSWORD : View = new View('Reset Password', null, '/reset-password');

	public static SKINS : View = new View('Skins', null, '/skins');
	public static SKIN : View = new View('Skin', null, '/skins/');

	public static BUNDLES : View = new View('Bundles', null, '/bundles');
	public static BUNDLE : View = new View('Bundle', null, '/bundles/');

	public static ABOUT : View = new View('About', null, '/about');
	public static ABOUT_AUTHOR_PAYOUTS : View = new View('Author Payouts', null, '/about-payout-system');
	public static INSTALLATION_GUIDE : View = new View('Installation Guide', null, '/installation-guide');
	public static HOMEPAGE : View = new View('Homepage', null, '/');
	public static BLOG : View = new View('Blog', null, '/blog');
	public static FAQ : View = new View('Frequently Asked Questions', null, '/faq');
	public static TERMS_OF_SERVICE : View = new View('Terms of Service', null, '/terms-of-service');
	public static REFUND_POLICY : View = new View('Refund Policy', null, '/refunds');
	public static PRIVACY_POLICY : View = new View('Privacy Policy', null, '/privacy');
	public static SUBMISSIONS_RULES : View = new View('Rules for Submissions', null, '/submissions');
	public static ROADMAP : View = new View('Roadmap', null, '/roadmap');

	public static CART_SUMMARY : View = new View('Cart Summary', TmRoles.REGULAR, '/cart/summary');

	public static PROFILE : View = new View('Profile', TmRoles.REGULAR, '/me');
	public static CREATED_SKINS : View = new View('Created Skins', TmRoles.REGULAR, '/me/skins');
	public static CREATED_BUNDLES : View = new View('Created Bundles', TmRoles.REGULAR, '/me/bundles');
	public static CHECKOUT_SUCCESS : View = new View('Checkout Success', TmRoles.REGULAR, '/me/collection/success');
	public static CHECKOUT_FAILED : View = new View('Checkout Failed', TmRoles.REGULAR, '/me/collection/failed');
	public static OWNED_SKINS : View = new View('Owned Skins', TmRoles.REGULAR, '/me/collection');
	public static PURCHASE_HISTORY : View = new View('Purchase History', TmRoles.REGULAR, '/me/purchase-history');
	public static PUBLISH : View = new View('Publish', TmRoles.REGULAR, '/me/publish');
	public static PUBLISH_SKIN : View = new View('Publish New Skin', TmRoles.REGULAR, '/me/publish/skin');
	public static PUBLISH_BUNDLE : View = new View('Publish New Bundle', TmRoles.REGULAR, '/me/publish/bundle');
	public static EDIT_SKIN : View = new View('Update Skin', TmRoles.REGULAR, '/me/skin/edit/:id');
	public static EDIT_BUNDLE : View = new View('Update Bundle', TmRoles.REGULAR, '/me/bundle/edit/:id');
	public static CREATIVITY_DASHBOARD : View = new View('Creativity Dashboard', TmRoles.REGULAR, '/me/dashboard');
	public static PAYOUTS : View = new View('My Payouts', TmRoles.REGULAR, '/me/payouts');
	public static PAYOUT : View = new View('My Payouts', TmRoles.REGULAR, '/me/payouts/:id');
	public static PROFILE_SETTINGS : View = new View('Profile Settings', TmRoles.REGULAR, '/me/settings');

	public static ADMIN : View = new View('Admin', TmRoles.ADMIN, '/admin');
	public static ADMIN_USERS : View = new View('All Users', TmRoles.ADMIN, '/admin/users');
	public static ADMIN_ORDERS : View = new View('All Orders', TmRoles.ADMIN, '/admin/orders');
	public static ADMIN_PAYOUT : View = new View('Payout', TmRoles.ADMIN, '/admin/payout/:id');
	public static ADMIN_PAYOUTS : View = new View('All Payouts', TmRoles.ADMIN, '/admin/payouts');
	public static ADMIN_COMMENTS : View = new View('All Comments', TmRoles.ADMIN, '/admin/comments');
	public static ADMIN_DASHBOARD : View = new View('Site Dashboard', TmRoles.ADMIN, '/admin/dashboard');
	public static ADMIN_SKINS : View = new View('All Skins', TmRoles.ADMIN, '/admin/skins');
	public static ADMIN_SKIN_SOCIAL_MEDIA : View = new View('Post to Social Media', TmRoles.ADMIN, '/admin/social-media');

	private readonly _name : string;
	private readonly _requiredRole : string;
	private readonly _path : string;
	private readonly _icon : SemanticICONS;

	constructor(name: string, requiredRole: string, path: string) {
		this._name = name;
		this._requiredRole = requiredRole;
		this._path = path;
	}

	get name(): string {
		return this._name;
	}

	get requiredRole(): string {
		return this._requiredRole;
	}

	get path(): string {
		return this._path;
	}
}
