import {Grid, Header, Icon, Modal} from "semantic-ui-react";
import React from "react";
import {BundleDto} from "../../../build/generated-sources/dto/BundleDto";
import SkinCard from "../skin/SkinCard";

interface BundleContentsModalProps {
	bundle: BundleDto,
	open: boolean,
	close: () => void,
}

export default function BundleContentsModal(props: BundleContentsModalProps) {

	return (
		<Modal
			className={'login-modal'}
			size={'large'}
			open={props.open}
			onClose={() => props.close()}
		>
			<Modal.Header>
				<Header as='h2'>
					<Icon name={'car'} />
					<Header.Content>
						Contents of Bundle - {props.bundle.title}
					</Header.Content>
				</Header>
			</Modal.Header>
			<Modal.Content>
				<Grid stackable columns={3}>
					{
						props.bundle.skins.map(s => {
							return <Grid.Column key={s.id}><SkinCard showRating showPurchase skin={s} linkToSkin/></Grid.Column>
						})
					}
				</Grid>
			</Modal.Content>
		</Modal>
	)
}
