import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, Grid, Header, Icon, Message, Radio, TextArea} from "semantic-ui-react";
import {Constant} from "../../../../base/enums/Constant";
import {Formatter} from "../../../../base/util/Formatter";
import {SkinTypeUtil} from "../../../../base/util/SkinTypeUtil";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import SkinAssetContainer from "../../components/SkinAsset";
import {Link, useHistory} from "react-router-dom";
import {View} from "../../../../base/enums/View";
import BundleCardPreview from "../../../public/bundle/BundleCardPreview";
import {SyncOperationState} from "../../../../base/state/SyncOperationState";
import {toast} from "react-hot-toast";
import {ApolloError} from "@apollo/client";
import {AssetUtil} from "../../../../base/util/AssetUtil";
import {DroppableUtil} from "../../../../base/util/DroppableUtil";
import {EResourceType} from "../../../../build/generated-sources/enum/EResourceType";
import {ResourceDto} from "../../../../build/generated-sources/dto/ResourceDto";
import {Asset} from "../../../../base/dto/Asset";
import {useGetMyCreatedSkins, useLazyGetBundle} from "../../../../build/generated-sources/service/QueryService";
import {UseGetBundleData, UseGetMyCreatedSkinsData} from "../../../../build/generated-sources/service/QueryServiceModel";
import BundleMapper from "../../../../base/mapper/BundleMapper";
import {useCreateBundle, useUpdateBundle} from "../../../../build/generated-sources/service/MutationService";
import {UseCreateBundleData, UseUpdateBundleData} from "../../../../build/generated-sources/service/MutationServiceModel";
import {BundleInputDtoInput} from "../../../../build/generated-sources/dto/BundleInputDtoInput";
import Dropzone from "../Dropzone";
import PageMapper from "../../../../base/mapper/PageMapper";
import SkinMapper from "../../../../base/mapper/SkinMapper";
import {SkinDto} from "../../../../build/generated-sources/dto/SkinDto";
import {ResourceUtil} from "../../../../base/util/ResourceUtil";
import {SkinInputDtoInput} from "../../../../build/generated-sources/dto/SkinInputDtoInput";
import ModalSkinEditor from "../../components/ModalSkinEditor";
import {BundleUtil} from "../../../../base/util/BundleUtil";

interface BundleEditorProps {
	bundle?: string
}

export interface MySkinEntry {
	key: string,
	value: string,
	image: MySkinImageEntry,
	text: string
}

export interface MySkinImageEntry {
	avatar: boolean,
	src: string
}

export default function BundleEditor(props: BundleEditorProps) {

	const history = useHistory();
	const [syncState, setSyncState] = useState<SyncOperationState>({inProgress: false, error: false});
	const [toastId, setToastId] = useState<string>();
	const [dropboxImages, setDropboxImages] = useState<File[]>([]);
	const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
	const [validate, setValidate] = useState<boolean>(false);
	// @ts-ignore
	const onDrop = useCallback(acceptedFiles => {
		setDropboxImages([...dropboxImages, ...acceptedFiles])
	}, [dropboxImages]);
	const [mySkinsDropdown, setMySkinsDropdown] = useState<MySkinEntry[]>([]);
	const [mySkins, setMySkins] = useState<SkinDto[]>([]);
	const [skinsInBundle, setSkinsInBundle] = useState<SkinInputDtoInput[]>([]);
	const [skinEditorOpen, setSkinEditorOpen] = useState<boolean>(false);

	const [images, setImages] = useState<Asset[]>([]);

	useEffect(() => {
		if (dropboxImages.length === 0) {
			return;
		}

		let existingFilenames = images.filter(i => !!i.file).map(i => i.file.name);
		let count = images.length;
		let assets: Asset[] = Object.assign([], images);

		dropboxImages.filter(i => !existingFilenames.includes(i.name)).forEach(file => assets.push(createFileAsset(file, EResourceType.IMAGE, count++)));
		setImages(assets);
	}, [dropboxImages]);

	useEffect(() => {
		if (syncState.inProgress) {
			return;
		}
		let assetsForUpload = images.filter(i => i.file && !uploadedFiles.includes(i.file.name));

		if (assetsForUpload.length === 0) {
			return;
		}

		let upldFls = Object.assign([], uploadedFiles);
		assetsForUpload.forEach(a => upldFls.push(a.file.name));
		setUploadedFiles(upldFls);

		assetsForUpload.forEach(a => {
			upload(a)
		});

	}, [images])

	const [bundle, setBundle] = useState<BundleInputDtoInput>({
		id: undefined,
		price: 0,
		title: '',
		description: '',
		videoUrl: '',
		type: SkinTypeUtil.DEFAULT_TYPE.key,
	});

	const createUrlAsset = (resource: ResourceDto): Asset => {
		return {
			id: resource.filename,
			url: '/public/' + resource.filename,
			type: resource.type,
			success: true,
			uploading: false,
			order: resource.order
		}
	}

	const createFileAsset = (file: File, type: EResourceType, order: number): Asset => {
		return {
			id: file.name,
			file: file,
			type: type,
			success: false,
			uploading: true,
			order: order
		}
	}

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		if (result.source.index === result.destination.index) {
			return;
		}

		const items = AssetUtil.reorder(
			images,
			result.source.index,
			result.destination.index
		);

		setImages(items);
	}

	const upload = (asset: Asset) => {
		let form = new FormData();
		form.append("file", asset.file);
		const requestOptions = {
			method: 'POST',
			body: form
		};

		fetch('/assets/skin/temp', requestOptions).then(response => {
			response.text().then(data => {
				let assetResponse = JSON.parse(data);
				let ar = {
					id: assetResponse.id,
					success: assetResponse.success,
					message: assetResponse.message,
					uploading: false,
				}
				setImages((currentImages) =>
					currentImages.map(i => (i.id === asset.id ? Object.assign({}, {...i, ...ar}) : i))
				);
			});
		});
	};

	const [getBundle] = useLazyGetBundle(BundleMapper.WITHOUT_AUTHOR, {
		onCompleted: (data: UseGetBundleData) => {
			setBundle({
				id: data.bundle.id,
				price: data.bundle.price,
				title: data.bundle.title,
				description: data.bundle.description,
				videoUrl: data.bundle.videoUrl ?? '',
				type: data.bundle.type
			});
			setSkinsInBundle(data.bundle.skins.map(skin => {
				return {
					id: skin.id,
					price: skin.price,
					availableAlone: skin.availableAlone
				}
			}));
			let assets: Asset[] = data.bundle.resources.map(r => {
				return createUrlAsset(r);
			});
			setImages(assets);
		}
	});

	useEffect(() => {
		if (props.bundle && !bundle.id) {
			getBundle({
				variables: {
					url: props.bundle
				}
			});
		}
	}, [props.bundle, getBundle]);

	const [create] = useCreateBundle(BundleMapper.WITHOUT_AUTHOR, {
		onCompleted: (data: UseCreateBundleData) => {
			toast.dismiss(toastId);
			toast.success(<strong>Bundle uploaded and published!</strong>);
			history.push(View.CREATED_BUNDLES.path);
		},
		onError: (data: ApolloError) => {
			toast.dismiss(toastId);
			toast.error(<strong>Error uploading the bundle.</strong>);
			setSyncState({
				...syncState,
				inProgress: false,
				error: true
			});
		}
	});

	const [update] = useUpdateBundle(BundleMapper.WITHOUT_AUTHOR, {
		onCompleted: (data: UseUpdateBundleData) => {
			toast.dismiss(toastId);
			history.push(View.CREATED_BUNDLES.path);
		},
		onError: (data: ApolloError) => {
			toast.dismiss(toastId);
			toast.error(<strong>Error uploading the bundle.</strong>);
			setSyncState({
				...syncState,
				inProgress: false,
				error: false
			});
		}
	});

	const onFieldChange = (value: any, field: string) => {
		setBundle({
			...bundle,
			[field]: value
		});
	}

	const onChange = (e: any, field: string) => {
		onFieldChange(e.target.value, field);
	}

	const onSelectChange = (target: any, field: string) => {
		onFieldChange(target.value, field);
	}

	const removeImage = (asset: Asset) => {
		let assets = images.filter(a => a.id !== asset.id)
			.map(a => {
				return {...a, order: a.order > asset.order ? a.order - 1 : a.order}
			});
		setUploadedFiles(uploadedFiles.filter(f => (!asset.file || f !== asset.file.name)));
		setImages(assets);
		setDropboxImages(dropboxImages.filter(f => (!asset.file || f.name !== asset.file.name)));
	}

	const getCreatedSkins = useGetMyCreatedSkins({
		pageInfo: PageMapper.ALL,
		content: SkinMapper.ALL
	}, {
		variables: {
			page: {
				page: 0,
				size: 100
			}
		},
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetMyCreatedSkinsData) => {
			setMySkins(data.myCreatedSkins.content);
			setMySkinsDropdown(data.myCreatedSkins.content.map(item => {
				const id = item.id.toString();
				return {
					key: id,
					value: id,
					text: item.title.length > 25 ? item.title.substr(0, 25) + '...' : item.title,
					image: {
						avatar: true,
						src: ResourceUtil.getThumbnailPath(item.resources)
					}
				};
			}));
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const publish = () => {
		if (!BundleUtil.validate(bundle, images, props.bundle)) {
			setValidate(true);
			return;
		}
		setValidate(false);
		setSyncState({
			...syncState,
			inProgress: true
		});
		let requestDto = {
			...bundle,
			skins: skinsInBundle
				.filter(skin => !!skin.id)
				.map(skin => {
					return {
						id: skin.id,
						availableAlone: skin.availableAlone,
						price: skin.price
					}
				}),
			images: images.map(i => {
				return {
					id: i.id,
					temporary: !!i.file,
					type: EResourceType.IMAGE,
					order: i.order
				}
			}),
		}
		if (props.bundle) {
			setToastId(toast.loading('Updating your bundle...'));
			update({
				variables: {
					dto: requestDto
				}
			});
		} else {
			setToastId(toast.loading('Publishing your new bundle...'));
			create({
				variables: {
					dto: requestDto
				}
			});
		}
	}

	const p: any = bundle.price;
	const price = Number.parseFloat(p);

	return (
		<>
			{
				skinEditorOpen && <ModalSkinEditor
					enableColorVariations={false}
					type={bundle.type}
					title={'Upload New Skin'}
					subtitle={'It will be added to the bundle automatically'}
					open={skinEditorOpen}
					close={() => setSkinEditorOpen(false)}
					onSave={(skin, toastMessage) => {
						let skins = Object.assign([], skinsInBundle);
						skins.push(skin);
						getCreatedSkins.refetch({page: {page: 0, size: 100}});
						setSkinsInBundle(skins);
						toast.success(toastMessage);
						setSkinEditorOpen(false);
					}}
				/>
			}
			<Grid>
				<Grid.Row>
					<Grid.Column width={10}>
						<Header as='h2'>
							<Icon name='upload' />
							<Header.Content>
								{props.bundle ? 'Update Bundle' : 'Publish New Bundle'}
								<Header.Subheader>Pack multiple color variations or similar-themed skins together</Header.Subheader>
							</Header.Content>
						</Header>
						<Form className={'editor'}>
							<Form.Group>
								<Form.Input width={12} error={validate && !BundleUtil.nameValid(bundle)} type={'text'}
											label={(validate && !BundleUtil.nameValid(bundle)) ? 'Name (between ' + Constant.MIN_SKIN_NAME_LENGTH + ' and ' + Constant.MAX_SKIN_NAME_LENGTH + ' characters)' : 'Name'}
											value={bundle.title} onChange={(e) => {
									if (e.target.value.length < Constant.MAX_SKIN_NAME_LENGTH) {
										onChange(e, 'title')
									}
								}}/>
								<Form.Input width={4} error={!BundleUtil.priceValid(bundle)} type={'number'}
											label={'Price (€)'} value={bundle.price} onChange={(e) => onChange(e, 'price')}/>
							</Form.Group>
							{(isNaN(price) || price === 0) &&
								<Message icon={'thumbs up'} positive header={'This will be a free bundle'}
										 content={'Anyone will be able to download all skins from this bundle.'}/>}
							{price > 0.001 && price < 2 &&
								<Message icon={'warning sign'} negative header={'Minimum price for paid bundle is ' + Formatter.money(Constant.MIN_SKIN_PRICE)}
										 content={'This is to protect both you and the site from flat fees charge by the payment gates (like PayPal and Stripe). This could result in all (if not most) of the money to be taken by them and none of it reaching you.'}/>}
							{price >= 2 && price <= Constant.MAX_SKIN_PRICE && <Message icon={'money bill alternate outline'} positive header={'This will be a paid bundle'}
																						content={'Every time someone buys this bundle, the money will be collected and periodically sent to your account. You can track these payments in your profile under My Payouts. Make sure you have your bank account details setup via Profile Settings.'}/>}
							{price > Constant.MAX_SKIN_PRICE &&
								<Message icon={'warning sign'} negative header={'Maximum price for paid bundle is ' + Formatter.money(Constant.MAX_SKIN_PRICE)}
										 content={'If you have serious interest in submitting such a high valued item to this site, let us personally know.'}/>}
							<Form.Select label='Game' value={bundle.type} options={SkinTypeUtil.getOptions()}
										 onChange={(e, target) => onSelectChange(target, 'type')}
							/>
							<Form.Input control={TextArea}
										label={(validate && !BundleUtil.descriptionValid(bundle)) ? 'Description (up to ' + Constant.MAX_SKIN_DESCR_LENGTH + ' characters)' : 'Description'}
										error={validate && !BundleUtil.descriptionValid(bundle)} value={bundle.description} onChange={(e) => {
								if (e.target.value.length < Constant.MAX_SKIN_DESCR_LENGTH) {
									onChange(e, 'description')
								}
							}}/>
							<Form.Input type={'text'} label={'Youtube video URL (optional)'} placeholder={'https://www.youtube.com/watch?v=dQw4w9WgXcQ'} icon={'youtube'}
										value={bundle.videoUrl}
										onChange={(e) => onChange(e, 'videoUrl')}/>
							{
								images.length > 0 && <DragDropContext onDragEnd={onDragEnd}>
									<Header color={'black'} as={'h4'}><Icon name={'info circle'}/> Order Your Images by Dragging Them</Header>
									<Droppable droppableId="1" isDropDisabled={syncState.inProgress} direction="horizontal">
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												style={DroppableUtil.getListStyle(snapshot.isDraggingOver)}
												{...provided.droppableProps}
											>
												{images.map((item, index) => (
													<Draggable key={item.id} draggableId={(item.id)} index={index}>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={DroppableUtil.getItemStyle(
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
															>
																<SkinAssetContainer asset={item} validate={validate} onRemove={removeImage}/>
															</div>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							}

							<Dropzone onDrop={onDrop} validate={validate} images={images} accept={{'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg']}} />

							{
								validate && !BundleUtil.validate(bundle, images, props.bundle) && <Message negative>
									<Message.Content>
										<Message.Header>Validation errors</Message.Header>
										<p>You must correct these issues before attempting to publish your bundle:</p>
										<Message.List>
											{!BundleUtil.imagesExist(images) && <Message.Item>You must upload at least one image.</Message.Item>}
											{!BundleUtil.imagesValid(images) && <Message.Item>
												You must fix any images that failed to upload by removing them and trying to upload them again. Upload may have
												failed due to incorrect format, image being larger than {Constant.MAX_SKIN_IMAGE_SIZE_MB} MB or connection issues.
											</Message.Item>}
											{!BundleUtil.nameValid(bundle) && <Message.Item>Bundle name is a required field. It must have
												between {Constant.MIN_SKIN_NAME_LENGTH} and {Constant.MAX_SKIN_NAME_LENGTH} characters.</Message.Item>}
											{!BundleUtil.descriptionValid(bundle) && <Message.Item>Description is a required field. It can have
												up to {Constant.MAX_SKIN_DESCR_LENGTH} characters.</Message.Item>}
										</Message.List>
									</Message.Content>
								</Message>
							}

							<Header>Bundle Contents ({skinsInBundle.length})</Header>

							<Button.Group widths={2}>
								<Button color={'black'} onClick={() => {
									let skins = Object.assign([], skinsInBundle);
									skins.push({});
									setSkinsInBundle(skins);
								}}>Add Existing Skin</Button>
								<Button floated={'right'} basic color={'black'} onClick={() => setSkinEditorOpen(true)}>Upload New Skin</Button>
							</Button.Group>

							<br/>
							<br/>

							{
								skinsInBundle.map((skin, i) => {
									return <Form.Group key={i}>
										<Form.Select value={skin.id ? skin.id.toString() : skin.id} selection search label={'Name'} width={9} options={mySkinsDropdown} onChange={(e, item) => {
											let skins = Object.assign([], skinsInBundle);
											let preset = mySkins.find(s => s.id.toString() === item.value);
											skins[i].id = preset.id;
											skins[i].price = preset.price;
											skins[i].availableAlone = preset.availableAlone;
											setSkinsInBundle(skins);
										}}/>
										<Form.Input width={4} style={{display: 'flex', justifyContent: 'center'}} label={'Available alone?'}>
											<Radio style={{marginTop: '8px'}} toggle checked={skin.availableAlone} onChange={(e, data) => {
												let skins = Object.assign([], skinsInBundle);
												skins[i].availableAlone = data.checked;
												setSkinsInBundle(skins);
											}}/>
										</Form.Input>
										<Form.Input label={'Remove'} width={2}>
											<Button basic color={'red'} icon={'trash'} onClick={() => setSkinsInBundle(skinsInBundle.filter(s => s.id !== skinsInBundle[i].id))} />
										</Form.Input>
									</Form.Group>
								})
							}

							<br/>
							<br/>
							<br/>

							<Link to={View.CREATED_SKINS.path}><Button size={'large'} floated={'left'} disabled={syncState.inProgress}>Cancel</Button></Link>
							<Button size={'large'} color={'black'} floated={'right'} loading={syncState.inProgress} onClick={() => publish()}>
								<Icon name={syncState.inProgress ? 'circle notch' : 'upload'}/> {props.bundle ? 'Update Bundle' : 'Publish Bundle'}
							</Button>
						</Form>
					</Grid.Column>
					<Grid.Column width={6}>
						<Header>Bundle Card Preview</Header>
						<BundleCardPreview bundle={bundle} image={images.length ? images[0] : undefined}/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
}
