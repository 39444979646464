import React, {useState} from "react";
import {Card, Flag, Image} from "semantic-ui-react";
import {View} from "../../../base/enums/View";
import {useHistory} from "react-router-dom";
import {ResourceUtil} from "../../../base/util/ResourceUtil";
import {BundleDto} from "../../../build/generated-sources/dto/BundleDto";
import GetBundleButton from "./GetBundleButton";
import BundleContentsModal from "./BundleContentsModal";

interface BundleCardProps {
	bundle: BundleDto
	showDescription?: boolean
	showPurchase?: boolean
	enableRating?: boolean
	linkToBundle?: boolean
}

export default function BundleCard(props: BundleCardProps) {

	const history = useHistory();
	const [contentsOpen, setContentsOpen] = useState<boolean>(false);

	const goToUser = (url: string) => {
		history.push(View.AUTHOR.path + url);
	}

	const goToBundle = (url: string) => {
		history.push(View.BUNDLE.path + url);
	}

	return (
		<Card className={(props.linkToBundle ? 'clickable' : '') + (' skin-overview borderful')} onClick={() => {if(props.linkToBundle) {goToBundle(props.bundle.url)}}}>
			<BundleContentsModal bundle={props.bundle} open={contentsOpen} close={() => setContentsOpen(false)} />
			<Image src={ResourceUtil.getThumbnailPath(props.bundle.resources)} wrapped ui={false}/>
			<Card.Content>
				<Card.Header style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.bundle.title}</Card.Header>
				<Card.Meta>
					<Flag name={props.bundle.author.country as any}/> <span className='date clickable' onClick={() => goToUser(props.bundle.author.url)}>{props.bundle.author.username}</span>
				</Card.Meta>
				{
					props.showDescription && <Card.Description>
						{props.bundle.description}
					</Card.Description>
				}
				{
					props.showPurchase && <Card.Content extra style={{marginTop: '16px', float: 'right'}}>
						<div className={'price'} onClick={(e) => e.stopPropagation()}>
							{
								props.showPurchase && <GetBundleButton onClick={() => setContentsOpen(true)} bundle={props.bundle} />
							}
						</div>
					</Card.Content>
				}
			</Card.Content>
		</Card>
	);
}
