import React, {useMemo, useState} from "react";
import {Grid, Header, Icon, Message, Popup, Segment, Statistic, Table} from "semantic-ui-react";
import SimpleLoader from "../../components/SimpleLoader";
import {Link} from "react-router-dom";
import {View} from "../../base/enums/View";
import {DownloadCountSummaryDto} from "../../build/generated-sources/dto/DownloadCountSummaryDto";
import {DownloadHistoryDto} from "../../build/generated-sources/dto/DownloadHistoryDto";
import {PurchaseHistoryDto} from "../../build/generated-sources/dto/PurchaseHistoryDto";
import {SkinDownloadCountSummaryDto} from "../../build/generated-sources/dto/SkinDownloadCountSummaryDto";
import {SkinPurchaseCountSummaryDto} from "../../build/generated-sources/dto/SkinPurchaseCountSummaryDto";
import {MoneySpentSummaryDto} from "../../build/generated-sources/dto/MoneySpentSummaryDto";
import {useGetDownloadCount, useGetDownloadHistory, useGetSkinDownloadPopularity} from "../../build/generated-sources/service/QueryService";
import SkinMapper from "../../base/mapper/SkinMapper";
import {UseGetDownloadCountData, UseGetDownloadHistoryData, UseGetSkinDownloadPopularityData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import moment from "moment/moment";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Formatter} from "../../base/util/Formatter";
import {Constant} from "../../base/enums/Constant";

export default function CreativityDashboard() {

	const [downloadCount, setDownloadCount] = useState<DownloadCountSummaryDto>();
	const [downloadHistoryRaw, setDownloadHistoryRaw] = useState<DownloadHistoryDto[]>([]);
	const [skinDownloadPopularity, setSkinDownloadPopularity] = useState<SkinDownloadCountSummaryDto>();

	const getSkinDownloadPopularity = useGetSkinDownloadPopularity({
		allTime: {
			skin: SkinMapper.ALL,
			total: true,
			unique: true
		},
		last30Days: {
			skin: SkinMapper.ALL,
			total: true,
			unique: true
		},
		lastYear: {
			skin: SkinMapper.ALL,
			total: true,
			unique: true
		},
	}, {
		fetchPolicy: 'network-only',
		variables: {
			length: 10
		},
		onCompleted: (data: UseGetSkinDownloadPopularityData) => {
			setSkinDownloadPopularity(data.skinDownloadPopularity);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const getDownloadCount = useGetDownloadCount({
		allTimeTotal: true,
		allTimeUnique: true,
		last30DaysTotal: true,
		last30DaysUnique: true,
		lastYearTotal: true,
		lastYearUnique: true,
	}, {
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetDownloadCountData) => {
			setDownloadCount(data.downloadCount);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const getDownloadHistory = useGetDownloadHistory({
		data: {
			game: true,
			month: true,
			total: true,
			unique: true,
			year: true
		}
	}, {
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetDownloadHistoryData) => {
			setDownloadHistoryRaw(data.downloadHistory.data);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const downloadHistory = useMemo(() => {
		if (!downloadHistoryRaw) {
			return [];
		}

		const result: any[] = [];
		downloadHistoryRaw.forEach(row => {
			const game = row.game;
			const key = moment(row.month, moment.ISO_8601).format('MM') + '-' + moment(row.month, moment.ISO_8601).format('YYYY');
			if (!result.hasOwnProperty(key)) {
				// @ts-ignore
				result[key] = {
					name: key
				};
			}
			// @ts-ignore
			result[key]['unique' + game] = row.unique;
			// @ts-ignore
			result[key]['total' + game] = row.total;
		});

		const r : any[] = [];
		for (const [key, value] of Object.entries(result)) {
			r.push(value);
		}
		return r;
	}, [downloadHistoryRaw]);

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='dashboard'/>
				<Header.Content>
					Creativity Dashboard
					<Header.Subheader>How's your work performing? <Popup trigger={<Icon circular name='question circle'/>} wide>
							<Popup.Header style={{lineHeight: 1.8}}></Popup.Header>
							<Popup.Content>
								<p>
									Download counter is updated once a day.
								</p>
								<p>
									<strong>Total</strong> downloads = Every time somebody downloads your skin, this counter is increased by one. If a player downloads a single skin multiple times, all these attempts will be counted towards this number.
								</p>
								<p>
									<strong>Unique</strong> downloads = Approximate number of unique players that downloaded the skin. Our site doesn't track its users, so this number is based on fairly inaccurate metrics and can not be (by design) precise.
								</p>
							</Popup.Content>
						</Popup></Header.Subheader>
				</Header.Content>
			</Header>
			<Grid>
				<Grid.Column width={8}>
					<Segment color='yellow'>
					{
						(!downloadCount || getDownloadCount.loading) ? <SimpleLoader text={"Loading All Time Downloads"} /> : <>
							<Header>Last 30 Days

							</Header>
							  <Statistic.Group>
								  <Statistic>
									<Statistic.Value>{downloadCount.last30DaysUnique}</Statistic.Value>
									<Statistic.Label>Unique downloads</Statistic.Label>
								  </Statistic>
								  <Statistic>
									<Statistic.Value>{downloadCount.last30DaysTotal}</Statistic.Value>
									<Statistic.Label>Total Downloads</Statistic.Label>
								  </Statistic>
							  </Statistic.Group>
						</>
					}
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color='yellow'>
					{
						(!downloadCount || getDownloadCount.loading) ? <SimpleLoader text={"Loading All Time Downloads"} /> : <>
							<Header>All Time</Header>
							  <Statistic.Group>
								  <Statistic>
									<Statistic.Value>{downloadCount.allTimeUnique}</Statistic.Value>
									<Statistic.Label>Unique downloads</Statistic.Label>
								  </Statistic>
								  <Statistic>
									<Statistic.Value>{downloadCount.allTimeTotal}</Statistic.Value>
									<Statistic.Label>Total Downloads</Statistic.Label>
								  </Statistic>
							  </Statistic.Group>
						</>
					}
					</Segment>
				</Grid.Column>
				<Grid.Column width={16}>
					<Segment color='yellow'>
						<Header>Downloads History (Unique)</Header>
						<ResponsiveContainer width={"100%"} height={240}>
							<BarChart
								width={500}
								height={300}
								data={downloadHistory}
								margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
								}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Bar name={"Trackmania 2020"} dataKey="uniqueTM2020" stackId="unique" fill="#17aae6" />
								<Bar name={"Trackmania Nations Forever"} dataKey="uniqueTMNF" stackId="unique" fill="#006700" />
								<Bar name={"Trackmania United Forever"} dataKey="uniqueTMUF" stackId="unique" fill="#003400" />
								<Bar name={"Trackmania Turbo"} dataKey="uniqueTM_TURBO" stackId="unique" fill="#ff00ff" />
								<Bar name={"Trackmania 2"} dataKey="uniqueTM2" stackId="unique" fill="#00e6e6" />
								<Bar name={"Trackmania Sunrise"} dataKey="uniqueTM_SUNRISE" stackId="unique" fill="#ffff00" />
								<Bar name={"Trackmania 2003"} dataKey="uniqueTM" stackId="unique" fill="#a9a9a9" />
							</BarChart>
						</ResponsiveContainer>
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color='yellow'>
						<Header>Most Downloaded Skins (Last 30 days)</Header>
						{
							(!skinDownloadPopularity || getSkinDownloadPopularity.loading) ? <SimpleLoader text={"Loading Most Downloaded Skins"} /> : <>
								{
									skinDownloadPopularity.last30Days.length ? <Table compact basic={'very'}>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell>Skin</Table.HeaderCell>
												<Table.HeaderCell textAlign={'center'}>Unique</Table.HeaderCell>
												<Table.HeaderCell textAlign={'center'}>Total</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{
												skinDownloadPopularity.last30Days.map(skin => <Table.Row key={'last30days_' + skin.skin.id}>
														<Table.Cell><Link to={View.SKIN.path + skin.skin.url}><strong>{skin.skin.title}</strong></Link></Table.Cell>
														<Table.Cell textAlign={'center'}>{skin.unique}</Table.Cell>
														<Table.Cell textAlign={'center'}>{skin.total}</Table.Cell>
													</Table.Row>)
											}
										</Table.Body>
									</Table>
									:
									<Message icon={'frown outline'} header={'No downloads'} content={'There were no downloads in the last 30 days.'} />
								}
							</>
						}
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color='yellow'>
						<Header>Most Downloaded Skins (Last Year)</Header>
						{
							(!skinDownloadPopularity || getSkinDownloadPopularity.loading) ? <SimpleLoader text={"Loading Most Downloaded Skins"} /> : <>
								{
									skinDownloadPopularity.lastYear.length ? <Table compact basic={'very'}>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell>Skin</Table.HeaderCell>
												<Table.HeaderCell textAlign={'center'}>Unique</Table.HeaderCell>
												<Table.HeaderCell textAlign={'center'}>Total</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{
												skinDownloadPopularity.lastYear.map(skin => <Table.Row key={'lastYear2_' + skin.skin.id}>
														<Table.Cell><Link to={View.SKIN.path + skin.skin.url}><strong>{skin.skin.title}</strong></Link></Table.Cell>
														<Table.Cell textAlign={'center'}>{skin.unique}</Table.Cell>
														<Table.Cell textAlign={'center'}>{skin.total}</Table.Cell>
													</Table.Row>)
											}
										</Table.Body>
									</Table>
									:
									<Message icon={'frown outline'} header={'No downloads'} content={'There were no downloads in the last year.'} />
								}
							</>
						}
					</Segment>
				</Grid.Column>
			</Grid>
		</>
	);
}
