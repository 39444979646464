import {AuthorPayoutBatchDto} from "../../../build/generated-sources/dto/AuthorPayoutBatchDto";
import {Formatter} from "../../../base/util/Formatter";
import {EPayoutStatus} from "../../../build/generated-sources/enum/EPayoutStatus";
import {Icon, Label, List, Popup} from "semantic-ui-react";
import React from "react";
import {EPayoutMethod} from "../../../build/generated-sources/enum/EPayoutMethod";
import moment from "moment";
import {PayoutUtil} from "../../../base/util/PayoutUtil";

interface PayoutInformationProps {
    payout: AuthorPayoutBatchDto
}

export default function PayoutInformation(props: PayoutInformationProps) {

    const sentOn = moment(props.payout.sentOn, moment.ISO_8601).diff(moment.now(), 'days')
    const estimatedPayoutDaysLeft = -moment(props.payout.estimatedPayoutDate, moment.ISO_8601).diff(moment.now(), 'days')
    const getDaysLeftLabel = (daysLeft : number) : string => {
        if (daysLeft <= 0) {
            return 'Today';
        } else if (daysLeft === 1) {
            return 'Tomorrow';
        } else if (daysLeft > 1) {
            return 'in ' + daysLeft + ' days';
        }
    }

    const getDaysAgoLabel = (daysAgo : number) : string => {
        if (daysAgo >= 0) {
            return 'Today';
        } else if (daysAgo === 1) {
            return 'Yesterday';
        } else if (daysAgo > 1) {
            return daysAgo + ' days ago';
        }
    }

    if (props.payout.payoutMethod === EPayoutMethod.PAYPAL) {
        return (
            <>
                {props.payout.payoutStatus === EPayoutStatus.PENDING && <Popup wide
                    trigger={<Label color={PayoutUtil.getColor(props.payout.payoutStatus)}><Icon name={'stopwatch'}/>  Collecting payments</Label>}
                    content="We are collecting payments for your skins and grouping them together under this Payout. We send payouts to PayPal manually upon your request."
                    basic
                />}
                {props.payout.payoutStatus === EPayoutStatus.LOCKED && <Popup wide
                    trigger={<Label color={PayoutUtil.getColor(props.payout.payoutStatus)}><Icon name={'lock'}/>  Locked</Label>}
                    content="This Payout is locked and being prepared to be sent to you. All new payments will be collected under a new Payout. Some transactions may
                    take up to a week to reach us so we need to wait to collect all the money to be able to send it then to you."
                    basic
                />}
                {props.payout.payoutStatus === EPayoutStatus.SENT && <Popup wide
                    trigger={<Label color={PayoutUtil.getColor(props.payout.payoutStatus)}><Icon name={'checkmark'}/> Sent</Label>}
                    content="The PayPal transaction for this Payout was sent to you. You should receive the money within 1-3 work days."
                    basic
                />}
                {props.payout.payoutStatus === EPayoutStatus.ERROR && <Popup wide
                    trigger={<Label color={PayoutUtil.getColor(props.payout.payoutStatus)}><Icon name={'x'}/> Error</Label>}
                    content="There was an error while transferring money to your PayPal account. This may happen if you fail to provide us with a correct PayPal account details in your Profile Settings."
                    basic
                />}
                <List>
                    {props.payout.sentOn && <List.Item><strong>Sent:</strong> {getDaysAgoLabel(sentOn)}</List.Item>}
                    {props.payout.sendToPaypalAccount && <List.Item><strong>PayPal account:</strong> {props.payout.sendToPaypalAccount}</List.Item>}
                    {props.payout.payoutMethod && <List.Item><strong>Payment method:</strong> PayPal</List.Item>}
                    <br/>
                    {!props.payout.amount && <List.Item><strong>Estimated payout: {Formatter.money(props.payout.items.map(item => item.authorReward).reduce((a, b) => a + b, 0))} (EUR)</strong></List.Item>}
                    {props.payout.amount && <List.Item><strong>Total payout: {Formatter.money(props.payout.amount)} (EUR)</strong></List.Item>}
                    {props.payout.payoutStatus !== EPayoutStatus.SENT && <List.Item>Estimated payout date: {getDaysLeftLabel(estimatedPayoutDaysLeft)}</List.Item>}
                </List>
            </>
        );
    } else {
        return (
            <>
                {props.payout.payoutStatus === EPayoutStatus.PENDING && <Popup wide
                    trigger={<Label color={PayoutUtil.getColor(props.payout.payoutStatus)}><Icon name={'stopwatch'}/>  Collecting payments</Label>}
                    content="We are collecting payments for your skins and grouping them together under this Payout. We send payouts approximately every 15 days."
                    basic
                />}
                {props.payout.payoutStatus === EPayoutStatus.LOCKED && <Popup wide
                    trigger={<Label color={PayoutUtil.getColor(props.payout.payoutStatus)}><Icon name={'lock'}/>  Locked</Label>}
                    content="This Payout is locked and being prepared to be sent to you. All new payments will be collected under a new Payout. Some transactions may
                    take up to a week to reach us so we need to wait to collect all the money to be able to send it then to you."
                    basic
                />}
                {props.payout.payoutStatus === EPayoutStatus.SENT && <Popup wide
                    trigger={<Label color={PayoutUtil.getColor(props.payout.payoutStatus)}><Icon name={'checkmark'}/> Sent</Label>}
                    content="The bank transaction for this Payout was sent to you. You should receive the money within 1-3 work days."
                    basic
                />}
                {props.payout.payoutStatus === EPayoutStatus.ERROR && <Popup wide
                    trigger={<Label color={PayoutUtil.getColor(props.payout.payoutStatus)}><Icon name={'x'}/> Error</Label>}
                    content="There was an error while transferring money to your account. This may happen if you fail to provide us with a correct account details in your Profile Settings."
                    basic
                />}
                <List>
                    {props.payout.sentOn && <List.Item><strong>Sent:</strong> {moment(props.payout.sentOn, moment.ISO_8601).format("DD. MM. YYYY")}</List.Item>}
                    {props.payout.sendToIban && <List.Item><strong>IBAN:</strong> {props.payout.sendToIban}</List.Item>}
                    {props.payout.sendToBic && <List.Item><strong>BIC:</strong> {props.payout.sendToBic}</List.Item>}
                    {props.payout.externalId && <List.Item><strong>Transaction ID:</strong> {props.payout.externalId}</List.Item>}
                    {props.payout.payoutMethod && <List.Item><strong>Payment method:</strong> Bank Transfer</List.Item>}
                    <br/>
                    {!props.payout.amount && <List.Item><strong>Estimated payout: {Formatter.money(props.payout.items.map(item => item.authorReward).reduce((a, b) => a + b, 0))} (EUR)</strong></List.Item>}
                    {props.payout.amount && <List.Item><strong>Total payout: {Formatter.money(props.payout.amount)} (EUR)</strong></List.Item>}
                    {props.payout.payoutStatus !== EPayoutStatus.SENT && <List.Item>Estimated payout date: {getDaysLeftLabel(estimatedPayoutDaysLeft)}</List.Item>}
                </List>
            </>
        );
    }
}
