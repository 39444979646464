import React, {useState} from "react";
import {Modal} from "semantic-ui-react";
import Register from "./Register";
import Login from "./Login";
import ForgottenPassword from "./ForgottenPassword";

interface LoginModalProps {
	open: boolean,
	mode: string,
	close: () => void
}

export default function LoginModal(props: LoginModalProps) {

	const [mode, setMode] = useState<string>(props.mode);

	return (
		<Modal
			className={'login-modal'}
			size={mode === 'register' ? 'tiny' : 'mini'}
			open={props.open}
			onClose={() => props.close()}
		>
			{
				mode === 'login' && <Login setMode={setMode} close={props.close}/>
			}
			{
				mode === 'register' && <Register setMode={setMode} close={props.close} />
			}
			{
				mode === 'forgot-password' && <ForgottenPassword setMode={setMode} close={props.close} />
			}
		</Modal>
	);
}
