import React, {useContext} from "react";
import {Button, Card, Flag, Image} from "semantic-ui-react";
import {SkinDto} from "../../../build/generated-sources/dto/SkinDto";
import {View} from "../../../base/enums/View";
import {useHistory} from "react-router-dom";
import SkinRating from "./SkinRating";
import GetSkinButton from "./GetSkinButton";
import {ResourceUtil} from "../../../base/util/ResourceUtil";
import TmRoles from "../../../base/auth/TmRoles";
import {Auth, AuthContext} from "../../../contexts/AuthContext";

interface SkinOverviewProps {
	skin: SkinDto
	showDescription?: boolean
	showRating?: boolean
	showPurchase?: boolean
	enableRating?: boolean
	borderless?: boolean
	linkToSkin?: boolean
}

export default function SkinCard(props: SkinOverviewProps) {

	const history = useHistory();
	const auth = useContext<Auth>(AuthContext);

	const goToUser = (url: string) => {
		history.push(View.AUTHOR.path + url);
	}

	const goToSkin = (url: string) => {
		history.push(View.SKIN.path + url);
	}

	const goToAdminSocialMedia = () => {
		history.push(View.ADMIN_SKIN_SOCIAL_MEDIA.path + '?s=' + props.skin.id);
	}

	return (
		<Card className={(props.borderless ? 'skin-overview borderless' : 'skin-overview borderful') + (props.linkToSkin ? ' clickable' : '')} onClick={() => { if(props.linkToSkin) {goToSkin(props.skin.url)}}}>
			{
				auth.hasRole(TmRoles.ADMIN) && <Button style={{margin: '10px', position: 'absolute', zIndex: 1, width: 40, height: 40}}
													   color={'yellow'} inverted icon={'instagram'}
													   onClick={(e) => {goToAdminSocialMedia(); e.stopPropagation();}}/>
			}
			<Image src={ResourceUtil.getThumbnailPath(props.skin.resources)} wrapped ui={false}/>
			<Card.Content>
				<Card.Header style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.skin.title}</Card.Header>
				<Card.Meta>
					<Flag name={props.skin.author.country as any}/> <span className='date clickable' onClick={() => goToUser(props.skin.author.url)}>{props.skin.author.username}</span>
				</Card.Meta>
				{
					props.showDescription && <Card.Description>
						{props.skin.description}
					</Card.Description>
				}
				{
					(props.showRating || props.showPurchase) && <Card.Content extra style={{marginTop: '16px'}}>
						<div className={'price'} onClick={(e) => e.stopPropagation()}>
							{
								props.showRating && <SkinRating size={'large'} skin={props.skin} disabled={!props.enableRating}/>
							}
							{
								props.showPurchase && <GetSkinButton skin={props.skin} />
							}
						</div>
					</Card.Content>
				}
			</Card.Content>
		</Card>
	);
}
