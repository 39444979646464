import {Asset} from "../dto/Asset";

export class AssetUtil {

	static reorder = (list: Asset[], startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		let order = 0;
		return result.map(a => {
			return {...a, order: order++}
		});
	};
}
