import {Container, Grid, Header, Image} from "semantic-ui-react";
import Lightbox from "react-image-lightbox";
import React, {useMemo} from "react";
import {SkinDto} from "../../../../build/generated-sources/dto/SkinDto";
import {EResourceType} from "../../../../build/generated-sources/enum/EResourceType";
import SkinCard from "../../skin/SkinCard";

interface SkinOfTheMonthProps {
	skin: SkinDto,
	galleryOpen: boolean,
	galleryIndex: number,
	setGalleryOpen: (open: boolean) => void,
	setGalleryIndex: (i: number) => void
}

export default function SkinOfTheMonth(props: SkinOfTheMonthProps) {

	const images = useMemo(() => {
		if (!props.skin) {
			return [];
		}
		return props.skin.resources
			.filter(resource => resource.type === EResourceType.IMAGE)
			.map(resource => resource.filename)
	}, [props.skin]);

	return (
		<Container style={{textAlign: 'center'}}>
			<Header as={'h1'} style={{color: '#111'}}>
				SKIN OF THE MONTH
			</Header>
			<Grid centered stackable>
				<Grid.Row>
					<Grid.Column style={{maxWidth: '560px'}}>
						<SkinCard skin={props.skin} borderless linkToSkin/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column>
						<Image.Group style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}} className={'gallery'}>
							{
								images.map((img, i) => {
									return <Image key={i} className={'clickable fit-cover'} size={'small'} src={'/public/' + img} onClick={() => {
										props.setGalleryIndex(i);
										props.setGalleryOpen(true)
									}
									}/>
								})
							}
						</Image.Group>
						{
							props.galleryOpen && <Lightbox
								imageTitle={props.skin.title}
								imageCaption={props.skin.description}
								mainSrc={'/public/' + images[props.galleryIndex]}
								nextSrc={'/public/' + images[(props.galleryIndex + 1) % images.length]}
								prevSrc={'/public/' + images[(props.galleryIndex + images.length - 1) % images.length]}
								onCloseRequest={() => props.setGalleryOpen(false)}
								onMovePrevRequest={() =>
									props.setGalleryIndex((props.galleryIndex + images.length - 1) % images.length)
								}
								onMoveNextRequest={() =>
									props.setGalleryIndex((props.galleryIndex + 1) % images.length)
								}
							/>
						}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	)
}
