import React, {useContext} from "react";
import {Button, Card, Flag, Icon, Image} from "semantic-ui-react";
import {UserDto} from "../../../build/generated-sources/dto/UserDto";
import {View} from "../../../base/enums/View";
import {useHistory} from "react-router-dom";
import TmRoles from "../../../base/auth/TmRoles";
import {Auth, AuthContext} from "../../../contexts/AuthContext";

interface AuthorOverviewProps {
	author: UserDto,
	detail: boolean,
	linkToProfile: boolean
}

export default function AuthorCard(props: AuthorOverviewProps) {

	const auth = useContext<Auth>(AuthContext);
	const history = useHistory();

	const openProfile = (author: UserDto) => {
		history.push(View.AUTHOR.path + author.url);
	}

	const goToAdminSocialMedia = () => {
		history.push(View.ADMIN_SKIN_SOCIAL_MEDIA.path + '?a=' + props.author.id);
	}

	const flag: any = props.author.country;

	return (
		<Card className={props.linkToProfile ? 'clickable author-overview' : 'author-overview'} onClick={() => openProfile(props.author)}>
			{
				auth.hasRole(TmRoles.ADMIN) && <Button style={{margin: '10px', position: 'absolute', zIndex: 1, width: 40, height: 40}}
													   color={'yellow'} inverted icon={'instagram'}
													   onClick={(e) => {goToAdminSocialMedia(); e.stopPropagation();}}/>
			}
			{props.author.avatar && <Image src={'/public/' + props.author.avatar} wrapped ui={false}/>}
			{!props.author.avatar && <Image src={'/img/1.jpg'} wrapped ui={false}/>}
			<Card.Content style={{height: '110px'}}>
				<Card.Header style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.author.username} <Flag name={flag}/></Card.Header>
				<Card.Meta>
					<span className='date'>Author of {props.author.skins.length} {props.author.skins.length === 1 ? 'skin' : 'skins'}</span>
				</Card.Meta>
				<br/>
				{
					props.detail && <Card.Description onClick={(e : any) => e.stopPropagation()}>
						{props.author.website && <a rel="noreferrer" target="_blank" href={props.author.website}><Icon size={'large'} name={'sitemap'} /></a>}
						{props.author.facebook && <a rel="noreferrer" target="_blank" href={'https://facebook.com/' + props.author.facebook}><Icon size={'large'} name={'facebook'} /></a>}
						{props.author.twitter && <a rel="noreferrer" target="_blank" href={'https://twitter.com/' + props.author.twitter}><Icon size={'large'} name={'twitter'} /></a>}
						{props.author.instagram && <a rel="noreferrer" target="_blank" href={'https://instagram.com/' + props.author.instagram}><Icon size={'large'} name={'instagram'} /></a>}
						{props.author.twitch && <a rel="noreferrer" target="_blank" href={'https://twitch.com/' + props.author.twitch}><Icon size={'large'} name={'twitch'} /></a>}
					</Card.Description>
				}
			</Card.Content>
		</Card>
	);
}
