import React, {useContext} from 'react';
import {Route} from 'react-router-dom';
import {RouteProps} from 'react-router';
import NoMatch from '../../views/404/404';
import TmRoles from '../../base/auth/TmRoles';
import {Auth, AuthContext} from "../../contexts/AuthContext";

interface PrivateRouteProps extends RouteProps {
	requiredRoles: TmRoles[]
}

export default function PrivateRoute(props: PrivateRouteProps) {
	const auth = useContext<Auth>(AuthContext);

	let isAuthorized = true;

	props.requiredRoles.forEach((role) => {
		if (!auth.hasRole(role)) {
			isAuthorized = false;
		}
	});

	return (
		<>
			{
				isAuthorized ?
					<Route path={props.path} render={props.render}>
						{props.children}
					</Route>
					:
					<NoMatch/>
			}
		</>
	);
};
