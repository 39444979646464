import {Constant} from "../enums/Constant";
import {Asset} from "../dto/Asset";
import {BundleInputDtoInput} from "../../build/generated-sources/dto/BundleInputDtoInput";

export class BundleUtil {

	public static nameValid = (bundle : BundleInputDtoInput) : boolean  => {
		if (!bundle.title || bundle.title.length < Constant.MIN_SKIN_NAME_LENGTH || bundle.title.length > Constant.MAX_SKIN_NAME_LENGTH) {
			return false;
		}
		return true;
	}

	public static descriptionValid = (bundle : BundleInputDtoInput) : boolean  => {
		if (bundle.description && bundle.description.length > Constant.MAX_SKIN_DESCR_LENGTH) {
			return false;
		}
		return true;
	}

	public static priceValid = (bundle : BundleInputDtoInput) : boolean  => {
		if (bundle.price < 0 || (bundle.price > 0 && bundle.price < 2) || bundle.price > Constant.MAX_SKIN_PRICE) {
			return false;
		}
		return true;
	}

	public static imagesExist = (images : Asset[]) : boolean  => {
		if (images.length === 0) {
			return false;
		}
		return true;
	}

	public static imagesValid = (images : Asset[]) : boolean  => {
		if (images.filter(i => (!i.id || i.uploading || (!i.uploading && !i.success))).length > 0) {
			return false;
		}
		return true;
	}

	public static validate(bundle : BundleInputDtoInput, images : Asset[], id?: string): boolean {
		if (!BundleUtil.imagesExist(images)) {
			return false;
		}
		if (!BundleUtil.imagesValid(images)) {
			return false;
		}
		if (!BundleUtil.nameValid(bundle)) {
			return false;
		}
		if (!BundleUtil.descriptionValid(bundle)) {
			return false;
		}
		if (!BundleUtil.priceValid(bundle)) {
			return false;
		}
		return true;
	}
}
