import React, {useState} from "react";
import {PieChart, Pie, Sector, Cell, ResponsiveContainer} from "recharts";
import {Formatter} from "../../base/util/Formatter";
import {useMediaQuery} from "react-responsive";

const renderActiveShape = (props: any, modifier: number) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + (10 / modifier)) * cos;
  const sy = cy + (outerRadius + (10 / modifier)) * sin;
  const mx = cx + (outerRadius + (30 / modifier)) * cos;
  const my = cy + (outerRadius + (30 / modifier)) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * (22 / modifier);
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 10} y={ey} textAnchor={textAnchor} fill="#333">{`${Formatter.money(value)}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 10} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    </g>
  );
};

export interface DataPoint {
    name: string,
    value: number
}

export interface InteractivePieChartProps {
    data: DataPoint[]
}

export default function InteractivePieChart(props: InteractivePieChartProps) {

	const [activeIndex, setActiveIndex] = useState<number>(0);
	const COLORS = ['#2185D0', '#DB2828', '#edbe13', '#21BA45', '#808080'];

    const onPieEnter = (_:any, index: number) => {
        setActiveIndex(index);
    }

    const small = useMediaQuery({ minWidth: 993, maxWidth: 1200 });
    const mini = useMediaQuery({ minWidth: 769, maxWidth: 992 });
    const mobile = useMediaQuery({ maxWidth: 768 });

    let innerRadius = 75;
    let outerRadius = 105;
    let modifier = 1;
    if (small) {
        innerRadius = 40;
        outerRadius = 60;
        modifier = 3;
    } else if (mini) {
        innerRadius = 25;
        outerRadius = 40;
        modifier = 4;
    } else if (mobile) {
        innerRadius = 60;
        outerRadius = 80;
        modifier = 2;
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                activeIndex={activeIndex}
                activeShape={(props) => renderActiveShape(props, modifier)}
                data={props.data}
                cx="50%"
                cy="50%"
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onPieEnter}
              >
                  {props.data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
              </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}
