import React, {useEffect} from "react";
import {Container, Grid, Header, Icon, List, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {View} from "../../../base/enums/View";
import {Constant} from "../../../base/enums/Constant";

export default function Roadmap() {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Container className={'roadmap'}>
			<Header as='h2'>
				<Icon name='map signs'/>
				<Header.Content>
					Roadmap - Planned (and finished) enhancements of {Constant.WEBSITE_TITLE}
					<Header.Subheader>Last updated on 14st of September 2023</Header.Subheader>
				</Header.Content>
			</Header>
			<br/>
			<Grid stackable>
				<Grid.Column width={8}>
					We started up as quickly as possible with basic functions to allow authors to publish their creations, but I see a lot of potential for this site to give both
					authors and players more tools to enhance their Trackmania experience. This page will list the rough outline of what features will be added in the feature. If
					there is anything you would like added, <Link to={View.ABOUT.path}>contact us</Link> and let us know about your idea. Great ideas will definitely be added to the
					list and implemented as soon as possible.
				</Grid.Column>
				<Grid.Column width={8}>
					The biggested improvement this site needs as soon as possible is everything surrounding the community support. I want to be able to promote new authors, original
					art and high quality content. This will require the site to support rating individual skins, keeping track of which items are downloaded the most and integrating
					this information with the community via Twitter, Discord etc.
				</Grid.Column>
			</Grid>
			<br/>
			<Segment color={'black'}>
				<Header as={'h3'}>
					<Icon name='clipboard list'/>
					<Header.Content>Feature progress board</Header.Content>
				</Header>
				<p>
					Below you can find list of planned features and their approximate order and time period in which they will be implemented. Other new features may be added at
					any point based on community's feedback or my own ideas. The order in which they are sorted may also change as this was originaly sorted by my own feelings,
					but if the community wants something else more, priorities will change based on that.
				</p>
			</Segment>
			<br/>
			<Grid stackable>
				<Grid.Column width={8}>
					<Segment color={'olive'} style={{height: '100%'}}>
						<Header as='h4'>2022</Header>
						<List>
							<List.Item>
								<List.Icon name='check square outline'/>
								<List.Content>
									<List.Header>Skin ratings</List.Header>
									<List.Description>
										Logged in players are now able to rate skins. Based on their selection the Skin of the Month will be selected. To rate a skin, one must log in
										and open page of a specific skin. Clicking on stars will save their rating.
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Icon name='check square outline'/>
								<List.Content>
									<List.Header>Skin of the Month</List.Header>
									<List.Description>
										Inspired by Track of the Day, we pick the best rated skin at the beginning of the month. This selection gets promoted on the homepage and Twitter.
										In the future it would be cool to include other social platforms.
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Icon name='check square outline'/>
								<List.Content>
									<List.Header>Filter by game</List.Header>
									<List.Description>
										Players can now search skins for their favorite Trackmania game by using the Game filter.
									</List.Description>
								</List.Content>
							</List.Item>
						</List>
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color={'yellow'} style={{height: '100%'}}>
						<Header as='h4'>Summer 2023</Header>
						<List>
							<List.Item>
								<List.Icon name='check square outline'/>
								<List.Content>
									<List.Header>Skin bundles</List.Header>
									<List.Description>
										Authors can bundle up multiple skins together. Some skins may be available only via claiming a bundle. A bundle can be
										a mix of color variations of the same skin, set of skins that share a theme or literally anything the author deems fit.
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Icon name='check square outline'/>
								<List.Content>
									<List.Header>Color variations</List.Header>
									<List.Description>
										Authors can set Primary and Secondary colors on skins. Authors can link skins together so players can easily view
										different color variations by visiting skin's page. Skin Editor enables authors to select colors and link color
										variations for both new and existing skins.
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Icon name='check square outline'/>
								<List.Content>
									<List.Header>Instagram integration</List.Header>
									<List.Description>
										Instagram makes for a great platform for sharing artwork such as the one Trackmania authors submit here. Instagram page will be
										created and used for promoting authors and their work.<br/>
										Skin of the Month can also be promoted, much like it is done on Twitter.<br/>
										Add necessary functions to simplify this process via Instagram API.<br/>
									</List.Description>
								</List.Content>
							</List.Item>
						</List>
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color={'red'} style={{height: '100%'}}>
						<Header as='h4'>Late 2023 & 2024</Header>
						<List>
							<List.Item>
								<List.Icon name='check square outline'/>
								<List.Content>
									<List.Header>Payment method - Paypal</List.Header>
									<List.Description>
										While Stripe has fairly low fees for European credit/debit cards, these fees are about twice as high for everyone outside European Economic Area.
										The more options for players to support the author the better.
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Icon name='square outline'/>
								<List.Content>
									<List.Header>Redirect to Author's personal website/shop</List.Header>
									<List.Description>
										Some authors may primarily present their work elsewhere (personal website, shop, Twitter, etc.). Enable them to submit skin here, but
										the "Download" or "Purchase" button will redirect players to their website where they can get the skin. The only thing authors would
										upload here would be the screenshots. This would enable everyone to rate/comment/browse skins here, but authors would have complete
										control over the distribution.
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Icon name='square outline'/>
								<List.Content>
									<List.Header>Creativity Dashboard</List.Header>
									<List.Description>
										Overview (statistics and graphs) for authors to see how popular their submissions are.
									</List.Description>
								</List.Content>
							</List.Item>

						</List>
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color={'blue'} style={{height: '100%'}}>
						<Header as='h4'>Possible ideas & The Future</Header>
						<List>
							<List.Item>
								<List.Icon name='square outline'/>
								<List.Content>
									<List.Header>Fundraising Trackmania events through sales</List.Header>
									<List.Description>
										Enable authors to submit skins where all the money raised for sales of that skins will go towards certain events (Trackmania
										tournaments, skinning competitions or similar happenings) or towards certain cause (charity or similar). Enable authors to turn this
										off once such event happens, so future sales go back to them.<br/>
										In addition to that, have a dedicated page with graphics displaying each active "fundraiser" with description and amount of money
										that was raised so far.
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Icon name='square outline'/>
								<List.Content>
									<List.Header>Discount codes and promotions</List.Header>
									<List.Description>
										Enable authors to put their submissions on sale
									</List.Description>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Icon name='square outline'/>
								<List.Content>
									<List.Header>More payment methods</List.Header>
									<List.Description>
										Widely requested payment method that would benefit both authors and player will be considered and integrated.
									</List.Description>
								</List.Content>
							</List.Item>
						</List>
					</Segment>
				</Grid.Column>
			</Grid>
		</Container>
	);
}
