import React, {useState} from "react";
import {Header, Icon, Label, Segment, Table} from "semantic-ui-react";
import {useGetMyPurchaseList} from "../../build/generated-sources/service/QueryService";
import {UseGetMyPurchaseListData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {PurchaseDto} from "../../build/generated-sources/dto/PurchaseDto";
import PageMapper from "../../base/mapper/PageMapper";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import PaginationControls from "../base/component/PaginationControls";
import PurchaseMapper from "../../base/mapper/PurchaseMapper";
import {EPaymentStatus} from "../../build/generated-sources/enum/EPaymentStatus";
import {Formatter} from "../../base/util/Formatter";
import SimpleLoader from "../../components/SimpleLoader";
import moment from "moment";

export default function PurchaseList() {

	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 10,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const [purchaseList, setPurchaseList] = useState<PurchaseDto[]>([]);

	const getMyPurchaseList = useGetMyPurchaseList({
			pageInfo: PageMapper.ALL,
			content: PurchaseMapper.ALL
		}, {
			variables: {
				page: {
					page: pageInfo.pageNumber,
					size: pageInfo.pageSize
				}
			},
			fetchPolicy: 'network-only',
			onCompleted: (data: UseGetMyPurchaseListData) => {
				setPurchaseList(data.myPurchaseList.content);
				setPageInfo(data.myPurchaseList.pageInfo);
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='cart' />
				<Header.Content>
					Purchase List
					<Header.Subheader>Review your purchase history</Header.Subheader>
				</Header.Content>
			</Header>
			{
				getMyPurchaseList.loading && <SimpleLoader text={'Loading your purchase history...'}/>
			}
			{
				!getMyPurchaseList.loading && purchaseList.length === 0 && <Segment placeholder>
					<Header icon>
						<Icon name='file outline'/> You have not placed any orders yet.
					</Header>
				</Segment>
			}
			{
				!getMyPurchaseList.loading && purchaseList.length > 0 && <Table unstackable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>#</Table.HeaderCell>
							<Table.HeaderCell textAlign={"center"}>Status</Table.HeaderCell>
							<Table.HeaderCell className={'hide-on-mobile'} collapsing textAlign={"center"}>Created</Table.HeaderCell>
							<Table.HeaderCell singleLine className={'hide-on-mobile'} >Order items</Table.HeaderCell>
							<Table.HeaderCell singleLine textAlign={"right"}>Total Price</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{
							purchaseList.map(p => {
								return <Table.Row key={p.externalId}>
									<Table.Cell collapsing>{p.externalId}</Table.Cell>
									<Table.Cell collapsing textAlign={"center"}>
										{p.paymentStatus === EPaymentStatus.CANCELLED && <Label color={'orange'}><Icon name={'x'}/><span className={'hide-on-mobile'}> Cancelled</span></Label>}
										{p.paymentStatus === EPaymentStatus.PAYMENT_ON_THE_WAY && <Label color={'grey'}><Icon name={'stopwatch'}/><span className={'hide-on-mobile'}> Awaiting payment</span></Label>}
										{p.paymentStatus === EPaymentStatus.PAYMENT_RECEIVED && <Label color={'olive'}><Icon name={'checkmark'}/><span className={'hide-on-mobile'}> Completed</span></Label>}
										{p.paymentStatus === EPaymentStatus.BEFORE_CHECKOUT && <Label color={'grey'}><Icon name={'cart'}/><span className={'hide-on-mobile'}> Before checkout</span></Label>}
									</Table.Cell>
									<Table.Cell className={'hide-on-mobile'} collapsing textAlign={"center"}>
										{moment(p.createdAt, moment.ISO_8601).format("DD. MM. YYYY")}
									</Table.Cell>
									<Table.Cell singleLine collapsing className={'hide-on-mobile'}>
										{p.items.filter(i => i.price > 0).map(item => {
											if (item.skin) {
												return <div><Icon size={'tiny'} bordered name={'car'} color={'red'} inverted/> 1x {item.name} for {Formatter.money(item.price)}</div>
											} else {
												return <div><Icon size={'tiny'} bordered name={'gift'} color={'blue'} inverted/> 1x {item.name} for {Formatter.money(item.price)}</div>
											}
										})}
									</Table.Cell>
									<Table.Cell collapsing textAlign={"right"}><strong>{Formatter.money(p.totalPrice)}</strong></Table.Cell>
								</Table.Row>
							})
						}
					</Table.Body>
				</Table>
			}

			<PaginationControls current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
				setPageInfo({
					...pageInfo,
					pageNumber: page
				});
			}}/>
		</>
	);
}
