export class Formatter {

    private static moneyFormatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'EUR'
    });

    public static money(amount: number) {
        return this.moneyFormatter.format(amount);
    }
}
