import React, {useEffect, useState} from "react";
import {Grid, Header, Icon, Table} from "semantic-ui-react";
import {useLazyGetPayout} from "../../build/generated-sources/service/QueryService";
import AuthorPayoutBatchMapper from "../../base/mapper/AuthorPayoutBatchMapper";
import {AuthorPayoutBatchDto} from "../../build/generated-sources/dto/AuthorPayoutBatchDto";
import {UseGetPayoutData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import SimpleLoader from "../../components/SimpleLoader";
import {Formatter} from "../../base/util/Formatter";
import {View} from "../../base/enums/View";
import {Link} from "react-router-dom";
import {EPurchaseItemType} from "../../build/generated-sources/enum/EPurchaseItemType";
import moment from "moment";
import InteractivePieChart, {DataPoint} from "../../components/chart/InteractivePieChart";
import PayoutInformation from "./components/PayoutInformation";

interface PayoutProps {
    id: string,
	parent: View
}

export default function Payout(props: PayoutProps) {

	const [payout, setPayout] = useState<AuthorPayoutBatchDto>();
	const [authorReward, setAuthorReward] = useState<number>();
	const [internalFee, setInternalFee] = useState<number>();
	const [externalFee, setExternalFee] = useState<number>();
	const [feesBreakdown, setFeesBreakdown] = useState<DataPoint[]>();
	const [itemsBreakdown, setItemsBreakdown] = useState<DataPoint[]>();

	const [getPayout] = useLazyGetPayout(
		AuthorPayoutBatchMapper.ALL, {
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetPayoutData) => {
			setPayout(data.payout);
			const authorReward = data.payout.items.map(item => item.authorReward).reduce((a, b) => a + b, 0);
			const internalFee = data.payout.items.map(item => item.internalFee).reduce((a, b) => a + b, 0);
			const externalFee = data.payout.items.map(item => item.externalFee).reduce((a, b) => a + b, 0);
			setAuthorReward(authorReward);
			setInternalFee(internalFee);
			setExternalFee(externalFee);
			const estimatedAmount = authorReward + internalFee + externalFee;
			setFeesBreakdown([
				{name: 'Author', value: authorReward},
				{name: 'Payment Gates', value: externalFee},
				{name: 'Trackmania-Skins', value: internalFee},
			]);
			let items : Map<string, DataPoint> = new Map();
			data.payout.items.filter(item => !item.inBundle).forEach(item => {
				const id = item.name;
				if (!items.has(id)) {
					items.set(id, {
						name: item.name,
						value: 0
					});
				}
				items.get(id).value += item.price;
			});

			let othersCutoff = 5;
			let sumOfItems = Array.from(items.values()).sort((a, b) => a.value > b.value ? -1 : 1);
			if (sumOfItems.length > othersCutoff) {
				let top = sumOfItems.slice(0, Math.min(sumOfItems.length, othersCutoff - 1));
				let othersPrice = estimatedAmount - top.map(item => item.value).reduce((a, b) => a + b, 0);
				setItemsBreakdown([...top, {name: 'Others', value: othersPrice}]);
			} else {
				setItemsBreakdown(sumOfItems.slice(0, Math.min(sumOfItems.length, othersCutoff)));
			}
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	})

	useEffect(() => {
		if (props.id) {
			getPayout({
				variables: {
					id: Number.parseInt(props.id)
				}
			});
		}
	}, [props.id, getPayout]);

	const loading = !payout || !feesBreakdown || !itemsBreakdown;

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='money bill alternate'/>
				<Header.Content>
					<Link to={props.parent.path}>{props.parent === View.PAYOUTS ? 'My Payouts' : 'Payouts'}</Link> {'>'} Detail of Payout {payout && moment(payout.createdAt, moment.ISO_8601).format("YYYY-MM-DD")}
					<Header.Subheader>Detailed breakdown of {props.parent === View.PAYOUTS ? 'your' : 'the'} sales</Header.Subheader>
				</Header.Content>
			</Header>
			<br/>
			{
				loading && <SimpleLoader text={'Loading payout...'}/>
			}
			{
				!loading && <>
					<Grid stackable>
						<Grid.Column width={16}>
							<PayoutInformation payout={payout}/>
						</Grid.Column>
						<Grid.Column width={16}>
							<Table unstackable compact={'very'}>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell className={'hide-on-mobile'}></Table.HeaderCell>
										<Table.HeaderCell>Items ({payout.items.filter(item => !item.inBundle).length})</Table.HeaderCell>
										<Table.HeaderCell textAlign={'right'}>Base Price</Table.HeaderCell>
										<Table.HeaderCell className={'hide-on-mobile'} textAlign={'right'}>Payment Gate</Table.HeaderCell>
										<Table.HeaderCell className={'hide-on-mobile'} textAlign={'right'}>Site Gets</Table.HeaderCell>
										<Table.HeaderCell textAlign={'right'}>Author Gets</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{
										payout.items.filter(item => !item.inBundle).map(item => {
											return <Table.Row key={item.id}>
												<Table.Cell className={'hide-on-mobile'}><Icon inverted bordered color={item.purchaseItemType === EPurchaseItemType.BUNDLE ? 'blue' : 'red'} name={item.purchaseItemType === EPurchaseItemType.BUNDLE ? 'gift' : 'car'}/></Table.Cell>
												<Table.Cell><strong>{item.name}</strong></Table.Cell>
												<Table.Cell textAlign={'right'}>{Formatter.money(item.price)}</Table.Cell>
												<Table.Cell className={'hide-on-mobile'} textAlign={'right'}>{Formatter.money(item.externalFee)}</Table.Cell>
												<Table.Cell className={'hide-on-mobile'} textAlign={'right'}>{Formatter.money(item.internalFee)}</Table.Cell>
												<Table.Cell textAlign={'right'}><strong>{Formatter.money(item.authorReward)}</strong></Table.Cell>
											</Table.Row>
										})
									}
								</Table.Body>
								<Table.Footer>
									<Table.Row>
										<Table.Cell className={'hide-on-mobile'} />
										<Table.Cell />
										<Table.Cell textAlign={'right'}><strong>{Formatter.money(payout.items.map(item => item.price).reduce((a, b) => a + b, 0))}</strong></Table.Cell>
										<Table.Cell className={'hide-on-mobile'} textAlign={'right'}><strong>{Formatter.money(externalFee)}</strong></Table.Cell>
										<Table.Cell className={'hide-on-mobile'} textAlign={'right'}><strong>{Formatter.money(internalFee)}</strong></Table.Cell>
										<Table.Cell textAlign={'right'}><strong>{Formatter.money(authorReward)}</strong></Table.Cell>
									</Table.Row>
								</Table.Footer>
							</Table>
						</Grid.Column>
						<Grid.Column width={8}>
							<Header textAlign={'center'}>Breakdown of Fees</Header>
							<InteractivePieChart data={feesBreakdown} />
						</Grid.Column>
						<Grid.Column width={8}>
							<Header textAlign={'center'}>Most popular items</Header>
							<InteractivePieChart data={itemsBreakdown} />
						</Grid.Column>
					</Grid>
				</>
			}
		</>
	);
}
