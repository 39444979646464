import React, {useEffect} from "react";
import {Container, Header, Icon, List} from "semantic-ui-react";
import {View} from "../../../base/enums/View";
import {Link} from "react-router-dom";

export default function RefundPolicy() {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Container>
			<Header as='h2'>
				<Icon name='cart arrow down' />
				<Header.Content>
					Refund Policy
					<Header.Subheader>Last updated on 3rd of April 2022</Header.Subheader>
				</Header.Content>
			</Header>

			<Header as='h4'>General information</Header>
			<p>
				Digital items purchased via this site are non-refundable once the item has been downloaded via any link provided to you.
			</p>

			<Header as='h4'>Exceptions to our Refund Policy</Header>
			<p>
				Exceptions will be made should the purchased item:
			</p>
			<List bulleted>
				<List.Item>be different than the item you purchased.</List.Item>
				<List.Item>looks way different/worse than what was advertised.</List.Item>
				<List.Item>break any of our <Link to={View.SUBMISSIONS_RULES.path}>Rules for Submissions</Link>.</List.Item>
			</List>
			<p>
				If you believe any of these points apply to your situation <Link to={View.ABOUT.path}>contact us</Link> immediately. Attach your username and order number if
				possible to help us tracking down the problem.
			</p>
			<p>
				<Icon name={'warning sign'}/> Also note that even if such exceptions apply, we are unable to refund money that was already transferred to the author. Any complaints
				made <strong>more than 7 days after the purchase</strong> will be taken into account (such as removing problematic item from the site or banning author for repeatedly breaking our <Link to={View.SUBMISSIONS_RULES.path}>Rules for
				Submission</Link>) but we will be unable to refund any money back to you for such purchase.
			</p>

		</Container>
	);
}
