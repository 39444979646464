import {DraggingStyle, NotDraggingStyle} from "react-beautiful-dnd";

export class DroppableUtil {
	static getListStyle = (isDraggingOver: boolean) => ({
		background: isDraggingOver ? '#f0f0f0' : 'inherit',
		display: 'flex',
		padding: '5px 10px 10px',
		margin: '-5px -10px',
		borderWidth: '2px',
		borderRadius: '0',
		borderColor: isDraggingOver ? '#bbb' : '#ddd',
		borderStyle: isDraggingOver ? 'dashed' : 'none',
		marginBottom: '20px',
		outline: 'none',
		overflow: 'auto'
	});

	static getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle) => ({
		margin: '0 7px 10px 0',
		background: isDragging ? '#ddd' : '#fafafa',
		...draggableStyle,
	});
}
