import React, {createContext, useContext, useEffect, useState} from "react";
import {useLazyGetMyOwnedBundleIds} from "../build/generated-sources/service/QueryService";
import {Auth, AuthContext} from "./AuthContext";

export type OwnedBundles = {
	addBundle: (id : number) => void;
	hasBundle: (id : number) => boolean;
};

export const initialValue: OwnedBundles = {
	addBundle: (id : number) => {},
	hasBundle: (id : number) => {return false},
};

export const OwnedBundlesContext = createContext<OwnedBundles>(initialValue);

export default function OwnedBundlesProvider(props: any) {

	const auth = useContext<Auth>(AuthContext);
	const [ownedBundles, setOwnedBundles] = useState<Set<number>>(new Set());

	const [getMyOwnedBundleIds] = useLazyGetMyOwnedBundleIds({
		id: true
	}, {
		skip: true
	});

	useEffect(() => {
		if (auth.token) {
			getMyOwnedBundleIds().then(data => {
				let set = new Set<number>();
				data.data.myOwnedBundleIds.forEach(d => set.add(d.id));
				setOwnedBundles(set);
			});
		}
	}, [auth, auth.token, getMyOwnedBundleIds]);

	const addBundle = (id : number) => {
		let set = new Set<number>();
		set.add(id);
		ownedBundles.forEach(d => set.add(d));
		setOwnedBundles(set);
	}

	const hasBundle = (id : number) : boolean => {
		return ownedBundles.has(id);
	}

	return (
		<OwnedBundlesContext.Provider value={{addBundle, hasBundle}}>
			{props.children}
		</OwnedBundlesContext.Provider>
	)
};
