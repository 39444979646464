import UserMapper from "./UserMapper";
import ResourceMapper from "./ResourceMapper";
import SkinMapper from "./SkinMapper";
import {BundleDtoMapper} from "../../build/generated-sources/dto/BundleDto";

export default class BundleMapper {

	public static ALL: BundleDtoMapper = {
		id: true,
		url: true,
		published: true,
		title: true,
		videoUrl: true,
		author: UserMapper.SHORT,
		createdAt: true,
		deletedAt: true,
		description: true,
		type: true,
		resources: ResourceMapper.ALL,
		skins: SkinMapper.ALL,
		price: true
	}

	public static WITHOUT_AUTHOR: BundleDtoMapper = {
		id: true,
		url: true,
		title: true,
		createdAt: true,
		deletedAt: true,
		description: true,
		videoUrl: true,
		type: true,
		resources: ResourceMapper.ALL,
		skins: SkinMapper.ALL,
		price: true
	}
}
