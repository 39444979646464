import React, {useEffect, useState} from "react";
import {Flag, Form, Header, Icon, Popup, Table} from "semantic-ui-react";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import {useGetSkins} from "../../build/generated-sources/service/QueryService";
import PageMapper from "../../base/mapper/PageMapper";
import {UseGetSkinsData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import moment from "moment/moment";
import PaginationControls from "../base/component/PaginationControls";
import {SkinDto} from "../../build/generated-sources/dto/SkinDto";
import {ResourceUtil} from "../../base/util/ResourceUtil";
import {Link} from "react-router-dom";
import {View} from "../../base/enums/View";
import {Formatter} from "../../base/util/Formatter";
import {SkinTypeUtil} from "../../base/util/SkinTypeUtil";
import {Constant} from "../../base/enums/Constant";
import {ESkinType} from "../../build/generated-sources/enum/ESkinType";
import SkinMapper from "../../base/mapper/SkinMapper";

export default function AdministrationSkins() {

	const [searchTemp, setSearchTemp] = useState<string>('');
	const [search, setSearch] = useState<string>('');
	const [type, setType] = useState<ESkinType | string>('all');
	const [list, setList] = useState<SkinDto[]>([]);
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 20,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const getSkins = useGetSkins({
		pageInfo: PageMapper.ALL,
		content: SkinMapper.ALL
	}, {
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize
			},
			search: search,
			type: type === 'all' ? undefined : type as ESkinType
		},
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetSkinsData) => {
			setList(data.skins.content);
			setPageInfo(data.skins.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	useEffect(() => {
		const delayTimeout = setTimeout(() => {
			if (searchTemp.length >= 3 || (searchTemp.length === 0 && search.length > 0)) {
				setSearch(searchTemp);
			}
		}, Constant.SEARCH_QUERY_TYPING_DELAY)
		return () => clearTimeout(delayTimeout)
	}, [searchTemp, search.length]);

	const handleTypeChange = (data: any) => {
		setType(data);
	}

	const onSearchChange = (text : string) => {
		setSearchTemp(text);
	}

	const typeOptions = [];
	typeOptions.push({key: 'all', value: 'all', text: 'All'});
	SkinTypeUtil.getOptions().forEach(i => typeOptions.push(i));

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='users'/>
				<Header.Content>
					List of Skins
					<Header.Subheader></Header.Subheader>
				</Header.Content>
			</Header>
			<Form>
				<Form.Group>
					<Form.Input icon={'search'} placeholder={'Find skin...'} type={'text'} value={searchTemp} onChange={(e) => onSearchChange(e.target.value)} />
					<Form.Select options={typeOptions} width={5}
								 onChange={(e, data) => handleTypeChange(data.value)} value={type} type={'text'}/>
				</Form.Group>
			</Form>
			{
				!getSkins.loading && <>
					<Table unstackable striped>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell className={'hide-on-mobile'}></Table.HeaderCell>
								<Table.HeaderCell className={'hide-on-mobile'}>Created</Table.HeaderCell>
								<Table.HeaderCell className={'hide-on-mobile'}>Modified</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Preview</Table.HeaderCell>
								<Table.HeaderCell>Description</Table.HeaderCell>
								<Table.HeaderCell>Author</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Price</Table.HeaderCell>
								<Table.HeaderCell className={'hide-on-mobile'} textAlign={'center'}>Type</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{
								list.length === 0 && <Table.Row>
									<Table.Cell colSpan={7} textAlign={'center'}>No skins match the query.</Table.Cell>
								</Table.Row>
							}
							{
								list.map(skin => {
									return <Table.Row key={skin.id}>
										<Table.Cell className={'hide-on-mobile'} textAlign={'center'} collapsing>
											{
												!skin.published && <Popup wide position={'top center'} content='Hidden' trigger={<Icon name='eye slash'/>}/>
											}
											{
												skin.published && <Popup wide position={'top center'} content='Published' trigger={<Icon name='eye'/>}/>
											}
										</Table.Cell>
										<Table.Cell className={'hide-on-mobile'} singleLine collapsing>
											{moment(skin.createdAt, moment.ISO_8601).format("DD. MM. YYYY")}
										</Table.Cell>
										<Table.Cell className={'hide-on-mobile'} singleLine collapsing>
											{skin.modifiedAt && moment(skin.modifiedAt, moment.ISO_8601).format("DD. MM. YYYY")}
										</Table.Cell>
										<Table.Cell collapsing textAlign={'center'}>
											<img alt={skin.title} style={{height: '50px', width: '80px', objectFit: 'cover'}} src={ResourceUtil.getThumbnailPath(skin.resources)}/>
										</Table.Cell>
										<Table.Cell>
											<Link to={View.SKIN.path + skin.url}><strong>{skin.title}</strong><br/></Link>
											<span className={'hide-on-mobile'}>{skin.description}</span>
										</Table.Cell>
										<Table.Cell collapsing>
											<Flag name={skin.author.country as any}/> {skin.author.username.substr(0, 10) + (skin.author.username.length > 10 ? '...' : '')}
										</Table.Cell>
										<Table.Cell collapsing textAlign={'center'}>
											<strong>{skin.price === 0 ? 'Free' : (Formatter.money(skin.price))}</strong><br/>
										</Table.Cell>
										<Table.Cell className={'hide-on-mobile'} collapsing textAlign={'center'}>
											<strong>{SkinTypeUtil.getOptionExtended(skin.type).shortText}</strong><br/>
										</Table.Cell>
									</Table.Row>
								})
							}
						</Table.Body>
					</Table>

					<PaginationControls current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
						setPageInfo({
							...pageInfo,
							pageNumber: page
						});
					}}/>
				</>
			}
		</>
	);
}
