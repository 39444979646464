import React, {useEffect, useState} from "react";
import {Flag, Form, Header, Icon, Table} from "semantic-ui-react";
import {UserDto} from "../../build/generated-sources/dto/UserDto";
import {useGetUsers} from "../../build/generated-sources/service/QueryService";
import PageMapper from "../../base/mapper/PageMapper";
import {UseGetUsersData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import UserMapper from "../../base/mapper/UserMapper";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import moment from "moment";
import PaginationControls from "../base/component/PaginationControls";
import {Constant} from "../../base/enums/Constant";

export default function AdministrationUsers() {

	const [searchTemp, setSearchTemp] = useState<string>('');
	const [search, setSearch] = useState<string>('');
	const [list, setList] = useState<UserDto[]>([]);
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 20,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const getUsers = useGetUsers({
		pageInfo: PageMapper.ALL,
		content: UserMapper.SHORT_ADMIN
	}, {
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize
			},
			search: search,
			includeAnonymous: false
		},
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetUsersData) => {
			setList(data.users.content);
			setPageInfo(data.users.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	useEffect(() => {
		const delayTimeout = setTimeout(() => {
			if (searchTemp.length >= 3 || (searchTemp.length === 0 && search.length > 0)) {
				setSearch(searchTemp);
			}
		}, Constant.SEARCH_QUERY_TYPING_DELAY)
		return () => clearTimeout(delayTimeout)
	}, [searchTemp, search.length]);

	const onSearchChange = (text : string) => {
		setSearchTemp(text);
	}

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='users'/>
				<Header.Content>
					List of Users
					<Header.Subheader></Header.Subheader>
				</Header.Content>
			</Header>
			<Form>
				<Form.Input width={4}icon={'search'} placeholder={'Find user...'} type={'text'} value={searchTemp} onChange={(e) => onSearchChange(e.target.value)} />
			</Form>
			{
				!getUsers.loading && <>
					<Table unstackable striped>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell textAlign={'center'}>ID</Table.HeaderCell>
								<Table.HeaderCell>Username</Table.HeaderCell>
								<Table.HeaderCell>Email</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Member since</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Last update</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Socials</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{
								list.length === 0 && <Table.Row>
									<Table.Cell colSpan={7} textAlign={'center'}>No users match the query.</Table.Cell>
								</Table.Row>
							}
							{
								list.map(user => {
									return <Table.Row key={user.id}>
										<Table.Cell singleLine collapsing>
											{user.id}
										</Table.Cell>
										<Table.Cell singleLine collapsing>
											<Flag name={user.country as any}/> {user.username}
										</Table.Cell>
										<Table.Cell singleLine collapsing>
											{user.email}
										</Table.Cell>
										<Table.Cell singleLine collapsing textAlign={'center'}>
											{moment(user.createdAt, moment.ISO_8601).format('Do MMMM YYYY')}
										</Table.Cell>
										<Table.Cell singleLine textAlign={'center'}>
											{user.modifiedAt && moment(user.modifiedAt, moment.ISO_8601).format('MMMM Do YYYY')}
										</Table.Cell>
										<Table.Cell singleLine textAlign={'center'}>
											{user.website && <a rel="noreferrer" target="_blank" href={user.website}><Icon size={'large'} name={'sitemap'}/></a>}
											{user.facebook &&
												<a rel="noreferrer" target="_blank" href={'https://facebook.com/' + user.facebook}><Icon size={'large'} name={'facebook'}/></a>}
											{user.twitter &&
												<a rel="noreferrer" target="_blank" href={'https://twitter.com/' + user.twitter}><Icon size={'large'} name={'twitter'}/></a>}
											{user.instagram &&
												<a rel="noreferrer" target="_blank" href={'https://instagram.com/' + user.instagram}><Icon size={'large'} name={'instagram'}/></a>}
											{user.twitch &&
												<a rel="noreferrer" target="_blank" href={'https://twitch.com/' + user.twitch}><Icon size={'large'} name={'twitch'}/></a>}
										</Table.Cell>
									</Table.Row>
								})
							}
						</Table.Body>
					</Table>

					<PaginationControls current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
						setPageInfo({
							...pageInfo,
							pageNumber: page
						});
					}}/>
				</>
			}
		</>
	);
}
