import {PageInfoMapper} from "../../build/generated-sources/dto/PageInfo";

export default class PageMapper {

	public static ALL: PageInfoMapper = {
		pageNumber: true,
		pageSize: true,
		totalPages: true,
		totalElements: true,
		numberOfElements: true
	}
}
