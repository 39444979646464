export interface SkinFilterOption {
	key: string,
	value: string,
	text: string
}

export class SkinFilterUtil {

	public static getSkinFilterOptions(): SkinFilterOption[] {
		return [
			{key: 'author', value: 'author.username', text: 'Author'},
			{key: 'createdAt', value: 'createdAt', text: 'Latest'},
			{key: 'price', value: 'price', text: 'Price'},
		]
	}
}
