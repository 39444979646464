import React, {useContext, useEffect, useState} from "react";
import {Container, Header, Icon, Label, Menu} from "semantic-ui-react";
import {View} from "../../base/enums/View";
import {Switch, useHistory, useLocation} from "react-router-dom";
import PrivateRoute from "../../components/private/PrivateRoute";
import TmRoles from "../../base/auth/TmRoles";
import PurchaseList from "./PurchaseList";
import OwnedSkins from "./OwnedSkins";
import CreatedSkins from "./CreatedSkins";
import ProfileSettings from "./ProfileSettings";
import CreativityDashboard from "./CreativityDashboard";
import Payouts from "./Payouts";
import {ProfileMenuUtil} from "../../base/util/ProfileMenuUtil";
import {useGetMyUser} from "../../build/generated-sources/service/QueryService";
import UserMapper from "../../base/mapper/UserMapper";
import {UseGetMyUserData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {UserDto} from "../../build/generated-sources/dto/UserDto";
import SimpleLoader from "../../components/SimpleLoader";
import {SkinDto} from "../../build/generated-sources/dto/SkinDto";
import EditorPrompt from "./editors/EditorPrompt";
import CreatedBundles from "./CreatedBundles";
import {toast} from "react-hot-toast";
import AdministrationSkins from "../admin/AdministrationSkins";
import AdministrationPurchase from "../admin/AdministrationPurchase";
import AdministrationPayouts from "../admin/AdministrationPayouts";
import AdministrationComments from "../admin/AdministrationComments";
import AdministrationUsers from "../admin/AdministrationUsers";
import SkinEditor from "./editors/skin/SkinEditor";
import BundleEditor from "./editors/bundle/BundleEditor";
import {Auth, AuthContext} from "../../contexts/AuthContext";
import AdministrationDashboard from "../admin/AdministrationDashboard";
import Payout from "./Payout";

export default function Profile() {

	const auth = useContext<Auth>(AuthContext);
	const history = useHistory();
	const location = useLocation();

	const [highlightCreativity, setHighlightCreativity] = useState<boolean>(false);
	const [activeMenuItem, setActiveMenuItem] = useState<string>();

	const [user, setUser] = useState<UserDto>();

	useGetMyUser(UserMapper.PRIVATE_ALL, {
		onCompleted: (data: UseGetMyUserData) => {
			setUser(data.myUser);
		},
		onError: (data: ApolloError) => {
			console.error(data.message)
		}
	});

	const updateUser = (user: UserDto) => {
		setUser({
			country: user.country,
			street: user.street,
			city: user.city,
			fullname: user.fullname,
			facebook: user.facebook,
			instagram: user.instagram,
			twitch: user.twitch,
			twitter: user.twitter,
			discord: user.discord,
			website: user.website,
			subscribedToNews: user.subscribedToNews,
			bankAccountIban: user.bankAccountIban,
			bankAccountBic: user.bankAccountBic
		});
	}

	const updateSkin = (skin: SkinDto, toastMessage: string) => {
		if (!user.skins || (skin.price > 0 && user.skins.filter(item => item.price > 0).length === 0)) {
			setHighlightCreativity(true);
		}

		let skinsCopy: SkinDto[] = Object.assign([], user.skins);
		skinsCopy.push(skin);
		setUser({
			...user,
			skins: skinsCopy
		});

		toast.success(<strong>{toastMessage}</strong>);
		history.push(View.CREATED_SKINS.path);
	}

	useEffect(() => {
		if ((!activeMenuItem && location.pathname === View.OWNED_SKINS.path)) {
			setActiveMenuItem('collection');
		} else if ((!activeMenuItem && location.pathname === View.PURCHASE_HISTORY.path)) {
			setActiveMenuItem('purchase');
		} else if ((!activeMenuItem && location.pathname === View.CREATED_SKINS.path)) {
			setActiveMenuItem('createdSkins');
		} else if ((!activeMenuItem && location.pathname === View.CREATED_BUNDLES.path)) {
			setActiveMenuItem('createdBundles');
		} else if ((!activeMenuItem && location.pathname === View.CREATIVITY_DASHBOARD.path)) {
			setActiveMenuItem('dashboard');
		} else if ((!activeMenuItem && location.pathname === View.PAYOUTS.path)) {
			setActiveMenuItem('payouts');
		} else if ((!activeMenuItem && location.pathname === View.PROFILE_SETTINGS.path)) {
			setActiveMenuItem('settings');
		} else if ((!activeMenuItem && location.pathname === View.CHECKOUT_FAILED.path)) {
			setActiveMenuItem('collection');
		} else if ((!activeMenuItem && location.pathname === View.CHECKOUT_SUCCESS.path)) {
			setActiveMenuItem('collection');
		} else if ((!activeMenuItem && location.pathname === View.ADMIN_DASHBOARD.path)) {
			setActiveMenuItem('adminDashboard');
		} else if ((!activeMenuItem && location.pathname === View.ADMIN_ORDERS.path)) {
			setActiveMenuItem('allOrders');
		} else if ((!activeMenuItem && location.pathname === View.ADMIN_PAYOUTS.path)) {
			setActiveMenuItem('allPayouts');
		} else if ((!activeMenuItem && location.pathname === View.ADMIN_SKINS.path)) {
			setActiveMenuItem('allSkins');
		} else if ((!activeMenuItem && location.pathname === View.ADMIN_COMMENTS.path)) {
			setActiveMenuItem('allComments');
		} else if ((!activeMenuItem && location.pathname === View.ADMIN_USERS.path)) {
			setActiveMenuItem('allUsers');
		}
	}, [location.pathname, activeMenuItem])

	if (!user) {
		return (<SimpleLoader text={'Loading profile...'}/>);
	}

	let redirect = (item : any) => {
		setActiveMenuItem(item.key);
		history.push(item.path);
	}

	return (
		<Container style={{display: 'flex', justifyContent: 'center'}}>
			<div className={'profile-menu normal'}>
				<Header as={'h4'}>PLAYER MENU</Header>
				<Menu pointing secondary vertical style={{margin: '0 40px 0 0'}}>
					{
						ProfileMenuUtil.getPlayerItems().filter(item => item.display ? item.display(user) : true).map(item => {
							return <Menu.Item onClick={() => redirect(item)} className={'profile-menu-item'} key={item.key} active={activeMenuItem === item.key}>
									{item.title} <Icon name={item.icon}/>
								</Menu.Item>
						})
					}
				</Menu>

				<br/>

				<Header as={'h4'}>AUTHOR MENU</Header>
				<Menu pointing secondary vertical style={{margin: '0 40px 0 0'}}>
					{
						ProfileMenuUtil.getAuthorItems().filter(item => item.display ? item.display(user) : true).map(item => {
							return <Menu.Item onClick={() => redirect(item)} key={item.key} className={'profile-menu-item'} active={activeMenuItem === item.key}>
									{item.title} {!highlightCreativity && <Icon name={item.icon}/>}
									{item.display && highlightCreativity && <Label color={'orange'}>NEW</Label>}
								</Menu.Item>
						})
					}
				</Menu>

				<br/>
				{
					auth.isAdmin() && <>
						<Header as={'h4'}>ADMIN MENU</Header>
						<Menu pointing secondary vertical style={{margin: '0 40px 0 0'}}>
							{
								ProfileMenuUtil.getAdminItems().filter(item => item.display ? item.display(user) : true).map(item => {
									return <Menu.Item onClick={() => redirect(item)} className={'profile-menu-item'} key={item.key} active={activeMenuItem === item.key}>
											{item.title} <Icon name={item.icon}/>
										</Menu.Item>
								})
							}
						</Menu>
					</>
				}
			</div>
			<Container>
				<Menu compact size={'tiny'} className={'profile-menu mobile'} icon='labeled' style={{display: 'flex', margin: '-50px auto 40px', width: '100%', overflow: 'auto'}}>
					{
						ProfileMenuUtil.getPlayerItems().filter(item => item.display ? item.display(user) : true).map(item => {
							return <Menu.Item onClick={() => redirect(item)} key={item.key} active={activeMenuItem === item.key}>
									<Icon name={item.icon}/> {item.shortTitle}
								</Menu.Item>
						})
					}
					{
						ProfileMenuUtil.getAuthorItems().filter(item => item.display ? item.display(user) : true).map(item => {
							return <Menu.Item onClick={() => redirect(item)} key={item.key} active={activeMenuItem === item.key}>
									<Icon name={item.icon}/> {item.shortTitle}
								</Menu.Item>
						})
					}
				</Menu>
				<Menu compact size={'tiny'} className={'profile-menu mobile'} icon='labeled' style={{display: 'flex', margin: '-20px auto 40px', width: '100%', overflow: 'auto'}}>
					{
						ProfileMenuUtil.getAdminItems().filter(item => item.display ? item.display(user) : true).map(item => {
							return <Menu.Item onClick={() => redirect(item)} key={item.key} active={activeMenuItem === item.key}>
									<Icon name={item.icon}/> {item.shortTitle}
								</Menu.Item>
						})
					}
				</Menu>
				<Switch>
					<PrivateRoute path={View.ADMIN_DASHBOARD.path} requiredRoles={[TmRoles.ADMIN]} render={() => <AdministrationDashboard />}/>
					<PrivateRoute path={View.ADMIN_USERS.path} requiredRoles={[TmRoles.ADMIN]} render={() => <AdministrationUsers />}/>
					<PrivateRoute path={View.ADMIN_ORDERS.path} requiredRoles={[TmRoles.ADMIN]} render={() => <AdministrationPurchase />}/>
					<PrivateRoute path={View.ADMIN_PAYOUT.path} requiredRoles={[TmRoles.ADMIN]} render={(props) => <Payout id={props.match.params.id} parent={View.ADMIN_PAYOUTS}/>}/>
					<PrivateRoute path={View.ADMIN_PAYOUTS.path} requiredRoles={[TmRoles.ADMIN]} render={() => <AdministrationPayouts />}/>
					<PrivateRoute path={View.ADMIN_COMMENTS.path} requiredRoles={[TmRoles.ADMIN]} render={() => <AdministrationComments />}/>
					<PrivateRoute path={View.ADMIN_SKINS.path} requiredRoles={[TmRoles.ADMIN]} render={() => <AdministrationSkins />}/>

					<PrivateRoute path={View.EDIT_SKIN.path} requiredRoles={[TmRoles.REGULAR]} render={(props) => <SkinEditor enableColorVariations={true} standalone skin={props.match.params.id} onSave={(skin : SkinDto, message : string) => updateSkin(skin, message)}/> }/>
					<PrivateRoute path={View.EDIT_BUNDLE.path} requiredRoles={[TmRoles.REGULAR]} render={(props) => <BundleEditor bundle={props.match.params.id} /> }/>
					<PrivateRoute path={View.PUBLISH_SKIN.path} requiredRoles={[TmRoles.REGULAR]} render={() => <SkinEditor enableColorVariations={true} standalone onSave={(skin : SkinDto, message : string) => updateSkin(skin, message)}/>}/>
					<PrivateRoute path={View.PUBLISH_BUNDLE.path} requiredRoles={[TmRoles.REGULAR]} render={() => <BundleEditor />}/>
					<PrivateRoute path={View.PUBLISH.path} exact requiredRoles={[TmRoles.REGULAR]} render={() => <EditorPrompt onSave={(skin, message) => updateSkin(skin, message)} />} />
					<PrivateRoute path={View.CHECKOUT_SUCCESS.path} requiredRoles={[TmRoles.REGULAR]} render={() => <OwnedSkins checkout={'success'} />}/>
					<PrivateRoute path={View.CHECKOUT_FAILED.path} requiredRoles={[TmRoles.REGULAR]} render={() => <OwnedSkins checkout={'failed'} />}/>
					<PrivateRoute path={View.OWNED_SKINS.path} requiredRoles={[TmRoles.REGULAR]} render={() => <OwnedSkins />}/>
					<PrivateRoute path={View.PURCHASE_HISTORY.path} requiredRoles={[TmRoles.REGULAR]} render={() => <PurchaseList />}/>
					<PrivateRoute path={View.CREATED_SKINS.path} requiredRoles={[TmRoles.REGULAR]} render={() => <CreatedSkins/>}/>
					<PrivateRoute path={View.CREATED_BUNDLES.path} requiredRoles={[TmRoles.REGULAR]} render={() => <CreatedBundles/>}/>
					<PrivateRoute path={View.CREATIVITY_DASHBOARD.path} requiredRoles={[TmRoles.REGULAR]} render={() => <CreativityDashboard/>}/>
					<PrivateRoute path={View.PAYOUT.path} requiredRoles={[TmRoles.REGULAR]} render={(props) => <Payout id={props.match.params.id} parent={View.PAYOUTS}/>} />
					<PrivateRoute path={View.PAYOUTS.path} requiredRoles={[TmRoles.REGULAR]} render={() => <Payouts/>}/>
					<PrivateRoute path={View.PROFILE_SETTINGS.path} requiredRoles={[TmRoles.REGULAR]} render={() => <ProfileSettings user={user} onUpdate={(user) => updateUser(user)}/>}/>

				</Switch>
			</Container>
		</Container>
	);
}
