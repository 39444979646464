import React, {useEffect, useState} from "react";
import {Form, Header, Icon, Label, Table} from "semantic-ui-react";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import {useGetPurchaseList} from "../../build/generated-sources/service/QueryService";
import PageMapper from "../../base/mapper/PageMapper";
import {UseGetPurchaseListData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import moment from "moment/moment";
import PaginationControls from "../base/component/PaginationControls";
import PurchaseMapper from "../../base/mapper/PurchaseMapper";
import {PurchaseDto} from "../../build/generated-sources/dto/PurchaseDto";
import {Formatter} from "../../base/util/Formatter";
import {EPaymentStatus} from "../../build/generated-sources/enum/EPaymentStatus";
import {EPaymentType} from "../../build/generated-sources/enum/EPaymentType";
import {Constant} from "../../base/enums/Constant";
import {PaymentStatusFilterUtil} from "../../base/util/PaymentStatusFilterUtil";

export default function AdministrationPurchase() {

	const [searchTemp, setSearchTemp] = useState<string>('');
	const [search, setSearch] = useState<string>('');
	const [status, setStatus] = useState<EPaymentStatus | string>('all');
	const [list, setList] = useState<PurchaseDto[]>([]);
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 20,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const getUsers = useGetPurchaseList({
		pageInfo: PageMapper.ALL,
		content: PurchaseMapper.ALL
	}, {
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize
			},
			search: search,
			status: status === 'all' ? undefined : status as EPaymentStatus
		},
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetPurchaseListData) => {
			setList(data.purchaseList.content);
			setPageInfo(data.purchaseList.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	useEffect(() => {
		const delayTimeout = setTimeout(() => {
			if (searchTemp.length >= 3 || (searchTemp.length === 0 && search.length > 0)) {
				setSearch(searchTemp);
			}
		}, Constant.SEARCH_QUERY_TYPING_DELAY)
		return () => clearTimeout(delayTimeout)
	}, [searchTemp, search.length]);

	const handleStatusChange = (data: any) => {
		setStatus(data);
	}

	const onSearchChange = (text : string) => {
		setSearchTemp(text);
	}

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='clipboard list'/>
				<Header.Content>
					List of Orders
				</Header.Content>
			</Header>
			<Form>
				<Form.Group>
					<Form.Input icon={'search'} placeholder={'Find order...'} type={'text'} value={searchTemp} onChange={(e) => onSearchChange(e.target.value)} />
					<Form.Select options={PaymentStatusFilterUtil.getPaymentStatusFilterOptions()}
								 style={{width: '180px'}}
								 onChange={(e, data) => handleStatusChange(data.value)}
								 value={status}
								 type={'text'}/>
				</Form.Group>
			</Form>
			{
				!getUsers.loading && <>
					<Table unstackable striped>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell textAlign={'center'}>ID</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Status</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Created</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Payment received</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Type</Table.HeaderCell>
								<Table.HeaderCell>Items</Table.HeaderCell>
								<Table.HeaderCell textAlign={'center'}>Total price</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{
								list.length === 0 && <Table.Row>
									<Table.Cell colSpan={7} textAlign={'center'}>No orders match the query.</Table.Cell>
								</Table.Row>
							}
							{
								list.map(purchase => {
									return <Table.Row key={purchase.externalId}>
										<Table.Cell singleLine collapsing>
											#{purchase.externalId}
										</Table.Cell>
										<Table.Cell collapsing textAlign={"center"}>
											{purchase.paymentStatus === EPaymentStatus.CANCELLED && <Label color={'orange'}><Icon name={'x'}/><span className={'hide-on-mobile'}> Cancelled</span></Label>}
											{purchase.paymentStatus === EPaymentStatus.PAYMENT_ON_THE_WAY && <Label color={'grey'}><Icon name={'stopwatch'}/><span className={'hide-on-mobile'}> Awaiting payment</span></Label>}
											{purchase.paymentStatus === EPaymentStatus.PAYMENT_RECEIVED && <Label color={'olive'}><Icon name={'checkmark'}/><span className={'hide-on-mobile'}> Received</span></Label>}
											{purchase.paymentStatus === EPaymentStatus.BEFORE_CHECKOUT && <Label color={'grey'}><Icon name={'cart'}/><span className={'hide-on-mobile'}> Before checkout</span></Label>}
										</Table.Cell>
										<Table.Cell singleLine textAlign={'center'}>
											{moment(purchase.createdAt, moment.ISO_8601).format('DD-MM-YYYY HH:mm')}
										</Table.Cell>
										<Table.Cell singleLine textAlign={'center'}>
											{purchase.paymentReceivedAt && moment(purchase.paymentReceivedAt, moment.ISO_8601).format('DD-MM-YYYY HH:mm')}
										</Table.Cell>
										<Table.Cell singleLine collapsing textAlign={'center'}>
											{purchase.paymentType === EPaymentType.PAYPAL ? <Icon name={'paypal'} bordered inverted color={'black'}/> : <Icon name={'university'} bordered inverted color={'black'}/>}
										</Table.Cell>
										<Table.Cell singleLine collapsing>
											{purchase.items.filter(i => i.price > 0).map(item => {
												if (item.skin) {
													return <div><Icon size={'tiny'} bordered name={'car'} color={'red'} inverted/> 1x {item.name} for {Formatter.money(item.price)}</div>
												} else {
													return <div><Icon size={'tiny'} bordered name={'gift'} color={'blue'} inverted/> 1x {item.name} for {Formatter.money(item.price)}</div>
												}
											})}
										</Table.Cell>
										<Table.Cell singleLine textAlign={'center'}>
											<strong>{Formatter.money(purchase.totalPrice)}</strong>
										</Table.Cell>
									</Table.Row>
								})
							}
						</Table.Body>
					</Table>

					<PaginationControls current={pageInfo.pageNumber} totalPages={pageInfo.totalPages} onPageChange={(page) => {
						setPageInfo({
							...pageInfo,
							pageNumber: page
						});
					}}/>
				</>
			}
		</>
	);
}
