import React, {useContext, useEffect, useState} from "react";
import {Accordion, Container, Grid, Header, Icon} from "semantic-ui-react";
import {View} from "../../../base/enums/View";
import {Link} from "react-router-dom";
import {Constant} from "../../../base/enums/Constant";
import {Auth, AuthContext} from "../../../contexts/AuthContext";

export default function FAQ() {

	const auth = useContext<Auth>(AuthContext);
	const [activeIndex, setActiveIndex] = useState<number>();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	return (
		<Container>
			<Header as='h2'>
				<Icon name='question circle'/>
				<Header.Content>
					Frequently Asked Questions
					<Header.Subheader>Can't find what you needed? <Link to={View.ABOUT.path}>Let me know.</Link></Header.Subheader>
				</Header.Content>
			</Header>

			<p>
				We hope {Constant.WEBSITE_TITLE} site will be as player friendly as possible, but we understand there
				may still be questions left unanswered. Perhaps this page will shed more light on what you need to know. For everything else, don't
				hesitate to <Link to={View.ABOUT.path}>contact me</Link>.
			</p>

			<br/><br/>

			<Grid stackable columns={2}>
				<Grid.Column>
					<Header>Creators</Header>
					<Accordion fluid styled>
						<Accordion.Title active={activeIndex === 0} index={0} onClick={() => setActiveIndex(0)}>
							<Icon name='dropdown'/> Who can present his/her art on {Constant.WEBSITE_TITLE}?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 0}>
							<p>
								Anyone. Create an account and upload your skin right away.
							</p>
						</Accordion.Content>
						<Accordion.Title active={activeIndex === 1} index={1} onClick={() => setActiveIndex(1)}>
							<Icon name='dropdown'/> Are there any limitations as to what I can upload?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 1}>
							<p>
								Read through the <Link to={View.SUBMISSIONS_RULES.path}>Rules for Submissions</Link>.
							</p>
						</Accordion.Content>
						<Accordion.Title active={activeIndex === 2} index={2} onClick={() => setActiveIndex(2)}>
							<Icon name='dropdown'/> How does the payout system work?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 2}>
							<p>
								Once you upload your first skin, you can enter your bank account details in your {auth.token ? <Link to={View.PROFILE.path}>profile</Link> : 'profile'}. Then every purchase
								of your skin will be credited to you (minus the fees) and paid out regularly once every half a month to that account. As an author, you can view
								all info about Payouts via your {auth.token ? <Link to={View.PROFILE.path}>profile</Link> : 'profile'}. That will tell you how many sales happened
								within this period and when the next payout happens. Until you setup your bank account, the money stays with you and keeps accumulating on your profile.
							</p>
							<p>
								You can read more about this on our dedicated <Link to={View.ABOUT_AUTHOR_PAYOUTS.path}>Author Payment system page</Link>.
							</p>
						</Accordion.Content>
						<Accordion.Title active={activeIndex === 5} index={5} onClick={() => setActiveIndex(5)}>
							<Icon name='dropdown'/> What if I sell some of my skins but don't have a bank account setup?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 5}>
							<p>
								Until the system knows where to send the money that belongs to you, it will accumulate on your profile without being sent. Once you setup your bank account
								via your {auth.token ? <Link to={View.PROFILE.path}>profile</Link> : 'profile'}, you will be eligible for payout. These payouts happen every
								half a month, so the first payout might take some time depending on when you set it up.
							</p>
						</Accordion.Content>
						<Accordion.Title active={activeIndex === 6} index={6} onClick={() => setActiveIndex(6)}>
							<Icon name='dropdown'/> What if I don't have a bank account?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 6}>
							<p>
								Paypal integration is planned, but there are some major issues that need to be resolved first. I want to stabilize the bank account payouts first.
							</p>
							<p>
								It is possible, however, that I can send out the payouts manually via Paypal, but you would have to contact me for that personally. It would come
								with a limitation as to how often I would process the payouts (I am certainly unable to do it every 15 days like the automated system for bank
								account payouts).
							</p>
							<p>
								If you want money transferred to you in some other way, let me know.
							</p>
						</Accordion.Content>
						<Accordion.Title active={activeIndex === 3} index={3} onClick={() => setActiveIndex(3)}>
							<Icon name='dropdown'/> What are the fees?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 3}>
							<p>
								This site is not meant to generate profit. As described in more detail on the <Link to={View.ABOUT.path}>About page</Link> the goal is to
								put all the <strong>amazing Trackmania creations together</strong>, to make it easy for players to browse and find their new ride and
								most important to give authors simple tools to get paid appropriately for their hard work and creativity.
							</p>
							<p>
								That being said, the site will need to cover fees for payment services and server costs. The site itself currently takes {Constant.INTERNAL_FEE_PERCENTAGE}% from
								total price of every order. If the community moves towards supporting this site via donations or if we find that the site can be sufficiently supported
								with lower fees, they will be lowered immediately. There is also the possibility of using the extra funds towards supporting competitions and giveaways.
								That is yet to be seen, but the community will decide.
							</p>
							<p>
								You can read more about fees on our page about <Link to={View.ABOUT_AUTHOR_PAYOUTS.path}>Author Payment system</Link>.
							</p>
						</Accordion.Content>
						<Accordion.Title active={activeIndex === 4} index={4} onClick={() => setActiveIndex(4)}>
							<Icon name='dropdown'/> Somebody else has uploaded my skin here and presents it as his/her own. What can I do about it?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 4}>
							<p>
								If you find submission that belongs to you, but is being presented by someone else, <Link to={View.ABOUT.path}>contact me</Link> immediately. Please
								attach some form of a proof that clearly shows you are the original author to speed up the process.
							</p>
						</Accordion.Content>
					</Accordion>
				</Grid.Column>
				<Grid.Column>
					<Header>Players</Header>

					<Accordion fluid styled>
						<Accordion.Title active={activeIndex === 10} index={10} onClick={() => setActiveIndex(10)}>
							<Icon name='dropdown'/> How can I download skin from this site?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 10}>
							<p>
								I have put up detailed <Link to={View.INSTALLATION_GUIDE.path}>Installation guide</Link> where you can read everything about getting a skin up and running.
							</p>
						</Accordion.Content>
						<Accordion.Title active={activeIndex === 11} index={11} onClick={() => setActiveIndex(11)}>
							<Icon name='dropdown'/> I bought a skin, but I later realized I don't like it. Can I get a refund?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 11}>
							<p>
								Given that there is no way to "return" a digital file once you download it, options to refund a skin are very limited. Authors
								are encouraged to present their creations as best as possible (with screenshots from multiple angles and/or with a video) to prevent
								any misunderstanding as to what the skin looks like.
							</p>
							<p>
								Exceptions apply if the submissions break any of our <Link to={View.SUBMISSIONS_RULES.path}>Rules for Submissions</Link>. If that's the case, please
								report such skin to for further investigation. You can find more details on our <Link to={View.REFUND_POLICY.path}>Refund Policy</Link> page.
							</p>
						</Accordion.Content>
						<Accordion.Title active={activeIndex === 12} index={12} onClick={() => setActiveIndex(12)}>
							<Icon name='dropdown'/> I bought a skin, but it looks different than what was presented here. Can I get a refund?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 12}>
							<p>
								If you suspect there is a skin that looks nothing like what is being advertised, please report this to me. Should this be a case
								of foul play, refund will be possible if reported soon enough (before money was transfered to the author). Such submission will then be removed and
								author will risk permanent ban for breaking our <Link to={View.SUBMISSIONS_RULES.path}>Rules for Submissions</Link>.
							</p>
						</Accordion.Content>
						<Accordion.Title active={activeIndex === 13} index={13} onClick={() => setActiveIndex(13)}>
							<Icon name='dropdown'/> I found a skin here that feels inappropriate. What can I do?
						</Accordion.Title>
						<Accordion.Content active={activeIndex === 13}>
							<p>
								If you suspect there is a skin that breaks any of our <Link to={View.SUBMISSIONS_RULES.path}>Rules for Submissions</Link>, please <Link
								to={View.SUBMISSIONS_RULES.path}>report it to me</Link>.
							</p>
							<p>
								If you thinks there is something not covered by our <strong>Rules for Submissions</strong> but should be, again, let me know.
							</p>
						</Accordion.Content>
					</Accordion>
				</Grid.Column>
			</Grid>
		</Container>
	);
}
