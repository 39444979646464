import React, {useEffect, useMemo, useState} from "react";
import {Container, Grid, Header, Icon, List, Image, Tab} from "semantic-ui-react";
import {Constant} from "../../../base/enums/Constant";
import Lightbox from "react-image-lightbox";
import {useHistory, useLocation} from "react-router-dom";

export default function InstallationGuide() {

	const [galleryOpen, setGalleryOpen] = useState<boolean>(false);
	const [galleryIndex, setGalleryIndex] = useState<number>(0);
	const history = useHistory();
	const location = useLocation();
	const search = new URLSearchParams(location.search);
	const game = search.get('game');

	const [guideActive, setGuideActive] = useState<string>(game ? (game === 'tm2020' ? 'tm2020' : 'tmufnf') : 'tm2020' );

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	const imagesTM2020 = useMemo(() => {
		return [
			{
				url: 'img/guide/tm2020/guide1.jpg',
				title: 'Target directory where you put downloaded skin',
				caption: 'Move downloaded .zip file to Trackmania Documents folder, which is typically located at C:/Users/-you-/Documents/Trackmania/Skins/Models/CarSport'
			},
			{
				url: 'img/guide/tm2020/guide2.jpg',
				title: 'Open game and go to your profile',
				caption: 'On main menu, click on your profile name in the top left corner.'
			},
			{
				url: 'img/guide/tm2020/guide3.jpg',
				title: 'Open Garage within your profile',
				caption: 'On your profile page, click on Garage in the bottom right corner.'
			},
			{
				url: 'img/guide/tm2020/guide4.jpg',
				title: 'Open Skin Browser via the Upload Skin button',
				caption: 'In the Skin Browser locate your skin .zip file within the Models/CarSport folder and click on it.'
			},
			{
				url: 'img/guide/tm2020/guide5.jpg',
				title: 'Find and select the .zip file you previously moved to the CarSport folder',
				caption: ''
			},
		];
	}, []);

	const imagesTMUFNF = useMemo(() => {
		return [
			{
				url: 'img/guide/tmufnf/guide1.jpg',
				title: 'Target directory where you put downloaded skin',
				caption: 'Create a folder named Carcommon (it has to be typed exactly like that) inside your TrackMania skins folder at C:/Users/-you-/Documents/TrackMania/Skins/Vehicles'
			},
			{
				url: 'img/guide/tmufnf/guide2.jpg',
				title: 'Open game and go to your profile',
				caption: 'On main menu, click on your profile name.'
			},
			{
				url: 'img/guide/tmufnf/guide3.jpg',
				title: 'Open Vehicles within your profile',
				caption: 'Click on your new skin, and then click \'Use model for all cars\'.'
			}
		];
	}, []);

	const panes = useMemo(() => {
		return [
			{
				menuItem: 'Trackmania 2020',
				render: () => <Tab.Pane style={{border: '0'}} attached={false}>
					<Grid>
						<Grid.Column width={16}>
							<Header as='h2'>
								<Icon name='download'/>
								<Header.Content>
									Installation Guide for Trackmania 2020
									<Header.Subheader>Last updated on 1st of July 2022</Header.Subheader>
								</Header.Content>
							</Header>

							<Header as='h3'>Club Access</Header>
							<p>
								To install custom skins downloaded via {Constant.WEBSITE_TITLE} players with <strong>Club Access</strong> are tasked to follow these few simple steps:
							</p>
							<List ordered>
								<List.Item>
									Download the <strong>.zip</strong> file from our website to your computer.
								</List.Item>
								<List.Item>
									Locate the <strong>Trackmania Documents</strong> folder. It is typically located at <strong>C:/Users/-you-/Documents/Trackmania</strong>.
								</List.Item>
								<List.Item>
									Inside, locate (or create if it is missing) folder <strong>CarSport</strong> inside the folder <strong>Skins/Models</strong>. Full path of this folder will then be
									C:/Users/-you-/Documents/Trackmania/Skins/Models/CarSport.
								</List.Item>
								<List.Item>
									Move the skin .zip file to the <strong>CarSport</strong> folder.
								</List.Item>
								<List.Item>
									Open Trackmania.
								</List.Item>
								<List.Item>
									On main menu, <strong>click on your profile name</strong> in the top left corner.
								</List.Item>
								<List.Item>
									On your profile page, click on <strong>Garage</strong> in the bottom right corner.
								</List.Item>
								<List.Item>
									In your <strong>Garage,</strong> click on <strong>Upload skin</strong>. This will open the <strong>Skin Browser</strong>.
								</List.Item>
								<List.Item>
									In the <strong>Skin Browser</strong> locate your skin <strong>.zip</strong> file within the <strong>Models/CarSport</strong> folder and click on it. This should load the car in your garage.
									The car may load without textures and look black on certain occasions. Jumping out of your garage and hopping back in should correctly load the skin.
								</List.Item>
							</List>

							{
								galleryOpen && <Lightbox
									imageTitle={imagesTM2020[galleryIndex].title}
									imageCaption={imagesTM2020[galleryIndex].caption}
									mainSrc={imagesTM2020[galleryIndex].url}
									nextSrc={imagesTM2020[(galleryIndex + 1) % imagesTM2020.length].url}
									prevSrc={imagesTM2020[(galleryIndex + imagesTM2020.length - 1) % imagesTM2020.length].url}
									onCloseRequest={() => setGalleryOpen(false)}
									onMovePrevRequest={() =>
										setGalleryIndex((galleryIndex + imagesTM2020.length - 1) % imagesTM2020.length)
									}
									onMoveNextRequest={() =>
										setGalleryIndex((galleryIndex + 1) % imagesTM2020.length)
									}
								/>
							}

							<Image.Group size={'small'} style={{textAlign: 'center', padding: '20px 0' }}>
								{
									imagesTM2020.map((i, index) => {
										return <Image key={index} className={'clickable'} src={i.url} onClick={() => {setGalleryIndex(index); setGalleryOpen(true)}}/>
									})
								}
							</Image.Group>
						</Grid.Column>


						<Header as='h3'>Non-Club Access</Header>
						<p>
							As of right now, there seems to be no known way for Non-Club player to use custom skins in their game.
							There used to be a way to use skins in a very limited way for Non-Club players, but there are reports if it being patched and not possible anymore.
						</p>
						<p>
							<strong>
								That being said, if you enjoy the game and you made it all the way here, consider buying the game if you can! There are huge discounts every couple of
								months that make the subscriptions way more affordable.
							</strong>
						</p>
					</Grid>
				</Tab.Pane>
			},
			{
				menuItem: 'Trackmania United Forever & Nations Forever',
				render: () => <Tab.Pane style={{border: '0'}} attached={false}>
					<Grid>
						<Grid.Column width={16}>
							<Header as='h2'>
								<Icon name='download'/>
								<Header.Content>
									Installation Guide for Trackmania United Forever & Trackmania Nations Forever
									<Header.Subheader>Last updated on 21st of June 2022</Header.Subheader>
								</Header.Content>
							</Header>

							<Header as='h3'>This guide applies to both Trackmania United Forever and Nations Forever</Header>
							<p>
								To install custom skins downloaded via {Constant.WEBSITE_TITLE} players are tasked to follow these few simple steps:
							</p>
							<List ordered>
								<List.Item>
									Download the <strong>.zip</strong> file from our website to your computer.
								</List.Item>
								<List.Item>
									Locate the <strong>TrackMania skins folder</strong>, typically located here: <strong>C:/Users/-you-/Documents/TrackMania/Skins</strong>
								</List.Item>
								<List.Item>
									In the skins folder, there will be a folder called <strong>Vehicles</strong>. Open this folder.
								</List.Item>
								<List.Item>
									Create a folder named <strong>Carcommon</strong> (it has to be typed <strong>exactly</strong> like that).
								</List.Item>
								<List.Item>
									<strong>Extract</strong> your vehicle's .zip folder into the Carcommon folder.
								</List.Item>
								<List.Item>
									Open TrackMania United Forever or Nations Forever.
								</List.Item>
								<List.Item>
									On the main page, click on <strong>Profile</strong> then <strong>Vehicles</strong>.
								</List.Item>
								<List.Item>
									Here, click on <strong>StadiumCar</strong>, and locate the skin you have just downloaded.
								</List.Item>
								<List.Item>
									Click on your new skin, and then click <strong>Use model for all cars</strong>.
								</List.Item>
							</List>
							<p><Icon name='warning'/><strong>Note for troubleshooting:</strong> Make sure the vehicle is in a .zip and <strong>NOT</strong> a .rar or otherwise. The skin will not work.</p>
							<p><Icon name='warning'/><strong>Additional note:</strong> If the game finds your skin, but you have a blank model/texture, make sure the vehicle is not in an additional folder in the .zip.</p>

							{
								galleryOpen && <Lightbox
									imageTitle={imagesTMUFNF[galleryIndex].title}
									imageCaption={imagesTMUFNF[galleryIndex].caption}
									mainSrc={imagesTMUFNF[galleryIndex].url}
									nextSrc={imagesTMUFNF[(galleryIndex + 1) % imagesTMUFNF.length].url}
									prevSrc={imagesTMUFNF[(galleryIndex + imagesTMUFNF.length - 1) % imagesTMUFNF.length].url}
									onCloseRequest={() => setGalleryOpen(false)}
									onMovePrevRequest={() =>
										setGalleryIndex((galleryIndex + imagesTMUFNF.length - 1) % imagesTMUFNF.length)
									}
									onMoveNextRequest={() =>
										setGalleryIndex((galleryIndex + 1) % imagesTMUFNF.length)
									}
								/>
							}

							<Image.Group size={'small'} style={{textAlign: 'center', padding: '20px 0' }}>
								{
									imagesTMUFNF.map((i, index) => {
										return <Image key={index} className={'clickable'} src={i.url} onClick={() => {setGalleryIndex(index); setGalleryOpen(true)}}/>
									})
								}
							</Image.Group>
						</Grid.Column>
					</Grid>
				</Tab.Pane>
			},
		]
	}, [imagesTM2020, imagesTMUFNF, galleryIndex, galleryOpen]);


	return (
		<Container className={'installation-guide'}>
			<Tab menu={{secondary: true, pointing: true}} activeIndex={guideActive === 'tm2020' ? 0 : 1} panes={panes} renderActiveOnly={true} onTabChange={(e, data) => {
				let game = data.activeIndex === 0 ? 'tm2020' : 'tmufnf';
				setGuideActive(game);
				history.push(window.location.pathname + '?game=' + game);
			}}/>
		</Container>
	);
}
