import {EResourceType} from "../../build/generated-sources/enum/EResourceType";
import {ResourceDto} from "../../build/generated-sources/dto/ResourceDto";

export class ResourceUtil {

    public static getThumbnailPath(resources : ResourceDto[]) : string {
        let images = resources.filter(resource => resource.type === EResourceType.IMAGE);
        if (images.length === 0) {
            return 'img/placeholder.jpg';
        }
        return '/public/' + images[0].filename;
    }
}
