import React, {useState} from "react";
import {Button, Container, Form, Grid, Header, Icon, List} from "semantic-ui-react";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-hot-toast";
import {View} from "../../../base/enums/View";

export default function ResetPassword() {

	const search = useLocation().search;
	const history = useHistory();

	const [validate, setValidate] = useState<boolean>(false);
	const [pw, setPw] = useState<string>('');
	const [pwConfirm, setPwConfirm] = useState<string>('');
	const [syncState, setSyncState] = useState<SyncOperationState>({
		inProgress: false,
		error: false,
		done: false
	});

	const isValid = () => {
		if (pw.length < 8 || pwConfirm.length < 8 || pw !== pwConfirm) {
			return false;
		}
		return true;
	}

	const reset = () => {
		setValidate(true);
		if (!isValid()) {
			return;
		}
		setSyncState({
			inProgress: true,
			error: false,
			done: false
		});
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({code: new URLSearchParams(search).get('c'), password: pw})
		};

		const toastId = toast.loading('Changing your password...');

		fetch('/api/auth/reset-password', requestOptions).then(response => {
			if (response.status >= 400) {
				if (response.status === 404) {
					toast.error(<strong>There was an error changing your password.</strong>, {id: toastId});

				}
				if (response.status === 410) {
					toast.error(<strong>This link was already used to reset your password.</strong>, {id: toastId});
				}
				setSyncState({
					error: true,
					done: true,
					id: response.status
				});
			} else {
				response.text().then(data => {
					toast.success(<strong>Password was successfully changed!<br/>You can login now.</strong>, {id: toastId});
					history.push(View.SKINS.path);
				});
			}
		});
	}

	return (
		<Container>


			<Grid centered>
				<Grid.Column width={6}>
					<Header as='h2'>
						<Icon name='privacy'/>
						<Header.Content>
							Reset Password
							<Header.Subheader>Setup a new password that's safe and secure</Header.Subheader>
						</Header.Content>
					</Header>
					<br/>
					<p><strong>There are only two requirements for new password:</strong></p>
					<List bulleted>
						<List.Item>Make it at least 8 characters long.</List.Item>
						<List.Item>Don't forget it. Or use password managers and feel free to never losing it again.</List.Item>
					</List>
					<br/>
					<Form>
						<Form.Input error={validate && pw.length < 8} label={'New password'} type={'password'} value={pw} onChange={(e) => setPw(e.target.value)}/>
						<Form.Input error={validate && pwConfirm.length < 8 && pwConfirm !== pw} label={'Confirmation of new password'} type={'password'} value={pwConfirm}
									onChange={(e) => setPwConfirm(e.target.value)}/>
						<br/>
						<Button color={'black'} floated={'right'} loading={syncState.inProgress} disabled={syncState.inProgress} onClick={() => reset()}>Setup new password</Button>
					</Form>
				</Grid.Column>
			</Grid>

		</Container>
	);
}
