import {GraphQLScalarType} from "graphql";
import {format} from 'date-fns';

export type LocalDateTime = string;

export const Resolver = new GraphQLScalarType({
	name: 'LocalDateTime',
	description: 'LocalDate custom scalar type',
	serialize(value) {
		return value && format(value, 'yyyy-MM-ddThh:mm:ss.SSSSSS');
	},
	parseValue(value) {
		return value && new Date(value);
	}
});
