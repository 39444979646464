import React, {useContext, useEffect, useState} from "react";
import {Button, Container, Grid, Header, Segment} from "semantic-ui-react";
import SkinCardPreview from "../skin/SkinCard";
import {SkinDto} from "../../../build/generated-sources/dto/SkinDto";
import {useGetMostDownloadedSkins, useGetSkinOfTheMonth, useGetSkins} from "../../../build/generated-sources/service/QueryService";
import PageMapper from "../../../base/mapper/PageMapper";
import SkinMapper from "../../../base/mapper/SkinMapper";
import {Direction} from "../../../build/generated-sources/enum/Direction";
import {UseGetMostDownloadedSkinsData, UseGetSkinOfTheMonthData, UseGetSkinsData} from "../../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {Link, useLocation} from "react-router-dom";
import {View} from "../../../base/enums/View";
import {Constant} from "../../../base/enums/Constant";
import LoginModal from "../../base/component/menu/LoginModal";
import {ShoppingCart, ShoppingCartContext} from "../../../contexts/ShoppingCartContext";
import {toast} from "react-hot-toast";
import SkinOfTheMonth from "./components/SkinOfTheMonth";
import SkinOfTheMonthAd from "./components/SkinOfTheMonthAd";
import SimpleLoaderIcon from "../../../components/SimpleLoaderIcon";
import SkinCard from "../skin/SkinCard";

export default function Homepage() {

	const location = useLocation();
	const cart = useContext<ShoppingCart>(ShoppingCartContext);
	const [skinOfTheMonth, setSkinOfTheMonth] = useState<SkinDto>();
	const [latestSkins, setLatestSkins] = useState<SkinDto[]>([]);
	const [mostDownloadedSkins, setMostDownloadedSkins] = useState<SkinDto[]>([]);

	const [galleryOpen, setGalleryOpen] = useState<boolean>(false);
	const [galleryIndex, setGalleryIndex] = useState<number>(0);
	const [showRegister, setShowRegister] = useState<boolean>(false);

	useEffect(() => {
		if ((cart.skins.length + cart.bundles.length) > 0 && location.pathname.endsWith('/success')) {
			toast.success(<strong>Thank you for your purchase! You should receive a mail confirmation with download link(s) in a few minutes.</strong>, {duration: 10000})
			cart.clear();
		}
	}, [location.pathname, cart]);

	const getSkinOfTheMonth = useGetSkinOfTheMonth(SkinMapper.ALL, {
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetSkinOfTheMonthData) => {
			setSkinOfTheMonth(data.skinOfTheMonth);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const getLatestSkins = useGetSkins({
		pageInfo: PageMapper.ALL,
		content: SkinMapper.ALL
	}, {
		fetchPolicy: 'network-only',
		variables: {
			page: {
				page: 0,
				size: 3,
				sortBy: 'createdAt',
				sortDirection: Direction.DESC
			}
		},
		onCompleted: (data: UseGetSkinsData) => {
			setLatestSkins(data.skins.content);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const getMostDownloadedSkins = useGetMostDownloadedSkins(SkinMapper.ALL, {
		fetchPolicy: 'network-only',
		variables: {
			page: {
				size: 3
			}
		},
		onCompleted: (data: UseGetMostDownloadedSkinsData) => {
			setMostDownloadedSkins(data.mostDownloadedSkins);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	return (
		<>
			<LoginModal open={showRegister} close={() => setShowRegister(false)} mode={'register'}/>
			<Segment className={'centered-background'}
					 style={{display: 'flex', background: 'url(/img/home5.jpg)', margin: '0', border: '0', flexDirection: 'column', alignItems: 'center', padding: '150px 50px'}}>
				<Header as={'h1'} style={{marginBottom: '10px', textAlign: 'center'}}>
					WELCOME TO {Constant.WEBSITE_TITLE.toUpperCase()}
				</Header>
				<Header as={'h2'} style={{marginBottom: '40px', marginTop: '0', textAlign: 'center'}}>
					FIND YOUR NEW RIDE
				</Header>
				<Link to={View.SKINS.path}><Button color={'blue'} size={'large'}>Explore skins</Button></Link>
			</Segment>
			<Segment className={'sotw'} style={{
				background: 'var(--yellow)',
				backgroundRepeat: 'no-repeat',
				backgroundImage: 'url(/img/sotw.svg)',
				backgroundPosition: 'center center',
			}}>
				{
					getSkinOfTheMonth.loading && <SimpleLoaderIcon opacity={0.6} margin={100} size={'huge'} color={'blue'}/>
				}
				{
					!getSkinOfTheMonth.loading && skinOfTheMonth &&
					<SkinOfTheMonth skin={skinOfTheMonth} galleryIndex={galleryIndex} galleryOpen={galleryOpen} setGalleryIndex={setGalleryIndex} setGalleryOpen={setGalleryOpen}/>
				}
				{
					!getSkinOfTheMonth.loading && !skinOfTheMonth && <SkinOfTheMonthAd/>
				}
			</Segment>
			{
				(getMostDownloadedSkins.loading || mostDownloadedSkins.length > 0) && <Segment style={{display: 'flex', background: 'var(--dark)', flexDirection: 'column', alignItems: 'center', padding: '60px 0 50px'}}>
					<Container style={{textAlign: 'center'}}>
						<Header as={'h2'} style={{color: 'white'}}>
							TRENDING SKINS
						</Header>
						{
							getMostDownloadedSkins.loading && <SimpleLoaderIcon opacity={0.6} margin={80} size={'huge'} color={'blue'}/>
						}
						{
							!getMostDownloadedSkins.loading && <Grid stackable columns={3} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
								{
									mostDownloadedSkins.map(skin => {
										return <Grid.Column key={skin.id}>
											<SkinCardPreview skin={skin} borderless linkToSkin/>
										</Grid.Column>
									})
								}
							</Grid>
						}
					</Container>
				</Segment>
			}
			{
				(getLatestSkins.loading || latestSkins.length > 0) && <Segment style={{display: 'flex', background: 'var(--dark)', flexDirection: 'column', alignItems: 'center', padding: '50px 0 60px'}}>
					<Container style={{textAlign: 'center'}}>
						<Header as={'h2'} style={{color: 'white'}}>
							LATEST SKINS
						</Header>
						{
							getLatestSkins.loading && <SimpleLoaderIcon opacity={0.6} margin={80} size={'huge'} color={'blue'}/>
						}
						{
							!getLatestSkins.loading && <Grid stackable columns={3} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
								{
									latestSkins.map(skin => {
										return <Grid.Column key={skin.id}>
											<SkinCard skin={skin} borderless linkToSkin/>
										</Grid.Column>
									})
								}
							</Grid>
						}
					</Container>
				</Segment>
			}
			<Segment className={'centered-background tagline'}
					 style={{display: 'flex', margin: '0', border: '0', background: 'url(/img/home4.jpg)', flexDirection: 'column', alignItems: 'center'}}>
				<Grid centered>
					<Grid.Column width={8} textAlign={'center'}>
						<Header as={'h1'} style={{marginBottom: '10px'}}>
							AUTHORS
						</Header>
						<Header as={'h2'} style={{marginBottom: '40px', marginTop: '0'}}>
							PUBLISH & SELL YOUR WORK
						</Header>
					</Grid.Column>
					<Grid.Column width={8} textAlign={'center'}>
						<Header as={'h1'} style={{marginBottom: '10px'}}>
							PLAYERS
						</Header>
						<Header as={'h2'} style={{marginBottom: '40px', marginTop: '0'}}>
							DOWNLOAD, RATE & COMMENT
						</Header>
					</Grid.Column>
					<Grid.Column width={16} textAlign={'center'}>
						<Button color={'yellow'} style={{color: '#333'}} size={'large'} onClick={() => setShowRegister(true)}>Create an Account</Button>
					</Grid.Column>
				</Grid>

			</Segment>
		</>
	);
}
