import React, {useContext} from "react";
import {Button, Icon, Image, Popup, Table} from "semantic-ui-react";
import {View} from "../../../base/enums/View";
import {Link} from "react-router-dom";
import {ShoppingCart, ShoppingCartContext} from "../../../contexts/ShoppingCartContext";
import {Country, CountrySelection} from "../../../base/util/Country";
import {Formatter} from "../../../base/util/Formatter";
import {Constant} from "../../../base/enums/Constant";
import {ResourceUtil} from "../../../base/util/ResourceUtil";

interface CartOverviewProps {
	country: CountrySelection
}

export default function CartOverview(props: CartOverviewProps) {

	const cart = useContext<ShoppingCart>(ShoppingCartContext);

	let totalPrice = cart.skins.flatMap(skin => skin.price).reduce((a, b) => a + b, 0) + cart.bundles.flatMap(skin => skin.price).reduce((a, b) => a + b, 0);

	let externalFeePercentage = (!props.country || Country.isEEU(props.country)) ? Constant.STRIPE_FEE_PERCENTAGE_EU : Constant.STRIPE_FEE_PERCENTAGE_OTHER;
	let externalFeeFixed = Constant.STRIPE_FEE_FIXED;
	let externalFeeAbsolute = ((totalPrice / 100) * externalFeePercentage) + externalFeeFixed;

	let ext = [];
	if (externalFeePercentage > 0) {
		ext.push(externalFeePercentage + '%');
	}
	if (externalFeeFixed > 0) {
		ext.push(Formatter.money(externalFeeFixed));
	}
	let internalFeePercentage = Constant.INTERNAL_FEE_PERCENTAGE;
	let internalFeeAbsolute = (totalPrice / 100) * internalFeePercentage;

	let items = cart.skins.length + cart.bundles.length;

	return (
		<Table padded collapsing className={'cart-overview'}>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell colSpan={4}>
						Summary - {items} {items > 1 ? 'items' : 'item'}
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{
					items === 0 && <Table.Row>
						<Table.Cell colSpan={4}>There are no items in your shopping cart.</Table.Cell>
					</Table.Row>
				}
				{
					cart.bundles.map(bundle => {
						return <React.Fragment key={'b' + bundle.id}>
							<Table.Row>
								<Table.Cell>
									<Image size={'tiny'} src={ResourceUtil.getThumbnailPath(bundle.resources)}/>
								</Table.Cell>
								<Table.Cell>
									<Link to={View.BUNDLE.path + bundle.url}><strong>{bundle.title}</strong></Link> <br/>
									By {bundle.author.username} <br/>
									<strong>{bundle.skins.length} {bundle.skins.length > 1 ? 'skins' : 'skin'}</strong>
								</Table.Cell>
								<Table.Cell collapsing textAlign={'right'}>
									<strong>{bundle.price > 0 ? Formatter.money(bundle.price) : 'Free'}</strong>
								</Table.Cell>
								<Table.Cell collapsing textAlign={'right'}>
									<Button size={'tiny'} icon={'trash'} color={'black'} onClick={() => cart.removeBundle(bundle)}/>
								</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell colSpan={4}>
									<Table compact size={'small'} basic={'very'} style={{marginBottom: '20px'}}>
										<Table.Body>
											{
												bundle.skins.map(skin => {
													return <Table.Row key={'s' + skin.id}>
														<Table.Cell><Link to={View.SKIN.path + skin.url}><strong>{skin.title}</strong></Link></Table.Cell>
														<Table.Cell textAlign={'right'}>Standalone price: <strong>{skin.price === 0 ? 'Free' : Formatter.money(skin.price)}</strong></Table.Cell>
													</Table.Row>
												})
											}
											{
												(bundle.skins.map(s => s.price).reduce((a, b) => a + b, 0) - bundle.price) > 0 && <Table.Row>
													<Table.Cell textAlign={'right'} colSpan={2}>
														<strong>By purchasing this bundle you save {Formatter.money(bundle.skins.map(s => s.price).reduce((a, b) => a + b, 0) - bundle.price)}</strong>
													</Table.Cell>
												</Table.Row>
											}
										</Table.Body>
									</Table>
								</Table.Cell>
							</Table.Row>
						</React.Fragment>
					})
				}
				{
					cart.skins.map(skin => {
						return <Table.Row key={skin.id}>
							<Table.Cell>
								<Image size={'tiny'} src={ResourceUtil.getThumbnailPath(skin.resources)}/>
							</Table.Cell>
							<Table.Cell>
								<Link to={View.SKIN.path + skin.url}><strong>{skin.title}</strong></Link> <br/>
								By {skin.author.username}
							</Table.Cell>
							<Table.Cell collapsing textAlign={'right'}>
								<strong>{skin.price > 0 ? Formatter.money(skin.price) : 'Free'}</strong>
							</Table.Cell>
							<Table.Cell collapsing textAlign={'right'}>
								<Button size={'tiny'} icon={'trash'} color={'black'} onClick={() => cart.removeSkin(skin)}/>
							</Table.Cell>
						</Table.Row>
					})
				}
			</Table.Body>
			<Table.Footer>
				<Table.Row>
					<Table.Cell>
						<Popup style={{textAlign: 'center'}} trigger={<Icon circular name='question circle'/>} wide>
							<Popup.Header style={{lineHeight: 1.8}}>Price breakdown information</Popup.Header>
							<Popup.Content>
								<p>
									{ext.length > 0 && <>Stripe fee ({ext.join(" + ")}): {Formatter.money(externalFeeAbsolute)}<br/></>}
									{Constant.WEBSITE_TITLE} fee ({internalFeePercentage}%): {Formatter.money(internalFeeAbsolute)}<br/>
									<strong>Author(s) will receive: {Formatter.money(totalPrice - externalFeeAbsolute - internalFeeAbsolute)}</strong><br/>
								</p>

								<p>
									Stripe has a payment fee of {Constant.STRIPE_FEE_PERCENTAGE_EU}% + {Constant.STRIPE_FEE_FIXED}€ for residents within the European Economic Area and {Constant.STRIPE_FEE_PERCENTAGE_GB}% + {Constant.STRIPE_FEE_FIXED}€ for everyone else.
									Our site takes {Constant.INTERNAL_FEE_PERCENTAGE}% to cover server cost and to fund future giveaways and competitions.
								</p>

								<p>
									PayPal has slightly larger fees and they depend on many factors including currency conversion options and your country. Refer to their official pricing to get more details.
								</p>
							</Popup.Content>
						</Popup>
					</Table.Cell>
					<Table.Cell colSpan={3} textAlign={'right'}>
						<strong>Total price: {Formatter.money(totalPrice)} (EUR)</strong>
					</Table.Cell>
				</Table.Row>
			</Table.Footer>
		</Table>
	);
}
