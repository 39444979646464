import React, {useContext, useEffect, useState} from "react";
import {Button, Container, Dropdown, Form, Grid, Header, Icon, List, Segment} from "semantic-ui-react";
import {ShoppingCart, ShoppingCartContext} from "../../../contexts/ShoppingCartContext";
import {View} from "../../../base/enums/View";
import {useHistory} from "react-router-dom";
import CartOverview from "./CartOverview";
import {Country} from "../../../base/util/Country";
import {UserDto} from "../../../build/generated-sources/dto/UserDto";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {Constant} from "../../../base/enums/Constant";
import {toast} from "react-hot-toast";
import {ESkinType} from "../../../build/generated-sources/enum/ESkinType";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import {CreateOrderActions, CreateOrderData} from "@paypal/paypal-js/types/components/buttons";

export default function CartSummary() {

	const auth = useContext<Auth>(AuthContext);
	const cart = useContext<ShoppingCart>(ShoppingCartContext);
	const history = useHistory();

	const [validate, setValidate] = useState<boolean>(false);

	const cartMissing = !localStorage.getItem(Constant.LOCAL_STORAGE_KEY_SKINS) && !localStorage.getItem(Constant.LOCAL_STORAGE_KEY_BUNDLES)

	if (cartMissing) {
		history.push(View.SKINS.path);
	}

	const [user, setUser] = useState<UserDto>({
		email: auth ? auth.email : '',
		fullname: auth ? auth.name : '',
		country: auth ? auth.country : Country.DEFAULT_COUNTRY.key,
	});

	useEffect(() => {
		setUser({
			...user,
			email: auth ? auth.email : '',
			fullname: auth ? auth.name : '',
			country: auth ? auth.country : Country.DEFAULT_COUNTRY.key,
		})
	}, [auth, auth.name, auth.email, auth.country]);

	const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
	const [reminderAccepted, setReminderAccepted] = useState<boolean>(false);

	const handleChange = (e: any, field: string) => {
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
		setUser({
			...user,
			[field]: value
		});
	}

	const requiredFieldsValid = () : boolean => {
		if (!user.email || !user.fullname) {
			return false;
		}
		if (!user.email.match(Constant.MAIL_REGEX) || user.fullname.length < 3) {
			return false;
		}
		if (!termsAccepted || !reminderAccepted) {
			return false;
		}
		return true;
	}

	const checkoutWithStripe = () => {
		if (!requiredFieldsValid()) {
			setValidate(true);
			return;
		}

		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
					email: user.email,
					fullname: user.fullname,
					country: user.country,
					skins: cart.skins.map(skin => skin.id),
					bundles: cart.bundles.map(bundle => bundle.id),
					termsAccepted: termsAccepted,
					reminderAccepted: reminderAccepted,
				}
			)
		};

		const toastId = toast.loading('Redirecting you to Stripe payment gate...');

		fetch('/api/stripe/checkout', requestOptions).then(response => {
			if (response.status >= 400 && response.status < 500) {
				setValidate(true);
			} else if (response.status >= 500) {
				toast.error(<strong>There was an error reaching Stripe. Please try again later.</strong>, {id: toastId});
			} else {
				response.text().then(data => {
					window.location.replace(data);
				});
			}
		})
	}

	const checkoutWithPaypal = (data: CreateOrderData, actions: CreateOrderActions): Promise<string> => {

		const skinIds = JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_KEY_SKINS));
		const bundleIds = JSON.parse(localStorage.getItem(Constant.LOCAL_STORAGE_KEY_BUNDLES));

		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
					email: document.getElementById("checkout-email-input").getAttribute("value"),
					fullname: document.getElementById("checkout-fullname-input").getAttribute("value"),
					country: document.getElementById("checkout-country-input").getAttribute("value"),
					skins: skinIds,
					bundles: bundleIds
				}
			)
		};

		// return new Promise<string>((resolve, reject) => {return reject()});
		return fetch('/api/paypal/create-order', requestOptions).then(response => {
			if (response.status >= 400) {
				setValidate(true);
				throw new Error('Bad request');
			} else if (response.status >= 500) {
				toast.error(<strong>There was an error reaching PayPal. Please try again later.</strong>);
				throw new Error('Internal server error');
			} else {
				return response.text().then(orderId => {
					return orderId;
				});
			}
		})
	}

	const onPaypalApprove = (data : any) => {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				orderId: data.orderID,
			}),
		};

		return fetch('/api/paypal/capture-order', requestOptions).then((response) => {
			if (response.status >= 400) {
				history.push(auth.token ? View.CHECKOUT_FAILED.path : ('/failed'));
			} else {
				history.push(auth.token ? View.CHECKOUT_SUCCESS.path : ('/success'));
			}
		})
	}

	return (
		<Container>
			<br/><br/>
			<Grid>
				<Grid.Row>
					<Header as='h3'>
						<Icon name='shopping cart'/>
						<Header.Content>
							Shopping cart
							<Header.Subheader>Check and confirm your order</Header.Subheader>
						</Header.Content>
					</Header>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={8}>
						<Form>
							<Form.Group>
								<Form.Input width={8} required id={"checkout-email-input"}
											error={validate && (!user.email || !user.email.match(Constant.MAIL_REGEX))} disabled={!!auth.email} label={'Email'}
											onChange={(event) => handleChange(event, 'email')} value={user.email} type={'text'}/>
								<Form.Input width={8} required id={"checkout-fullname-input"}
											error={validate && (!user.fullname || user.fullname.length < 3)} label={'Name'} onChange={(event) => handleChange(event, 'fullname')}
											value={user.fullname} type={'text'}/>
							</Form.Group>
							<label><strong>Country</strong></label>
							<Dropdown required labeled placeholder='Select Country' fluid search selection
									  id={"checkout-country-input"}
									  options={Country.ALL_COUNTRIES}
									  value={user.country}
									  onChange={(event, data: any) => setUser({...user, country: data.value})}
							/>

							<br/><br/>

							<Form.Checkbox error={validate && !termsAccepted}
										   checked={termsAccepted}
										   label={<label>By checking this you confirm that you've read and agree to our <strong>Terms of Service</strong>, <strong>Privacy
											   Policy</strong> and <strong>Refund Policy</strong>.</label>}
										   onChange={(e, data) => setTermsAccepted(data.checked)}/>
							<Form.Checkbox error={validate && !reminderAccepted}
										   checked={reminderAccepted}
										   label={<label>You agree to <strong>not</strong> resell, modify or recolor purchased items without author's consent and to <strong>not</strong> present purchased work as your own.</label>}
										   onChange={(e, data) => setReminderAccepted(data.checked)}/>

							<br/>

							<div className={'payment-methods'}>
								<Button className={'stripe'} size={'large'}
										onClick={checkoutWithStripe}
										style={{padding: 0, borderRadius: 5, width: '100%', background: '#665bff'}}>
									<Icon name={'stripe'} size={'huge'} style={{color: '#fff', opacity: 1, margin: 0}}/>
								</Button>

								<br/>
								<br/>

								<PayPalScriptProvider options={{clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID, currency: "EUR"}} >
									<PayPalButtons
										createOrder={checkoutWithPaypal}
										disabled={!requiredFieldsValid()}
										onApprove={onPaypalApprove}/>
								</PayPalScriptProvider>

							</div>

							<br/>

							{
								cart.skins.filter(item => item.type === ESkinType.TM2020).length > 0 && <Segment color={'red'}><Icon name={'info circle'} /> <strong>Trackmania 2020</strong> requires <strong>Club Access</strong> for custom skins to be used.</Segment>
							}

							{process.env.REACT_APP_ENVIRONMENT !== 'prod' && <>
								<br/><br/><br/><br/>
								<Segment color={'orange'}>
									<p><Icon color={'orange'} name={'info circle'}/> This is a <strong>test</strong> server. Payments are done on a <strong>test</strong> Stripe
										environment. Do not use your
										real card. They are not going to work anyway.</p>

									<strong>You can use these test card numbers:</strong>

									<List bulleted>
										<List.Item>Successful payment: 4242 4242 4242 4242</List.Item>
										<List.Item>Failed payment: 4000 0000 0000 9995</List.Item>
										<List.Item>Required authentication: 4000 0025 0000 3155</List.Item>
									</List>

									<strong>As for the other values, follow these instructions:</strong>

									<List bulleted>
										<List.Item>Use a valid future date, such as 12/29.</List.Item>
										<List.Item>Use any three digits for CVC (four digits for American Express cards).</List.Item>
										<List.Item>Use any value you like for other form fields.</List.Item>
									</List>
								</Segment>
							</>
							}
						</Form>
					</Grid.Column>
					<Grid.Column width={8}>
						<CartOverview country={Country.getByKey(user.country)}/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<br/><br/>
		</Container>
	);
}
