import { Resolver as LongResolver } from  "@redrobot/npm-core-generator/dist/src/type/Long" ;
import { Resolver as LocalDateResolver } from  "../../scalars/LocalDate" ;
import { Resolver as LocalDateTimeResolver } from  "../../scalars/LocalDateTime" ;
import { gql } from "@apollo/client" ;
import { makeExecutableSchema } from "@graphql-tools/schema" ;


const resolvers = {
  Long: LongResolver,  LocalDate: LocalDateResolver,  LocalDateTime: LocalDateTimeResolver};

const typeDefs = gql`
  scalar Long  scalar LocalDate  scalar LocalDateTime`;

export const schema = makeExecutableSchema({
    typeDefs,
    resolvers,
  });
