import React, {createContext, useContext, useEffect, useState} from "react";
import {useLazyGetMyOwnedSkinIds} from "../build/generated-sources/service/QueryService";
import {Auth, AuthContext} from "./AuthContext";

export type OwnedSkins = {
	addSkin: (id : number) => void;
	hasSkin: (id : number) => boolean;
};

export const initialValue: OwnedSkins = {
	addSkin: (id : number) => {},
	hasSkin: (id : number) => {return false},
};

export const OwnedSkinsContext = createContext<OwnedSkins>(initialValue);

export default function OwnedSkinsProvider(props: any) {

	const auth = useContext<Auth>(AuthContext);
	const [ownedSkins, setOwnedSkins] = useState<Set<number>>(new Set());

	const [getMyOwnedSkinIds] = useLazyGetMyOwnedSkinIds({
		id: true
	}, {
		skip: true
	});

	useEffect(() => {
		if (auth.token) {
			getMyOwnedSkinIds().then(data => {
				let set = new Set<number>();
				data.data.myOwnedSkinIds.forEach(d => set.add(d.id));
				setOwnedSkins(set);
			});
		}
	}, [auth, auth.token, getMyOwnedSkinIds]);

	const addSkin = (id : number) => {
		let set = new Set<number>();
		set.add(id);
		ownedSkins.forEach(d => set.add(d));
		setOwnedSkins(set);
	}

	const hasSkin = (id : number) : boolean => {
		return ownedSkins.has(id);
	}

	return (
		<OwnedSkinsContext.Provider value={{addSkin, hasSkin}}>
			{props.children}
		</OwnedSkinsContext.Provider>
	)
};
