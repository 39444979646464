import React, {useState} from "react";
import {Button, Form, Header, Icon, Modal, Segment} from "semantic-ui-react";
import {SyncOperationState} from "../../../../base/state/SyncOperationState";

interface ForgottenPasswordProps {
	close: () => void
	setMode: (mode: string) => void
}

export default function ForgottenPassword(props: ForgottenPasswordProps) {

	const [email, setEmail] = useState<string>('');
	const [syncState, setSyncState] = useState<SyncOperationState>({
		inProgress: false,
		error: false,
		done: false
	});

	const askForReset = () => {
		const requestOptions = {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({email: email})
		};
		setSyncState({
			inProgress: true,
			error: false,
			done: false
		});
		fetch('/api/auth/forgot-password', requestOptions).then(response => {
			if (response.status >= 400) {
				setSyncState({
					error: true,
					done: true,
					id: response.status
				});
			} else {
				response.text().then(data => {
					setSyncState({
						id: response.status,
						done: true,
					});
				});
			}
		});
	}

	return (
		<>
			<Modal.Content>
				<Header as='h2'>
					<Icon name='unlock alternate' />
					<Header.Content>
						Reset Password
					</Header.Content>
				</Header>
				{
					syncState.done ?
						<Segment className={'error-msg'} color={'olive'}>
							<p><Icon name={'info circle'}/> Sent!</p>
							<p>If there is an account under the email you've entered, you should receive message an a short while. Hang tight!</p>
						</Segment>
						:
						<><br/><p>Enter email you've registered with and we will send you a link through which you will be able to reset your password.</p></>
				}
				<br/>
				<Form>
					<Form.Input icon={'mail'} disabled={syncState.inProgress || syncState.done} className={syncState.inProgress || syncState.done ? 'disabled' : ''} label={'Email'} type={'text'} value={email} onChange={(e) => setEmail(e.target.value)}/>

					<br/>
					<br/>

					<Button floated={'left'} disabled={syncState.inProgress} onClick={() => props.setMode('login')}>
						Back
					</Button><Button floated={'right'} loading={syncState.inProgress} disabled={syncState.inProgress || syncState.done} color={'black'} onClick={() => askForReset()}>
					Request Password Reset
				</Button>
				</Form>
				<br/>
				<br/>
			</Modal.Content>
		</>
	);
}
