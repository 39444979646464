import React, {useEffect, useMemo, useState} from "react";
import {Container, Embed, Grid, Header, Icon, Image, Popup, Segment} from "semantic-ui-react";
import {useGetSkin} from "../../../build/generated-sources/service/QueryService";
import {UseGetSkinData} from "../../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {SkinDto} from "../../../build/generated-sources/dto/SkinDto";
import SkinCard from "./SkinCard";
import SkinMapper from "../../../base/mapper/SkinMapper";
import {EResourceType} from "../../../build/generated-sources/enum/EResourceType";
import SimpleLoader from "../../../components/SimpleLoader";
import Comments from "../comment/Comments";
import {ResourceUtil} from "../../../base/util/ResourceUtil";
import {VideoUtil} from "../../../base/util/VideoUtil";
import SkinColorMarker from "./SkinColorMarker";
import {Link} from "react-router-dom";
import {View} from "../../../base/enums/View";
import Lightbox from "react-image-lightbox";

interface SkinProps {
	url: string
}

export default function Skin(props: SkinProps) {

	const [skin, setSkin] = useState<SkinDto>();
	const [currentImg, setCurrentImg] = useState<number>();
	const [galleryOpen, setGalleryOpen] = useState<boolean>(false);
	const [galleryIndex, setGalleryIndex] = useState<number>(0);

	const getSkin = useGetSkin(SkinMapper.ALL, {
			fetchPolicy: 'network-only',
			variables: {
				url: props.url
			},
			onCompleted: (data: UseGetSkinData) => {
				setSkin(data.skin);
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	const images = useMemo(() => {
		if (!skin) {
			return [];
		}
		return skin.resources
			.filter(resource => resource.type === EResourceType.IMAGE)
			.map(resource => resource.filename)
	}, [skin]);

	useEffect(() => {
		if (images.length > 0) {
			setCurrentImg(0);
		}
	}, [images]);

	if (getSkin.loading || !skin) {
		return (<SimpleLoader text={'Loading skin...'}/>);
	}

	let videoCode = VideoUtil.getVideoCode(skin.videoUrl);

	return (
		<Container>
			<Grid stackable className={'skin'}>
				<Grid.Column width={5}>
					<SkinCard skin={skin} showDescription showRating enableRating showPurchase/>
					{
						skin.colorVariations.length > 0 && <div style={{textAlign: 'center', marginTop: '20px'}}>
							<h4>Available color variations</h4>
							<div style={{display: 'flex', justifyContent: 'center'}}>
								{
									skin.colorVariations.map(s => {
										return <Popup key={s.id} content={<Image src={ResourceUtil.getThumbnailPath(s.resources)}/>} trigger={<Link to={View.SKIN.path + s.url}>
											<SkinColorMarker primary={s.colorPrimary} secondary={s.colorSecondary}/>
										</Link>}/>
									})
								}
							</div>
						</div>
					}
				</Grid.Column>
				<Grid.Column width={11} style={{textAlign: 'center', margin: '0 auto'}}>
					{
						!videoCode && images.length === 0 && <Image src={'/img/placeholder.jpg'}>
							<Segment placeholder>
								<Header icon>
									<Icon name='image'/>
									User didn't upload any extra screenshots or videos for this skin.<br/><br/> Let him know you would like to see more!
								</Header>
							</Segment>
						</Image>
					}
					{
						videoCode && <Embed id={videoCode} placeholder={ResourceUtil.getThumbnailPath(skin.resources)} source='youtube'/>
					}
					{
						!videoCode && images.length > 0 && <div>
							<div className={'hide-on-mobile'} style={{height: '440px'}}>
								{
									currentImg !== undefined ? <Image style={{cursor: 'pointer', objectFit: 'contain', margin: 'auto', maxHeight: '100%'}}
																	  src={'/public/' + images[currentImg]} fluid onClick={() => {
										setGalleryIndex(currentImg);
										setGalleryOpen(true)
									}}/>
									:
									<Image src={'/img/placeholder.jpg'} fluid/>
								}
							</div>
							<Image.Group className={'gallery'} style={{display: 'flex', overflow: 'auto', justifyContent: 'center'}}>
								{
									images.map((img, i) => {
										return <Image key={i} src={'/public/' + img} onMouseEnter={() => {
											setCurrentImg(i)
										}} onClick={() => {
											setGalleryIndex(i);
											setGalleryOpen(true)
										}
										}/>
									})
								}
							</Image.Group>
						</div>
					}
					{
						galleryOpen && <Lightbox
							imageTitle={skin.title}
							imageCaption={skin.description}
							mainSrc={'/public/' + images[galleryIndex]}
							nextSrc={'/public/' + images[(galleryIndex + 1) % images.length]}
							prevSrc={'/public/' + images[(galleryIndex + images.length - 1) % images.length]}
							onCloseRequest={() => setGalleryOpen(false)}
							onMovePrevRequest={() =>
								setGalleryIndex((galleryIndex + images.length - 1) % images.length)
							}
							onMoveNextRequest={() =>
								setGalleryIndex((galleryIndex + 1) % images.length)
							}
						/>
					}
					<div style={{marginTop: '30px', width: '100%'}}>
						<Comments skin={skin.id}/>
					</div>
				</Grid.Column>
			</Grid>
		</Container>
	);
}
