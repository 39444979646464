import React, {useContext, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Button, Container, Icon} from "semantic-ui-react";
import {Auth, AuthContext} from "../../../../contexts/AuthContext";
import {View} from "../../../../base/enums/View";
import {ShoppingCart, ShoppingCartContext} from "../../../../contexts/ShoppingCartContext";
import LoginModal from "./LoginModal";
import {Toaster} from "react-hot-toast";
import {Constant} from "../../../../base/enums/Constant";

export default function TopNavigation() {

	const auth = useContext<Auth>(AuthContext);
	const cart = useContext<ShoppingCart>(ShoppingCartContext);

	const location = useLocation();
	const [activeView, setActiveView] = useState<string>(location.pathname);
	const [showLogin, setShowLogin] = useState<boolean>(false);

	const leftItems = [
		{
			icon: 'car',
			view: View.SKINS
		}, {
			icon: 'gift',
			view: View.BUNDLES
		}, {
			icon: 'users',
			view: View.AUTHORS
		}
	];

	return (
		<div className={'top-navigation'} style={{background: 'var(--dark)'}}>
			<div>
				<Toaster toastOptions={{
					duration: 5000,
					className: '',
					style: {
						border: 0,
						top: '6px',
						backgroundColor: 'white',
						padding: '16px',
						borderRadius: '10px 0 10px 0',
						color: '#333',
					},
				}}/>
			</div>
			<LoginModal open={showLogin} close={() => setShowLogin(false)} mode={'register'}/>
			<Container>
				<div style={{display: 'flex', flexDirection: 'column'}}>
					<div className={'title'} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', top:'37px', height: '0', color: 'white', textAlign: 'center'}}>
						<Link to={View.HOMEPAGE.path} className={'clickable'}><span style={{fontWeight: 'bold', fontSize: '1.4em', color: 'white'}}>{Constant.WEBSITE_TITLE_SIMPLE}</span></Link>
					</div>
					<div className={'buttons'} style={{display: 'flex', alignItems: 'center', padding: '16px 0 20px', textAlign: 'center'}}>
						<Button.Group className={'left-items regular'}>
							{
								leftItems.map(item => {
									return <Link key={item.view.name} to={item.view.path} style={{marginRight: '14px'}}>
										<Button style={{width: '100px', borderRadius: '0'}} color={'blue'} active={activeView === item.view.name}
												onClick={() => setActiveView(item.view.name)}>
											{item.view.name}
										</Button>
									</Link>
								})
							}
						</Button.Group>
						<Button.Group className={'left-items icons-only'}>
							{
								leftItems.map(item => {
									return <Link key={item.view.name} to={item.view.path} style={{marginRight: '14px'}}>
										<Button icon={item.icon} style={{borderRadius: '0'}} color={'blue'} active={activeView === item.view.name}
												onClick={() => setActiveView(item.view.name)} />
									</Link>
								})
							}
						</Button.Group>
						<Button.Group>
							{
								auth.token ? <Link to={View.OWNED_SKINS.path} style={{marginLeft: '14px'}}>
										<Button color={'blue'} active={activeView === View.OWNED_SKINS.name}
												onClick={() => setActiveView(View.OWNED_SKINS.name)}>
											<Icon name={'user'}/> Profile
										</Button>
									</Link>
									:
									<Button color={'blue'} onClick={() => setShowLogin(true)}>Sign Up</Button>
							}
							{
								(cart.skins.length + cart.bundles.length) > 0 && <Link to={View.CART_SUMMARY.path} style={{marginLeft: '14px'}}>
									<Button disabled={(cart.skins.length + cart.bundles.length) === 0} color={'blue'} active={activeView === View.CART_SUMMARY.name}
											onClick={() => setActiveView(View.CART_SUMMARY.name)}>
										<Icon name={'shop'}/> ({cart.skins.length + cart.bundles.length})
									</Button>
								</Link>
							}
						</Button.Group>
					</div>
				</div>
			</Container>
		</div>
	);
}
