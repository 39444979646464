import React, {useMemo, useState} from "react";
import {Grid, Header, Icon, Segment, Statistic, Table} from "semantic-ui-react";
import {
	useGetDownloadCount,
	useGetDownloadHistory, useGetMoneySpent,
	useGetPurchaseHistory,
	useGetSkinDownloadPopularity, useGetSkinPurchasePopularity
} from "../../build/generated-sources/service/QueryService";
import {
	UseGetDownloadCountData,
	UseGetDownloadHistoryData, UseGetMoneySpentData,
	UseGetPurchaseHistoryData,
	UseGetSkinDownloadPopularityData, UseGetSkinPurchasePopularityData
} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import moment from "moment";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {DownloadHistoryDto} from "../../build/generated-sources/dto/DownloadHistoryDto";
import {DownloadCountSummaryDto} from "../../build/generated-sources/dto/DownloadCountSummaryDto";
import SimpleLoader from "../../components/SimpleLoader";
import {PurchaseHistoryDto} from "../../build/generated-sources/dto/PurchaseHistoryDto";
import {EPaymentStatus} from "../../build/generated-sources/enum/EPaymentStatus";
import {EPaymentType} from "../../build/generated-sources/enum/EPaymentType";
import SkinMapper from "../../base/mapper/SkinMapper";
import {SkinDownloadCountSummaryDto} from "../../build/generated-sources/dto/SkinDownloadCountSummaryDto";
import {SkinPurchaseCountSummaryDto} from "../../build/generated-sources/dto/SkinPurchaseCountSummaryDto";
import {Link} from "react-router-dom";
import {View} from "../../base/enums/View";
import {Formatter} from "../../base/util/Formatter";
import {MoneySpentSummaryDto} from "../../build/generated-sources/dto/MoneySpentSummaryDto";

export default function AdministrationDashboard() {


	const [downloadCount, setDownloadCount] = useState<DownloadCountSummaryDto>();
	const [downloadHistoryRaw, setDownloadHistoryRaw] = useState<DownloadHistoryDto[]>([]);
	const [purchaseHistoryRaw, setPurchaseHistoryRaw] = useState<PurchaseHistoryDto[]>([]);
	const [skinDownloadPopularity, setSkinDownloadPopularity] = useState<SkinDownloadCountSummaryDto>();
	const [skinPurchasePopularity, setSkinPurchasePopularity] = useState<SkinPurchaseCountSummaryDto>();
	const [moneySpent, setMoneySpent] = useState<MoneySpentSummaryDto>();

	const getMoneySpent = useGetMoneySpent({
		allTime: {
			totalSpent: true,
			externalFeesTotal: true,
			internalFeesTotal: true,
		},
		last30Days: {
			totalSpent: true,
			externalFeesTotal: true,
			internalFeesTotal: true,
		},
		lastYear: {
			totalSpent: true,
			externalFeesTotal: true,
			internalFeesTotal: true,
		},
	}, {
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetMoneySpentData) => {
			setMoneySpent(data.moneySpent);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const getSkinDownloadPopularity = useGetSkinDownloadPopularity({
		allTime: {
			skin: SkinMapper.ALL,
			total: true,
			unique: true
		},
		last30Days: {
			skin: SkinMapper.ALL,
			total: true,
			unique: true
		},
		lastYear: {
			skin: SkinMapper.ALL,
			total: true,
			unique: true
		},
	}, {
		fetchPolicy: 'network-only',
		variables: {
			length: 5
		},
		onCompleted: (data: UseGetSkinDownloadPopularityData) => {
			setSkinDownloadPopularity(data.skinDownloadPopularity);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const getSkinPurchasePopularity = useGetSkinPurchasePopularity({
		allTime: {
			skin: SkinMapper.ALL,
			totalPrice: true
		},
		last30Days: {
			skin: SkinMapper.ALL,
			totalPrice: true
		},
		lastYear: {
			skin: SkinMapper.ALL,
			totalPrice: true
		},
	}, {
		fetchPolicy: 'network-only',
		variables: {
			length: 5
		},
		onCompleted: (data: UseGetSkinPurchasePopularityData) => {
			setSkinPurchasePopularity(data.skinPurchasePopularity);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const getPurchaseHistory = useGetPurchaseHistory({
		data: {
			month: true,
			paymentStatus: true,
			paymentType: true,
			totalCount: true,
			totalPrice: true,
			year: true,
		}
	}, {
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetPurchaseHistoryData) => {
			setPurchaseHistoryRaw(data.purchaseHistory.data);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const getDownloadCount = useGetDownloadCount({
		allTimeTotal: true,
		allTimeUnique: true,
		last30DaysTotal: true,
		last30DaysUnique: true,
		lastYearTotal: true,
		lastYearUnique: true,
	}, {
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetDownloadCountData) => {
			setDownloadCount(data.downloadCount);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const getDownloadHistory = useGetDownloadHistory({
		data: {
			game: true,
			month: true,
			total: true,
			unique: true,
			year: true
		}
	}, {
		fetchPolicy: 'network-only',
		onCompleted: (data: UseGetDownloadHistoryData) => {
			setDownloadHistoryRaw(data.downloadHistory.data);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	});

	const downloadHistory = useMemo(() => {
		if (!downloadHistoryRaw) {
			return [];
		}

		const result: any[] = [];
		downloadHistoryRaw.forEach(row => {
			const game = row.game;
			const key = moment(row.month, moment.ISO_8601).format('MM') + '-' + moment(row.month, moment.ISO_8601).format('YYYY');
			if (!result.hasOwnProperty(key)) {
				// @ts-ignore
				result[key] = {
					name: key
				};
			}
			// @ts-ignore
			result[key]['unique' + game] = row.unique;
			// @ts-ignore
			result[key]['total' + game] = row.total;
		});

		const r : any[] = [];
		for (const [key, value] of Object.entries(result)) {
			r.push(value);
		}
		return r;
	}, [downloadHistoryRaw]);

	const purchaseHistory = useMemo(() => {
		if (!purchaseHistoryRaw) {
			return [];
		}

		const result: any[] = [];
		purchaseHistoryRaw.forEach(row => {
			const key = moment(row.month, moment.ISO_8601).format('MM') + '-' + moment(row.month, moment.ISO_8601).format('YYYY');
			if (!result.hasOwnProperty(key)) {
				// @ts-ignore
				result[key] = {
					name: key,
					beforeCheckout: 0,
					completedPaypal: 0,
					completedStripe: 0,
					cancelled: 0,
					paymentOnTheWay: 0,
				};
			}
			if (row.paymentStatus === EPaymentStatus.PAYMENT_RECEIVED && row.paymentType === EPaymentType.PAYPAL) {
				// @ts-ignore
				result[key]['completedPaypal'] += row.totalCount;
			} else if (row.paymentStatus === EPaymentStatus.PAYMENT_RECEIVED && row.paymentType === EPaymentType.STRIPE) {
				// @ts-ignore
				result[key]['completedStripe'] += row.totalCount;
			} else if (row.paymentStatus === EPaymentStatus.CANCELLED) {
				// @ts-ignore
				result[key]['cancelled'] += row.totalCount;
			} else if (row.paymentStatus === EPaymentStatus.PAYMENT_ON_THE_WAY) {
				// @ts-ignore
				result[key]['paymentOnTheWay'] += row.totalCount;
			} else if (row.paymentStatus === EPaymentStatus.BEFORE_CHECKOUT) {
				// @ts-ignore
				result[key]['beforeCheckout'] += row.totalCount;
			}
		});

		const r : any[] = [];
		for (const [key, value] of Object.entries(result)) {
			r.push(value);
		}
		return r;
	}, [purchaseHistoryRaw]);

	const moneySpentData = useMemo(() => {
		if (!purchaseHistoryRaw) {
			return [];
		}

		const result: any[] = [];
		purchaseHistoryRaw.forEach(row => {
			const key = moment(row.month, moment.ISO_8601).format('MM') + '-' + moment(row.month, moment.ISO_8601).format('YYYY');
			if (!result.hasOwnProperty(key)) {
				// @ts-ignore
				result[key] = {
					name: key,
					spentPaypal: 0,
					spentStripe: 0,
				};
			}
			if (row.paymentStatus === EPaymentStatus.PAYMENT_RECEIVED && row.paymentType === EPaymentType.PAYPAL) {
				// @ts-ignore
				result[key]['spentPaypal'] += row.totalPrice;
			} else if (row.paymentStatus === EPaymentStatus.PAYMENT_RECEIVED && row.paymentType === EPaymentType.STRIPE) {
				// @ts-ignore
				result[key]['spentStripe'] += row.totalPrice;
			}
		});

		const r : any[] = [];
		for (const [key, value] of Object.entries(result)) {
			r.push(value);
		}
		return r;
	}, [purchaseHistoryRaw]);

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='dashboard'/>
				<Header.Content>
					Site Dashboard
				</Header.Content>
			</Header>
			<Grid>
				<Grid.Column width={8}>
					<Segment color='yellow'>
					{
						(!downloadCount || getDownloadCount.loading) ? <SimpleLoader text={"Loading All Time Downloads"} /> : <>
							<Header>Last 30 Days</Header>
							  <Statistic.Group>
								  <Statistic>
									<Statistic.Value>{downloadCount.last30DaysUnique}</Statistic.Value>
									<Statistic.Label>Unique downloads</Statistic.Label>
								  </Statistic>
								  <Statistic>
									<Statistic.Value>{downloadCount.last30DaysTotal}</Statistic.Value>
									<Statistic.Label>Total Downloads</Statistic.Label>
								  </Statistic>
							  </Statistic.Group>
						</>
					}
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color='yellow'>
					{
						(!downloadCount || getDownloadCount.loading) ? <SimpleLoader text={"Loading All Time Downloads"} /> : <>
							<Header>All Time</Header>
							  <Statistic.Group>
								  <Statistic>
									<Statistic.Value>{downloadCount.allTimeUnique}</Statistic.Value>
									<Statistic.Label>Unique downloads</Statistic.Label>
								  </Statistic>
								  <Statistic>
									<Statistic.Value>{downloadCount.allTimeTotal}</Statistic.Value>
									<Statistic.Label>Total Downloads</Statistic.Label>
								  </Statistic>
							  </Statistic.Group>
						</>
					}
					</Segment>
				</Grid.Column>
				<Grid.Column width={16}>
					<Segment color='yellow'>
						<Header>Downloads History</Header>
						<ResponsiveContainer width={"100%"} height={240}>
							<BarChart
								width={500}
								height={300}
								data={downloadHistory}
								margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
								}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Bar name={"Trackmania 2020 (Total)"} dataKey="totalTM2020" stackId="total" fill="#17aae6" />
								<Bar name={"Trackmania Nations Forever (Total)"} dataKey="totalTMNF" stackId="total" fill="#006700" />
								<Bar name={"Trackmania United Forever (Total)"} dataKey="totalTMUF" stackId="total" fill="#003400" />
								<Bar name={"Trackmania Turbo (Total)"} dataKey="totalTM_TURBO" stackId="total" fill="#ff00ff" />
								<Bar name={"Trackmania 2 (Total)"} dataKey="totalTM2" stackId="total" fill="#00e6e6" />
								<Bar name={"Trackmania Sunrise (Total)"} dataKey="totalTM_SUNRISE" stackId="total" fill="#ffff00" />
								<Bar name={"Trackmania 2003 (Total)"} dataKey="totalTM" stackId="total" fill="#a9a9a9" />
								<Bar name={"Trackmania 2020 (Unique)"} dataKey="uniqueTM2020" stackId="unique" fill="#17aae6" />
								<Bar name={"Trackmania Nations Forever (Unique)"} dataKey="uniqueTMNF" stackId="unique" fill="#006700" />
								<Bar name={"Trackmania United Forever (Unique)"} dataKey="uniqueTMUF" stackId="unique" fill="#003400" />
								<Bar name={"Trackmania Turbo (Unique)"} dataKey="uniqueTM_TURBO" stackId="unique" fill="#ff00ff" />
								<Bar name={"Trackmania 2 (Unique)"} dataKey="uniqueTM2" stackId="unique" fill="#00e6e6" />
								<Bar name={"Trackmania Sunrise (Unique)"} dataKey="uniqueTM_SUNRISE" stackId="unique" fill="#ffff00" />
								<Bar name={"Trackmania 2003 (Unique)"} dataKey="uniqueTM" stackId="unique" fill="#a9a9a9" />
							</BarChart>
						</ResponsiveContainer>
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color='yellow'>
						<Header>Most Downloaded Skins (Last 30 days)</Header>
						{
							(!skinDownloadPopularity || getSkinDownloadPopularity.loading) ? <SimpleLoader text={"Loading Most Downloaded Skins"} /> : <>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Skin</Table.HeaderCell>
											<Table.HeaderCell textAlign={'center'}>Unique</Table.HeaderCell>
											<Table.HeaderCell textAlign={'center'}>Total</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{
											skinDownloadPopularity.last30Days.map(skin => <Table.Row key={'last30days_' + skin.skin.id}>
													<Table.Cell><Link to={View.SKIN.path + skin.skin.url}><strong>{skin.skin.title}</strong></Link></Table.Cell>
													<Table.Cell textAlign={'center'}>{skin.unique}</Table.Cell>
													<Table.Cell textAlign={'center'}>{skin.total}</Table.Cell>
												</Table.Row>
											)
										}
									</Table.Body>
								</Table>
							</>
						}
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color='yellow'>
						<Header>Most Downloaded Skins (Last Year)</Header>
						{
							(!skinDownloadPopularity || getSkinDownloadPopularity.loading) ? <SimpleLoader text={"Loading Most Downloaded Skins"} /> : <>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Skin</Table.HeaderCell>
											<Table.HeaderCell textAlign={'center'}>Unique</Table.HeaderCell>
											<Table.HeaderCell textAlign={'center'}>Total</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{
											skinDownloadPopularity.lastYear.map(skin => <Table.Row key={'lastYear2_' + skin.skin.id}>
													<Table.Cell><Link to={View.SKIN.path + skin.skin.url}><strong>{skin.skin.title}</strong></Link></Table.Cell>
													<Table.Cell textAlign={'center'}>{skin.unique}</Table.Cell>
													<Table.Cell textAlign={'center'}>{skin.total}</Table.Cell>
												</Table.Row>
											)
										}
									</Table.Body>
								</Table>
							</>
						}
					</Segment>
				</Grid.Column>

				<Grid.Column width={8}>
					<Segment color='yellow'>
					{
						(!moneySpent || getMoneySpent.loading) ? <SimpleLoader text={"Loading Spending Summary"} /> : <>
							<Header>Spendings (Last 30 Days)</Header>
							  <Statistic.Group size={'tiny'}>
								  <Statistic>
									<Statistic.Value>{Formatter.money(moneySpent.last30Days.totalSpent)}</Statistic.Value>
									<Statistic.Label>Money Spent</Statistic.Label>
								  </Statistic>
								  <Statistic>
									<Statistic.Value>{Formatter.money(moneySpent.last30Days.externalFeesTotal)}</Statistic.Value>
									<Statistic.Label>Fees</Statistic.Label>
								  </Statistic>
								  <Statistic>
									<Statistic.Value>{Formatter.money(moneySpent.last30Days.internalFeesTotal)}</Statistic.Value>
									<Statistic.Label>Profit</Statistic.Label>
								  </Statistic>
							  </Statistic.Group>
						</>
					}
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color='yellow'>
					{
						(!moneySpent || getMoneySpent.loading) ? <SimpleLoader text={"Loading Spending Summary"} /> : <>
							<Header>Spendings (All Time)</Header>
							  <Statistic.Group size={'tiny'}>
								  <Statistic>
									<Statistic.Value>{Formatter.money(moneySpent.allTime.totalSpent)}</Statistic.Value>
									<Statistic.Label>Money Spent</Statistic.Label>
								  </Statistic>
								  <Statistic>
									<Statistic.Value>{Formatter.money(moneySpent.allTime.externalFeesTotal)}</Statistic.Value>
									<Statistic.Label>Fees</Statistic.Label>
								  </Statistic>
								  <Statistic>
									<Statistic.Value>{Formatter.money(moneySpent.allTime.internalFeesTotal)}</Statistic.Value>
									<Statistic.Label>Profit</Statistic.Label>
								  </Statistic>
							  </Statistic.Group>
						</>
					}
					</Segment>
				</Grid.Column>
				<Grid.Column width={16}>
					<Segment color='yellow'>
						<Header>Purchase History</Header>
						<ResponsiveContainer width={"100%"} height={240}>
							<BarChart width={500} height={300}
								data={purchaseHistory}
								margin={{top: 5, right: 30, left: 20, bottom: 5}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Bar name={"Before Checkout"} dataKey="beforeCheckout" stackId="inProgress" fill="#111111" />
								<Bar name={"Completed via PayPal"} dataKey="completedPaypal" stackId="finished" fill="#e6c000" />
								<Bar name={"Completed via Stripe"} dataKey="completedStripe" stackId="finished" fill="#665bff" />
								<Bar name={"Cancelled"} dataKey="cancelled" stackId="finished" fill="#8b0000" />
								<Bar name={"Payment is on the Way"} dataKey="paymentOnTheWay" stackId="finished" fill="#808080" />
							</BarChart>
						</ResponsiveContainer>
					</Segment>
				</Grid.Column>
				<Grid.Column width={16}>
					<Segment color='yellow'>
						<Header>Spending History</Header>
						<ResponsiveContainer width={"100%"} height={240}>
							<BarChart width={500} height={300}
								data={moneySpentData}
								margin={{top: 5, right: 30, left: 20, bottom: 5,}}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey="name" />
								<YAxis />
								<Tooltip />
								<Bar unit={" €"} name={"Spent on PayPal"} stackId={'total'} dataKey="spentPaypal" fill="#e6c000" />
								<Bar unit={" €"} name={"Spent on Stripe"} stackId={'total'} dataKey="spentStripe" fill="#665bff" />
							</BarChart>
						</ResponsiveContainer>
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color='yellow'>
						<Header>Most Purchased Skins (Last 30 days)</Header>
						{
							(!skinPurchasePopularity || getSkinPurchasePopularity.loading) ? <SimpleLoader text={"Loading Most Purchased Skins"} /> : <>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Skin</Table.HeaderCell>
											<Table.HeaderCell textAlign={'center'}>Money Spent</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{
											skinPurchasePopularity.last30Days.length === 0 && <Table.Row>
												<Table.Cell colSpan={2} textAlign={'center'}>There is no purchase in the last month.</Table.Cell>
											</Table.Row>
										}
										{
											skinPurchasePopularity.last30Days.map(skin => <Table.Row key={'last30day_' + skin.skin.id}>
													<Table.Cell><Link to={View.SKIN.path + skin.skin.url}><strong>{skin.skin.title}</strong></Link></Table.Cell>
													<Table.Cell textAlign={'center'}>{Formatter.money(skin.totalPrice)}</Table.Cell>
												</Table.Row>
											)
										}
									</Table.Body>
								</Table>
							</>
						}
					</Segment>
				</Grid.Column>
				<Grid.Column width={8}>
					<Segment color='yellow'>
						<Header>Most Purchased Skins (Last Year)</Header>
						{
							(!skinPurchasePopularity || getSkinPurchasePopularity.loading) ? <SimpleLoader text={"Loading Most Purchased Skins"} /> : <>
								<Table>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Skin</Table.HeaderCell>
											<Table.HeaderCell textAlign={'center'}>Money Spent</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{
											skinPurchasePopularity.lastYear.length === 0 && <Table.Row>
												<Table.Cell colSpan={2} textAlign={'center'}>There is no purchase this year.</Table.Cell>
											</Table.Row>
										}
										{
											skinPurchasePopularity.lastYear.map(skin => <Table.Row key={'lastYear' + skin.skin.id}>
													<Table.Cell><Link to={View.SKIN.path + skin.skin.url}><strong>{skin.skin.title}</strong></Link></Table.Cell>
													<Table.Cell textAlign={'center'}>{Formatter.money(skin.totalPrice)}</Table.Cell>
												</Table.Row>
											)
										}
									</Table.Body>
								</Table>
							</>
						}
					</Segment>
				</Grid.Column>
			</Grid>
		</>
	);
}
