import {GraphQLScalarType} from "graphql";
import {format} from 'date-fns';

export type LocalDate = string;

export const Resolver = new GraphQLScalarType({
	name: 'LocalDate',
	description: 'LocalDate custom scalar type',
	serialize(value) {
		return value && format(value, 'yyyy-MM-dd');
	},
	parseValue(value) {
		return value && new Date(value);
	}
});
