import {ColorResult, SketchPicker} from "react-color";
import React, {useState} from "react";
import SkinColorMarker from "../../../public/skin/SkinColorMarker";

interface ColorPickerProps {
	color: string
	onChange: (color: ColorResult) => void
}

export default function ColorPicker(props: ColorPickerProps) {

	const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

	return (
		<div style={{textAlign: 'center'}}>
			<SkinColorMarker primary={props.color} onClick={() => setShowColorPicker(true)}/>
			{
				showColorPicker && <div style={{position: 'absolute', zIndex: '2'}}>
					<div style={{position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px',}} onClick={() => setShowColorPicker(false)}/>
					<SketchPicker color={props.color} onChange={props.onChange}/>
				</div>
			}
		</div>
	)
}

