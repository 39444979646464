import React, {useState} from "react";
import {Form, Grid, Header, Icon, Message, Segment, Statistic} from "semantic-ui-react";
import {useGetMyPayouts} from "../../build/generated-sources/service/QueryService";
import AuthorPayoutBatchMapper from "../../base/mapper/AuthorPayoutBatchMapper";
import PageMapper from "../../base/mapper/PageMapper";
import {PageInfo} from "../../build/generated-sources/dto/PageInfo";
import {AuthorPayoutBatchDto} from "../../build/generated-sources/dto/AuthorPayoutBatchDto";
import {Direction} from "../../build/generated-sources/enum/Direction";
import {UseGetMyPayoutsData} from "../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {AuthorPayoutBatchFilterUtil} from "../../base/util/AuthorPayoutBatchFilterUtil";
import SimpleLoader from "../../components/SimpleLoader";
import {Formatter} from "../../base/util/Formatter";
import {View} from "../../base/enums/View";
import {Link, useHistory} from "react-router-dom";
import PayoutInformation from "./components/PayoutInformation";
import {EPurchaseItemType} from "../../build/generated-sources/enum/EPurchaseItemType";
import {PayoutUtil} from "../../base/util/PayoutUtil";

export default function Payouts() {

	const history = useHistory();
	const [status, setStatus] = useState<string>('all');
	const [pageInfo, setPageInfo] = useState<PageInfo>({
		pageNumber: 0,
		pageSize: 10,
		totalPages: -1,
		totalElements: -1,
		numberOfElements: -1
	});

	const [payouts, setPayouts] = useState<AuthorPayoutBatchDto[]>([]);

	const getMyPayouts = useGetMyPayouts({
		content: AuthorPayoutBatchMapper.ALL,
		pageInfo: PageMapper.ALL
	}, {
		fetchPolicy: 'network-only',
		variables: {
			page: {
				page: pageInfo.pageNumber,
				size: pageInfo.pageSize,
				sortBy: 'createdAt',
				sortDirection: Direction.DESC
			},
			status: status
		},
		onCompleted: (data: UseGetMyPayoutsData) => {
			setPayouts(data.myPayouts.content);
			setPageInfo(data.myPayouts.pageInfo);
		},
		onError: (data: ApolloError) => {
			console.error(data.message);
		}
	})

	const handleStatusChange = (data: any) => {
		setStatus(data);
	}

	const loading = getMyPayouts.loading || !payouts;

	return (
		<>
			<Header as='h2' style={{margin: '0 0 40px'}}>
				<Icon name='money bill alternate'/>
				<Header.Content>
					My Payouts
					<Header.Subheader>Transaction history of your paid skins</Header.Subheader>
				</Header.Content>
			</Header>

			<Form>
				<Form.Select options={AuthorPayoutBatchFilterUtil.getAuthorPayoutBatchFilterOptions()}
							 style={{maxWidth: '200px'}}
							 label={'Status'}
							 onChange={(e, data) => handleStatusChange(data.value)}
							 value={status}
							 type={'text'}/>
			</Form>
			<br/>
			{
				loading && <SimpleLoader text={'Loading your payouts history...'}/>
			}
			{
				!loading && <>
					{
						payouts.length === 0 ? <Segment placeholder>
								<Header icon>
									<Icon name='search'/> There are no payouts {status !== 'all' && 'of this type'} yet. {status === 'all' && <span>Either noone bought your skin yet, or you don't have any paid skins.</span>}
								</Header>
							</Segment>
							:
							payouts.map(payout => {
								return <div style={{marginBottom: '20px'}} key={payout.id}>
									<Segment onClick={() => history.push(View.PAYOUT.path.replace(":id", payout.id.toString()))} className={'payout clickable'} color={PayoutUtil.getColor(payout.payoutStatus)}>
										<Grid stackable>
											<Grid.Column width={6}>
												<PayoutInformation payout={payout} />
											</Grid.Column>
											<Grid.Column width={10} style={{margin: 'auto'}}>
												<Statistic.Group style={{justifyContent: 'center'}}>
													<Statistic>
														<Statistic.Value>{payout.items.filter(i => i.purchaseItemType === EPurchaseItemType.BUNDLE).length}</Statistic.Value>
														<Statistic.Label>{payout.items.filter(i => i.purchaseItemType === EPurchaseItemType.BUNDLE).length === 1 ? 'Bundle' : 'Bundles'}</Statistic.Label>
													</Statistic>
													<Statistic>
														<Statistic.Value>{payout.items.filter(i => !i.inBundle).filter(i => i.purchaseItemType === EPurchaseItemType.SKIN).length}</Statistic.Value>
														<Statistic.Label>{payout.items.filter(i => !i.inBundle).filter(i => i.purchaseItemType === EPurchaseItemType.SKIN).length === 1 ? 'Skin' : 'Skins'}</Statistic.Label>
													</Statistic>

													<Statistic>
														<Statistic.Value>
															{
																payout.amount ? Formatter.money(payout.amount) : Formatter.money(payout.items.map(item => item.authorReward).reduce((a, b) => a + b, 0))
															}
														</Statistic.Value>
														<Statistic.Label>{payout.amount ? 'Total payout' : 'Estimated payout'}</Statistic.Label>
													</Statistic>
												</Statistic.Group>
											</Grid.Column>
										</Grid>
									</Segment>
								</div>
							})
					}
					<Message>
						<Icon name={'question circle'}/><strong>Do you understand the Author Payout system?</strong> You can read everything about it <Link to={View.ABOUT_AUTHOR_PAYOUTS.path}>here</Link>.
					</Message>
				</>
			}
		</>
	);
}
