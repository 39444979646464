import React, {useContext} from "react";
import {Card, Flag, Image} from "semantic-ui-react";
import {SkinInputDtoInput} from "../../../build/generated-sources/dto/SkinInputDtoInput";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {Asset} from "../../../base/dto/Asset";

interface SkinCardProps {
	skin: SkinInputDtoInput,
	image?: Asset
}

export default function SkinCardPreview(props: SkinCardProps) {

	const auth = useContext<Auth>(AuthContext);

	return (
		<Card className={'skin-overview'}>
			{
				props.image && props.image.file && <Image src={URL.createObjectURL(props.image.file)} wrapped ui={false}/>
			}
			{
				props.image && props.image.url && <Image src={props.image.url} wrapped ui={false}/>
			}
			{
				!props.image && <Image src={'/img/placeholder.jpg'} wrapped ui={false}/>
			}
			<Card.Content>
				<Card.Header style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: props.skin.title ? 'inherit' : 'lightgrey'}}>
					{props.skin.title ? props.skin.title : 'My new awesome skin'}
				</Card.Header>
				<Card.Meta>
					<Flag name={auth.country as any} /> <span className='date'>{auth.username}</span>
				</Card.Meta>
			</Card.Content>
		</Card>
	);
}
