import React from 'react';
import {Card, Icon, Image, Popup, SemanticCOLORS} from "semantic-ui-react";
import {Asset} from "../../../base/dto/Asset";

interface SkinAssetContainerProps {
	asset: Asset
	validate: boolean
	onRemove: (asset : Asset) => void
}

export default function SkinAssetContainer(props : SkinAssetContainerProps) {
	const getColor = () : SemanticCOLORS => {
		if (props.asset.uploading) {
			return 'grey';
		}
		if (props.asset.success) {
			return 'green';
		} else if (!props.asset.success) {
			return 'red';
		}
	}

	const getErrorMessage = () => {
		switch (props.asset.message) {
			case 'UNKNOWN_FILENAME':
			case 'INVALID_FILENAME':
				return 'Invalid file. Only JPG and PNG images are allowed.'
			case 'IMAGE_TOO_LARGE':
				return 'Image is too large.'
			default:
				return 'There was an error uploading the image. Remove it and try again.'
		}
	}

	const uploading = props.asset.uploading;
	const uploadedSuccess = !uploading && props.asset.success;
	const uploadedError = !uploading && !props.asset.success;
	const message = getErrorMessage();

	return (
		<Card style={(props.validate && uploadedError) ? {boxShadow: '0 0 2px 2px red', width: '100px', borderRadius: '0'} : {width: '100px', borderRadius: '0'}} color={getColor()}>
			{props.asset.file && <Image size={'tiny'} src={URL.createObjectURL(props.asset.file)} wrapped ui={false} />}
			{props.asset.url && <Image size={'tiny'} src={props.asset.url} wrapped ui={false} />}
			<Card.Content extra style={{display: 'flex', justifyContent: 'center', padding: '4px'}}>
				{
					uploading && <div><Icon name={'circle notch'} loading /></div>
				}
				{
					uploadedSuccess && <Popup
						position={'top center'}
						trigger={<div><Icon name={'checkmark'} color={'green'}/></div>}
						content={'Uploaded'}
					/>
				}
				{
					uploadedError && <Popup
						position={'top center'}
						trigger={<div><Icon name={'close'} color={'red'} /></div>}
						content={message}
					/>
				}
				<div style={{width: '90px'}}></div>
				<Popup
					position={'top center'}
					trigger={<div><Icon className={'clickable opacity-on-hover'} float={'right'} color={'red'} name={'trash'} onClick={() => props.onRemove(props.asset)}/></div>}
					content={'Remove'}
				/>
			</Card.Content>
		</Card>
	);
}
