
export class Constant {
	public static LOCAL_STORAGE_KEY_SKINS : string = 'cs';
	public static LOCAL_STORAGE_KEY_BUNDLES : string = 'cb';
	public static WEBSITE_URL : string = 'https://trackmania-skins.com';
	public static WEBSITE_URL_SHORT : string = 'trackmania-skins.com';
	public static WEBSITE_TITLE_SIMPLE : string = 'Trackmania Skins';
	public static WEBSITE_TITLE : string = 'Trackmania-Skins.com';
	public static WEBSITE_EMAIL : string = 'info@trackmania-skins.com';
	public static WEBSITE_OPERATOR : string = 'Trackmania-Skins.com';
	public static TWITTER_URL : string = 'https://twitter.com/TrackmaniaSkins';
	public static INSTAGRAM_URL : string = 'https://instagram.com/trackmaniaskins';
	public static DISCORD_URL : string = 'https://discord.com/???';
	public static PAGE_LENGTH_SKINS : number = 15;
	public static PAGE_LENGTH_AUTHORS : number = 16;
	public static PAGE_LENGTH_COMMENTS : number = 10;
	public static INTERNAL_FEE_PERCENTAGE : number = 2;
	public static STRIPE_FEE_PERCENTAGE_EU : number = 1.5;
	public static STRIPE_FEE_PERCENTAGE_GB : number = 2.5;
	public static STRIPE_FEE_PERCENTAGE_OTHER : number = 3.25;
	public static STRIPE_FEE_FIXED : number = 0.3;
	public static MIN_PAYOUT_AMOUNT : number = 50;
	public static MIN_SKIN_PRICE : number = 2;
	public static MIN_SKIN_NAME_LENGTH : number = 4;
	public static MAX_SKIN_NAME_LENGTH : number = 60;
	public static MAX_SKIN_DESCR_LENGTH : number = 200;
	public static MAX_SKIN_PRICE : number = 100;
	public static MAX_SKIN_SOURCE_SIZE_MB : number = 25;
	public static MAX_SKIN_SOURCE_SIZE : number = 26214400;
	public static MAX_SKIN_IMAGE_SIZE : number = 8000000;
	public static MAX_SKIN_IMAGE_SIZE_MB : number = Math.round(Constant.MAX_SKIN_IMAGE_SIZE / 1000000);
	public static SEARCH_QUERY_TYPING_DELAY : number = 600;
	public static MAIL_REGEX : string = "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$";
}
