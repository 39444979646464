import {AuthorPayoutBatchDtoMapper} from "../../build/generated-sources/dto/AuthorPayoutBatchDto";

export default class AuthorPayoutBatchMapper {

	public static ALL: AuthorPayoutBatchDtoMapper = {
		id: true,
		amount: true,
		estimatedPayoutDate: true,
		externalId: true,
		payoutStatus: true,
		sentOn: true,
		payoutMethod: true,
		sendToIban: true,
		sendToBic: true,
		sendToPaypalAccount: true,
		createdAt: true,
		items: {
			id: true,
			name: true,
			price: true,
			authorReward: true,
			tip: true,
			internalFee: true,
			externalFee: true,
		  	inBundle: true,
			purchaseItemType: true,
		}
	}
}
