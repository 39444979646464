import React from "react";
import {Accept, DropEvent, FileRejection, useDropzone} from "react-dropzone";
import {Constant} from "../../../base/enums/Constant";
import {Asset} from "../../../base/dto/Asset";

interface DropzoneProps {
	accept: Accept
	validate: boolean
	images: Asset[]
	onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
}

export default function Dropzone(props: DropzoneProps) {

	const {
		getRootProps,
		getInputProps,
	} = useDropzone({onDrop: props.onDrop, accept: props.accept, maxSize: Constant.MAX_SKIN_IMAGE_SIZE, maxFiles: 10, onDropRejected: fileRejections => {console.log(fileRejections)}});

	return (
		<section className="dropzone-container">
			<div style={props.validate && props.images.length === 0 ? {borderColor: '#9F3A38', backgroundColor: '#FFF6F6'} : {}}
				 className={'file-upload dropzone'} {...getRootProps()}>
				<input {...getInputProps()} />
				<p>Drag 'n' drop <strong>up to 10 more</strong> screenshots here to show your artwork from all angles and all color combinations (if there are
					more). <strong>First image will be used as a thumbnail</strong>. Make sure your pictures meet these criteria:</p>
				<ul>
					<li><strong>Up to 10 screenshots</strong></li>
					<li><strong>Each image can have up to {Constant.MAX_SKIN_IMAGE_SIZE_MB} MB in size.</strong></li>
					<li><strong>Allowed format: JPG or PNG</strong></li>
				</ul>
				<p>This is to ensure page loads fast and doesn't kill limits of our mobile data viewers.</p>
			</div>
		</section>
	)
}
