import {SkinRatingDtoMapper} from "../../build/generated-sources/dto/SkinRatingDto";

export default class SkinRatingMapper {

	public static ALL: SkinRatingDtoMapper = {
		average: true,
		totalRatings: true,
		usersRating: true
	}
}
