import {RoleDtoMapper} from "../../build/generated-sources/dto/RoleDto";

export default class RoleMapper {

	public static ALL: RoleDtoMapper = {
		id: true,
		name: true,
		description: true,
	}
}
