export class VideoUtil {

	public static getVideoCode = (url : string) : string | undefined  => {
		if (url) {
			let videoRegex = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
			if (videoRegex && videoRegex.length > 1) {
				return videoRegex[1];
			}
		}
	}
}
