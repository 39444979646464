import React, {useEffect, useMemo, useState} from "react";
import {Card, Container, Form, Image, Button, Icon} from "semantic-ui-react";
import {useLazyGetAuthor, useLazyGetSkin} from "../../../build/generated-sources/service/QueryService";
import SkinMapper from "../../../base/mapper/SkinMapper";
import {UseGetAuthorData, UseGetSkinData} from "../../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {SkinDto} from "../../../build/generated-sources/dto/SkinDto";
import SimpleLoader from "../../../components/SimpleLoader";
import {SocialMediaCreateRequestDtoInput} from "../../../build/generated-sources/dto/SocialMediaCreateRequestDtoInput";
import {EResourceType} from "../../../build/generated-sources/enum/EResourceType";
import {ResourceDto} from "../../../build/generated-sources/dto/ResourceDto";
import {usePublishSkinOnInstagram, usePublishSkinOnTwitter} from "../../../build/generated-sources/service/MutationService";
import {UsePublishSkinOnInstagramData, UsePublishSkinOnTwitterData} from "../../../build/generated-sources/service/MutationServiceModel";
import {toast} from "react-hot-toast";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import {View} from "../../../base/enums/View";
import {Constant} from "../../../base/enums/Constant";
import {useLocation} from "react-router-dom";
import UserMapper from "../../../base/mapper/UserMapper";
import {QueryUtil} from "../../../base/util/QueryUtil";
import {UserDto} from "../../../build/generated-sources/dto/UserDto";

interface TagButton {
	icon: string
	label: string
	content: string
}

export default function PostToSocialMedia() {
	const location = useLocation();
	const [skinUrl] = useState<string>(QueryUtil.getParam(location.search, 's'));
	const [authorUrl] = useState<string>(QueryUtil.getParam(location.search, 'a'));
	const [skin, setSkin] = useState<SkinDto>();
	const [author, setAuthor] = useState<UserDto>();
	const [images, setImages] = useState<ResourceDto[]>([]);
	const [publishOnInstagram, setPublishOnInstagram] = useState<boolean>(true);
	const [publishOnTwitter, setPublishOnTwitter] = useState<boolean>(true);
	const [request, setRequest] = useState<SocialMediaCreateRequestDtoInput>({
		message: "",
		imageIds: [],
		authorId: 0
	});
	const [instagramSyncState, setInstagramSyncState] = useState<SyncOperationState>({
		inProgress: false,
		error: false,
		done: false
	});
	const [twitterSyncState, setTwitterSyncState] = useState<SyncOperationState>({
		inProgress: false,
		error: false,
		done: false
	});

	const [getSkin] = useLazyGetSkin(SkinMapper.ALL, {
			fetchPolicy: 'network-only',
			onCompleted: (data: UseGetSkinData) => {
				setSkin(data.skin);
				setRequest({
					...request,
					authorId: data.skin.author.id
				})
				setAuthor(data.skin.author);
				setImages(data.skin.resources.filter(resource => resource.type === EResourceType.IMAGE));
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	const [getAuthor] = useLazyGetAuthor(UserMapper.AUTHOR, {
			fetchPolicy: 'network-only',
			onCompleted: (data: UseGetAuthorData) => {
				setRequest({...request, authorId: data.author.id});
				const skinsImages : ResourceDto[] = [];
				data.author.skins.forEach(skin => {
					skin.resources
						.filter(resource => resource.type === EResourceType.IMAGE)
						.forEach(i => skinsImages.push(i));
				});
				setAuthor(data.author);
				setImages(skinsImages);
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	useEffect(() => {
		if (skinUrl) {
			getSkin({
				variables: {
					url: skinUrl
				}
			});
		}
	}, [skinUrl, getSkin]);

	useEffect(() => {
		if (authorUrl) {
			getAuthor({
				variables: {
					url: authorUrl
				}
			});
		}
	}, [authorUrl, getAuthor]);

	const [publishInstagram] = usePublishSkinOnInstagram({
		data: true,
		message: true,
		type: true
	}, {
		onCompleted: (data: UsePublishSkinOnInstagramData) => {
			setInstagramSyncState({inProgress: false, error: false, done: true});
		},
		onError: (data: ApolloError) => {
			setInstagramSyncState({inProgress: false, error: true, done: false});
		}
	});

	const [publishTwitter] = usePublishSkinOnTwitter({
		data: true,
		message: true,
		type: true
	}, {
		onCompleted: (data: UsePublishSkinOnTwitterData) => {
			setTwitterSyncState({inProgress: false, error: false, done: true});
		},
		onError: (data: ApolloError) => {
			setTwitterSyncState({inProgress: false, error: true, done: false});
		}
	});

	const publish = () => {
		if (publishOnInstagram) {
			setInstagramSyncState({
				inProgress: true,
				error: false,
				done: false
			});
			toast.promise(
				publishInstagram({
					variables: {
						dto: request
					}
				}),
				{
					loading: 'Publishing the skin to Trackmania-Skins.com Instagram page...',
					success: <strong>Success!</strong>,
					error: <strong>There was an error uploading skin to Instagram.</strong>,
				}
			);
		}
		if (publishOnTwitter) {
			setTwitterSyncState({
				inProgress: true,
				error: false,
				done: false
			});
			toast.promise(
				publishTwitter({
					variables: {
						dto: request
					}
				}),
				{
					loading: 'Publishing the skin to Trackmania-Skins.com Instagram page...',
					success: <strong>Success!</strong>,
					error: <strong>There was an error uploading skin to Instagram.</strong>,
				}
			);
		}
	}

	const onFieldChange = (value: any, field: string) => {
		setRequest({
			...request,
			[field]: value
		});
	}

	const onImageChange = (image : ResourceDto) => {
		let selectedImages: number[] = Object.assign([], request.imageIds);
		if (request.imageIds.includes(image.id)) {
			setRequest({
				...request,
				imageIds: selectedImages.filter(i => i !== image.id)
			});
		} else {
			selectedImages.push(image.id);
			setRequest({
				...request,
				imageIds: selectedImages
			});
		}
	}

	const onChange = (e: any, field: string) => {
		onFieldChange(e.target.value, field);
	}

	const tags : TagButton[] = useMemo(() => {
		const tags : TagButton[] = [];
		if (skin) {
			tags.push({icon: 'car', label: 'Name', content: skin.title});
			tags.push({icon: 'linkify', label: 'Skin Page URL', content: Constant.WEBSITE_URL + View.SKIN.path + skin.url});
		}
		if (author) {
			tags.push({icon: 'linkify', label: 'Author Page URL', content: Constant.WEBSITE_URL + View.AUTHOR.path + author.url});
		}
		tags.push(
			{icon: 'user', label: '{author}', content: '{author}'},
			{icon: 'user', label: '#trackmania', content: '#trackmania'},
			{icon: 'user', label: '#trackmaniaskins', content: '#trackmaniaskins'},
		);
		return tags;
	}, [author, skin]);

	if (images.length === 0) {
		return (<SimpleLoader text={'Loading images...'}/>);
	}

	return (
		<Container>
			<Form>
				<Form.Input type={'text'} label={"Message (" + request.message.length + "/260)"}
						value={request.message} onChange={(e) => {
						onChange(e, 'message')
				}}/>
				<Form.Group style={{margin: '1em 0'}}>
					{
						tags && tags.map(tag =>
							<Button icon={tag.icon ? tag.icon : undefined}
									key={tag.label}
									color={'yellow'}
									size={'tiny'}
									content={tag.label}
									onClick={() => {
										const msg = (request.message.length === 0 || request.message.endsWith(' ')) ?
											request.message + tag.content : request.message + ' ' + tag.content
										setRequest({...request, message: msg});
									}}>
							</Button>
						)
					}
				</Form.Group>

				<div style={{marginBottom: 10, display: 'inline-flex', alignItems: 'center'}}>
					{twitterSyncState.inProgress && <Icon name={'circle notched'} loading />}
					{twitterSyncState.done && <Icon color={'green'} name={'check'} />}
					{twitterSyncState.error && <Icon color={'red'} name={'x'} />}
					<Form.Checkbox checked={publishOnTwitter} label={'Twitter'}
								   onChange={(e, data) => setPublishOnTwitter(data.checked)}/>
				</div>
				<br/>
				<div style={{marginBottom: 10, display: 'inline-flex', alignItems: 'center'}}>
					{instagramSyncState.inProgress && <Icon name={'circle notched'} loading />}
					{instagramSyncState.done && <Icon color={'green'} name={'check'} />}
					{instagramSyncState.error && <Icon color={'red'} name={'x'} />}
					<Form.Checkbox checked={publishOnInstagram} label={'Instagram'}
								   onChange={(e, data) => setPublishOnInstagram(data.checked)}/>
				</div>
			  <Card.Group itemsPerRow={4}>
				{
					images.map(image => {
						const selected = request.imageIds.includes(image.id);
							return <Card key={image.id} style={{cursor: 'pointer'}} color={selected ? 'green' : undefined} onClick={() => onImageChange(image)}>
							<Image src={'/public/' + image.filename} wrapped ui={false} />
							<Card.Content>
								<Form.Checkbox
									checked={selected}
									label={selected ? (request.imageIds.indexOf(image.id) + 1) : 'Add'}
							   		onChange={(e, data) => {
									   		onImageChange(image)
									   }}/>
							</Card.Content>
						  </Card>
					})
				}
			  </Card.Group>
				<br/>
				<Button icon={'cloud upload'}
						color={(instagramSyncState.done && twitterSyncState.done) ? 'green' : 'black'}
						content={(instagramSyncState.done && twitterSyncState.done) ? 'Published' : 'Publish'}
						loading={instagramSyncState.inProgress || twitterSyncState.inProgress}
						disabled={(instagramSyncState.inProgress || twitterSyncState.inProgress) || (instagramSyncState.done && twitterSyncState.done)}
						onClick={() => publish()}>

				</Button>
			</Form>
		</Container>
	);
}
