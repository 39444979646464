import React, {useEffect, useMemo, useState} from "react";
import {Container, Embed, Grid, Header, Icon, Image, Segment} from "semantic-ui-react";
import {useGetBundle} from "../../../build/generated-sources/service/QueryService";
import {UseGetBundleData} from "../../../build/generated-sources/service/QueryServiceModel";
import {ApolloError} from "@apollo/client";
import {BundleDto} from "../../../build/generated-sources/dto/BundleDto";
import BundleCard from "./BundleCard";
import Lightbox from "react-image-lightbox";
import BundleMapper from "../../../base/mapper/BundleMapper";
import SimpleLoader from "../../../components/SimpleLoader";
import Comments from "../comment/Comments";
import {ResourceUtil} from "../../../base/util/ResourceUtil";
import {VideoUtil} from "../../../base/util/VideoUtil";
import {Link} from "react-router-dom";
import {View} from "../../../base/enums/View";
import SkinCard from "../skin/SkinCard";
import {Formatter} from "../../../base/util/Formatter";

interface BundleProps {
	url: string
}

export default function Bundle(props: BundleProps) {

	const [bundle, setBundle] = useState<BundleDto>();
	const [currentImg, setCurrentImg] = useState<number>();
	const [galleryOpen, setGalleryOpen] = useState<boolean>(false);
	const [galleryIndex, setGalleryIndex] = useState<number>(0);

	const getBundle = useGetBundle(BundleMapper.ALL, {
			fetchPolicy: 'network-only',
			variables: {
				url: props.url
			},
			onCompleted: (data: UseGetBundleData) => {
				setBundle(data.bundle);
			},
			onError: (data: ApolloError) => {
				console.error(data.message);
			}
		}
	);

	const savings = useMemo(() => {
		if (bundle) {
			return bundle.skins.map(s => s.price).reduce((a, b) => a + b, 0) - bundle.price;
		}
		return 0;
	}, [bundle]);

	const images = useMemo(() => {
		if (!bundle) {
			return [];
		}
		return bundle.resources.map(resource => resource.filename)
	}, [bundle]);

	useEffect(() => {
		if (images.length > 0) {
			setCurrentImg(0);
		}
	}, [images]);

	if (getBundle.loading || !bundle) {
		return (<SimpleLoader text={'Loading bundle...'}/>);
	}

	let videoCode = VideoUtil.getVideoCode(bundle.videoUrl);

	return (
		<Container>
			<Grid stackable className={'bundle'}>
				<Grid.Row>
					<Grid.Column width={5}>
						<BundleCard bundle={bundle} showDescription enableRating showPurchase/>
						{savings > 0 && <strong>By purchasing this bundle you save {Formatter.money(savings)}</strong>}
					</Grid.Column>
					<Grid.Column width={11} style={{textAlign: 'center', margin: '0 auto'}}>
						{
							!videoCode && images.length === 0 && <Image src={'/img/placeholder.jpg'}>
								<Segment placeholder>
									<Header icon>
										<Icon name='image'/>
										User didn't upload any extra screenshots or videos for this bundle.<br/><br/> Let him know you would like to see more!
									</Header>
								</Segment>
							</Image>
						}
						{
							videoCode && <Embed id={videoCode} placeholder={ResourceUtil.getThumbnailPath(bundle.resources)} source='youtube'/>
						}
						{
							!videoCode && images.length > 0 && <div>
								<div className={'hide-on-mobile'} style={{height: '440px'}}>
									{
										currentImg !== undefined ? <Image style={{cursor: 'pointer', objectFit: 'contain', margin: 'auto', maxHeight: '100%'}}
																		  src={'/public/' + images[currentImg]} fluid onClick={() => {
											setGalleryIndex(currentImg);
											setGalleryOpen(true)
										}}/>
										:
										<Image src={'/img/placeholder.jpg'} fluid />
									}
								</div>
								<Image.Group className={'gallery'} style={{display: 'flex', overflow: 'auto', justifyContent: 'center'}}>
									{
										images.map((img, i) => {
											return <Image key={i} src={'/public/' + img} onMouseEnter={() => {
												setCurrentImg(i)
											}} onClick={() => {
												setGalleryIndex(i);
												setGalleryOpen(true)
											}
											}/>
										})
									}
								</Image.Group>
							</div>
						}
						{
							galleryOpen && <Lightbox
								imageTitle={bundle.title}
								imageCaption={bundle.description}
								mainSrc={'/public/' + images[galleryIndex]}
								nextSrc={'/public/' + images[(galleryIndex + 1) % images.length]}
								prevSrc={'/public/' + images[(galleryIndex + images.length - 1) % images.length]}
								onCloseRequest={() => setGalleryOpen(false)}
								onMovePrevRequest={() =>
									setGalleryIndex((galleryIndex + images.length - 1) % images.length)
								}
								onMoveNextRequest={() =>
									setGalleryIndex((galleryIndex + 1) % images.length)
								}
							/>
						}
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<Header><Icon name={'boxes'}/> Bundle Contents ({bundle.skins.length})</Header>
			<Grid stackable columns={3}>
						{
							bundle.skins.map(skin => {
								return <Grid.Column key={skin.id} style={{marginBottom: '20px'}}>
										<Link to={View.SKIN.path + skin.url}>
										<SkinCard showRating showPurchase skin={skin} linkToSkin/>
									</Link>
								</Grid.Column>
							})
						}
			</Grid>
			<div style={{marginTop: '30px'}}>
				<Comments bundle={bundle.id} />
			</div>
		</Container>
	);
}
