import {Icon, SemanticCOLORS} from "semantic-ui-react";
import React from "react";
import {IconSizeProp} from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

interface SimpleLoaderIconProps {
	color?: SemanticCOLORS,
	size?: IconSizeProp,
	opacity?: number,
	margin?: number
}

export default function SimpleLoaderIcon(props: SimpleLoaderIconProps) {

	return (
		<Icon style={{
			opacity: props.opacity ?? '0.5',
			margin: props.margin ? props.margin + 'px' : '0'
		}}
			  size={props.size ?? 'large'}
			  color={props.color ?? 'blue'}
			  name={'circle notch'}
			  loading
		/>
	);
}
